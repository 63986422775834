import { ICurrency } from "@zenfolio/core-components/dist/models/models";
import { Utilities } from "@zenfolio/core-components/dist/utilities/utilities";
import axios from "axios";
import _ from "lodash";
import pluralize from "pluralize";
import { v4 as uuidv4 } from "uuid";
import { ISellingWelcomeStep } from "../models/orders";
import { IUserInfo } from "../models/session";
import { ActionStatus } from "../store/common";
import * as logging from "./logging";

export const isDevelopment = process.env.REACT_APP_ENVIRONMENT === "development";

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export function isEmptyGuid(guid: string | null | undefined) {
  return !guid || guid === EmptyGuid;
}

export function newGuid() {
  return uuidv4();
}

export function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(resolve, ms));
}

export function stringsAreEqualIgnoreCase(first: string | null | undefined, second: string | null | undefined) {
  return first?.toLowerCase() === second?.toLowerCase();
}

interface IRetryConfig<T> {
  /**
   * Number of additional retries to the main attempt. So it doesn't make sense to use this function with 0 retries.
   */
  retries: number;
  /**
   * Function that generates delay in milliseconds between retries. Parameter is the retry number and starts from 1.
   */
  delay: (retry: number) => number;
  /**
   * Optional log message to log retries.
   */
  logMessage?: string;
  /**
   * Asynchronous action that needs to be retried. Parameter indicates whether it's last retry or not.
   */
  action: (lastRetry: boolean) => Promise<T>;
}

export async function retry<T>(config: IRetryConfig<T>) {
  let retry = 0;

  while (true) {
    const lastRetry = retry >= config.retries;

    try {
      const result = await config.action(lastRetry);

      if (retry > 0 && config.logMessage) {
        logging.log(`${_.trim(config.logMessage)} (completed after ${pluralize("retry", retry, true)})`);
      }

      return result;
    } catch (e) {
      if (lastRetry) {
        if (retry > 0 && config.logMessage) {
          logging.error(`${_.trim(config.logMessage)} (failed after ${pluralize("retry", retry, true)})`);
        }

        return undefined;
      }

      const delay = config.delay(++retry);

      if (config.logMessage) {
        logging.log(`${_.trim(config.logMessage)} (retry #${retry} in ${pluralize("second", delay / 1000, true)})`);
      }

      await sleep(delay);
    }
  }
}

export const mappingCurrency = (code: string = "usd"): ICurrency => {
  return Utilities.getCurrencyInfo(code);
};

// see: https://stackoverflow.com/a/133997
export function postForm(path: string, params: Record<string, string>, method: string = "post") {
  const form = document.createElement("form");
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

export function buildDiff<T extends object>(oldObj: T, newObj: T): Partial<T> {
  const result: Partial<T> = {};

  for (const key in newObj) {
    if (newObj.hasOwnProperty(key) && !_.isEqual(newObj[key], oldObj[key])) {
      result[key] = newObj[key];
    }
  }

  return result;
}

export function mathMinMax(min: number, max: number, value: number): number {
  return Math.min(max, Math.max(min, value));
}

export const copyright = `© ${new Date().getFullYear()} Zenfolio, Inc.`;
export function formatPriceByCurrency(number: string | number, symbol: string = "$"): string {
  return `${symbol}${number}`;
}

export const formatPrice = (price: number, locale?: string): string => {
  return new Intl.NumberFormat(locale || "en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(price);
};

export const getFullName = (userInfo: IUserInfo): string => {
  return `${userInfo.firstName} ${userInfo.lastName}`;
};

export const titleTemplateCase = (title: string) =>
  title
    .split(/-/g)
    .map((word) => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
    .join(" ");

export const isActionCompleted = (status: ActionStatus) => {
  return status === "Success" || status === "Error";
};

export async function executeCancellableRequest<T>(
  callback: (signal?: AbortSignal) => Promise<T>,
  logMessage: string,
  signal?: AbortSignal,
  handleError?: (e: unknown) => T | undefined
) {
  if (!signal?.aborted) {
    try {
      const result = await callback(signal);

      if (!signal?.aborted) {
        return result;
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        const result = handleError?.(e);
        if (result != null) {
          return result;
        }

        logging.error(`${logMessage} failed with error:`, e);
      }
    }
  }

  return undefined;
}

export const checkIsPWAMode = () =>
  // @ts-ignore:next-line
  window.matchMedia("(display-mode: standalone").matches || window.navigator.standalone === true;

export const truncateText = (name: string, maxLength: number): string => {
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + "...";
  }
  return name;
};

/** Checks if the *element* matches the *predicate*, or it is inside an element that matches the *predicate*. */
export function isOrInsideElement(element: HTMLElement | null, predicate: (element: HTMLElement) => boolean): boolean {
  while (element) {
    if (predicate(element)) {
      return true;
    }
    element = element.parentElement;
  }
  return false;
}

export const getCompletedSellingTasksAmount = (sellingSteps: ISellingWelcomeStep[]) => {
  return sellingSteps.reduce((amount, step) => {
    const stepCompleted = step.tasks.every((task) => task.isComplete);
    return stepCompleted ? amount + 1 : amount;
  }, 0);
};

export const hasIncompleteSellingTasks = (sellingSteps: ISellingWelcomeStep[]) => {
  return _.some(
    sellingSteps.flatMap((step) => step.tasks),
    (task) => !task.isComplete
  );
};
