import AccountSettings from "./AccountSettings";
import ApproveAndDeclineBooking from "./ApproveAndDeclineBooking";
import Base from "./Base";
import BottomPanel from "./BottomPanel";
import Comment from "./Comment";
import Dialog from "./Dialog";
import FullScreen from "./FullScreen";

const Modal = {
  Base,
  Dialog,
  BottomPanel,
  FullScreen,
  ApproveAndDeclineBooking,
  AccountSettings,
  Comment,
};

export default Modal;
