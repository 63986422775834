const isDevelopment = process.env.REACT_APP_ENVIRONMENT === "development";

function formatPathPrefix(access: string) {
  return ["zg", "zf"].includes(access) ? "/" : `/${access}/`;
}

function getParamsString(previewMode: number, impersonateToken: string | null) {
  const paramPreviewMode = previewMode !== 0 ? `&pr=${previewMode}` : "";
  const suffix = `?vip=true&login_token=${impersonateToken}${paramPreviewMode}`;
  return suffix;
}

function combineURLs(baseURL: string, relativeURL: string) {
  return relativeURL ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "") : baseURL;
}

export function getPublishedSiteUrl(customDomain: string, subDomain: string) {
  if (customDomain) {
    return `${/^http(s)?:/.test(customDomain) ? customDomain : `https://${customDomain}`}`;
  }

  let url = process.env.REACT_APP_SITE_VIEWER_URL as string;
  if (!isDevelopment) {
    return url.replace("<subdomain>", subDomain);
  } else {
    const urlObject = new URL(url);
    if (urlObject.hostname !== window.location.hostname) {
      urlObject.hostname = window.location.hostname;
      url = urlObject.toString();
    }
  }

  return combineURLs(url, subDomain);
}

function getCustomDomainUrl(
  customDomain: string,
  access: string,
  albumAliasOrFolderId: string,
  params: string,
  impersonateToken: string | null
) {
  const pathPrefix = formatPathPrefix(access);
  const url = `${
    /^http(s)?:/.test(customDomain) ? customDomain : `https://${customDomain}`
  }${pathPrefix}${albumAliasOrFolderId}`;

  return impersonateToken ? `${url}${params}` : url;
}

function getDefaultDomainUrl(
  customDomain: string,
  subdomain: string,
  access: string,
  albumAliasOrFolderId: string,
  params: string,
  impersonateToken: string | null
) {
  const baseUrl = getPublishedSiteUrl(customDomain, subdomain);
  const pathPrefix = formatPathPrefix(access);
  const url = combineURLs(baseUrl, `${pathPrefix}${albumAliasOrFolderId}`);
  return impersonateToken ? `${url}${params}` : url;
}

function getShareUrl(
  customDomain: string,
  subdomain: string,
  albumAliasOrFolderId: string,
  impersonateToken: string | null,
  previewMode: number,
  accessPath: string
) {
  const params = getParamsString(previewMode, impersonateToken);

  if (customDomain) {
    return getCustomDomainUrl(customDomain, accessPath, albumAliasOrFolderId, params, impersonateToken);
  }

  return getDefaultDomainUrl(customDomain, subdomain, accessPath, albumAliasOrFolderId, params, impersonateToken);
}

export function getShareGalleryUrl(
  customDomain: string,
  subdomain: string,
  albumAlias: string,
  impersonateToken: string | null = null,
  useClientPortal = false,
  previewMode = 0
) {
  const access = useClientPortal ? "cp" : "zg";
  return getShareUrl(customDomain, subdomain, albumAlias, impersonateToken, previewMode, access);
}
