import { useEffect } from "react";
import { connect } from "react-redux";
import { ConnectionManager } from "../../../api/connectionManager";
import { hubType } from "../../../api/hubType";
import { Dispatch } from "../../../store/store";

const mapDispatch = (dispatch: Dispatch) => ({
  onSiteEditorHubConnected: () => dispatch.session.siteEditorHubConnected(),
  onSiteEditorHubDisconnected: () => dispatch.session.siteEditorHubDisconnected(),
  onNotificationReceived: (type: string, payload: unknown) =>
    dispatch.session.dispatchNotificationAsync({ type, payload }),
});

type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = DispatchProps;

function SiteEditorHub(props: Props) {
  const { onSiteEditorHubConnected, onSiteEditorHubDisconnected, onNotificationReceived } = props;

  useEffect(onMount, [onSiteEditorHubConnected, onSiteEditorHubDisconnected, onNotificationReceived]);

  function onMount() {
    ConnectionManager.getInstance(hubType.siteEditor).connect({
      onConnect: onSiteEditorHubConnected,
      onReconnect: onSiteEditorHubConnected,
      onError: onSiteEditorHubDisconnected,
      onNotificationReceived: onNotificationReceived,
    });

    return function onUnmount() {
      ConnectionManager.getInstance(hubType.siteEditor).disconnect();
    };
  }

  return null;
}

export default connect(null, mapDispatch)(SiteEditorHub);
