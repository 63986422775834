import IIConProps from "../IIconProps";

const IconReport = (props: IIConProps) => {
  const color = props.color || "#1E1F22";
  const width = props.width || 24;
  const height = props.width || 24;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g stroke={color} strokeWidth="1.5" fill="none" fillRule="evenodd">
        <path d="m6.5 12.75.25 7.5h-4.5V13l4.25-.25zM14 2.75l.25 17.5h-4.5V3L14 2.75zM21.5 7.75l.25 12.5h-4.5V8l4.25-.25z" />
        <path strokeLinecap="round" d="M1 23.25h22" />
      </g>
    </svg>
  );
};

export default IconReport;
