import { IFRAME_BACK_TO, iframeParams } from "../../../models/iframe";
import { addQueryParams } from "../../../utilities/query";

/** Url manager for more menu. */
export default class UrlManager {
  public static generateDynamicRoute(
    pagePath: string,
    backTo?: IFRAME_BACK_TO | null,
    backUrl?: string | null,
    iframePath?: string | null,
    iframeRoot?: string | null
  ): string {
    return addQueryParams(this.prefix + pagePath, {
      [iframeParams.backTo]: backTo,
      [iframeParams.backUrl]: backUrl,
      [iframeParams.path]: iframePath,
      [iframeParams.root]: iframeRoot,
    });
  }

  public static generateRouteBackFromIFrame(selectedLinkName: string, pendo?: string): string {
    return addQueryParams(this.prefix, {
      [this.selectedLinkParam]: selectedLinkName,
      [this.pendoParam]: pendo,
    });
  }

  public static readonly selling = "/selling";
  public static readonly reports = "/reports";
  public static readonly crm = "/crm";
  public static readonly bookings = "/bookings";
  public static readonly website = "/website";
  public static readonly blog = "/blog";
  public static readonly addons = "/addons";

  public static readonly bookingsIframe = "/bookme";
  public static readonly crmIframe = "/comm-center";
  public static readonly addonsIframe = "/addons";
  public static readonly toolsIframe = "/tools";

  public static readonly prefix = "/more";

  public static readonly selectedLinkParam = "selectedLink";
  public static readonly pendoParam = "pendo";

  public static readonly route = `${UrlManager.prefix}/*`;
}
