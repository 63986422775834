export enum SegmentOnBoardingEventName {
  personalizeAccount = "personalize account",
  typesOfPhotography = "types of photography",
  primaryShootType = "primary shoot type",
  whatAreYourGoals = "what are your goals",
  selectPhotos = "select photos",
  uploadPhotos = "upload photos",
  galleryOrWebsiteFork = "gallery or website fork",
  chooseSitePreview = "choose a site - preview",
  chooseSiteSelect = "choose a site - select",
  redirectToWebsiteBuilder = "redirect to website builder",
}
