import React, { Suspense, useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import BaseModal from "../../../ui/Modal/Base";
import Loading from "../../../features/Loading";
import Close from "../../../ui/Modal/Close";
import { NewCheckoutModalProps } from "./CheckoutForm";
import styles from "./checkout.module.scss";
import { Dispatch, RootState } from "../../../../store/store";

const NewCheckoutForm = React.lazy(() => import("../../../ui/Modal/CheckoutModal/CheckoutForm"));

interface IOwnProps {
  open: boolean;
  onClose: () => void;
  params: Partial<NewCheckoutModalProps> | null;
  className?: string;
}

type IMappedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;
type IProps = IOwnProps & IMappedProps;

function CheckoutModal(props: IProps) {
  const {
    params,
    className,
    open,
    onClose,
    userInfo,
    activePlans,
    bookmeBundleInfo,
    stripeProvider,
    checkoutDepsLoaded,
    getActivePlans,
    getBookmeBundleInfo,
    getStripeProvider,
  } = props;

  const initCheckoutDeps = async () => {
    await Promise.all([
      activePlans || getActivePlans(userInfo.planCode),
      bookmeBundleInfo || getBookmeBundleInfo(),
      stripeProvider || getStripeProvider(),
    ]);
  };

  useEffect(() => {
    if (open) initCheckoutDeps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onCloseCheckout = () => {
    // TODO: handle more cases
    onClose();
  };

  const onCheckoutSuccess = useCallback(() => {
    // TODO: better implement this
    window.location.href = "/";
  }, []);

  const showSubModal = useCallback(() => {
    // TODO: open other modals
  }, []);

  const modalProps = useMemo(() => {
    if (!params?.planCode && userInfo.isUserTrial) {
      return { ...params, planCode: userInfo.planCode };
    }
    return params || {};
  }, [params, userInfo]);

  return (
    <BaseModal
      open={open}
      onClose={onCloseCheckout}
      animationMode="none"
      className={cx(styles.modal, className)}
      overlayClassName={styles.modalOverlay}
    >
      <Close onClick={onCloseCheckout} />
      <div className={styles.header}>SUBSCRIBE TO ZENFOLIO</div>
      {!checkoutDepsLoaded ? (
        <Loading />
      ) : (
        userInfo &&
        activePlans &&
        bookmeBundleInfo &&
        stripeProvider && (
          <Suspense fallback={<Loading />}>
            <NewCheckoutForm
              className={styles.content}
              modalProps={modalProps}
              accountSettings={userInfo}
              activePlans={activePlans}
              bookmeBundleInfo={bookmeBundleInfo}
              stripeProvider={stripeProvider}
              promotionForDeepLink={undefined} // TODO: implement deeplink
              onCheckoutSuccess={onCheckoutSuccess}
              showPlanModal={showSubModal}
              showPrivacyPolicyModal={showSubModal}
              showTermsOfUseModal={showSubModal}
            />
          </Suspense>
        )
      )}
    </BaseModal>
  );
}

const mapState = (state: RootState) => ({
  deviceId: state.session.deviceId,
  userInfo: state.session.userInfo!,
  activePlans: state.session.activePlans,
  bookmeBundleInfo: state.session.bookmeBundleInfo,
  stripeProvider: state.session.stripeProvider,
  checkoutDepsLoaded:
    (state.session.getActivePlans.status === "Error" || state.session.getActivePlans.status === "Success") &&
    (state.session.getBookmeBundleInfo.status === "Error" || state.session.getBookmeBundleInfo.status === "Success") &&
    (state.session.getStripeProvider.status === "Error" || state.session.getStripeProvider.status === "Success"),
});

const mapDispatch = (dispatch: Dispatch) => ({
  getActivePlans: (currentPlanCode: string) => dispatch.session.getActivePlansAsync({ currentPlanCode }),
  getBookmeBundleInfo: () => dispatch.session.getBookmeBundleInfoAsync(),
  getStripeProvider: () => dispatch.session.getStripeProviderAsync(),
});

export default connect(mapState, mapDispatch)(CheckoutModal) as React.ComponentType<IOwnProps>;
