export interface IUserSubscriptionDto {
  subscriptionId: string;
  isSubscriptionExisted: boolean;
  token: string;
}

export interface IPromotionCampaignDto {
  id: string;
  description: string;
  mobileDescription?: string;
  promotionId: string;
  promotionCode: string;
  promotionType: string;
  amount: number;
  startDate: Date;
  endDate: Date;
  promotionCampaignPlans: PromotionCampaignPlan[];
  isTrialUser: boolean;
  promotionValidities: PromotionValidity[];
  definitionOfPlans: string;
  disclaimer: string;
  subscriptionType: SubscriptionType;
  from?: number;
  to?: number;
  currencies?: string[];
  isCurrencyIntlExist?: boolean;
  isCurrencyUSExist?: boolean;
}

export enum SubscriptionType {
  None = 0,
  Trial = 1,
  Paid = 2,
  Both = 3,
}

export interface IPromotionCampaignWrapperDto {
  promotionCampaigns: IPromotionCampaignDto[];
}

export interface PromotionCampaignPlan {
  id: string;
  planCode: string;
}

export interface PromotionValidity {
  id: string;
  type: number;
  description: string;
}

export interface IActivePlansDto {
  pageableContext: IPageableContextDto;
  plans: ISubscriptionPlanDto[];
}

export interface IPageableContextDto {
  totalItems: number;
  totalPages: number;
  firstItem: number;
  lastItem: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  pageNumber: number;
  pageSize: number;
}

export interface ISubscriptionPlanDto {
  id: string;
  code: string;
  rank: number;
  name: string;
  displayName: string;
  planPeriods: IPlanPeriodDto[];
  period: string;
  charge: string;
  tier: string;
  status: boolean;
  regionType: RegionType;
  planCountries: IPlanCountryDto[];
}

export interface IPlanCountryDto {
  name: string;
  code: string;
  region: string;
  currencyCode: string;
  id: string;
  dateCreated: Date;
  dateModified: null;
  userCreated: null;
  userModified: null;
}

export interface IPlanPeriodDto {
  id: string;
  chargePeriod: number;
  periodMultiplier: number;
  currencyId: string;
  currency: string;
  charge: number;
  iterations: null;
  nextPeriodId: null;
  isActive: boolean;
}

export enum RegionType {
  Country = 0,
  International = 1,
  Europe = 2,
}
