import { pick } from "lodash";
import { createSelector } from "reselect";
import { IN_PROGRESS_STATES } from "../../../store/backgroundJobs/constants";
import { totalUploadProgress } from "../../../store/backgroundJobs/helpers";
import { BackgroundJob, JobState, JobType } from "../../../store/backgroundJobs/models";
import { nonParentUploadJobsSelector, parentJobsSelector } from "../../../store/backgroundJobs/selectors";

type FailedInfo = {
  state: BackgroundJob["state"];
  name: string;
  errorMessage: string;
};

type RootJobBase = {
  id: string;
  name: string;
  albumId: string;
  state: JobState;
  cancelable: boolean;
  totalItemsCount: number;
  completedItemsCount: number;
  queuedItemsCount: number;
  progress: number;
  failedJobsInfo?: FailedInfo[];
  isAlbum: boolean;
  thumbnail?: string;
  skippedItemsCount: number;
  timeRemaining?: number;
  failedItemsCount: number;
};

type UploadJob = RootJobBase & {
  jobType: JobType.MediaUpload;
  canceledItemsCount: number;
};

type InstagramUploadJob = RootJobBase & {
  jobType: JobType.InstagramUpload;
};

type RootJob = UploadJob | InstagramUploadJob;

const mapMediaUploadJobToRootJob = (parentJob: BackgroundJob, childJobs: BackgroundJob[]): RootJob => {
  const totalProgress = totalUploadProgress(childJobs);

  const failedJobs = childJobs
    .filter((job) => job.state === JobState.Error)
    .map((job) => ({
      state: job.state,
      errorMessage: job.progressStatusText || "Error processing file",
      name: job.description,
    }));

  return childJobs.reduce(
    (rootJob: RootJob, job: BackgroundJob) => {
      if (rootJob.jobType !== JobType.MediaUpload) {
        return rootJob;
      }
      const completedItemsCount = rootJob.completedItemsCount + (job.state === JobState.Completed ? 1 : 0);
      const totalItemsCount = rootJob.totalItemsCount + (job.state === JobState.Duplicate ? 0 : 1);
      const skippedItemsCount = rootJob.skippedItemsCount + (job.state === JobState.Duplicate ? 1 : 0);
      const canceledItemsCount = rootJob.canceledItemsCount + (job.state === JobState.Canceled ? 1 : 0);

      return {
        ...rootJob,
        totalItemsCount,
        completedItemsCount,
        skippedItemsCount,
        failedItemsCount: rootJob.failedItemsCount + (job.state === JobState.Error ? 1 : 0),
        queuedItemsCount: rootJob.queuedItemsCount + (IN_PROGRESS_STATES.has(job.state) ? 1 : 0),
        canceledItemsCount,
      };
    },
    {
      ...pick(parentJob, ["id", "jobType", "state"]),
      albumId: parentJob.galleryId,
      isAlbum: true,
      cancelable: true,
      name: parentJob.galleryName,
      thumbnail: parentJob.jobType === JobType.MediaUpload ? parentJob.objectUrl : undefined,
      progress: totalProgress,
      totalItemsCount: 0,
      completedItemsCount: 0,
      queuedItemsCount: 0,
      failedItemsCount: 0,
      skippedItemsCount: 0,
      canceledItemsCount: 0,
      timeRemaining: parentJob.remainingSec || 0,
      failedJobsInfo: failedJobs,
    } as RootJob
  );
};

const mapInstagramUploadJobToRootJob = (job: BackgroundJob): InstagramUploadJob | null => {
  if (job.jobType !== JobType.InstagramUpload) {
    return null;
  }
  const failedJobs =
    job.state === JobState.Error
      ? job.files
          .filter((file) => file.state === JobState.Error)
          .map((file) => ({
            state: file.state,
            errorMessage: "Error processing file",
            name: file.name,
          }))
      : [];

  const totalItemsCount = job.files.length;
  const completedItemsCount = job.state === JobState.Completed ? job.files.length : job.completedCount;
  const progress = totalItemsCount > 0 ? Math.min(completedItemsCount / totalItemsCount, 100) * 100 : 0;
  const skippedItemsCount =
    job.state === JobState.Duplicate
      ? job.files.reduce((total, file) => {
          if (file.state === JobState.Duplicate) {
            total++;
          }
          return total;
        }, 0)
      : 0;
  const failedItemsCount =
    job.state === JobState.Error
      ? job.files.reduce((total, file) => {
          if (file.state === JobState.Error) {
            total++;
          }
          return total;
        }, 0)
      : 0;

  return {
    ...pick(job, ["id", "jobType", "state"]),
    albumId: job.galleryId,
    isAlbum: true,
    cancelable: false,
    name: job.galleryName,
    totalItemsCount,
    completedItemsCount,
    queuedItemsCount: totalItemsCount - completedItemsCount,
    progress,
    skippedItemsCount,
    failedJobsInfo: failedJobs,
    failedItemsCount,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultPredicate = (_val: any): boolean => true;

const createGalleryJobsSelector = (parentJobFilter = defaultPredicate) =>
  createSelector([parentJobsSelector, nonParentUploadJobsSelector], (parentJobs, nonParentUploadJobs) => {
    return parentJobs
      .map((job: BackgroundJob) => {
        const uploadJobsForAlbumJob = nonParentUploadJobs.filter(({ parentId }) => parentId === job.id);

        return {
          ...job,
          children: uploadJobsForAlbumJob,
        };
      })
      .filter((job) => job.children.length > 0 || job.jobType === JobType.InstagramUpload)
      .map((job) =>
        job.jobType === JobType.InstagramUpload
          ? mapInstagramUploadJobToRootJob(job)
          : mapMediaUploadJobToRootJob(job, job.children)
      )
      .filter(parentJobFilter);
  });

export const galleryJobsSelector = createGalleryJobsSelector(defaultPredicate);
export const queuedJobsSelector = createGalleryJobsSelector((job: RootJob) => job.queuedItemsCount > 0);

export const completedJobsSelector = createGalleryJobsSelector((job: RootJob): boolean => job.queuedItemsCount === 0);

export const failedJobsSelector = createGalleryJobsSelector((job: RootJob) => job.failedItemsCount > 0);
