/** Url manager for Notifications Tab. */
export default class UrlManager {
  public static readonly prefix = "/notifications";
  public static readonly ordersToApprove = "/orders-to-approve";
  public static readonly bookingsToApprove = "/bookings-to-approve";
  public static readonly upcomingBookings = "/upcoming-bookings";
  public static readonly respondToComments = "/respond-to-comments";
  public static readonly ordersToApproveRoute = this.prefix + this.ordersToApprove;
  public static readonly bookingsToApproveRoute = this.prefix + this.bookingsToApprove;
  public static readonly upcomingBookingsRoute = this.prefix + this.upcomingBookings;
  public static readonly respondToCommentsRoute = this.prefix + this.respondToComments;
  public static readonly route = `${UrlManager.prefix}/*`;
}
