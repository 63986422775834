import qs from "qs";
import { searchApi } from "..";
import {
  IFilteredGalleriesRequest,
  IFilteredGalleriesResponse,
  IFindTopResultsByText,
  ILastViewedResponse,
  SearchItemType,
} from "../../models/search";
import {
  map_IFilteredGalleriesResponseDto_To_IFilteredGalleriesResponse,
  map_IFindTopResultsByTextDto_To_IFindTopResultsByText,
} from "./mappers";
import { IFilteredGalleriesResponseDto, IFindTopResultsByTextDto, ILastViewedResponseDto } from "./models";

export interface IApiSearch {
  getLastViewed: (type?: SearchItemType) => Promise<ILastViewedResponse>;
  findTopResultsByText: (query: string, type?: SearchItemType) => Promise<IFindTopResultsByText>;
  getFilteredGalleries: (
    request: IFilteredGalleriesRequest,
    sendEvent?: boolean
  ) => Promise<IFilteredGalleriesResponse>;
}

const search: IApiSearch = {
  getLastViewed: (type: SearchItemType = SearchItemType.Galleries) =>
    searchApi
      .get<ILastViewedResponseDto>("top-results/last-viewed", {
        params: {
          type,
        },
      })
      .then((res) => res.data),
  findTopResultsByText: (query: string, type: SearchItemType = SearchItemType.Galleries) =>
    searchApi
      .get<IFindTopResultsByTextDto>("top-results/search/by-text", {
        params: { query, type },
      })
      .then((res) => map_IFindTopResultsByTextDto_To_IFindTopResultsByText(res.data)),
  getFilteredGalleries: (request: IFilteredGalleriesRequest) =>
    searchApi
      .get<IFilteredGalleriesResponseDto>("galleries/search", {
        params: { ...request, query: request.query || undefined },
        paramsSerializer: {
          serialize: (params) => {
            return qs.stringify(params);
          },
        },
      })
      .then((res) => map_IFilteredGalleriesResponseDto_To_IFilteredGalleriesResponse(res.data)),
};

export default search;
