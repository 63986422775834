import { createSelector } from "reselect";
import { RootState } from "../store";
import { IN_PROGRESS_STATES } from "./constants";
import { JobState, JobType } from "./models";

export const parentJobsSelector = createSelector([(state: RootState) => state.backgroundJobs.jobs], (jobs) =>
  jobs.filter((job) => !job.parentId)
);

export const uploadJobsSelector = createSelector([(state: RootState) => state.backgroundJobs.jobs], (jobs) =>
  jobs.filter(({ jobType }) => jobType === JobType.MediaUpload)
);

export const parentUploadJobsSelector = createSelector([uploadJobsSelector], (jobs) =>
  jobs.filter((job) => !job.parentId)
);

export const nonParentUploadJobsSelector = createSelector([uploadJobsSelector], (jobs) =>
  jobs.filter(({ jobType }) => jobType === JobType.MediaUpload).filter((job) => !!job.parentId)
);

export const instagramUploadJobsSelector = createSelector([(state: RootState) => state.backgroundJobs.jobs], (jobs) =>
  jobs.filter(({ jobType }) => jobType === JobType.InstagramUpload)
);

export const uploadJobItemsCountSelector = createSelector([nonParentUploadJobsSelector], (jobs) =>
  Array.isArray(jobs) ? jobs.filter(({ state }) => state !== JobState.Duplicate).length : 0
);

export const instagramUploadJobItemsCountSelector = createSelector([instagramUploadJobsSelector], (jobs) =>
  jobs.reduce((total, job) => {
    if (job.jobType === JobType.InstagramUpload) {
      return total + (job.files.length || 0);
    }
    return total;
  }, 0)
);

export const totalItemCountSelector = createSelector(
  [uploadJobItemsCountSelector, instagramUploadJobItemsCountSelector],
  (uploadCount, instagramUploadCount) => uploadCount + instagramUploadCount
);

export const completedItemsCountSelector = createSelector(
  [nonParentUploadJobsSelector, instagramUploadJobsSelector],
  (nonParentUploadJobs, instagramUploadJobs) => {
    return (
      nonParentUploadJobs.filter(({ state }) => state === JobState.Completed).length +
      instagramUploadJobs.reduce((total, job) => {
        if (job.jobType === JobType.InstagramUpload) {
          return total + (job.state === JobState.Completed ? job.files.length : job.completedCount);
        }

        return total;
      }, 0)
    );
  }
);

export const totalProgressSelector = createSelector(
  [nonParentUploadJobsSelector, instagramUploadJobsSelector],
  (nonParentUploadJobs, instagramUploadJobs) => {
    const progressForUploadJobs = nonParentUploadJobs
      .filter(({ state }) => state !== JobState.Error)
      .map(({ progressStatus }) => progressStatus);

    const progressForInstagramUploadJobs = instagramUploadJobs
      .filter(({ state }) => state !== JobState.Error)
      .map(({ progressStatus }) => progressStatus);

    const allProgressStatuses = progressForUploadJobs
      .concat(progressForInstagramUploadJobs)
      .filter((status) => typeof status === "number");

    if (allProgressStatuses.length < 1) {
      return 100;
    }

    return allProgressStatuses.reduce((sum, itemProgress) => sum + itemProgress, 0) / allProgressStatuses.length;
  }
);

export const failedItemsCountSelector = createSelector(
  [instagramUploadJobsSelector, nonParentUploadJobsSelector],
  (instagramUploadJobs, uploadJobs) => {
    return (
      instagramUploadJobs
        .filter(({ state }) => state === JobState.Error)
        .map((job) => (job.jobType === JobType.InstagramUpload ? job.files : []))
        .flat()
        .filter((file) => file.state === JobState.Error).length +
      uploadJobs.filter(({ state }) => state === JobState.Error).length
    );
  }
);

export const inProgressItemsCountSelector = createSelector(
  [nonParentUploadJobsSelector, instagramUploadJobsSelector],
  (uploadJobs, instagramUploadJobs) => {
    const inProgressInstgramUploadJobs = instagramUploadJobs
      .filter(({ state }) => IN_PROGRESS_STATES.has(state))
      .map((job) => (job.jobType === JobType.InstagramUpload ? job.files : []))
      .flat()
      .filter(({ state }) => IN_PROGRESS_STATES.has(state)).length;
    const inProgressUploads = uploadJobs.filter(({ state }) => IN_PROGRESS_STATES.has(state)).length;
    return inProgressInstgramUploadJobs + inProgressUploads;
  }
);

export const totalTimeRemainingSelector = createSelector([parentUploadJobsSelector], (parentUploadJobs) => {
  return parentUploadJobs.reduce((highest, parentJob) => {
    if (typeof parentJob.remainingSec === "number") {
      return Math.max(parentJob.remainingSec, highest);
    }
    return highest;
  }, 0);
});

export const cancelableItemsCountSelector = createSelector(
  [parentUploadJobsSelector],
  (uploadJobs) => uploadJobs.filter((job) => IN_PROGRESS_STATES.has(job.state)).length
);

export const uncancelableItemsCountSelector = createSelector(
  [instagramUploadJobsSelector],
  (instagramUploadJobs) => instagramUploadJobs.length
);
