import IIConProps from "../IIconProps";

const IconSelling = (props: IIConProps) => {
  const color = props.color || "#1E1F22";
  const width = props.width || 24;
  const height = props.width || 24;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g stroke={color} fill="none" fillRule="evenodd">
        <path
          d="M11.997 1.397c2.927 0 5.577 1.186 7.495 3.104a10.568 10.568 0 0 1 3.105 7.496c0 2.928-1.186 5.578-3.104 7.496a10.567 10.567 0 0 1-7.496 3.104 10.568 10.568 0 0 1-7.496-3.105 10.567 10.567 0 0 1-3.104-7.495c0-2.927 1.186-5.577 3.105-7.495a10.567 10.567 0 0 1 7.495-3.105z"
          strokeWidth="1.75"
        />
        <path
          d="M8.998 14.605c0 1.44 1.342 2.608 2.999 2.608s3-1.167 3-2.608c0-1.44-1.343-2.608-3-2.608s-3-1.167-3-2.608c0-1.44 1.343-2.608 3-2.608s3 1.168 3 2.608M11.997 5.216v13.562"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IconSelling;
