import _ from "lodash";
import {
  IInitialGalleriesInfo,
  IInitialGallery,
  IOnBoardingProcess,
  IStep,
  OnboardingStep,
} from "../../models/onBoarding";
import { IUserInfo } from "../../models/session";
import { IGeneratedSite } from "../../models/siteEngine";
import { GenericState } from "../generic";

export const mainSteps = [
  OnboardingStep.BusinessNameAndPassword,
  OnboardingStep.ShootTypes,
  OnboardingStep.PrimaryShootType,
  OnboardingStep.Goals,
];

export const finalSteps = [OnboardingStep.SelectPhotos];

export const siteSteps = [OnboardingStep.GalleryOrWebsite, OnboardingStep.ChooseSite];

export const virtualSteps = [...finalSteps, OnboardingStep.UploadPhotos, ...siteSteps];

const initialSteps: IStep[] = _.map([...mainSteps, ...virtualSteps], (id) => ({
  id,
  isAnswered: false,
  stepAnswerOptions: [],
  stepAnswers: [],
}));

export const initialState: IOnBoardingState = {
  createInitialGallery: { status: "Init" },
  getOnBoardingProcess: { status: "Init" },
  onBoardingProcess: {
    isPasswordChanged: false,
    steps: initialSteps,
    isCompleted: false,
    isSocial: false,
  },
  stepsData: {
    businessName: "",
    shootTypes: [],
    goals: [],
  },
  sitesGenerationStartedAt: 0,
};

export interface IStepsData {
  businessName: string;
  password?: string;
  shootTypes: string[];
  primaryShootTypeId?: string;
  goals: string[];
}

/** State for the on-boarding process. */
export interface IOnBoardingState {
  galleriesInfo?: IInitialGalleriesInfo;
  createInitialGallery: CreateInitialGalleryState;
  getOnBoardingProcess: GetOnBoardingProcessState;
  onBoardingProcess: IOnBoardingProcess;
  stepsData: IStepsData;
  sites?: IGeneratedSite[];
  sitesGenerationStartedAt: number;
}

export type CreateInitialGalleryState = GenericState<ICreateInitialGalleryInput>;
export type GetOnBoardingProcessState = GenericState;

export interface ICreateInitialGalleryInput {
  isSample: boolean;
  shootTypeId: number;
}

export interface ICreateInitialGalleryResult {
  gallery: IInitialGallery;
}

export interface IGetOnBoardingProcessResult {
  onBoardingProcess: IOnBoardingProcess;
  userInfo: IUserInfo;
  sites?: IGeneratedSite[];
}
