import { createModel } from "@rematch/core";
import api from "../../api";
import { IBookingsDto, IBookingsTotalDto, IBookmeStateDto } from "../../api/bookings/model";
import { BookingStatusCode, IBookingStatus } from "../../models/booking";
import { ISearchContact } from "../../models/contact";
import { getErrorFromApi } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import {
  IAllBookingsResult,
  IApproveBookingParams,
  IBookingResult,
  IBookingsData,
  IBookingsParams,
  IBookingsState,
  ICancelBookingParams,
  IDeclineBookingParams,
  IGetBookingsParams,
  IUpdateBookingParams,
  initialState,
} from "./models";

export const BOOKING_PAGE_SIZE = 20;

export const bookings = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...getGenericReducers("getBookings")<IBookingsState, {}, IAllBookingsResult>({
      success: (_state, payload) => {
        const { totalUpcomingBookings, totalBookingsToApprove, upcomingBookings, bookingsToApprove } = payload;
        return {
          upcomingBookings: {
            ..._state.upcomingBookings,
            total: totalUpcomingBookings,
            bookings: upcomingBookings,
          },
          bookingsToApprove: {
            ..._state.bookingsToApprove,
            total: totalBookingsToApprove,
            bookings: bookingsToApprove,
          },
        };
      },
      error: (_state, payload) => {
        return {
          upcomingBookings: {
            ..._state.upcomingBookings,
            bookings: [],
            total: 0,
          },
          bookingsToApprove: {
            ..._state.bookingsToApprove,
            bookings: [],
            total: 0,
          },
        };
      },
    }),
    ...getGenericReducers("getBooking")<IBookingsState, {}, IBookingResult>({
      success: (_state, payload) => {
        return {
          getBooking: {
            status: "Success",
            booking: payload.booking,
          },
        };
      },
    }),
    ...getGenericReducers("getUpcomingBookings")<IBookingsState, {}, IBookingsData>({
      success: (_state, { bookings, hasNextPage, total }) => ({
        upcomingBookings: {
          ..._state.upcomingBookings,
          bookings,
          hasNextPage,
          total,
        },
      }),
      error: (_state, payload) => {
        return {
          upcomingBookings: {
            ..._state.upcomingBookings,
            bookings: [],
            total: 0,
          },
        };
      },
    }),
    ...getGenericReducers("getBookingsToApprove")<IBookingsState, {}, IBookingsData>({
      success: (_state, { bookings, hasNextPage, total }) => ({
        bookingsToApprove: {
          ..._state.bookingsToApprove,
          bookings,
          hasNextPage,
          total,
        },
      }),
      error: (_state, payload) => {
        return {
          bookingsToApprove: {
            ..._state.bookingsToApprove,
            bookings: [],
            total: 0,
          },
        };
      },
    }),
    ...getGenericReducers("getBookingsTotal")<IBookingsState, {}, IBookingsTotalDto>({
      success: (_state, { upcomingCount, pendingCount }) => ({
        bookingsToApprove: {
          ..._state.bookingsToApprove,
          total: pendingCount,
        },
        upcomingBookings: {
          ..._state.upcomingBookings,
          total: upcomingCount,
        },
      }),
      error: (_state) => {
        return {
          bookingsToApprove: {
            ..._state.bookingsToApprove,
            total: 0,
          },
          upcomingBookings: {
            ..._state.upcomingBookings,
            total: 0,
          },
        };
      },
    }),
    ...getGenericReducers("approveBooking")<IBookingsState, {}, { bookingStatus: IBookingStatus }>({
      success: (_state, payload) => {
        return {
          approveBooking: {
            status: "Success",
            bookingStatus: payload.bookingStatus,
          },
        };
      },
    }),
    ...getGenericReducers("declineBooking")<IBookingsState>(),
    ...getGenericReducers("updateBooking")<IBookingsState>(),
    ...getGenericReducers("cancelBooking")<IBookingsState>(),
    ...getGenericReducers("getBookmeState")<IBookingsState, {}, IBookmeStateDto>({
      success: (_state, payload) => {
        return {
          bookmeState: {
            welcomeClosed: payload.welcomeClosed,
            welcomeCompleted: payload.welcomeCompleted,
          },
        };
      },
    }),
    ...getGenericReducers("cancelBooking")<IBookingsState>(),
    ...getGenericReducers("searchContactByEmail")<IBookingsState, {}, { contact: ISearchContact }>({
      success: (_state, payload) => {
        return {
          contact: payload.contact,
        };
      },
    }),
    resetBookings() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async getBookingsAsync() {
      dispatch.bookings.getBookingsStarted({});
      try {
        const getUpcoming = api.bookings.getBookings({
          status: BookingStatusCode.Upcoming,
          take: BOOKING_PAGE_SIZE,
          skip: 0,
        });
        const getToApprove = api.bookings.getBookings({
          status: BookingStatusCode.Pending,
          take: BOOKING_PAGE_SIZE,
          skip: 0,
        });
        const response: IBookingsDto[] = await Promise.all([getUpcoming, getToApprove]);
        const upcomingBookings = response[0].bookings || [];
        const bookingsToApprove = response[1].bookings || [];
        const totalUpcomingBookings = response[0].count || 0;
        const totalBookingsToApprove = response[1].count || 0;
        dispatch.bookings.getBookingsSuccess({
          upcomingBookings,
          bookingsToApprove,
          totalUpcomingBookings,
          totalBookingsToApprove,
        });
      } catch (error) {
        dispatch.bookings.getBookingsError({ error: getErrorFromApi(error) });
      }
    },
    async getBookingAsync({ status, id }: IBookingsParams) {
      dispatch.bookings.getBookingStarted({});
      try {
        const response: IBookingsDto = await api.bookings.getBookings({ status, id });
        const booking = response.bookings[0];
        dispatch.bookings.getBookingSuccess({ booking });
        return booking;
      } catch (error) {
        dispatch.bookings.getBookingError({ error: getErrorFromApi(error) });
      }
    },
    async getUpcomingBookingsAsync({ take, skip, pageNumber }: IGetBookingsParams, state) {
      dispatch.bookings.getUpcomingBookingsStarted({});
      try {
        const response: IBookingsDto = await api.bookings.getBookings({
          status: BookingStatusCode.Upcoming,
          take,
          skip,
        });
        const { bookings = [], count = 0 } = response;
        const hasNextPage = bookings.length < BOOKING_PAGE_SIZE ? false : true;
        let newBookings = [...bookings];
        if (pageNumber > 1) {
          newBookings = [...state.bookings.upcomingBookings.bookings, ...bookings];
        }
        dispatch.bookings.getUpcomingBookingsSuccess({ bookings: newBookings, hasNextPage, total: count });
      } catch (error) {
        dispatch.bookings.getUpcomingBookingsError({ error: getErrorFromApi(error) });
      }
    },
    async getBookingsToApproveAsync({ take, skip, pageNumber }: IGetBookingsParams, state) {
      dispatch.bookings.getBookingsToApproveStarted({});
      try {
        const response: IBookingsDto = await api.bookings.getBookings({
          status: BookingStatusCode.Pending,
          take,
          skip,
        });
        const { bookings = [], count = 0 } = response;
        const hasNextPage = bookings.length < BOOKING_PAGE_SIZE ? false : true;
        let newBookings = [...bookings];
        if (pageNumber > 1) {
          newBookings = [...state.bookings.bookingsToApprove.bookings, ...bookings];
        }
        dispatch.bookings.getBookingsToApproveSuccess({ bookings: newBookings, hasNextPage, total: count });
      } catch (error) {
        dispatch.bookings.getBookingsToApproveError({ error: getErrorFromApi(error) });
      }
    },
    async getBookingsTotalAsync() {
      try {
        dispatch.bookings.getBookingsTotalStarted({});
        const { upcomingCount = 0, pendingCount = 0 } = await api.bookings.getBookingsTotal();
        dispatch.bookings.getBookingsTotalSuccess({ upcomingCount, pendingCount });
      } catch (error) {
        dispatch.bookings.getBookingsTotalError({ error: getErrorFromApi(error) });
      }
    },
    async approveBookingAsync(params: IApproveBookingParams) {
      dispatch.bookings.approveBookingStarted({});
      try {
        const response: IBookingStatus = await api.bookings.approveBooking(params);
        dispatch.bookings.approveBookingSuccess({ bookingStatus: response });
      } catch (error) {
        dispatch.bookings.approveBookingError({ error: getErrorFromApi(error) });
      }
    },
    async declineBookingAsync(params: IDeclineBookingParams) {
      dispatch.bookings.declineBookingStarted({});
      try {
        await api.bookings.declineBooking(params);
        dispatch.bookings.declineBookingSuccess({});
      } catch (error) {
        dispatch.bookings.declineBookingError({ error: getErrorFromApi(error) });
      }
    },
    async updateBookingAsync(params: IUpdateBookingParams) {
      dispatch.bookings.updateBookingStarted({});
      try {
        const { bookingId, updater } = params;
        const { contactUpdated, contactPhoneNumberUpdated } = await api.bookings.updateBooking(params);

        dispatch.bookings.updateBookingSuccess({
          bookingId,
          updater,
          contactUpdated,
          contactPhoneNumberUpdated,
        });
      } catch (error) {
        dispatch.bookings.updateBookingError({ error: getErrorFromApi(error) });
      }
    },
    async cancelBookingAsync(params: ICancelBookingParams) {
      dispatch.bookings.cancelBookingStarted({});
      try {
        await api.bookings.cancelBooking(params);
        dispatch.bookings.cancelBookingSuccess({});
      } catch (error) {
        dispatch.bookings.cancelBookingError({ error: getErrorFromApi(error) });
      }
    },
    async searchContactByEmailAsync(email: string) {
      dispatch.bookings.searchContactByEmailStarted({});
      try {
        const response = await api.contact.searchContactByEmail(email);
        dispatch.bookings.searchContactByEmailSuccess(response);
      } catch (error) {
        dispatch.bookings.searchContactByEmailError({ error: getErrorFromApi(error) });
      }
    },
    async getBookmeStateAsync() {
      dispatch.bookings.getBookmeStateStarted({});
      try {
        const response = await api.bookings.getBookmeState();
        dispatch.bookings.getBookmeStateSuccess(response);
      } catch (error) {
        dispatch.bookings.getBookmeStateError({ error: getErrorFromApi(error) });
      }
    },
  }),
});
