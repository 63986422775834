import { IPoint } from "../../models/common";
import {
  GalleryCoverMedia,
  GalleryMedia,
  IGallery,
  IGalleryMedia,
  IGalleryMediaDescriptor,
  IGalleryMediaFile,
} from "../../models/gallery";
import { updateState } from "../common";
import { IGalleryState, ISetGalleryCoverMediaInput } from "./models";

export function buildCover(
  gallery: IGallery,
  coverId: string | undefined,
  focalPoint: IPoint
): GalleryCoverMedia | undefined {
  const media = coverId && gallery.medias.find((media) => media.id === coverId);
  if (!media) {
    return undefined;
  }

  return {
    ...media,
    focalPoint,
  };
}

export function updateCover(
  currentCover: GalleryCoverMedia | undefined,
  newCover: GalleryCoverMedia | undefined,
  updateFocalPoint?: boolean
): GalleryCoverMedia | undefined {
  if (!currentCover || !newCover || currentCover.id !== newCover.id) {
    return newCover;
  }

  return {
    ...newCover,
    ...(updateFocalPoint ? null : { focalPoint: currentCover.focalPoint }),
  };
}

export function updateGalleryStateCover(state: IGalleryState, payload: ISetGalleryCoverMediaInput) {
  return {
    gallery: updateState(state.gallery, (gallery) => ({
      cover: updateCover(gallery.cover, buildCover(gallery, payload.media.id, payload.media.focalPoint), true),
      details: updateState(gallery.details, (details) => ({
        ...details,
        dateModified: new Date(),
      })),
    })),
  };
}

export function buildEmptyMedia(
  gallery: IGallery,
  media: IGalleryMediaDescriptor,
  file: IGalleryMediaFile
): GalleryMedia {
  return {
    ...media,
    dateCreated: new Date(),
    name: "",
    file,
    version: 0,
    ...(media.type === "video" ? { urlTemplate: gallery.videoUrlTemplate } : { urlTemplate: gallery.photoUrlTemplate }),
  };
}

export function expireProcessingMedia<T extends IGalleryMedia>(media: T, expiredMediaIds: Set<string>): T {
  return expiredMediaIds.has(media.id)
    ? { ...media, error: `The ${media.type} has taken too much time to process` }
    : media;
}
