import { isErrorType } from "@zenfolio/core-components/dist/utilities/helpers";
import PublicApiUrlManager from "../components/features/PublicApi/UrlManager";
import { IPublicApiParams } from "../models/publicApi";
import { IError } from "../store/common";
import { validateTokens } from "./token";

export function isPublicApiMode() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof window !== "undefined" && !!(window as any).publicApiMode;
}

export function parseLocation(location: { search: string; hash: string }): IPublicApiParams {
  const searchParams = new URLSearchParams(location.search);

  return {
    applicationId: searchParams.get(PublicApiUrlManager.applicationIdParam),
    redirectUrl: searchParams.get(PublicApiUrlManager.redirectUrlParam),
    hash: location.hash,
  };
}

export function toLocation(params: IPublicApiParams) {
  const searchParams = new URLSearchParams();

  if (params.applicationId) {
    searchParams.set(PublicApiUrlManager.applicationIdParam, params.applicationId);
  }

  if (params.redirectUrl) {
    searchParams.set(PublicApiUrlManager.redirectUrlParam, params.redirectUrl);
  }

  return {
    search: searchParams.toString(),
    hash: params.hash,
  };
}

const publicApiSessionStartedStorageKey = "public_api_session_started";

export function tryStartPublicApiSession() {
  const activeValue = "true";

  if (sessionStorage.getItem(publicApiSessionStartedStorageKey) === activeValue) {
    return false;
  }

  sessionStorage.setItem(publicApiSessionStartedStorageKey, activeValue);

  return true;
}

export function buildCompleteUrl(params: IPublicApiParams, apiKey?: string) {
  if (params.redirectUrl) {
    try {
      const url = new URL(params.redirectUrl);
      const userId = validateTokens(true);
      if (apiKey && userId) {
        url.searchParams.set("userId", userId);
        url.searchParams.set("apiKey", apiKey);
      }

      return url.toString();
    } catch (e) {
      // ignore redirect URL if it's invalid
    }
  }

  return "";
}

export const publicApiValidationError = "public_api_validation_error";

export const userAccountNotReadyError = "user_account_not_ready_error";

export function getPublicApiValidationError(error: IError): string {
  if (isErrorType(error.type, "application_already_connected_error")) {
    return "Application is already connected.";
  }

  if (isErrorType(error.type, "user_plan_not_permitted_error")) {
    return "Account’s plan does not support this application.";
  }

  if (
    error.type === userAccountNotReadyError ||
    isErrorType(error.type, "user_not_found_error") ||
    isErrorType(error.type, "user_not_active_error")
  ) {
    return "Account is not active.";
  }

  return "Connection could not be established.";
}
