import _ from "lodash";
import { accountsApi, addGaEventIdHeader } from "..";
import { ISocialConnection, ITrialExtendTasks, IUserProperties } from "../../models/account";
import { ISellingWelcomeStep } from "../../models/orders";
import { FeatureCode } from "../../models/session";
import { ILoginParams } from "../../store/session/models";
import { PartialRecord } from "../../types";
import { getEventId } from "../../utilities/gtm";
import * as logging from "../../utilities/logging";
import { map_ITrialExtendTasksDto_To_ITrialExtendTasks, map_UserInfoDto_To_IUserInfo } from "./mappers";
import { IFeaturesDto, ILoginDto, ITrialExtendTasksDto, IUserInfoDto } from "./models";

export interface IApiAccount {
  getAccountSettings: (id: string) => Promise<ReturnType<typeof map_UserInfoDto_To_IUserInfo>>;
  getAccountFeatures: () => Promise<PartialRecord<FeatureCode, boolean>>;
  login: (params: ILoginParams) => Promise<ILoginDto>;
  getSellingTasks: () => Promise<ISellingWelcomeStep[]>;
  getUserProperties: () => Promise<IUserProperties>;
  getTrialExtendTasks: () => Promise<ITrialExtendTasks>;
  getSocialConnections: () => Promise<ISocialConnection[]>;
  getUserPropertyByKey: (key: string) => Promise<{}>;
  patchUserProperties: (payload: Record<string, string>) => Promise<{}>;
}

const account: IApiAccount = {
  getAccountSettings: (id: string) =>
    accountsApi
      .get<IUserInfoDto>("user/settings", {
        params: {
          isMobile: true,
          subscriptionOverview: true,
          orderFeeSetting: false,
          shootTypes: true,
          socialLink: true,
          identifySegment: true,
          hVTier: true,
          userLogo: false,
          hasZenLink: false,
        },
      })
      .then((response) => map_UserInfoDto_To_IUserInfo(id, response)),
  getAccountFeatures: () =>
    accountsApi
      .post<IFeaturesDto>("features", _.values(FeatureCode))
      .then((response) => response.data?.features || {})
      .catch((error) => {
        logging.error("Getting account features failed with error:", error);
        return {};
      }),
  login: (params: ILoginParams) =>
    accountsApi
      .post<ILoginDto>("user/login", params, addGaEventIdHeader())
      .then((response) => {
        return {
          ...response.data,
          eventId: getEventId(response),
        };
      })
      .catch((error) => {
        return error;
      }),
  getSellingTasks: () =>
    accountsApi
      .get("users/sellingtasks")
      .then((res) => res.data)
      .catch((error) => {
        return error;
      }),
  getUserProperties: () =>
    accountsApi
      .get("users/properties")
      .then((res) => res.data)
      .catch((error) => {
        return error;
      }),
  getTrialExtendTasks: () =>
    accountsApi
      .get<ITrialExtendTasksDto>("user/widget/extendtrial")
      .then((response) => map_ITrialExtendTasksDto_To_ITrialExtendTasks(response.data)),
  getSocialConnections: () =>
    accountsApi.get<ISocialConnection[]>("user/social/connects").then((response) => response.data),
  getUserPropertyByKey: (key: string) =>
    accountsApi
      .get<{}>(`user/property/${key}`)
      .then((response) => response.data)
      .catch((error) => {
        logging.error("Get user property failed with error:", error);
        return error;
      }),
  patchUserProperties: (payload) =>
    accountsApi
      .patch<IFeaturesDto>("user/properties", { properties: { ...payload } })
      .then((response) => response.data || {})
      .catch((error) => {
        logging.error("Patch user properties failed with error:", error);
        return {};
      }),
};

export default account;
