import { generatePath } from "react-router-dom";
/** Url manager for Orders. */
export default class UrlManager {
  public static get orderDetailPath(): string {
    return `${this.prefix}/:${this.orderIdParam}`;
  }

  public static order(id: string): string {
    return generatePath(this.orderDetailPath, {
      [this.orderIdParam]: id,
    });
  }

  public static readonly prefix = "/orders";
  public static readonly orderIdParam = "orderId";
  public static readonly statusSearchParam = "status";
  public static readonly route = `${UrlManager.prefix}/*`;
}
