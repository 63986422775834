import { IIdentitySegment } from "../api/account/models";
import { GtmEventCategory } from "../models/userEvent";
import BookmarkSegment from "../plugins/segment/tracking/bookmark";
import { pushGtmEventAsync } from "./gtm";
import { checkIsPWAMode } from "./helpers";
import { isAddedToHome } from "./session";

export const sendGA4AddToHomeClick = () => {
  BookmarkSegment.clickAddToHomeScreenEvent();

  pushGtmEventAsync({
    category: GtmEventCategory.Bookmark,
    name: "click add to home screen",
  });
};

export const sendGA4AddedToHome = (
  identitySegment: IIdentitySegment,
  updateUserProperties: (properties: Record<string, string>) => void
) => {
  const isPWAMode: boolean = checkIsPWAMode();
  if (isPWAMode) {
    // Should be sent only once
    if (isAddedToHome(identitySegment)) {
      return;
    }

    BookmarkSegment.addedToHomeScreenEvent();

    pushGtmEventAsync({
      category: GtmEventCategory.Bookmark,
      name: "added to home screen",
    });

    updateUserProperties({
      addedToHomeScreen: "true",
    });
  }
};
