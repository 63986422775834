import { IOrdersDto } from "../../api/orders/models";
import { ActionStatus, IError } from "../common";

export const initialState: IOrdersState = {
  getOrders: { status: "Init" },
  ordersList: {
    orders: [],
    hasNextPage: false,
    totalAmount: 0,
    totalItems: 0,
  },
};

export interface IOrdersState {
  getOrders: GetOrdersState;
  ordersList: IOrdersDto;
}

export type GetOrdersState = { status: ActionStatus; error?: IError };
