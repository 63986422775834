import { createModel } from "@rematch/core";
import api from "../../api";
import { IAppExistsAccountsDto } from "../../api/identities/models";
import OnBoardingUrlManager from "../../components/features/OnBoarding/UrlManager";
import { IError, getResetPasswordError } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import { IEmailRecovery, IResetPassword, IResetPasswordState, ISendEmailRecoveryParams, initialState } from "./models";

export const resetPassword = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...getGenericReducers("sendEmail")<IResetPasswordState, {}, IEmailRecovery>({
      success: (_state, payload) => ({
        emailRecovery: payload,
        isSent: true,
      }),
      error: (_state, payload: { error: IError }) => ({
        error: payload.error.message,
      }),
    }),
    ...getGenericReducers("validateTicket")<IResetPasswordState, {}, { ticket: string }>({
      success: (_state, payload) => ({
        ticket: payload.ticket,
        isSent: true,
      }),
      error: (_state, payload: { error: IError }) => {
        return {
          validateTicketError: payload.error.message,
        };
      },
    }),
    ...getGenericReducers("resetPassword")<IResetPasswordState>({
      success: (_state) => ({
        resetPasswordError: "",
      }),
      error: (_state, payload: { error: IError }) => ({
        resetPasswordError: payload.error.message,
      }),
    }),
    resetForgotPasswordLink: (_state) => {
      return {
        ..._state,
        isSent: false,
      };
    },
  },
  effects: (dispatch) => ({
    fetchAppAccountExists: async (email: string) => {
      dispatch.resetPassword.sendEmailStarted({});
      try {
        const appExistsAccount: IEmailRecovery = {
          userName: email,
          isMobile: true,
        };
        const resCheckUnique = await api.identities.checkEmailPBExists(email);
        const { isUniqueEmail } = resCheckUnique;
        let response;
        if (!isUniqueEmail) {
          response = await api.identities.getAppAccountExistsPB(appExistsAccount);
        } else {
          response = await api.identities.getAppAccountExists(appExistsAccount);
        }
        const result: IAppExistsAccountsDto = response;
        if (result && (!result.fsCheckEmailExist.isAccountExist || !result.nzCheckEmailExist.isAccountExist)) {
          dispatch.resetPassword.sendEmailSuccess(appExistsAccount);
        }
        return response;
      } catch (error) {
        const message =
          error.response.status === 500 ? "Unavailable, please try again." : "Verify your email address is correct";
        dispatch.resetPassword.sendEmailError({
          error: getResetPasswordError(error, message),
        });
        return error;
      }
    },
    sendEmailRecovery: async ({ email, isClassic }: ISendEmailRecoveryParams) => {
      dispatch.resetPassword.sendEmailStarted({});
      try {
        const emailRecovery: IEmailRecovery = {
          userName: email,
          isMobile: true,
          isClassic,
        };
        await api.identities.sendEmailRecovery(emailRecovery);
        dispatch.resetPassword.sendEmailSuccess(emailRecovery);
      } catch (error) {
        const message =
          error.response.status === 500 ? "Unavailable, please try again." : "Verify your email address is correct";
        dispatch.resetPassword.sendEmailError({
          error: getResetPasswordError(error, message),
        });
        return error;
      }
    },
    validateTicket: async (ticket: string) => {
      dispatch.resetPassword.validateTicketStarted({});
      try {
        await api.identities.validateTicket(ticket);
        dispatch.resetPassword.validateTicketSuccess({ ticket });
      } catch (error) {
        const message = "The password reset link has expired.";
        dispatch.resetPassword.validateTicketError({
          error: getResetPasswordError(error, message),
        });
        return error;
      }
    },
    resetPassword: async (data: IResetPassword) => {
      dispatch.resetPassword.resetPasswordStarted({});
      try {
        await api.identities.resetPassword(data);
        dispatch.resetPassword.resetPasswordSuccess({});
        window.location.href = OnBoardingUrlManager.prefix;
      } catch (error) {
        const message = "The password reset link has expired.";
        dispatch.resetPassword.validateTicketError({
          error: getResetPasswordError(error, message),
        });
        return error;
      }
    },
  }),
});
