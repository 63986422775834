import IIConProps from "../IIconProps";

const IconArrowRight = (props: IIConProps) => {
  const color = props.color || "#757575";
  const width = props.width || 16;
  const height = props.width || 16;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h16v16H0z" />
        <path
          d="M5.448 1.5c-.357 0-.7.137-.973.397a1.377 1.377 0 0 0 0 1.942L8.628 8 4.46 12.156a1.377 1.377 0 0 0 0 1.943 1.383 1.383 0 0 0 1.946 0l5.14-5.13c.26-.26.398-.602.398-.972s-.15-.711-.398-.971l-5.126-5.13a1.43 1.43 0 0 0-.973-.397z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default IconArrowRight;
