import { createModel } from "@rematch/core";
import api from "../../api";
import { IApplicationInfo } from "../../models/publicApi";
import { getErrorFromApi } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import { IConnectApplicationByIdInput, IConnectApplicationByIdResult, initialState, IPublicApiState } from "./models";

export const publicApi = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setApplication(state, application: IApplicationInfo) {
      return {
        ...state,
        application,
      };
    },
    ...getGenericReducers("connectApplicationById")<
      IPublicApiState,
      IConnectApplicationByIdInput,
      IConnectApplicationByIdResult
    >(),
  },
  effects: (dispatch) => ({
    async getApplicationAsync(id: string) {
      try {
        const application = await api.publicApi.getApplicationById(id);
        dispatch.publicApi.setApplication(application);

        return application;
      } catch (error) {
        return undefined;
      }
    },
    async connectApplicationByIdAsync(payload: IConnectApplicationByIdInput) {
      dispatch.publicApi.connectApplicationByIdStarted(payload);
      try {
        const response = await api.publicApi.connectApplicationById(payload.id, payload.validate);
        dispatch.publicApi.connectApplicationByIdSuccess({ ...payload, ...response });

        return undefined;
      } catch (error) {
        dispatch.publicApi.connectApplicationByIdError({ ...payload, error: getErrorFromApi(error) });

        return error;
      }
    },
  }),
});
