import classNames from "classnames";
import { iconInstagram, videoPlaceholder } from "../../../../icons";
import { BackgroundJob, JobType } from "../../../../store/backgroundJobs/models";
import { isVideoFile } from "../../../../utilities/files";
import Cover from "../../../ui/Cover";
import styles from "./index.module.scss";

interface IPreviewProps {
  className?: string;
  size: number;
  job: BackgroundJob;
}

function Preview(props: IPreviewProps) {
  const { className, size, job } = props;

  const [mediaUrl, mediaClassName] = getMediaInfo(job);
  const cover = job.jobType === JobType.FaceRecognition ? job.cover : undefined;

  return mediaUrl ? (
    <img
      className={classNames(styles.preview, mediaClassName, className)}
      alt="preview"
      src={mediaUrl}
      loading="lazy"
      decoding="async"
      style={{ width: size, height: size }}
    />
  ) : (
    <Cover className={classNames(styles.preview, className)} size={size} cover={cover} />
  );
}

function getMediaInfo(job: BackgroundJob) {
  if (job.jobType === JobType.InstagramUpload) {
    return [iconInstagram, styles.instagram];
  }

  if (job.jobType === JobType.MediaUpload) {
    if (job.mediaFile && isVideoFile(job.mediaFile)) {
      return [videoPlaceholder, styles.video];
    }

    return [job.objectUrl, styles.raw];
  }

  return [undefined, undefined];
}

export default Preview;
