import { Utilities } from "@zenfolio/core-components/dist/utilities/utilities";
import classNames from "classnames";
import _ from "lodash";
import pluralize from "pluralize";
import { useCallback, useRef } from "react";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import GalleryUrlManager from "../../../../components/features/Gallery/UrlManager";
import { isJobActive, isJobFailed, isUploadJob } from "../../../../store/backgroundJobs/helpers";
import { BackgroundJob, JobState, JobType } from "../../../../store/backgroundJobs/models";
import colors from "../../../../utilities/colors";
import { setFullHeightBeforeTransition } from "../../../../utilities/transitions";
import useCssVariables from "../../../hooks/useCssVariables";
import FaceRecognitionIcon from "../../../ui/Icons/FaceRecognitionIcon";
import MediaUploadIcon from "../../../ui/Icons/MediaUploadIcon";
import ChildJob from "../ChildJob";
import Preview from "../Preview";
import Progress from "../Progress";
import styles from "./index.module.scss";

interface IParentJobProps {
  job: BackgroundJob;
  jobs: BackgroundJob[];
  expanded: boolean;
  onCancel: (jobId: string) => void;
  onExpand: (jobId: string, expanded: boolean) => void;
}

const transitionDuration = 300;

function ParentJob(props: IParentJobProps) {
  const { job, jobs, expanded, onCancel, onExpand } = props;

  useCssVariables({
    "--zf-activity-monitor-parent-job-transition-duration": `${transitionDuration}ms`,
  });

  const childrenRef = useRef(null);

  const onCancelClick = useCallback(() => {
    onCancel(job.id);
  }, [job.id, onCancel]);

  const onExpandClick = useCallback(() => {
    onExpand(job.id, !expanded);
  }, [job.id, onExpand, expanded]);

  const iconProps = {
    size: 16,
    color: colors.fontGrey,
  };

  const childJobs = _.chain(jobs)
    .filter((j) => j.parentId === job.id)
    .sortBy((j) => j.state !== JobState.Error)
    .value();

  const inProgress = isJobActive(job);
  const hasChildren = !_.isEmpty(childJobs);
  const canCancel = job.jobType !== JobType.InstagramUpload;
  const childErrorsCount = _.sumBy(childJobs, (j) => (j.state === JobState.Error ? 1 : 0));
  const speedError = job.speedError || (!inProgress && childErrorsCount > 0);
  const jobError = isJobFailed(job) || speedError;
  const speedText = !inProgress && childErrorsCount > 0 ? pluralize("Error", childErrorsCount, true) : job.speedText;

  return (
    <div className={styles.container}>
      <div className={styles.parent}>
        <NavLink className={styles.preview} to={GalleryUrlManager.gallery(job.galleryId)}>
          <Preview size={48} job={job} />
        </NavLink>
        <div className={styles.details}>
          <div className={styles.title}>
            {isUploadJob(job) && <MediaUploadIcon {...iconProps} />}
            {job.jobType === JobType.FaceRecognition && <FaceRecognitionIcon {...iconProps} />}
            <div className={styles.description}>{job.description}</div>
            {inProgress && canCancel && <button className={styles.cancel} onClick={onCancelClick} />}
          </div>
          <Progress progress={job.progressStatus} hasError={jobError} />
          <div className={styles.stats}>
            <div className={styles.status}>{job.progressStatusText}</div>
            <div className={classNames(styles.speed, speedError && styles.error)}>{speedText}</div>
            {hasChildren && (
              <button className={classNames(styles.expand, expanded && styles.expanded)} onClick={onExpandClick} />
            )}
          </div>
        </div>
      </div>
      {hasChildren && (
        <CSSTransition
          nodeRef={childrenRef}
          in={expanded}
          timeout={transitionDuration}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames={Utilities.getTransitionClassNames(styles, "expand")}
          {...setFullHeightBeforeTransition(childrenRef)}
        >
          <div ref={childrenRef} className={styles.expand}>
            {_.map(childJobs, (job) => (
              <ChildJob key={job.id} job={job} />
            ))}
          </div>
        </CSSTransition>
      )}
    </div>
  );
}

export default ParentJob;
