import { createModel } from "@rematch/core";
import _ from "lodash";
import { IGalleryUploadNotificationDto } from "../../api/gallery/models";
import { RootModel } from "../store";
import {
  cancelAllUploadJobs,
  cancelJob,
  cancelJobsByGalleryIds,
  createInstagramJob,
  createUploadJob,
  failInstagramJob,
  refreshInstagramJobs,
  tryPublishGtmUploadEvent,
} from "./helpers";
import { BackgroundJob, IInstagramJobPayload, IUploadJobPayload, JobType, initialState } from "./models";

export const backgroundJobs = createModel<RootModel>()({
  state: initialState,
  reducers: {
    addBackgroundJob(state, payload: { job: BackgroundJob }) {
      tryPublishGtmUploadEvent(state.jobs, payload.job);
      return { ...state, jobs: [...state.jobs, payload.job] };
    },
    updateBackgroundJob(state, payload: { job: BackgroundJob; force?: boolean }) {
      tryPublishGtmUploadEvent(state.jobs, payload.job);
      return {
        ...state,
        jobs: _.map(state.jobs, (job) =>
          job.id === payload.job.id && (payload.force || job.progressStatus <= payload.job.progressStatus)
            ? payload.job
            : job
        ),
      };
    },
    removeBackgroundJob(state, payload: { jobId: string }) {
      return { ...state, jobs: _.filter(state.jobs, (job) => job.id !== payload.jobId) };
    },
    clearJobs(state) {
      _.each(state.jobs, (job) => {
        if (job.jobType === JobType.MediaUpload && job.parentId && job.objectUrl) {
          URL.revokeObjectURL(job.objectUrl);
        }
      });

      return {
        ...state,
        jobs: [],
      };
    },
  },
  effects: (dispatch) => ({
    async createUploadJobAsync(payload: IUploadJobPayload) {
      await createUploadJob(dispatch, payload);
    },
    async cancelJobAsync(payload: { jobId: string }) {
      await cancelJob(dispatch, payload.jobId);
    },
    async cancelAllUploadJobsAsync(_payload, rootState) {
      await cancelAllUploadJobs(rootState, dispatch);
    },
    async cancelJobsByGalleryIdsAsync(payload: { galleryIds: string[] }) {
      await cancelJobsByGalleryIds(dispatch, payload.galleryIds);
    },
    createInstagramJob(payload: IInstagramJobPayload) {
      return createInstagramJob(dispatch, payload);
    },
    failInstagramJob(payload: { jobId: string }) {
      failInstagramJob(dispatch, payload.jobId);
    },
    refreshInstagramJobs(payload: IGalleryUploadNotificationDto) {
      refreshInstagramJobs(dispatch, payload);
    },
  }),
});
