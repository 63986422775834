import classNames from "classnames";
import moment from "moment";
import pluralize from "pluralize";
import { FC, useEffect, useRef, useState } from "react";
import { IIdentitySegment } from "../../../../api/account/models";
import { iconBell } from "../../../../icons";
import { IPromotionCampaign, ISubscription } from "../../../../models/subscription";
import AccountSegment from "../../../../plugins/segment/tracking/account";
import {
  CheckoutViewFrom,
  InteractPlanOffer,
  ShowTrialBanner,
  TrackingInteractPlanModalActions,
} from "../../../../plugins/segment/tracking/account/model";
import PromoText from "../PromoText";
import ViewPlansIFrame from "../ViewPlansIFrame";
import iconClose from "./close-icon.svg";
import styles from "./index.module.scss";

interface IProps {
  trialDaysLeft: number;
  onCloseTrialBanner: (isLastDay: boolean) => void;
  promotion: IPromotionCampaign | null;
  subscriptionOverview: ISubscription[] | null;
  identitySegment: IIdentitySegment;
}

const TrialBanner: FC<IProps> = (props) => {
  const { trialDaysLeft, onCloseTrialBanner, promotion, subscriptionOverview, identitySegment } = props;

  const [showViewPlans, setShowViewPlans] = useState(false);
  const tracking = useRef(new Map<string, boolean>());

  const onViewPlansClick = () => {
    setShowViewPlans(true);
    AccountSegment.clickSubscribeBanner({ view_from: CheckoutViewFrom.TRIAL_BANNER });
  };

  const onClose = () => {
    onCloseTrialBanner(trialDaysLeft <= 1); // TODO: Maybe move this logic into TrialBannerStarter
  };

  const onPurchaseSuccess = () => {
    onCloseViewPlans();
    window.location.reload();
  };

  const onCloseViewPlans = () => {
    setShowViewPlans(false);
    segmentInteractPlanOfferTrack(TrackingInteractPlanModalActions.Close);
  };

  useEffect(() => {
    segmentTrackForTrialBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const segmentTrackForTrialBanner = () => {
    // Only track each event once
    const actionName = "in app trial banner loaded";
    if (!subscriptionOverview) return;
    if (tracking.current.has(actionName)) return;
    const subscription = subscriptionOverview?.find((x) => x.isTrialUser);
    if (!subscription) return;

    const payload: ShowTrialBanner = {
      actionName,
      has_extended_trial: !!identitySegment.extendTrial,
      number_of_days: 1 + moment().diff(moment(subscription.dateCreated).startOf("day"), "days"),
    };
    AccountSegment.trackTrialBanner(payload);
    tracking.current.set(actionName, true);
  };

  const segmentInteractPlanOfferTrack = (trackingAction: TrackingInteractPlanModalActions) => {
    // Only track each event once

    if (!trackingAction) return;
    if (!subscriptionOverview) return;

    const subscription = subscriptionOverview?.find((x) => x.isTrialUser);
    if (!subscription) return;

    const actionName = "interact with plans modal";
    const payload: InteractPlanOffer = {
      action: trackingAction,
      actionName,
      has_extended_trial: !!identitySegment.extendTrial,
      number_of_days: 1 + moment().diff(moment(subscription.dateCreated).startOf("day"), "days"),
    };
    AccountSegment.trackInteractPlanOfferForTrial(payload);
  };
  const showStaticText = !promotion || (!promotion.mobileDescription && !promotion.description);

  return (
    <>
      <div className={classNames(styles.root, styles.innerContainer)}>
        <div className={styles.content}>
          <div className={styles.alertIcon}>
            <img src={iconBell} alt="Alert" />
          </div>
          <div className={styles.text} onClick={onViewPlansClick}>
            {showStaticText && (
              <div className={styles.primary}>{`Your free trial expires in ${pluralize(
                "DAY",
                trialDaysLeft,
                true
              )}`}</div>
            )}
            <div>
              {promotion && <PromoText promo={promotion} isTrial={true} subscriptionOverview={subscriptionOverview} />}
              {showStaticText && <div className={styles.viewPlans}>Subscribe now</div>}
            </div>
          </div>
        </div>
        <div className={styles.closeContainer}>
          <img className={styles.closeIcon} src={iconClose} alt="Close" onClick={onClose} />
        </div>
      </div>
      {showViewPlans && (
        <ViewPlansIFrame
          onPurchaseSuccess={onPurchaseSuccess}
          onClose={onCloseViewPlans}
          view_from={CheckoutViewFrom.TRIAL_BANNER}
        />
      )}
    </>
  );
};

export default TrialBanner;
