import { AxiosResponse } from "axios";
import { addGaEventIdHeader, foldersApi } from "..";
import {
  IDownloadResolutionsCodes,
  MediaDownloadResolutionCode,
  PhotoDownloadResolutionCode,
  VideoDownloadResolutionCode,
} from "../../models/downloadMedia";
import {
  ICreateGalleryResult,
  IDeleteGalleryResult,
  IGallery,
  IGalleryCoverMediaDescriptor,
  IGalleryCreator,
  IGalleryDeleteInfo,
  IGalleryDetails,
  IGalleryMediaDescriptor,
  IGalleryShootTypes,
} from "../../models/gallery";
import { GtmEventCategory } from "../../models/userEvent";
import { pushGtmEvent } from "../../utilities/gtm";
import { executeCancellableRequest } from "../../utilities/helpers";
import { ICommentSettingDto } from "../comments/models";
import {
  mapGallery,
  mapGalleryDeleteInfo,
  mapGalleryDetails,
  mapShootDate,
  mapShootTypes,
  reverseMapGalleryMediaDescriptors,
} from "./mappers";
import {
  ICoverMediaResponse,
  ICreateGalleryDto,
  IDeleteMediaRequest,
  IDownloadAlbumMediaRequest,
  IGalleryDeleteInfoDto,
  IGalleryDetailsDto,
  IGalleryDto,
  IGalleryShootTypesResponse,
  IGetMediaDownloadUrlResponse,
  IGetPhotoDownloadUrlRequest,
  IGetVideoDownloadUrlRequest,
  IRenameGalleryDto,
  ISetCoverMediaRequest,
} from "./models";

/** API for detailed single gallery experience. */
export interface IApiGallery {
  getById: (id: string) => Promise<IGallery>;
  deleteById: (id: string) => Promise<IDeleteGalleryResult>;
  create: (creator: IGalleryCreator) => Promise<ICreateGalleryResult>;
  rename: (id: string, name: string) => Promise<string>;
  updateDescription: (id: string, description: string) => Promise<void>;
  updateShootType: (id: string, shootTypeId: number) => Promise<void>;
  updateShootDate: (id: string, shootDate?: Date) => Promise<void>;
  updateTags: (id: string, tags: string[]) => Promise<void>;
  setCoverMedia: (galleryId: string, media: IGalleryCoverMediaDescriptor) => Promise<void>;
  deleteMedias: (galleryId: string, medias: IGalleryMediaDescriptor[]) => Promise<string | undefined>;
  getMediaDownloadUrl: (
    galleryId: string,
    media: IGalleryMediaDescriptor,
    resolution: MediaDownloadResolutionCode
  ) => Promise<string>;
  downloadMedias: (
    galleryId: string,
    medias: IGalleryMediaDescriptor[],
    resolutions: IDownloadResolutionsCodes
  ) => Promise<void>;
  getDetailsById: (id: string) => Promise<IGalleryDetails>;
  getCommentSetting: (galleryId: string) => Promise<ICommentSettingDto>;
  getDeleteInfoById: (id: string) => Promise<IGalleryDeleteInfo>;
  getShootTypes: (signal?: AbortSignal) => Promise<IGalleryShootTypes | undefined>;
}

const gallery: IApiGallery = {
  getById: async (id: string) => mapGallery((await foldersApi.get<IGalleryDto>(`/folders/${id}/photos`)).data),
  deleteById: async (id: string) =>
    (
      await foldersApi.request<IDeleteGalleryResult>({
        method: "delete",
        url: "/albums",
        data: { id },
      })
    ).data,
  create: async (creator: IGalleryCreator) => {
    const hasGtmEvent = creator.isGettingStartedWidget != null;
    const response = await foldersApi.post<ICreateGalleryDto>(
      "/albums",
      {
        ...creator,
        shootDate: mapShootDate(creator.shootDate),
        clients: [],
        privacySetting: {
          password: "",
          passwordExpiration: "",
          signInRequired: false,
          showOnWebsite: true,
          isActive: true,
          isVisitorAllowed: true,
        },
      },
      hasGtmEvent ? addGaEventIdHeader() : undefined
    );

    if (hasGtmEvent) {
      pushGtmEvent(
        {
          category: GtmEventCategory.Gallery,
          name: "create a gallery",
          detail: {
            is_getting_started_widget: creator.isGettingStartedWidget!.toString(),
          },
        },
        response
      );
    }

    return response.data;
  },
  rename: async (id: string, name: string) =>
    (
      await foldersApi.put<IRenameGalleryDto>("/albums/rename", {
        id,
        name,
      })
    ).data.name,
  updateDescription: async (id: string, description: string) =>
    (
      await foldersApi.put<void>(`/albums/${id}/description`, {
        description,
      })
    ).data,
  updateShootType: async (id: string, shootTypeId: number) =>
    (
      await foldersApi.put<void>(`/albums/${id}/shoottype`, {
        shootTypeId,
      })
    ).data,
  updateShootDate: async (id: string, shootDate?: Date) =>
    (
      await foldersApi.put<void>(`/albums/${id}/shootdate`, {
        shootDate: mapShootDate(shootDate),
      })
    ).data,
  updateTags: async (id: string, tags: string[]) =>
    (
      await foldersApi.put<void>(`/albums/${id}/tags`, {
        tags,
      })
    ).data,
  setCoverMedia: async (galleryId: string, media: IGalleryCoverMediaDescriptor) =>
    (
      await foldersApi.put<void, AxiosResponse<void>, ISetCoverMediaRequest>(
        `/${media.type === "photo" ? "photos" : "videos"}/${media.id}/cover`,
        { albumId: galleryId, focalPointX: media.focalPoint?.x, focalPointY: media.focalPoint?.y }
      )
    ).data,
  deleteMedias: async (galleryId: string, medias: IGalleryMediaDescriptor[]) => {
    const response = (
      await foldersApi.delete<ICoverMediaResponse, AxiosResponse<ICoverMediaResponse>, IDeleteMediaRequest>(`/media`, {
        data: { albumId: galleryId, mediaIds: reverseMapGalleryMediaDescriptors(medias) },
      })
    ).data;
    return response.coverPhoto?.id ?? response.coverVideo?.id;
  },
  getMediaDownloadUrl: async (
    galleryId: string,
    media: IGalleryMediaDescriptor,
    resolution: MediaDownloadResolutionCode
  ) =>
    (
      await foldersApi.put<
        IGetMediaDownloadUrlResponse,
        AxiosResponse<IGetMediaDownloadUrlResponse>,
        IGetPhotoDownloadUrlRequest | IGetVideoDownloadUrlRequest
      >(`/${media.type === "photo" ? "photos" : "video"}/download`, {
        albumId: galleryId,
        ...(media.type === "photo"
          ? { photoId: media.id, resolution: resolution as PhotoDownloadResolutionCode }
          : { videoId: media.id, videoResolution: resolution as VideoDownloadResolutionCode }),
      })
    ).data.downloadUrl,
  downloadMedias: async (
    galleryId: string,
    medias: IGalleryMediaDescriptor[],
    resolutions: IDownloadResolutionsCodes
  ) =>
    (
      await foldersApi.post<void, AxiosResponse<void>, IDownloadAlbumMediaRequest>(
        `/albums/${galleryId}/media/downloads`,
        {
          mediaIds: reverseMapGalleryMediaDescriptors(medias),
          resolution: resolutions.photo,
          videoResolution: resolutions.video,
        }
      )
    ).data,
  getDetailsById: async (id: string) =>
    mapGalleryDetails((await foldersApi.get<IGalleryDetailsDto>(`/albums/${id}/details`)).data),
  getCommentSetting: async (galleryId: string) =>
    (await foldersApi.get<ICommentSettingDto>(`folders/${galleryId}/settings/commenting`)).data,
  getDeleteInfoById: async (id: string) =>
    mapGalleryDeleteInfo((await foldersApi.post<IGalleryDeleteInfoDto>("/albums/confirm/delete", { id })).data),
  getShootTypes: (signal?: AbortSignal) =>
    executeCancellableRequest(
      async (signal) =>
        mapShootTypes(
          (await foldersApi.get<IGalleryShootTypesResponse>("/shoottypes?includeGeneral=true", { signal })).data
        ),
      "Getting shoot types",
      signal
    ),
};

export default gallery;
