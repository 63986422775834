import { createModel } from "@rematch/core";
import { loadStripe } from "@stripe/stripe-js";
import { DeviceInfo } from "@zenfolio/core-components/dist/utilities/notification/constants";
import api, { isAuthError } from "../../api";
import { IGalleryUploadNotificationDto } from "../../api/gallery/models";
import { IPageCreatedNotificationDto, ISiteLayoutCreatedNotificationDto } from "../../api/siteEngine/models";
import { SubscriptionType } from "../../api/subscription/models";
import { getSubscriptionDateCreated } from "../../components/features/Trial/TrialValidator";
import { IUserProperties } from "../../models/account";
import { bookMeAddOnCode, BundleType } from "../../models/addon";
import { ISellingWelcomeStep } from "../../models/orders";
import { FeatureCode, IUserInfo } from "../../models/session";
import { GtmEventCategory } from "../../models/userEvent";
import { pushGtmEvent } from "../../utilities/gtm";
import * as logging from "../../utilities/logging";
import {
  getPublicApiValidationError,
  isPublicApiMode,
  parseLocation,
  publicApiValidationError,
  userAccountNotReadyError,
} from "../../utilities/publicApi";
import { clearSession, getLoginEventId, setLoginEventId, updateSessionAsync } from "../../utilities/session";
import { checkToShowCountdown, isFreeSubscription } from "../../utilities/subscription";
import { getToken, setTokens } from "../../utilities/token";
import { logoutFromDesktopAsync } from "../../utilities/url";
import { getErrorFromApi, IError } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import {
  IGetActivePlansInput,
  IGetActivePlansResult,
  IGetBookmeBundleInfoResult,
  IGetPromotionCampaignsInput,
  IGetPromotionCampaignsResult,
  IGetSellingTaskResult,
  IGetStripeResult,
  IGetUserInfoResult,
  ILoginNextZenParams,
  ILoginParams,
  initialState,
  ISessionState,
  IUpdateUserInfoInput,
} from "./models";

export const session = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setPageLoaded(state) {
      return { ...state, pageLoadingState: "none" };
    },
    setPageLoading(state) {
      return state.pageLoadingState === "none" ? { ...state, pageLoadingState: "secondary" } : state;
    },
    siteEditorHubConnected(state) {
      return { ...state, siteEditorHubConnected: true };
    },
    siteEditorHubDisconnected(state) {
      return { ...state, siteEditorHubConnected: false };
    },
    reset(state, reason?: string) {
      if (reason && getToken()) {
        logging.error(`Logout reason: ${reason}.`);
      }

      clearSession();
      return { ...state, userInfo: undefined, showTrialBanner: false, welcomeSellingTasks: [] };
    },
    setUserInfo(state, userInfo: IUserInfo) {
      return {
        ...state,
        userInfo,
      };
    },
    setDeviceId(state, deviceId: string | undefined) {
      return {
        ...state,
        deviceId,
      };
    },
    getWelcomeSellingTasksSuccess(state, welcomeSellingTasks: ISellingWelcomeStep[]) {
      return {
        ...state,
        welcomeSellingTasks: welcomeSellingTasks,
      };
    },
    getUserPropertiesSuccess(state, userProperties: IUserProperties) {
      return {
        ...state,
        userProperties: userProperties,
      };
    },
    ...getGenericReducers("getSellingTask")<ISessionState, {}, IGetSellingTaskResult>({
      success: (_state, payload) => ({
        welcomeSellingTasks: payload.welcomeSellingTasks,
      }),
    }),
    ...getGenericReducers("getUserInfo")<ISessionState, {}, IGetUserInfoResult>({
      success: (_state, payload) => ({
        userInfo: payload.userInfo,
      }),
    }),
    ...getGenericReducers("login")<ISessionState>(),
    hideTrialBanner(state) {
      return { ...state, showTrialBanner: false };
    },
    setShowTrialBanner(state) {
      return {
        ...state,
        showTrialBanner: true,
      };
    },
    ...getGenericReducers("getPromotionCampaigns")<
      ISessionState,
      IGetPromotionCampaignsInput,
      IGetPromotionCampaignsResult
    >({
      success(state, payload) {
        return {
          ...state,
          promotionCampaigns: payload.promotionCampaigns,
        };
      },
    }),
    ...getGenericReducers("getActivePlans")<ISessionState, IGetActivePlansInput, IGetActivePlansResult>({
      success: (_state, payload) => ({
        activePlans: payload.activePlans.plans,
      }),
    }),
    ...getGenericReducers("subscribeOrUnSubscribeNotification")<ISessionState, {}, { deviceId: string | undefined }>({
      success: (_state, payload) => ({
        deviceId: payload.deviceId,
      }),
    }),
    ...getGenericReducers("getBookmeBundleInfo")<ISessionState, {}, IGetBookmeBundleInfoResult>({
      success: (_state, payload) => ({
        bookmeBundleInfo: payload.bundleInfo,
      }),
    }),
    ...getGenericReducers("getStripeProvider")<ISessionState, {}, IGetStripeResult>({
      success: (_state, payload) => ({
        stripeProvider: payload.stripeProvider,
      }),
    }),
  },
  effects: (dispatch) => ({
    async dispatchNotificationAsync(payload: { type: string; payload: unknown }, state) {
      switch (payload.type) {
        case "GALLERY_UPLOAD_NOTIFICATION_TYPE": {
          const notification = payload.payload as IGalleryUploadNotificationDto;
          dispatch.onBoarding.processUploadNotification(notification);
          dispatch.gallery.processUploadNotification(notification);
          dispatch.backgroundJobs.refreshInstagramJobs(notification);
          break;
        }
        case "USER_ACCOUNT_CLOSED": {
          await dispatch.session.logoutAsync("account is closed");
          break;
        }
        case "BOOKME_ADDON_PURCHASED_NOTIFICATION_TYPE": {
          dispatch.session.hideTrialBanner();
          await dispatch.session.updateUserInfoAsync({
            userInfo: {
              features: {
                ...state.session.userInfo?.features,
                [FeatureCode.BOOKING_BOOKME]: true,
              },
            },
            updateSegment: true,
          });
          break;
        }
        case "SITE_LAYOUT_CREATED_NOTIFICATION_TYPE": {
          dispatch.onBoarding.siteLayoutCreated(payload.payload as ISiteLayoutCreatedNotificationDto);
          break;
        }
        case "PAGE_CREATED_NOTIFICATION_TYPE": {
          dispatch.onBoarding.pageCreated(payload.payload as IPageCreatedNotificationDto);
          break;
        }
      }
    },
    getUserInfoAsync: async (id: string) => {
      dispatch.session.getUserInfoStarted({});
      try {
        const data = await Promise.all([
          api.account.getAccountSettings(id),
          api.account.getAccountFeatures(),
          api.account.getSellingTasks(),
          api.account.getUserProperties(),
        ]);
        const userInfo: IUserInfo = {
          ...data[0],
          features: data[1],
        };

        if (isPublicApiMode()) {
          if (
            !userInfo.onBoardingCompleted ||
            (userInfo.isTrial && !isFreeSubscription(userInfo.subscriptionOverview) && userInfo.expiredDays <= 0)
          ) {
            throw new Error(userAccountNotReadyError);
          }

          const params = parseLocation(window.location);
          if (params.applicationId) {
            const error = await dispatch.publicApi.connectApplicationByIdAsync({
              id: params.applicationId,
              validate: true,
            });
            if (error) {
              throw error;
            }
          }
        }

        dispatch.session.getUserInfoSuccess({ userInfo });
        dispatch.session.getWelcomeSellingTasksSuccess(data[2]);
        dispatch.session.getUserPropertiesSuccess(data[3]);
        const eventId = getLoginEventId();
        if (eventId) {
          pushGtmEvent(
            {
              category: GtmEventCategory.Account,
              name: "login",
            },
            eventId
          );
        }
      } catch (error) {
        const parsedError: IError =
          (error as Error)?.message === userAccountNotReadyError
            ? { type: userAccountNotReadyError }
            : getErrorFromApi(error);

        if (isPublicApiMode()) {
          sessionStorage.setItem(publicApiValidationError, getPublicApiValidationError(parsedError));
        } else if (isAuthError(error)) {
          await dispatch.session.logoutAsync("access is denied");
        }

        dispatch.session.getUserInfoError({ error: parsedError });
      }
    },
    getSellingTasksAsync: async () => {
      dispatch.session.getSellingTaskStarted({});
      try {
        const data = await api.account.getSellingTasks();
        dispatch.session.getSellingTaskSuccess({ welcomeSellingTasks: data });
      } catch (error) {
        dispatch.session.getSellingTaskError({ error: getErrorFromApi(error) });
      }
    },
    loginAsync: async (payload: ILoginParams) => {
      dispatch.session.loginStarted({});
      try {
        const response = await api.account.login(payload);
        const { nzLogin, flagshipLogin, eventId } = response;
        if (!nzLogin || !flagshipLogin) {
          throw response;
        }
        const isNextZen = nzLogin.loginSuccess;
        const isFlagship = flagshipLogin.loginSuccess;
        const isLoginTimeout = flagshipLogin.isLoginTimeout;

        if (isNextZen && isFlagship) {
          return { ...response, isFlagshipAndNextZen: true };
        }
        if (isNextZen) {
          const token = nzLogin.token;
          if (token) {
            setTokens(token);
            setLoginEventId(eventId);
            dispatch.session.loginSuccess({});
            return nzLogin;
          }
        } else if (isFlagship && !isLoginTimeout) {
          window.location.href = flagshipLogin.successRedirectUrl!;
          return {
            ...flagshipLogin,
            isFlagship,
          };
        }
        if (!isNextZen && !isFlagship) {
          throw nzLogin;
        }
        throw response;
      } catch (error) {
        dispatch.session.loginError({ error: getErrorFromApi(error) });
        return error.response ? error.response : error;
      }
    },
    loginNextZenAsync: async ({ token, eventId }: ILoginNextZenParams) => {
      setTokens(token);
      setLoginEventId(eventId);
      dispatch.session.loginSuccess({});
    },
    getPromotionCampaignsAsync: async (payload: IGetPromotionCampaignsInput, state) => {
      dispatch.session.getPromotionCampaignsStarted(payload);
      try {
        const promotionCampaigns = await api.subscription.getActivePromotionCampaign();

        const userSubscriptionType = payload.isTrialUser ? SubscriptionType.Trial : SubscriptionType.Paid;
        const filteredPromotionCampaigns = promotionCampaigns
          .filter((promotion) => {
            return (
              promotion.subscriptionType === SubscriptionType.Both ||
              promotion.subscriptionType === userSubscriptionType
            );
          })
          .filter((promotion) => {
            const userInfo = state.session.userInfo;
            if (!payload.isTrialUser) return true;

            const subscriptions = userInfo?.subscriptionOverview;
            const dateCreated = getSubscriptionDateCreated(subscriptions);
            return checkToShowCountdown(dateCreated, promotion.from, promotion.to);
          })
          // sort descending by amount
          .sort((a, b) => b.amount - a.amount);

        dispatch.session.getPromotionCampaignsSuccess({ ...payload, promotionCampaigns: filteredPromotionCampaigns });
      } catch (error) {
        dispatch.session.getPromotionCampaignsError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    getActivePlansAsync: async (payload: IGetActivePlansInput) => {
      dispatch.session.getActivePlansStarted(payload);
      try {
        const activePlans = await api.subscription.getActivePlans(payload.currentPlanCode);
        dispatch.session.getActivePlansSuccess({ ...payload, activePlans });
      } catch (error) {
        dispatch.session.getActivePlansError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    updateUserInfoAsync: async (payload: IUpdateUserInfoInput, state) => {
      const stateUserInfo = state.session.userInfo;
      if (!stateUserInfo) {
        return;
      }

      const newUserInfo = { ...stateUserInfo, ...payload.userInfo };
      if (payload.userInfo.primaryShootType) {
        newUserInfo.identitySegment = {
          ...newUserInfo.identitySegment,
          primaryShootType: payload.userInfo.primaryShootType,
        };
      }

      await updateSessionAsync(newUserInfo, payload.updateSegment);

      dispatch.session.setUserInfo(newUserInfo);
    },
    logoutAsync: async (reason?: string) => {
      await logoutFromDesktopAsync();
      dispatch.bookings.resetBookings();
      dispatch.notifications.resetNotifications();
      dispatch.session.reset(reason);
    },
    subscribeNotificationAsync: async (subscriptionPayload: DeviceInfo) => {
      dispatch.session.subscribeOrUnSubscribeNotificationStarted({});
      try {
        const res = await api.notifications.subscribeDeviceToReceiveNotification(subscriptionPayload);
        dispatch.session.subscribeOrUnSubscribeNotificationSuccess({ deviceId: res.data.deviceId });
        return true;
      } catch (error) {
        dispatch.session.subscribeOrUnSubscribeNotificationError({ error: getErrorFromApi(error) });
        return false;
      }
    },
    unSubscribeNotificationAsync: async (deviceId: string) => {
      dispatch.session.subscribeOrUnSubscribeNotificationStarted({});
      try {
        await api.notifications.unSubscribeDevices(deviceId);
        dispatch.session.subscribeOrUnSubscribeNotificationSuccess({ deviceId: undefined });
        return true;
      } catch (error) {
        dispatch.session.subscribeOrUnSubscribeNotificationError({ error: getErrorFromApi(error) });
        return false;
      }
    },
    updateUserPropertiesAsync: async (properties: Record<string, string>, state) => {
      const stateUserInfo = state.session.userInfo;
      if (!stateUserInfo) {
        return;
      }
      await api.account.patchUserProperties(properties);
      const newUserInfo = { ...stateUserInfo };
      if (stateUserInfo.identitySegment.customProperties) {
        newUserInfo.identitySegment.customProperties = {
          ...newUserInfo.identitySegment.customProperties,
          ...properties,
        };
      }
      dispatch.session.setUserInfo(newUserInfo);
    },
    getBookmeBundleInfoAsync: async (_payload: void, state) => {
      dispatch.session.getBookmeBundleInfoStarted({});
      try {
        const bundleInfos = await api.subscription.getBundleInfoByType(
          BundleType.BookMe,
          state.session.userInfo?.planCode,
          undefined,
          state.session.userInfo?.planRegionType
        );
        const bookme = bundleInfos.find((bi) => bookMeAddOnCode.includes(bi.code));
        dispatch.session.getBookmeBundleInfoSuccess({ bundleInfo: bookme });
      } catch (error) {
        dispatch.session.getBookmeBundleInfoError({ error: getErrorFromApi(error) });
      }
    },
    getStripeProviderAsync: async (_payload: void) => {
      dispatch.session.getStripeProviderStarted({});
      try {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
        dispatch.session.getStripeProviderSuccess({ stripeProvider: stripe || undefined });
      } catch (error) {}
    },
  }),
});
