import { mediaDownloadApi } from "..";
import { IDownloadExtendBody } from "../../models/orders";
import { IDownloadExtendDto } from "./models";

export interface IApiMediaDownload {
  downloadExtend: (data: IDownloadExtendBody) => Promise<IDownloadExtendDto>;
}

const mediaDownload: IApiMediaDownload = {
  downloadExtend: (data: IDownloadExtendBody) =>
    mediaDownloadApi.post("/download/extend", {
      ...data,
      payload: JSON.stringify(data.payload),
    }),
};

export default mediaDownload;
