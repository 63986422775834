import { createModel } from "@rematch/core";
import api from "../../api";
import { getErrorFromApi } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import { IDashboardState, IGetInitialGalleriesInfoResult, IGetTrialExtendTasksResult, initialState } from "./models";

export const dashboard = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...getGenericReducers("getTrialExtendTasks")<IDashboardState, {}, IGetTrialExtendTasksResult>({
      success: (_state, payload) => ({ trialExtendTasks: payload.trialExtendTasks }),
    }),
    ...getGenericReducers("getInitialGalleriesInfo")<IDashboardState, {}, IGetInitialGalleriesInfoResult>({
      success: (_state, payload) => ({ initialGalleriesInfo: payload.galleriesInfo }),
    }),
  },
  effects: (dispatch) => ({
    async getTrialExtendTasksAsync() {
      dispatch.dashboard.getTrialExtendTasksStarted({});
      try {
        const trialExtendTasks = await api.account.getTrialExtendTasks();
        dispatch.dashboard.getTrialExtendTasksSuccess({ trialExtendTasks });
      } catch (error) {
        dispatch.dashboard.getTrialExtendTasksError({ error: getErrorFromApi(error) });
      }
    },
    async getInitialGalleriesInfoAsync() {
      dispatch.dashboard.getInitialGalleriesInfoStarted({});
      try {
        const galleriesInfo = await api.onBoarding.getInitialGalleriesInfo();
        dispatch.dashboard.getInitialGalleriesInfoSuccess({ galleriesInfo });
      } catch (error) {
        dispatch.dashboard.getInitialGalleriesInfoError({ error: getErrorFromApi(error) });
      }
    },
  }),
});
