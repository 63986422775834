import { generatePath } from "react-router-dom";
import { iframeParams } from "../../../models/iframe";
import { buildIframePath } from "../../../utilities/iframe";
import { addQueryParams } from "../../../utilities/query";
import { checkShareGalleryClickedAt } from "../../../utilities/session";
import { widgetQueryParam } from "../../../utilities/url";

/** Url manager for detailed single gallery experience. */
export default class UrlManager {
  public static get galleryPath(): string {
    return `${this.prefix}/:${this.galleryIdParam}/:${this.tabOrMediaIdParam}?`;
  }

  public static get mediaPath(): string {
    return `${this.prefix}/:${this.galleryIdParam}/:${this.tabOrMediaIdParam}`;
  }

  public static get mediaMenuModalPath(): string {
    return `${this.prefix}/:${this.galleryIdParam}/:${this.tabOrMediaIdParam}/more`;
  }

  public static gallery(id: string, tab: GalleryTab = "media", isNew: boolean = false): string {
    return addQueryParams(
      generatePath(this.galleryPath, {
        [this.galleryIdParam]: id,
        [this.tabOrMediaIdParam]: tab === "media" ? undefined : tab,
      }),
      {
        [iframeParams.path]: buildIframePath("", {
          [widgetQueryParam]: tab === "share" && checkShareGalleryClickedAt() ? "true" : undefined,
        }),
        [this.isNewQueryParam]: isNew ? "true" : null,
      }
    );
  }

  public static galleryPreview(id: string, onboardingMode: boolean = false): string {
    return onboardingMode
      ? addQueryParams(this.gallery(id, "preview"), {
          [iframeParams.path]: buildIframePath("", { "mobile-preview": "true" }),
        })
      : this.gallery(id, "preview");
  }

  public static media(galleryId: string, mediaId: string): string {
    return generatePath(this.mediaPath, {
      [this.galleryIdParam]: galleryId,
      [this.tabOrMediaIdParam]: mediaId,
    });
  }

  public static mediaMenuModal(galleryId: string, mediaId: string): string {
    return generatePath(this.mediaMenuModalPath, {
      [this.galleryIdParam]: galleryId,
      [this.tabOrMediaIdParam]: mediaId,
    });
  }

  public static readonly isNewQueryParam = "isNew";
  public static readonly galleryIdParam = "galleryId";
  public static readonly tabOrMediaIdParam = "tabOrMediaId";
  public static readonly prefix = "/photos/album";
  public static readonly route = `${UrlManager.prefix}/*`;
}

// for internal use
enum GalleryTabEnum {
  "media",
  "settings",
  "design",
  "activity",
  "info",
  "preview",
  "share",
  "instagram",
}

export const galleryTabs = Object.keys(GalleryTabEnum).filter((k) => isNaN(Number(k))) as GalleryTab[];

export type GalleryTab = keyof typeof GalleryTabEnum;

export const mobileOnlyTabs: GalleryTab[] = ["media", "instagram"];
