import moment from "moment";

export const formatDate = (dateString: string): string => {
  const date = moment.utc(dateString);
  return date.format("MMM DD, YYYY");
};

export const formatBookingDate = (dateString: string): string => {
  const date = moment.utc(dateString);
  return date.format("dddd, MMM. D");
};

export const parseDate = (dateString: string, utc: boolean = true) => {
  const result = moment.utc(dateString);
  if (!utc) {
    result.local(true);
  }

  return result.toDate();
};
