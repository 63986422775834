import { init, Models, RematchDispatch, RematchRootState } from "@rematch/core";
import { activityMonitorVisibility } from "./ActivityMonitorVisibility";
import { backgroundJobs } from "./backgroundJobs";
import { bookings } from "./bookings";
import { comments } from "./comments";
import { dashboard } from "./dashboard";
import { galleries } from "./galleries";
import { gallery } from "./gallery";
import { gallerySettings } from "./gallerySettings";
import { notifications } from "./notifications";
import { onBoarding } from "./onBoarding";
import { orders } from "./orders";
import { publicApi } from "./publicApi";
import { resetPassword } from "./resetPassword";
import { search } from "./search";
import { session } from "./session";
import { toast } from "./toast";

export interface RootModel extends Models<RootModel> {
  gallery: typeof gallery;
  galleries: typeof galleries;
  gallerySettings: typeof gallerySettings;
  session: typeof session;
  notifications: typeof notifications;
  backgroundJobs: typeof backgroundJobs;
  orders: typeof orders;
  onBoarding: typeof onBoarding;
  search: typeof search;
  resetPassword: typeof resetPassword;
  bookings: typeof bookings;
  dashboard: typeof dashboard;
  toast: typeof toast;
  comments: typeof comments;
  activityMonitorVisibility: typeof activityMonitorVisibility;
  publicApi: typeof publicApi;
}

export const models: RootModel = {
  gallery,
  galleries,
  gallerySettings,
  session,
  notifications,
  backgroundJobs,
  orders,
  onBoarding,
  search,
  resetPassword,
  bookings,
  dashboard,
  toast,
  comments,
  activityMonitorVisibility,
  publicApi,
};

export const store = init({
  models,
});

export const { getState, dispatch } = store;

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
