import { AxiosPromise } from "axios";
import { addGaEventIdHeader, userEventApi } from "..";
import { UserEventDetail } from "../../models/userEvent";
import { getCategoryName } from "../../utilities/gtm";
import * as logging from "../../utilities/logging";

export interface IApiUserEvent {
  pushEvent: (detail: UserEventDetail) => AxiosPromise<void>;
}

const userEvent: IApiUserEvent = {
  pushEvent: async (detail: UserEventDetail) => {
    const data = detail.detail
      ? detail
      : {
          ...detail,
          detail: {},
        };

    logging.log(`GA event detail [${getCategoryName(data.category)}]:`, data);
    return userEventApi.post("/userevent/ga/events", data, addGaEventIdHeader());
  },
};

export default userEvent;
