import { publicApiApi } from "..";
import { IApplicationInfo, IConnectionInfo } from "../../models/publicApi";
import { IApplicationInfoDto, IConnectionInfoDto } from "./models";

/** API for connecting to public API. */
export interface IApiPublicApi {
  getApplicationById: (id: string) => Promise<IApplicationInfo>;
  connectApplicationById: (id: string, validate: boolean) => Promise<IConnectionInfo>;
}

const publicApi: IApiPublicApi = {
  getApplicationById: async (id: string) => (await publicApiApi.get<IApplicationInfoDto>(`/application/${id}`)).data,
  connectApplicationById: async (id: string, validate: boolean) =>
    (await publicApiApi.post<IConnectionInfoDto>("/user/connect", { applicationId: id, validate })).data,
};

export default publicApi;
