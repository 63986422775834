import _ from "lodash";
import { DropzoneOptions, ErrorCode } from "react-dropzone";
import { photoSizeError, videoSizeError } from "../store/backgroundJobs/models";
import { MAX_PHOTO_SIZE, MAX_VIDEO_SIZE } from "./media";

const acceptedPhotoTypes = {
  "image/jpeg": [".jpg", ".jpeg", ".jpe"],
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/heif": [".heif"],
  "image/heic": [".heic"],
};

const acceptedVideoTypes = {
  "video/mp4": [".mp4"],
  "video/quicktime": [".mov"],
};

export function isVideoFile(file: File) {
  return /^video(\/|$)/i.test(file.type || "");
}

function fileSizeValidator(file: File) {
  const criteria = isVideoFile(file)
    ? {
        maxSize: MAX_VIDEO_SIZE,
        message: videoSizeError,
      }
    : {
        maxSize: MAX_PHOTO_SIZE,
        message: photoSizeError,
      };

  if (file.size > criteria.maxSize) {
    return {
      code: ErrorCode.FileTooLarge,
      message: criteria.message,
    };
  }

  return null;
}

export const getDropzoneOptions = _.memoize(function (allowVideos: boolean): Partial<DropzoneOptions> {
  const common = {
    noDrag: true,
    noKeyboard: true,
    noClick: true,
  };

  if (!allowVideos) {
    return {
      ...common,
      accept: acceptedPhotoTypes,
      maxSize: MAX_PHOTO_SIZE,
    };
  }

  return {
    ...common,
    accept: {
      ...acceptedPhotoTypes,
      ...acceptedVideoTypes,
    },
    validator: fileSizeValidator,
  };
});
