import classNames from "classnames";
import { FC, useState } from "react";
import { createPortal } from "react-dom";
import SiteEditorIframe, { ISiteEditorIframeProps } from "../SiteEditorIframe";
import Spinner from "../Spinner";
import styles from "./index.module.scss";

interface IProps extends Pick<ISiteEditorIframeProps, "path" | "title" | "onCustomMessage"> {}

const FullScreenSiteEditorIFrame: FC<IProps> = (props) => {
  const { path, title, onCustomMessage } = props;

  const [isLoading, setIsLoading] = useState(true);

  return createPortal(
    <div className={styles.root}>
      <SiteEditorIframe
        path={path}
        title={title}
        onPageLoad={() => setIsLoading(false)}
        className={classNames({
          [styles.iFrame]: isLoading,
        })}
        onCustomMessage={onCustomMessage}
      />
      {isLoading && <Spinner className={styles.spinner} size={64} colorScheme="orange" />}
    </div>,
    document.body
  );
};

export default FullScreenSiteEditorIFrame;
