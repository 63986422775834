import { IOrdersDto } from "../../api/orders/models";
import { ActionStatus, IError } from "../common";
import { GenericState } from "../generic";

export const initialState: INotificationsState = {
  getOrdersToApprove: { status: "Init" },
  cancelOrders: { status: "Init" },
  approveOrders: { status: "Init" },
  markOrderAsShipped: { status: "Init" },
  getOrdersToApproveTotal: { status: "Init" },
  ordersApprove: {
    orders: [],
    hasNextPage: false,
    totalAmount: 0,
    totalItems: 0,
  },
};

/** State for detailed single gallery experience. */
export interface INotificationsState {
  getOrdersToApprove: GetOrdersToApproveStatus;
  cancelOrders: CancelOrdersStatus;
  approveOrders: ApproveOrdersStatus;
  markOrderAsShipped: MarkOrdersShippedStatus;
  getOrdersToApproveTotal: GenericState;
  ordersApprove: IOrdersDto;
}

export type GetOrdersToApproveStatus = { status: ActionStatus; error?: IError };
export type CancelOrdersStatus = { status: ActionStatus; error?: IError };
export type ApproveOrdersStatus = { status: ActionStatus; error?: IError };
export type MarkOrdersShippedStatus = { status: ActionStatus; error?: IError };

export enum ORDER_STATUS_FILTER {
  All = "All",
  NeedApproval = "NeedApproval",
  InProgress = "InProgress",
  Complete = "Complete",
  Canceled = "Cancelled",
  SentToLab = "SentToLab",
}

export enum ORDER_COLUMNS {
  OrderNumber = "orderNumber",
  Customer = "buyerName",
  Amount = "amount",
  Date = "dateCreated",
  OrderState = "orderState",
  Type = "type",
  GalleryName = "galleryName",
  ShootTypeName = "shootTypeName",
  BuyerEmail = "buyerEmail",
  Photographer = "photographerName",
  BookmePackage = "bookingId",
}

export enum ORDER_SORT_DIRECTION {
  None = "None",
  Asc = "Asc",
  Desc = "Desc",
}

export const MAP_ORDER_SORT_COLUMNS = {
  [ORDER_COLUMNS.OrderNumber]: "ordernumber",
  [ORDER_COLUMNS.Customer]: "buyername",
  [ORDER_COLUMNS.Amount]: "price",
  [ORDER_COLUMNS.Date]: "ordercreated",
  [ORDER_COLUMNS.OrderState]: "orderstate",
  [ORDER_COLUMNS.Type]: "ordertype",
  [ORDER_COLUMNS.GalleryName]: "gallery",
  [ORDER_COLUMNS.ShootTypeName]: "shoottype",
  [ORDER_COLUMNS.BuyerEmail]: "email",
  [ORDER_COLUMNS.Photographer]: "photographer",
  [ORDER_COLUMNS.BookmePackage]: "bookingid",
};

export interface IOrderSearchParams {
  searchText?: string; // search text in all columns
  statusFilter?: ORDER_STATUS_FILTER[];
  hasOrderBy?: boolean;
  orderBy?: string; // column name, use for sort by
  orderDirection?: ORDER_SORT_DIRECTION;
  pageNumber: number;
  pageSize: number;
  lastItemIndex?: number;
  vendor?: string;
}

export interface IMarkAsShippedParams {
  isSentEmail: boolean;
  sendMeACopy: boolean;
  trackingUrl: string;
  orderIds: string[];
  isSFFLab?: boolean;
}
