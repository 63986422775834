import { FC } from "react";
import { IFRAME_PAGE_TITLE } from "../../../../models/iframe";
import { CheckoutViewFrom } from "../../../../plugins/segment/tracking/account/model";
import FullScreenSiteEditorIFrame from "../../../ui/FullScreenSiteEditorIFrame";

interface IProps {
  onClose: () => void;
  onPurchaseSuccess: () => void;
  view_from: CheckoutViewFrom;
}

const ViewPlansIFrame: FC<IProps> = (props) => {
  const { onClose, onPurchaseSuccess, view_from } = props;

  return (
    <FullScreenSiteEditorIFrame
      path={`/view-plans${view_from ? `?view_from=${view_from}` : ""}`}
      title={IFRAME_PAGE_TITLE.VIEW_PLANS}
      onCustomMessage={(message) => {
        if (message.type === "close-view-plans") {
          onClose();
        }

        if (message.type === "purchase-success") {
          onPurchaseSuccess();
        }
      }}
    />
  );
};

export default ViewPlansIFrame;
