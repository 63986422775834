import classNames from "classnames";
import styles from "./index.module.scss";

interface IProgressProps {
  progress: number;
  hasError?: boolean;
}

function Progress(props: IProgressProps) {
  const { progress, hasError } = props;

  return (
    <div className={styles.container}>
      <div className={classNames(styles.progress, hasError && styles.error)} style={{ width: `${progress}%` }} />
    </div>
  );
}

export default Progress;
