import {
  IDownloadOrderRequest,
  IEmailCustomer,
  IGetShippingMethod,
} from "@zenfolio/nz-core-components/dist/model/order";
import qs from "qs";
import { ecOrderApi, ecProductCatalogApi, foldersApi } from "..";
import { IOrderPhotoFolder, IPhotographerOrderDetail, IPricingSnapshotRequest } from "../../models/orders";
import { IMarkAsShippedParams, IOrderSearchParams } from "../../store/notifications/models";
import {
  IBeautyShotsDto,
  IConfirmOrdersDto,
  IDownloadOrderPayload,
  IGetOrdersToApproveTotalDto,
  IOrdersDto,
  IShippingMethodsDto,
  ISnapshotOrderAlbumDto,
  ISnapshotPricingsDto,
  ITrackingUrlOrderDetailPayload,
  IUpdateOrderDetailPayload,
  IUpdateOrderPriceDto,
} from "./models";
export interface IApiOrders {
  getOrders: (params: IOrderSearchParams) => Promise<IOrdersDto>;
  cancelOrder: (orderIds: string[]) => Promise<IConfirmOrdersDto[]>;
  approveOrder: (orderIds: string[]) => Promise<IConfirmOrdersDto[]>;
  markAsShipped: (params: IMarkAsShippedParams) => Promise<IConfirmOrdersDto[]>;
  getOrderDetail: (orderId: string) => Promise<IPhotographerOrderDetail>;
  getOrderAlbumSnapshot: (orderPhotoFolders: IOrderPhotoFolder[]) => Promise<ISnapshotOrderAlbumDto>;
  getPricingSnapshot: (pricingSnapshot: IPricingSnapshotRequest) => Promise<ISnapshotPricingsDto>;
  getShippingMethods: (data: IGetShippingMethod) => Promise<IShippingMethodsDto>;
  getShippingMethodSFF: (data: IGetShippingMethod) => Promise<IShippingMethodsDto>;
  updatePriceOrderDetail: (order: IPhotographerOrderDetail) => Promise<IUpdateOrderPriceDto>;
  updateOrderDetail: (order: IPhotographerOrderDetail) => Promise<IUpdateOrderDetailPayload>;
  sendEmailToCustomer: (orderId: string, payload: IEmailCustomer) => Promise<null>;
  trackingUrlOrderDetail: (
    orderId: string,
    trackingUrl: string,
    isSFFLab: boolean
  ) => Promise<ITrackingUrlOrderDetailPayload>;
  downloadOrder: (downloadRequest: IDownloadOrderRequest) => Promise<IDownloadOrderPayload>;
  getBeautyShots: () => Promise<IBeautyShotsDto>;
  getOrdersToApproveTotal: () => Promise<IGetOrdersToApproveTotalDto>;
}

const orders: IApiOrders = {
  getOrders: async (params: IOrderSearchParams) =>
    (
      await ecOrderApi.get("/orders/search/extended", {
        params: {
          text: params.searchText,
          state: "All",
          states: params.statusFilter,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          orderBy: params.hasOrderBy && params.orderBy,
          lastItemIndex: params.pageNumber === 1 ? undefined : params.lastItemIndex,
          vendor: params.vendor,
        },
        paramsSerializer: {
          serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
        },
      })
    ).data,
  cancelOrder: async (orderIds: string[]) => (await ecOrderApi.put("/orders/cancel", { orderIds })).data,
  approveOrder: async (orderIds: string[]) => (await ecOrderApi.put("/orders/approve", { orderIds })).data,
  markAsShipped: async (params: IMarkAsShippedParams) => (await ecOrderApi.put("/orders/shipped", { ...params })).data,
  getOrderDetail: async (id: string) => (await ecOrderApi.get(`/orders/${id}`)).data,
  getOrderAlbumSnapshot: async (orderPhotoFolders: IOrderPhotoFolder[]) =>
    (await foldersApi.post("/folders/orderphotos/snapshot", orderPhotoFolders)).data,
  getPricingSnapshot: async (pricingSnapshot: IPricingSnapshotRequest) =>
    (await ecProductCatalogApi.post("/pricings/snapshot", pricingSnapshot)).data,
  getShippingMethods: async (data: IGetShippingMethod) =>
    await ecOrderApi.post(`/orders/${data.orderId}/shipping`, data),
  getShippingMethodSFF: async (data: IGetShippingMethod) =>
    await ecOrderApi.post(`/orders/${data.orderId}/self-fulfillment/shipping`, data),
  updatePriceOrderDetail: async (order: IPhotographerOrderDetail) =>
    await ecOrderApi.post(`/orders/calculation/price`, order),
  updateOrderDetail: async (order: IPhotographerOrderDetail) => await ecOrderApi.put(`/orders/${order.id}`, order),
  sendEmailToCustomer: async (orderId: string, payload: IEmailCustomer) =>
    await ecOrderApi.post(`/orders/email/${orderId}`, payload),
  trackingUrlOrderDetail: (orderId: string, trackingUrl: string, isSFFLab?: boolean) =>
    ecOrderApi.put(`/order/${orderId}/tracking-url`, { trackingUrl, isSFFShipped: !!isSFFLab }),
  getBeautyShots: () => ecProductCatalogApi.get("/beautyshots"),
  downloadOrder: (downloadRequest: IDownloadOrderRequest) =>
    ecOrderApi.post(`/orders/download`, { ...downloadRequest }),
  getOrdersToApproveTotal: async () =>
    (
      await ecOrderApi.post("/orders/total-order-by-status", {
        states: [25],
      })
    ).data,
};

export default orders;
