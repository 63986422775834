import { intersection } from "lodash";
import { RegionType } from "../../../api/subscription/models";
import { IPromotionCampaign, ISubscription, ISubscriptionPlan } from "../../../models/subscription";
import { checkToShowCountdown } from "../../../utilities/subscription";

export const getApplicableCampaignsForPlan = (
  isTrialUser: boolean,
  subscriptionOverview: ISubscription[] | null,
  campaigns: IPromotionCampaign[],
  plan: ISubscriptionPlan
  // chargePeriod?: PeriodChargeType
) => {
  // trial
  if (isTrialUser) {
    const subscription = subscriptionOverview?.find((subs) => !!subs.planPeriod);
    const dateCreated = subscription ? subscription.dateCreated.toString() : "";
    campaigns = campaigns.filter((promo) => checkToShowCountdown(dateCreated, promo.from, promo.to));
  }

  // plan code (tier)
  campaigns = campaigns.filter((x) =>
    x.promotionCampaignPlans.some((item) => item.planCode.toLowerCase() === plan.code.toLowerCase())
  );

  // Don't need chargePeriod for banner
  // validity
  // if (chargePeriod) {
  //   const validity =
  //     chargePeriod === PeriodChargeType.Annually
  //       ? ValidityPromotionType.YearlySubscription
  //       : ValidityPromotionType.MonthSubscription;

  //   campaigns = campaigns.filter((promo) => promo.promotionValidities.some((pv) => pv.type === validity));
  // }

  // currency INT
  if (plan.regionType === RegionType.International) {
    return campaigns.filter((x) => x.isCurrencyIntlExist);
  }

  // currencies
  const currencies = plan.planPeriods.map((x) => x.currencyId);
  return campaigns.filter((x) => intersection(x.currencies || [], currencies).length > 0);
};

export const getBestCampaign = (campaigns: IPromotionCampaign[]): IPromotionCampaign | null => {
  return (
    // prioritize countdown timer
    campaigns.find((item) => item.isTrialUser) ||
    // or the promotion with highest value
    campaigns[0] ||
    null
  );
};
