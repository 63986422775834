import { createModel } from "@rematch/core";
import api from "../../api";
import { getErrorFromApi } from "../common";
import { getGenericReducers } from "../generic";
import { getState, RootModel } from "../store";
import {
  IGetLastViewedInput,
  IGetLastViewedResult,
  initialState,
  ISearchGalleriesInput,
  ISearchGalleriesResult,
  ISearchState,
  IUpdateSearchTextInput,
  IUpdateSearchTextResult,
} from "./models";

export const search = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...getGenericReducers("getLastViewed")<ISearchState, IGetLastViewedInput, IGetLastViewedResult>(),
    ...getGenericReducers("updateSearchText")<ISearchState, IUpdateSearchTextInput, IUpdateSearchTextResult>(),
    ...getGenericReducers("searchGalleries")<ISearchState, ISearchGalleriesInput, ISearchGalleriesResult>({
      started: (state, payload) => ({
        galleries: payload.reset ? undefined : state.galleries,
      }),
      success: (state, payload) => ({
        galleries: state.galleries ? [...state.galleries, ...payload.galleries] : payload.galleries,
      }),
    }),
  },
  effects: (dispatch) => ({
    async getLastViewedAsync() {
      dispatch.search.getLastViewedStarted({});
      try {
        const response = await api.search.getLastViewed();
        dispatch.search.getLastViewedSuccess(response);
      } catch (error) {
        dispatch.search.getLastViewedError({ error: getErrorFromApi(error) });
      }
    },

    async updateSearchTextAsync(payload: IUpdateSearchTextInput) {
      const state = getState().search.updateSearchText;
      if (state.status === "Success" && state.searchText === payload.searchText) {
        return;
      }

      dispatch.search.updateSearchTextStarted(payload);
      try {
        const response = await api.search.findTopResultsByText(payload.searchText);
        dispatch.search.updateSearchTextSuccess({ ...payload, ...response });
      } catch (error) {
        dispatch.search.updateSearchTextError({ ...payload, error: getErrorFromApi(error) });
      }
    },

    async searchGalleriesAsync(payload: ISearchGalleriesInput) {
      dispatch.search.searchGalleriesStarted(payload);
      try {
        const response = await api.search.getFilteredGalleries(payload.request);
        dispatch.search.searchGalleriesSuccess({
          ...payload,
          ...response,
        });
      } catch (error) {
        dispatch.search.searchGalleriesError({ ...payload, error: getErrorFromApi(error) });
      }
    },
  }),
});
