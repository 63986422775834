import IIConProps from "../IIconProps";

const Galleries = (props: IIConProps) => {
  const color = props.color || "#606060";
  const width = props.width || 26;
  const height = props.width || 24;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 24"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g transform="translate(3.5 2)" fill="none" fillRule="evenodd">
        <rect stroke={color} strokeWidth="1.5" y="5" width="19" height="15" rx="1" />
        <path
          d="M13.871 9.68c-.688 0-1.246-.575-1.246-1.283 0-.707.558-1.282 1.246-1.282.689 0 1.247.575 1.247 1.282 0 .708-.558 1.282-1.247 1.282z"
          fill={color}
        />
        <path stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="m0 15 6-6 5 6 3-3 5 6" />
        <path stroke={color} strokeWidth="1.25" strokeLinecap="round" d="M1.5 2.5h16M2.5.5h14" />
      </g>
    </svg>
  );
};

export default Galleries;
