import * as FullStory from "@fullstory/browser"
import * as logging from "../utilities/logging";

export const isFullStoryEnabled = process.env.REACT_APP_ENABLE_FULLSTORY === "true";

export const fullStoryInit = () => {
  const ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID;
  
  try {
    if (isFullStoryEnabled && ORG_ID) {
      FullStory.init({
        orgId: ORG_ID
      });
    }
  } catch (error) {
    logging.error("FullStory.init has error:", error);
  }
};
