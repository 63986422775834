export enum BookingStatusCode {
  Completed = "completed",
  Upcoming = "upcoming",
  Canceled = "canceled",
  Pending = "pending",
}

export interface IBookingStatus {
  code: BookingStatusCode;
  name: string;
}

export interface IBookingBase {
  id: string;
  status: IBookingStatus;
  name: string;
  startDateTime: string;
  duration: number;
  totalAmount: number;
  currency: string;
  serviceName?: string;
  customer?: IBookingCustomer;
}

export interface IActivity {
  id: string;
  amount: number;
  availableRefundAmount?: number;
  availableForPartialRefund: number;
  subscriptionCardChargeAmount: number;
  dateAndTime?: string;
  minAmount?: number;
  forPackage?: boolean;
  taxAmount: number;
}

export interface IBooking extends IBookingBase {
  address: string;
  locationIsCustom: boolean;
  locationDetails?: string;
  locationDisplayName: string;
  locationUrl?: string;
  isClientLocation: boolean;
  isAddressRequired: boolean;
  additionalInfo?: string;
  paidAmount: number;
  remainingAmount: number;
  canceledOn?: string;
  canceledNote?: string;
  approvalRequired: boolean;
  approved: boolean;
  acceptanceNote?: string;
  internalNote?: string;
  useOfflinePayment: boolean;
  offlinePaymentInstructions?: string;
  galleryId?: string;
  serviceDetails?: string[];
  totalCollectedFee: number;
  totalTargetFee: number;
  orderId?: string;
  isTaxable: boolean;
  openTextFieldName: string;
  openTextFieldValue: string;
  customer: IBookingCustomer;
  latestBillingActivity?: IActivity;
}

export interface IBookingCustomer {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  isVerified: boolean;
}

export type BookingType = "Upcoming" | "Pending";

export type FieldName = keyof IBookingField;

type InputType = "text-input" | "text-area" | "phone-number";
export interface IField {
  name: FieldName;
  value: string;
  onChange: (value: string) => void;
  inputType?: InputType;
  formattedValue?: string;
  label?: BookingLabel | "";
  maxLength?: number;
  onBlur?: () => void;
  error?: string;
  disabled?: boolean;
}

export interface IBookingField {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  locationDisplayName: string;
  locationAddress: string;
  locationDetails?: string;
  bookingNotes?: string;
  acceptanceNotes?: string;
  internalNote?: string;
  includedWithService?: string;
}

export interface IBookingUpdater {
  name?: string;
  customer?: IBookingCustomer;
  address?: string;
  locationDetails?: string;
  notes?: string;
  acceptanceNote?: string;
  internalNote?: string;
  canceledNote?: string;
  locationDisplayName?: string;
  offlinePaymentInstructions?: string;
}

export type BookingLabel =
  | "Name"
  | "First Name"
  | "Last Name"
  | "Email"
  | "Phone Number"
  | "Included with Service"
  | "Location Display Name"
  | "Location Address"
  | "Location Details"
  | "Acceptance Notes"
  | "Booking Notes";

export interface IBookmeState {
  welcomeClosed: boolean;
  welcomeCompleted: boolean;
}
