import { createModel } from "@rematch/core";
import api from "../../api";
import { getErrorFromApi } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import {
  IGallerySettingsState,
  IGetDownloadSettingsInput,
  IGetDownloadSettingsResult,
  IGetPrivacySettingsInput,
  IGetPrivacySettingsResult,
  IGetShareSettingsInput,
  IGetShareSettingsResult,
  initialState,
} from "./models";

export const gallerySettings = createModel<RootModel>()({
  state: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    ...getGenericReducers("getShareSettings")<IGallerySettingsState, IGetShareSettingsInput, IGetShareSettingsResult>({
      started: () => ({ share: undefined }),
      success: (_state, payload) => ({
        share: payload.shareSettings,
      }),
    }),
    ...getGenericReducers("getDownloadSettings")<
      IGallerySettingsState,
      IGetDownloadSettingsInput,
      IGetDownloadSettingsResult
    >({
      started: () => ({ download: undefined }),
      success: (_state, payload) => ({
        download: payload.downloadSettings,
      }),
    }),
    ...getGenericReducers("getPrivacySettings")<
      IGallerySettingsState,
      IGetPrivacySettingsInput,
      IGetPrivacySettingsResult
    >({
      started: () => ({ privacy: undefined }),
      success: (_state, payload) => ({
        privacy: payload.privacySettings,
      }),
    }),
  },
  effects: (dispatch) => ({
    async getShareSettingsAsync(payload: IGetShareSettingsInput) {
      dispatch.gallerySettings.getShareSettingsStarted(payload);
      try {
        const shareSettings = await api.gallerySettings.getShareSettings(payload.galleryId);
        dispatch.gallerySettings.getShareSettingsSuccess({ ...payload, shareSettings });
      } catch (error) {
        dispatch.gallerySettings.getShareSettingsError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async getDownloadSettingsAsync(payload: IGetDownloadSettingsInput) {
      dispatch.gallerySettings.getDownloadSettingsStarted(payload);
      try {
        const downloadSettings = await api.gallerySettings.getDownloadSettings(payload.galleryId);
        dispatch.gallerySettings.getDownloadSettingsSuccess({ ...payload, downloadSettings });
      } catch (error) {
        dispatch.gallerySettings.getDownloadSettingsError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async getPrivacySettingsAsync(payload: IGetPrivacySettingsInput) {
      dispatch.gallerySettings.getPrivacySettingsStarted(payload);
      try {
        const privacySettings = await api.gallerySettings.getPrivacySettings(payload.galleryId);
        dispatch.gallerySettings.getPrivacySettingsSuccess({ ...payload, privacySettings });
      } catch (error) {
        dispatch.gallerySettings.getPrivacySettingsError({ ...payload, error: getErrorFromApi(error) });
      }
    },
  }),
});
