import { RichText } from "@zenfolio/core-components";
import _ from "lodash";
import api from "../api";
import { TrackFoldersProperties } from "../api/folders/models";
import { ISize } from "../models/common";
import {
  GalleryMedia,
  GalleryMediaType,
  IGalleryMedia,
  IGalleryMediaDescriptor,
  IGalleryParentShootType,
  IGalleryPhoto,
  IGalleryShootType,
  IGalleryUploadNotification,
  IGalleryVideo,
} from "../models/gallery";
import { IPresetInfo } from "../models/presets";
import { IRenderPhoto, IRenderVideo, IRenderVideoThumbnail } from "../models/renderMedia";
import { IUserInfo } from "../models/session";
import { getImageSizeInAreaContain } from "./renderMedia";
import { getShareGalleryUrl } from "./siteViewerUrl";

export function getGalleryPhotoRender(photo: IGalleryPhoto): IRenderPhoto {
  if (!photo.size) {
    throw new Error(
      `The size of the photo with ID ${photo.id} is unknown. You should only use this function for photos with known size.`
    );
  }
  return {
    type: "photo",
    id: photo.id,
    name: resolveGalleryMediaName(photo),
    size: photo.size,
    urlTemplate: photo.urlTemplate,
    version: photo.version,
    watermarkVersion: photo.watermarkVersion,
  };
}

export function getGalleryVideoRender(video: IGalleryVideo): IRenderVideo {
  if (!video.thumbnail) {
    throw new Error(
      `The video with ID ${video.id} doesn't have a thumbnail. You should only use this function for videos with thumbnail.`
    );
  }
  if (!video.duration) {
    throw new Error(
      `The duration of the video with ID ${video.id} is unknown. You should only use this function for videos with known duration.`
    );
  }
  return {
    type: "video",
    id: video.id,
    name: resolveGalleryMediaName(video),
    duration: video.duration,
    thumbnailId: video.thumbnail.id,
    urlTemplate: video.urlTemplate,
    version: video.version,
  };
}

export function getGalleryVideoThumbnailRender(video: IGalleryVideo): IRenderVideoThumbnail {
  if (!video.thumbnail) {
    throw new Error(
      `The video with ID ${video.id} doesn't have a thumbnail. You should only use this function for videos with thumbnail.`
    );
  }
  if (!video.duration) {
    throw new Error(
      `The duration of the video with ID ${video.id} is unknown. You should only use this function for videos with known duration.`
    );
  }
  return {
    type: "video-thumbnail",
    id: video.thumbnail.id,
    name: resolveGalleryMediaName(video),
    size: video.thumbnail.size,
    urlTemplate: video.thumbnail.urlTemplate,
    videoDuration: video.duration,
    version: video.version,
  };
}

export function getGalleryMediaSizeInAreaContain(media: GalleryMedia, areaSize: ISize) {
  const imageSize = (isGalleryPhoto(media) ? getGalleryPhotoRender(media) : getGalleryVideoThumbnailRender(media)).size;
  return getImageSizeInAreaContain(imageSize, areaSize);
}

export function resolveGalleryMediaName(media: IGalleryMedia): string {
  return media.title || media.name || media.file.name;
}

export function isGalleryPhoto(media: GalleryMedia): media is IGalleryPhoto {
  return media.type === "photo";
}

export function isGalleryVideo(media: GalleryMedia): media is IGalleryVideo {
  return media.type === "video";
}

export function isMediaError(media: IGalleryMedia) {
  return !!media.error;
}

export function isPhotoProcessed(photo: IGalleryPhoto) {
  return !!photo.size;
}

export function isVideoProcessed(video: IGalleryVideo) {
  return video.duration != null;
}

export function isThumbnailProcessed(video: IGalleryVideo) {
  return !!video.thumbnail;
}

export function isMediaProcessed(media: GalleryMedia) {
  return isGalleryPhoto(media) ? isPhotoProcessed(media) : isVideoProcessed(media);
}

export function canRenderMedia(media: GalleryMedia) {
  return isGalleryPhoto(media) ? isPhotoProcessed(media) : isThumbnailProcessed(media);
}

function reorderMedias(medias: GalleryMedia[], newOrder: IGalleryMediaDescriptor[]) {
  const photoOrdersMap: Record<string, number> = {};
  const videoOrdersMap: Record<string, number> = {};

  _.each(newOrder, (descriptor, index) => {
    if (descriptor.type === "video") {
      videoOrdersMap[descriptor.id] = index;
    } else {
      photoOrdersMap[descriptor.id] = index;
    }
  });

  return _.orderBy(medias, (media) => (isGalleryPhoto(media) ? photoOrdersMap[media.id] : videoOrdersMap[media.id]));
}

function mediaFailed(oldMedia: GalleryMedia, newMedia: GalleryMedia) {
  return !isMediaError(oldMedia) && isMediaError(newMedia);
}

function photoUpdated(oldPhoto: IGalleryPhoto, newPhoto: IGalleryPhoto) {
  return !isPhotoProcessed(oldPhoto) && isPhotoProcessed(newPhoto);
}

function videoUpdated(oldVideo: IGalleryVideo, newVideo: IGalleryVideo) {
  return (
    isVideoProcessed(newVideo) &&
    (!isVideoProcessed(oldVideo) ||
      oldVideo.thumbnail?.id !== newVideo.thumbnail?.id ||
      oldVideo.thumbnail?.isCustom !== newVideo.thumbnail?.isCustom)
  );
}

function mediaUpdated(oldMedia: GalleryMedia, newMedia: GalleryMedia) {
  return isGalleryPhoto(newMedia)
    ? photoUpdated(oldMedia as IGalleryPhoto, newMedia)
    : videoUpdated(oldMedia as IGalleryVideo, newMedia);
}

function needMediaUpdate(oldMedia: GalleryMedia | null | undefined, newMedia: GalleryMedia | null | undefined) {
  return (
    !!newMedia &&
    (!oldMedia ||
      oldMedia.type !== newMedia.type ||
      oldMedia.version < newMedia.version ||
      (oldMedia.version === newMedia.version && (mediaFailed(oldMedia, newMedia) || mediaUpdated(oldMedia, newMedia))))
  );
}

export function updateGalleryMedias(medias: GalleryMedia[], notification: IGalleryUploadNotification) {
  let updated = false;
  const newMedias: GalleryMedia[] = [];
  const updatedMediasMap = _.chain<GalleryMedia>(notification.updatedPhotos)
    .concat(notification.updatedVideos)
    .keyBy((media) => media.id)
    .value();

  _.each(medias, (media) => {
    const newMedia = updatedMediasMap[media.id];
    if (newMedia) {
      delete updatedMediasMap[media.id];
      if (needMediaUpdate(media, newMedia)) {
        updated = true;
        newMedias.push(newMedia);
      } else {
        newMedias.push(media);
      }
    } else {
      newMedias.push(media);
    }
  });

  _.each(updatedMediasMap, (media) => {
    updated = true;
    newMedias.push(media);
  });

  if (!updated) {
    return undefined;
  }

  return reorderMedias(newMedias, notification.medias);
}

export function getNameFromUrl(url: string) {
  const start = url.lastIndexOf("/") + 1;
  let end = url.indexOf("?", start);

  return url.substring(start, end < 0 ? undefined : end);
}

export function findShootType(
  allShootTypes: IGalleryParentShootType[],
  shootTypeId: number
): [IGalleryShootType, IGalleryShootType | null] | null {
  for (const parent of allShootTypes) {
    if (!_.isEmpty(parent.children)) {
      for (const child of parent.children!) {
        if (child.id === shootTypeId) {
          return [child, parent];
        }
      }
    } else if (parent.id === shootTypeId) {
      return [parent, null];
    }
  }

  return null;
}

export function convertShootDate(date: moment.Moment | null) {
  return date ? date.clone().startOf("day").toDate() : undefined;
}

export function toGalleryMediaType(isVideo: boolean): GalleryMediaType {
  return isVideo ? "video" : "photo";
}

export function getGalleryShareUrl(userInfo: IUserInfo, galleryAlias: string) {
  const customDomain = userInfo.useCustomDomain && userInfo.isCustomDomainActive ? userInfo.customDomain || "" : "";
  const subdomain = userInfo.photographerAlias || "";
  const url = getShareGalleryUrl(customDomain, subdomain, galleryAlias);
  return url;
}

export async function trackGalleryShare(id: string, shareType: "direct link" | "message") {
  await api.folders.trackFolders({
    eventName: "share gallery",
    childId: id,
    trackProperties: TrackFoldersProperties.GalleryFull,
    extraProperties: {
      category: "gallery",
      child_category: "gallery actions",
      gallery_share_type: shareType,
      share_recipients: null,
    },
  });
}

export async function trackGalleryMedia(
  id: string,
  photosCount: number,
  videosCount: number,
  isNew?: boolean,
  preset?: IPresetInfo
) {
  await api.folders.trackFolders({
    eventName: isNew ? "upload gallery" : "add media",
    childId: id,
    trackProperties: isNew ? TrackFoldersProperties.GalleryBase : TrackFoldersProperties.GalleryFull,
    extraProperties: {
      category: "gallery",
      child_category: isNew ? "gallery actions" : "gallery content",
      photos_uploaded: photosCount,
      videos_uploaded: videosCount,
      ...(preset
        ? {
            preset_type: preset.isCustom ? "custom" : "default",
            preset_name: preset.name,
          }
        : null),
    },
  });
}

export async function trackEditGalleryInfo(
  id: string,
  infoType: "title" | "description" | "shoot type" | "shoot date" | "tags"
) {
  await api.folders.trackFolders({
    eventName: "edit gallery info",
    childId: id,
    trackProperties: TrackFoldersProperties.GalleryFull,
    extraProperties: {
      category: "gallery",
      child_category: "gallery info",
      info_type: infoType,
    },
  });
}

export type RichTextType = NonNullable<ReturnType<typeof RichText.fromJSON>>;
