import { Optional } from "../types";

export type ToastType = "success" | "error"; /* add more if needed: success | xxx | yyy */

export interface IToast {
  id: string;
  content: string;
  type: ToastType;
  duration: number;
  className?: string;
  style?: React.CSSProperties;
}

export type ToastCreator = Optional<IToast, "id" | "type" | "duration">;

export const defaultToastType: ToastType = "success";
export const defaultToastDuration: number = 3000;
export const toastDefaults = { type: defaultToastType, duration: defaultToastDuration };
