import { MESizeMethod, TextArea, useWindowSize } from "@zenfolio/core-components";
import _, { isEmpty } from "lodash";
import moment from "moment";
import { ChangeEvent, FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import api from "../../../../api";
import { IPostCommentParams } from "../../../../api/comments/models";
import { IconCRM } from "../../../../icons";
import { ICommentDetail, IUnreadComment } from "../../../../models/comments";
import colors from "../../../../utilities/colors";
import { isGalleryPhoto, isGalleryVideo } from "../../../../utilities/gallery";
import { validateTokens } from "../../../../utilities/token";
import Thumbnail from "../../../features/Gallery/GalleryPage/Media/Thumbnail";
import Cover from "../../Cover";
import Spinner from "../../Spinner";
import FullScreen from "../FullScreen";
import styles from "./comment.module.scss";
interface ICommentModalProps {
  open: boolean;
  onClose: () => void;
  commentDetail: IUnreadComment;
}

interface IFormatComment {
  id: string;
  user: string;
  text: string;
  time: string;
  isYou: boolean;
  isDeleted: boolean;
}

const Comment: FC<ICommentModalProps> = ({ open, onClose, commentDetail }) => {
  const [message, setMessage] = useState<string>("");
  const [threadDetail, setThread] = useState<ICommentDetail[]>([]);
  const [isEnableComment, setEnableComment] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCommenting, setCommenting] = useState<boolean>(false);

  const commentsToScrollRef = useRef<HTMLDivElement | null>(null);

  const { photo, video } = commentDetail;
  const media = photo || video;
  const userId = validateTokens(true);

  const getTime = (dateCreated: string) => {
    const now = moment().utc().format("MM/DD/YYYY HH:mm:ss");
    const end = moment(new Date(dateCreated)).format("MM/DD/YYYY HH:mm:ss");
    const difference = new Date(now).getTime() - new Date(end).getTime();
    const resultInMinutes = Math.round(difference / 60000);

    if (resultInMinutes < 1) {
      return `now`;
    } else if (resultInMinutes >= 1 && resultInMinutes < 60) {
      return `${resultInMinutes} m`;
    } else if (resultInMinutes >= 60 && resultInMinutes < 1440) {
      return `${Math.floor(resultInMinutes / 60)} h`;
    } else if (resultInMinutes >= 1440 && resultInMinutes < 10080) {
      return `${Math.floor(resultInMinutes / 1440)} d`;
    } else if (resultInMinutes >= 10080) {
      return `${Math.floor(resultInMinutes / 10080)} w`;
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      const { folderId } = commentDetail;
      const params = {
        photoId: photo?.id,
        videoId: video?.id,
        folderId,
      };
      api.comments.getThreadComment(params).then((res) => {
        const sortedComment = _.orderBy(
          res.comments,
          [
            (comment) => {
              return new Date(comment.dateCreated);
            },
          ],
          ["asc"]
        );
        setThread(sortedComment);
        setLoading(false);
      });
      api.gallery.getCommentSetting(folderId).then((res) => {
        setEnableComment(res.isActive);
      });
    } catch (error) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentDetail]);

  useLayoutEffect(() => {
    commentsToScrollRef.current?.scrollIntoView({
      block: "end",
    });
  }, [threadDetail?.length]);

  const formatComment = (comment: ICommentDetail): IFormatComment => {
    const isYou = userId === comment.commenter.id;
    const userName = isYou ? "You" : comment.commenter.fullName;
    return {
      id: comment.id,
      user: userName,
      text: comment.text,
      isYou,
      isDeleted: comment.isDeleted,
      time: `${getTime(comment.dateCreated)}`,
    } as IFormatComment;
  };

  const renderComment = (comment: ICommentDetail) => {
    const formattedComment: IFormatComment = formatComment(comment);
    const { time, user, text, isDeleted, id } = formattedComment;
    return (
      <div className={styles.comment} key={id}>
        <p className={styles.name}>{user}</p>
        <p className={styles.text}>{!isDeleted ? text : "~comment deleted~"}</p>
        <p className={styles.time}>{time}</p>
      </div>
    );
  };
  const handleChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const onSendMessage = async () => {
    if (isCommenting || isEmpty(message)) return;
    try {
      setCommenting(true);
      const { folderId } = commentDetail;
      const payload: IPostCommentParams = {
        commenterType: 0,
        folderId,
        text: message,
        photoId: photo?.id,
        videoId: video?.id,
      };
      await api.comments.postComment(payload).then((res) => {
        const newComment = {
          ...res,
          commenter: {
            fullName: "You",
            id: userId,
          },
        };
        const newThread = threadDetail;
        newThread.push(newComment);
        setThread(newThread);
        setMessage("");
        setCommenting(false);
      });
    } catch (error) {
      setCommenting(false);
    }
  };

  const handleCloseModal = () => {
    if (!isCommenting) {
      onClose();
    }
  };

  const isTyping = message.length > 0;
  const windowSize = useWindowSize();
  const isPhoto = media && isGalleryPhoto(media);

  // In this one place in the whole app we don't want to use media's focal point in thumbnail if it's a video
  // for some reason. Previously it was done via a special property "isIgnoreFocusPoint" and I decided to rework
  // it. The name was kinda incorrect (it ignored focal point ONLY for videos) and I decided it was not right
  // to introduce a whole new property if it was just a "hack" for one place.
  const thumbnailVideo = useMemo(
    () => (media && isGalleryVideo(media) ? _.omit(media, ["focalPoint"]) : undefined),
    [media]
  );

  return (
    <FullScreen
      open={open}
      onClose={handleCloseModal}
      header={commentDetail.commenterFullName}
      className={styles.commentModal}
      headerClassName={styles.header}
    >
      {isLoading ? (
        <div className={styles.spinnerWrapper}>
          <Spinner size={64} colorScheme={"orange"} />
        </div>
      ) : (
        <div className={styles.container} ref={commentsToScrollRef}>
          <div>
            <div className={styles.imageWrapper}>
              {media && (
                <div className={styles.image}>
                  {isPhoto ? (
                    <Cover cover={media} size={150} className={styles.commentCoverImg} />
                  ) : (
                    <Thumbnail
                      areaSize={windowSize}
                      sizeMethod={MESizeMethod.Contain}
                      className={styles.videoThumbNail}
                      media={thumbnailVideo!}
                    />
                  )}
                </div>
              )}
              <p className={styles.photoName}>{media?.name}</p>
            </div>
            <div className={styles.comments}>{threadDetail?.map((comment) => renderComment(comment))}</div>
          </div>

          <div className={styles.replyPanel}>
            <div className={styles.replyBoxWrapper}>
              <TextArea
                disabled={!isEnableComment}
                className={styles.inputWrapper}
                placeholder="Post a comment"
                value={message}
                onChange={handleChangeMessage}
              />
              <div onClick={onSendMessage}>
                {isCommenting ? (
                  <Spinner className={styles.textBoxIcon} size={22} colorScheme={"orange"} />
                ) : (
                  <IconCRM
                    className={styles.textBoxIcon}
                    color={isTyping ? colors.primaryOrange : colors.black}
                    fill={isTyping ? "rgba(255, 90, 0, 0.15)" : "none"}
                    size={22}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </FullScreen>
  );
};

export default Comment;
