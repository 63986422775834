import React from "react";
import NewRelic from "../../../utilities/newRelic";

interface IProps {
  children?: React.ReactNode;
}

export default class ErrorBoundary extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);

    if (NewRelic.isEnabled) {
      this.componentDidCatch = (error, errorInfo) => {
        NewRelic.logError(error, errorInfo.componentStack ? { componentStack: errorInfo.componentStack } : undefined);
      };
    }
  }

  public render() {
    return this.props.children;
  }
}
