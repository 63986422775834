import classNames from "classnames";
import { useCallback, useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { nextZenLogoWhite } from "../../../icons";
import { PageLoadingState } from "../../../store/session/models";
import { RootState } from "../../../store/store";
import { hideScrollClassName } from "../../../utilities/constants";
import { copyright } from "../../../utilities/helpers";
import { isPublicApiMode } from "../../../utilities/publicApi";
import { initialized } from "../../../utilities/session";
import { useTimeout } from "../../hooks/useTimeout";
import Background from "../../ui/Background";
import Spinner from "../../ui/Spinner";
import styles from "./index.module.scss";

const mapState = (state: RootState) => ({
  pageLoadingState: state.session.pageLoadingState,
});

type StateProps = ReturnType<typeof mapState>;
type Props = StateProps;

type ClosedState = {
  visibility: "closed";
};

type VisibilityState =
  | {
      visibility: "open" | "loaded" | "spinning" | "completed" | "closing";
      loading: Exclude<PageLoadingState, "none">;
    }
  | ClosedState;

function isVisible(state: VisibilityState | undefined): state is Exclude<VisibilityState, ClosedState> {
  return !!state && state.visibility !== "closed";
}

/** If secondary loading takes less than that, then we don't even show the spinner. */
const noSpinnerTimeout = 150;

/** If we have shown the spinner, then it will be shown for at least during this time. */
const minInitialSpinnerTimeout = 1000;
const minSecondarySpinnerTimeout = 500;

const spinnerSize = 64;

function Loading(props: Props) {
  const { pageLoadingState } = props;
  const [state, setState] = useState<VisibilityState>();
  const [noSpinnerTimeoutRef, clearNoSpinnerTimeout] = useTimeout();
  const [spinnerTimeoutRef, clearSpinnerTimeout] = useTimeout();
  const publicApiMode = isPublicApiMode();

  useLayoutEffect(() => {
    setState((current) => {
      if (pageLoadingState !== "none") {
        return { visibility: pageLoadingState === "initial" ? "spinning" : "open", loading: pageLoadingState };
      }

      switch (current?.visibility) {
        case "open":
          return { visibility: "loaded", loading: current.loading };
        case "loaded":
          return current;
        case "spinning":
          return { visibility: "completed", loading: current.loading };
        case "completed":
          return { visibility: "closing", loading: current.loading };
        default:
          return { visibility: "closed" };
      }
    });
  }, [pageLoadingState]);

  const currentNoSpinnerTimeout =
    state?.visibility === "open" || state?.visibility === "loaded" ? noSpinnerTimeout : null;

  useLayoutEffect(() => {
    if (currentNoSpinnerTimeout != null) {
      if (!noSpinnerTimeoutRef.current) {
        noSpinnerTimeoutRef.current = setTimeout(() => {
          noSpinnerTimeoutRef.current = undefined;

          setState((current) =>
            current?.visibility === "open"
              ? { visibility: "spinning", loading: current.loading }
              : current?.visibility === "loaded"
              ? { visibility: "closing", loading: current.loading }
              : current
          );
        }, currentNoSpinnerTimeout);
      }
    } else {
      clearNoSpinnerTimeout();
    }
  }, [currentNoSpinnerTimeout, noSpinnerTimeoutRef, clearNoSpinnerTimeout]);

  const currentSpinnerTimeout =
    state?.visibility === "spinning" || state?.visibility === "completed"
      ? state.loading === "initial"
        ? minInitialSpinnerTimeout
        : minSecondarySpinnerTimeout
      : null;

  useLayoutEffect(() => {
    if (currentSpinnerTimeout != null) {
      if (!spinnerTimeoutRef.current) {
        spinnerTimeoutRef.current = setTimeout(() => {
          spinnerTimeoutRef.current = undefined;

          setState((current) =>
            current?.visibility === "spinning"
              ? { visibility: "completed", loading: current.loading }
              : current?.visibility === "completed"
              ? { visibility: "closing", loading: current.loading }
              : current
          );
        }, currentSpinnerTimeout);
      }
    } else {
      clearSpinnerTimeout();
    }
  }, [currentSpinnerTimeout, spinnerTimeoutRef, clearSpinnerTimeout]);

  useLayoutEffect(() => {
    if (!publicApiMode) {
      initialized(true);
    }

    document.body.classList.toggle(hideScrollClassName, isVisible(state));

    if (state && state.visibility !== "open") {
      document.body.classList.remove("loading");
    }
  }, [state, publicApiMode]);

  const onTransitionEnd = useCallback((event: React.TransitionEvent<HTMLDivElement>) => {
    if (event.propertyName === "opacity") {
      setState((current) => (current?.visibility === "closing" ? { visibility: "closed" } : current));
    }
  }, []);

  return !isVisible(state)
    ? null
    : ReactDOM.createPortal(
        <div
          className={classNames(styles.container, {
            [styles.closing]: state.visibility === "closing",
            [styles.secondary]: state.loading === "secondary",
            [styles.publicApiMode]: publicApiMode,
          })}
          onTransitionEnd={onTransitionEnd}
        >
          {state.loading === "initial" ? (
            <>
              <Background />
              <div className={styles.content} style={{ height: `calc(50% + ${spinnerSize / 2}px)` }}>
                <div className={styles.header}>
                  <img src={nextZenLogoWhite} alt="logo" />
                </div>
                <Spinner className={styles.spinner} size={spinnerSize} colorScheme="orange" />
              </div>
              <div className={styles.copyright}>{copyright}</div>
            </>
          ) : (
            (state.visibility === "spinning" || state.visibility === "completed") && (
              <Spinner
                className={styles.spinner}
                size={spinnerSize}
                style={{ marginTop: -spinnerSize / 2, marginLeft: -spinnerSize / 2 }}
                colorScheme={publicApiMode ? "orange" : "brown"}
              />
            )
          )}
        </div>,
        document.body
      );
}

export default connect(mapState)(Loading);
