import IIConProps from "../IIconProps";

const IconProcessingStatus = (props: IIConProps) => {
  const color = props.color || "#606060";
  const width = props.width || 32;
  const height = props.width || 32;
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M9 16v9c.037.748.354.962 1 1h16a1 1 0 0 0 1-1V9.619h-3.79a1 1 0 0 1-1-1V5H19"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M22 5h1l4 4v2" />
        <g transform="translate(13.5 12)">
          <path
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m0 7.5 2.813-6 1.687 4 1.688-2L9 7.5z"
          />
          <circle fill={color} cx="6" cy="1" r="1" />
        </g>
        <g fill={color} fillRule="nonzero" stroke={color} strokeWidth=".5">
          <path d="m3.245 5.224-.057.05-2.043 2.062a.497.497 0 0 0 .009.711c.184.176.467.19.665.04l.057-.049 1.166-1.176V7c0 3.311 2.665 6 5.958 6 1.773 0 3.42-.785 4.544-2.12a.498.498 0 0 0-.067-.708.516.516 0 0 0-.719.066A4.9 4.9 0 0 1 9 11.994c-2.669 0-4.847-2.144-4.934-4.829L4.064 7l-.001-.139L5.23 8.038c.197.199.52.203.722.009a.497.497 0 0 0 .058-.654l-.049-.057L3.92 5.275a.517.517 0 0 0-.674-.05zM9 1a5.932 5.932 0 0 0-4.698 2.31.498.498 0 0 0 .096.705.516.516 0 0 0 .716-.095A4.905 4.905 0 0 1 9 2.006c2.669 0 4.847 2.144 4.934 4.829l.002.165.001.139-1.167-1.177a.516.516 0 0 0-.722-.009.497.497 0 0 0-.058.654l.049.057 2.042 2.061c.182.184.472.201.674.05l.057-.05 2.043-2.061a.497.497 0 0 0-.009-.711.517.517 0 0 0-.665-.04l-.057.049-1.166 1.176V7c0-3.311-2.665-6-5.958-6z" />
        </g>
      </g>
    </svg>
  );
};

export default IconProcessingStatus;
