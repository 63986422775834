import { IDownloadResolutionsCodes, MediaDownloadResolutionCode } from "../../models/downloadMedia";
import {
  IGallery,
  IGalleryCoverMediaDescriptor,
  IGalleryDeleteInfo,
  IGalleryDetails,
  IGalleryMediaDescriptor,
  IGalleryMediaFile,
} from "../../models/gallery";
import { GenericState } from "../generic";

export const initialState: IGalleryState = {
  getGalleryById: { status: "Init" },
  deleteGalleryById: { status: "Init" },
  renameGallery: { status: "Init" },
  updateGalleryDescription: { status: "Init" },
  updateGalleryShootType: { status: "Init" },
  updateGalleryShootDate: { status: "Init" },
  updateGalleryTags: { status: "Init" },
  getGalleryDetailsById: { status: "Init" },
  setGalleryCoverMedia: { status: "Init" },
  deleteGalleryMedias: { status: "Init" },
  getGalleryMediaDownloadUrl: { status: "Init" },
  downloadGalleryMedias: { status: "Init" },
  getDeleteGalleryInfo: { status: "Init" },
};

/** State for detailed single gallery experience. */
export interface IGalleryState {
  gallery?: IGallery;
  fromBookingId?: string;
  getGalleryById: GetGalleryByIdState;
  deleteGalleryById: DeleteGalleryByIdState;
  renameGallery: RenameGalleryState;
  updateGalleryDescription: UpdateGalleryDescriptionState;
  updateGalleryShootType: UpdateGalleryShootTypeState;
  updateGalleryShootDate: UpdateGalleryShootDateState;
  updateGalleryTags: UpdateGalleryTagsState;
  getGalleryDetailsById: GetGalleryDetailsByIdState;
  setGalleryCoverMedia: SetGalleryCoverMediaState;
  deleteGalleryMedias: DeleteGalleryMediasState;
  getGalleryMediaDownloadUrl: GetGalleryMediaDownloadUrlState;
  downloadGalleryMedias: DownloadGalleryMediasState;
  getDeleteGalleryInfo: GetDeleteGalleryInfoState;
}

export type GetGalleryByIdState = GenericState<IGetGalleryByIdInput>;
export type DeleteGalleryByIdState = GenericState<IDeleteGalleryByIdInput>;
export type RenameGalleryState = GenericState<IRenameGalleryInput, IRenameGalleryResult>;
export type UpdateGalleryDescriptionState = GenericState<IUpdateGalleryDescriptionInput>;
export type UpdateGalleryShootTypeState = GenericState<IUpdateGalleryShootTypeInput>;
export type UpdateGalleryShootDateState = GenericState<IUpdateGalleryShootDateInput>;
export type UpdateGalleryTagsState = GenericState<IUpdateGalleryTagsInput>;
export type GetGalleryDetailsByIdState = GenericState<IGetGalleryDetailsByIdInput>;
export type SetGalleryCoverMediaState = GenericState<ISetGalleryCoverMediaInput>;
export type DeleteGalleryMediasState = GenericState<IDeleteGalleryMediasInput, IDeleteGalleryMediasResult>;
export type GetGalleryMediaDownloadUrlState = GenericState<
  IGetGalleryMediaDownloadUrlInput,
  IGetGalleryMediaDownloadUrlResult
>;
export type DownloadGalleryMediasState = GenericState<IDownloadGalleryMediasInput>;
export type GetDeleteGalleryInfoState = GenericState<IGetDeleteGalleryInfoInput, IGetDeleteGalleryInfoResult>;

export interface IGetGalleryByIdInput {
  galleryId: string;
}
export interface IDeleteGalleryByIdInput {
  galleryId: string;
}
export interface IRenameGalleryInput {
  galleryId: string;
  name: string;
}
export interface IRenameGalleryResult {
  newName: string;
}
export interface IUpdateGalleryDescriptionInput {
  galleryId: string;
  description: string;
}
export interface IUpdateGalleryShootTypeInput {
  galleryId: string;
  shootTypeId: number;
}
export interface IUpdateGalleryShootDateInput {
  galleryId: string;
  shootDate?: Date;
}
export interface IUpdateGalleryTagsInput {
  galleryId: string;
  tags: string[];
}
export interface IGetGalleryByIdResult {
  gallery: IGallery;
}
export interface IGetGalleryDetailsByIdInput {
  galleryId: string;
}
export interface IGetGalleryDetailsByIdResult {
  details: IGalleryDetails;
}
export interface ISetGalleryCoverMediaInput {
  galleryId: string;
  media: IGalleryCoverMediaDescriptor;
}

export interface IDeleteGalleryMediasInput {
  galleryId: string;
  medias: IGalleryMediaDescriptor[];
}

export interface IDeleteGalleryMediasResult {
  coverId?: string;
}

export interface IGetGalleryMediaDownloadUrlInput {
  galleryId: string;
  media: IGalleryMediaDescriptor;
  resolution: MediaDownloadResolutionCode;
}

export interface IGetGalleryMediaDownloadUrlResult {
  downloadUrl: string;
}

export interface IDownloadGalleryMediasInput {
  galleryId: string;
  medias: IGalleryMediaDescriptor[];
  resolutions: IDownloadResolutionsCodes;
}

export interface IDownloadGalleryMediasAutoInput extends IDownloadGalleryMediasInput {}

export interface IGetDeleteGalleryInfoInput {
  galleryId: string;
}

export interface IGetDeleteGalleryInfoResult {
  galleryDeleteInfo: IGalleryDeleteInfo;
}

export interface IMediaUploadSuccessInput {
  galleryId: string;
  media: IGalleryMediaDescriptor;
  collectionId: string | null;
  file: IGalleryMediaFile;
}

export interface IExpireProcessingMediasInput {
  mediaIds: Set<string>;
}

export interface IChangeGallerySharingInput {
  shareEnabled: boolean;
}

export interface IChangeGalleryExpirationInput {
  galleryActive: boolean;
  expirationDate?: Date;
}
