import React from "react";
import { Navigate, Route, Routes, matchRoutes } from "react-router-dom";
import AccountUrlManager from "./components/features/Account/UrlManager";
import AddToHomeUrlManager from "./components/features/AddToHome/UrlManager";
import ClientViewUrlManager from "./components/features/ClientView/UrlManager";
import DashboardUrlManager from "./components/features/Dashboard/UrlManager";
import GalleriesUrlManager from "./components/features/Galleries/UrlManager";
import GalleryUrlManager from "./components/features/Gallery/UrlManager";
import InstagramUrlManager from "./components/features/InstagramSelection/UrlManager";
import { render } from "./components/features/Lazy/helpers";
import BookingsUrlManager from "./components/features/Notifications/Bookings/UrlManager";
import NotificationsUrlManager from "./components/features/Notifications/UrlManager";
import OnBoardingUrlManager from "./components/features/OnBoarding/UrlManager";
import OrdersUrlManager from "./components/features/Orders/UrlManager";

import MoreUrlManager from "./components/features/More/UrlManager";
import usePageTracker from "./components/hooks/usePageTracker";
import { IUserInfo } from "./models/session";
import { defaultMobileUrl } from "./utilities/url";

const OnBoarding = React.lazy(() => import("./components/features/OnBoarding"));
const Orders = React.lazy(() => import("./components/features/Orders"));
const Dashboard = React.lazy(() => import("./components/features/Dashboard"));
const Notifications = React.lazy(() => import("./components/features/Notifications/NotificationsRoutes"));

const Gallery = React.lazy(() => import("./components/features/Gallery"));
const Galleries = React.lazy(() => import("./components/features/Galleries"));
const More = React.lazy(() => import("./components/features/More/MoreRoutes"));
const OrderDetailContainer = React.lazy(() => import("./components/features/OrderDetailContainer"));
const InstagramSelection = React.lazy(() => import("./components/features/InstagramSelection"));
const UploadInstagramPhotos = React.lazy(
  () => import("./components/features/InstagramSelection/UploadInstagramPhotos")
);
const BookingRoutes = React.lazy(() => import("./components/features/Notifications/Booking/BookingRoutes"));
const Account = React.lazy(() => import("./components/features/Account"));
const ClientView = React.lazy(() => import("./components/features/ClientView"));
const AddToHome = React.lazy(() => import("./components/features/AddToHome"));

const V2_ONBOARDING_FLOW_IDENTIFIER = "v2";

export const isV2OnboardingFlow = (userInfo: IUserInfo) => {
  return !!userInfo?.identitySegment?.signupMedium?.includes(V2_ONBOARDING_FLOW_IDENTIFIER);
};

export function isOnBoardingUrl(url?: string) {
  return matchRoutes(
    [
      { path: OnBoardingUrlManager.route },
      { path: InstagramUrlManager.route },
      { path: InstagramUrlManager.uploadRoute },
    ],
    url || window.location
  );
}

interface IAppRoutes {
  userInfo: IUserInfo;
}

const AppRoutes = ({ userInfo }: IAppRoutes) => {
  usePageTracker(userInfo);

  return (
    <Routes>
      {!isOnBoardingUrl() && !userInfo.onBoardingCompleted && !isV2OnboardingFlow(userInfo) && (
        <Route path="*" element={<Navigate to={OnBoardingUrlManager.prefix} replace={true} />} />
      )}
      <Route path={OnBoardingUrlManager.route} element={render(OnBoarding)} />
      <Route path={OrdersUrlManager.route} element={render(Orders)} />
      <Route path={DashboardUrlManager.route} element={render(Dashboard)} />
      <Route path={NotificationsUrlManager.route} element={render(Notifications)} />
      <Route path={GalleriesUrlManager.route} element={render(Galleries)} />
      <Route path={OrdersUrlManager.orderDetailPath} element={render(OrderDetailContainer)} />
      <Route path={GalleryUrlManager.route} element={render(Gallery)} />
      <Route path={MoreUrlManager.route} element={render(More)} />
      <Route path={InstagramUrlManager.route} element={render(InstagramSelection)} />
      <Route path={InstagramUrlManager.uploadRoute} element={render(UploadInstagramPhotos)} />
      <Route path={BookingsUrlManager.route} element={render(BookingRoutes)} />
      <Route path={AccountUrlManager.route} element={render(Account)} />
      <Route path={ClientViewUrlManager.route} element={render(ClientView)} />
      <Route path={AddToHomeUrlManager.route} element={render(AddToHome)} />
      <Route path="*" element={<Navigate to={defaultMobileUrl} replace={true} />} />
    </Routes>
  );
};

export default AppRoutes;
