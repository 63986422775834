import { commentingApi } from "..";
import { ICommentDetail } from "../../models/comments";
import { mapCommentMedias } from "./mappers";
import {
  ICommentsParams,
  IPostCommentParams,
  IThreadDto,
  IThreadParams,
  IUnreadComments,
  IUnreadCommentsDto,
  IUnreadCommentsTotalDto,
} from "./models";

export interface IApiComments {
  getUnreadComments: (params: ICommentsParams) => Promise<IUnreadComments>;
  getUnreadCommentsTotal: () => Promise<IUnreadCommentsTotalDto>;
  getThreadComment: (params: IThreadParams) => Promise<IThreadDto>;
  postComment: (payload: IPostCommentParams) => Promise<ICommentDetail>;
}

const comments: IApiComments = {
  getUnreadComments: async (params?: ICommentsParams) => {
    const { comments, count } = (await commentingApi.get<IUnreadCommentsDto>("comment/unread", { params })).data;
    return {
      comments: mapCommentMedias(comments),
      count,
    };
  },
  getUnreadCommentsTotal: async () => (await commentingApi.get<IUnreadCommentsTotalDto>("comment/unread/count")).data,
  getThreadComment: async (params: IThreadParams) => (await commentingApi.get<IThreadDto>("thread", { params })).data,
  postComment: async (payload: IPostCommentParams) =>
    (await commentingApi.post<ICommentDetail>("comment", { ...payload })).data,
};

export default comments;
