import { DeviceInfo } from "@zenfolio/core-components/dist/utilities/notification/constants";
import { AxiosPromise } from "axios";
import { notificationApi } from "..";
import { ISubscribedDevicesDto } from "./models";

export interface IApiNotifications {
  subscribeDeviceToReceiveNotification: (deviceInfo: DeviceInfo) => AxiosPromise<{ deviceId: string }>;
  getSubscribedDevices: () => AxiosPromise<ISubscribedDevicesDto>;
  unSubscribeDevices: (deviceId: string) => Promise<void>;
}

const notifications: IApiNotifications = {
  subscribeDeviceToReceiveNotification: (deviceInfo: DeviceInfo) =>
    notificationApi.post("webpush/user/device", deviceInfo),
  getSubscribedDevices: () => notificationApi.get("webpush/user/devices"),
  unSubscribeDevices: (deviceId: string) =>
    notificationApi.delete("webpush/user/devices", { data: { deviceIds: [deviceId] } }),
};

export default notifications;
