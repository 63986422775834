import IIConProps from "../IIconProps";

const IconCancelledStatus = (props: IIConProps) => {
  const color = props.color || "#606060";
  const width = props.width || 32;
  const height = props.width || 32;
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g stroke={color} strokeWidth="1.5" fill="none" fillRule="evenodd">
        <g transform="translate(1)">
          <path d="M16 7h2a2 2 0 0 1 2 2v14h-9m-5.37-.543A1.994 1.994 0 0 1 5 21v-3" strokeLinecap="round" />
          <path d="M20 11h2l4.707 4.707a1 1 0 0 1 .293.707V17h-7v-6z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 23h-1v-6h8v6h-2" />
          <circle cx="23.5" cy="24.5" r="2.5" />
          <circle cx="8.5" cy="24.5" r="2.5" />
          <path strokeLinecap="round" d="M26 23h3M11 23h9M3 23h3" />
        </g>
        <path d="M15 9c0 3.867-3.133 7-7 7s-7-3.133-7-7 3.133-7 7-7 7 3.133 7 7z" strokeLinejoin="round" />
        <path strokeLinecap="round" d="m3.781 4.313 8.438 9.375" />
      </g>
    </svg>
  );
};

export default IconCancelledStatus;
