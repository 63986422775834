import { IUnreadComment } from "../../models/comments";
import { GenericState } from "../generic";

export const initialState: ICommentsState = {
  unreadComments: [],
  unreadCommentsTotal: 0,
  getUnreadComments: { status: "Init" },
  getUnreadCommentsTotal: { status: "Init" },
  hasNextPage: false,
};

export interface ICommentsState {
  unreadComments: IUnreadComment[];
  getUnreadComments: GenericState;
  getUnreadCommentsTotal: GenericState;
  unreadCommentsTotal: number;
  hasNextPage: boolean;
}

export interface IUnreadCommentsPayload {
  comments: IUnreadComment[];
  hasNextPage: boolean;
  total: number;
}
