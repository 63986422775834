import { generatePath } from "react-router-dom";
import { iframeParams } from "../../../../models/iframe";
import { buildIframePath } from "../../../../utilities/iframe";
import { addQueryParams } from "../../../../utilities/query";
import { BookingActionType } from "../Booking/UpcomingBooking/BookingAction";

export default class UrlManager {
  public static getBookingPath(type: bookingType): string {
    return `${this.prefix}/${type}/:${this.bookingIdParam}`;
  }

  public static getActionPath(action: BookingActionType, type: bookingType): string {
    return `${this.prefix}/${type}/:${this.bookingIdParam}/${action}`;
  }

  public static booking(id: string, type: bookingType): string {
    return generatePath(this.getBookingPath(type), {
      [this.bookingIdParam]: id,
    });
  }

  public static billing(id: string): string {
    return this.addIframePath(
      generatePath(this.getActionPath("billing", "upcoming"), {
        [this.bookingIdParam]: id,
      })
    );
  }

  public static reschedule(id: string, type: bookingType): string {
    return this.addIframePath(
      generatePath(this.getActionPath("reschedule", type), {
        [this.bookingIdParam]: id,
      })
    );
  }

  private static addIframePath(path: string): string {
    return addQueryParams(path, {
      [iframeParams.path]: buildIframePath("", { hideLayout: "true" }),
    });
  }

  public static readonly bookingIdParam = "bookingId";
  public static readonly prefix = "/bookings";
  public static readonly desktopPrefix = "/bookme";
  public static readonly route = `${UrlManager.prefix}/*`;
  public static readonly bookingTypeParam = "bookingType";
  public static readonly desktopRoute = `${UrlManager.desktopPrefix}${UrlManager.prefix}/:${this.bookingTypeParam}/:${this.bookingIdParam}`;
}

export type bookingType = "pending" | "upcoming";
