import { createModel } from "@rematch/core";
import api from "../../api";
import { IGetUnreadCommentsParams, IUnreadCommentsTotalDto } from "../../api/comments/models";
import { getErrorFromApi } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import { ICommentsState, IUnreadCommentsPayload, initialState } from "./model";

export const COMMENTS_PAGE_SIZE = 20;

export const comments = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...getGenericReducers("getUnreadComments")<ICommentsState, {}, IUnreadCommentsPayload>({
      success(_state, { comments, hasNextPage, total }) {
        return {
          unreadComments: comments,
          hasNextPage,
          unreadCommentsTotal: total,
        };
      },
    }),
    ...getGenericReducers("getUnreadCommentsTotal")<ICommentsState, {}, IUnreadCommentsTotalDto>({
      success(_state, payload) {
        return {
          unreadCommentsTotal: payload.count,
        };
      },
    }),
  },
  effects: (dispatch) => ({
    async getUnreadCommentsAsync({ take, skip, pageNumber }: IGetUnreadCommentsParams, state) {
      dispatch.comments.getUnreadCommentsStarted({});
      try {
        const { comments = [], count = 0 } = await api.comments.getUnreadComments({
          take,
          skip,
        });
        const hasNextPage = comments.length < COMMENTS_PAGE_SIZE ? false : true;
        let newComments = [...comments];
        if (pageNumber > 1) {
          newComments = [...state.comments.unreadComments, ...comments];
        }
        dispatch.comments.getUnreadCommentsSuccess({ comments: newComments, hasNextPage, total: count });
      } catch (error) {
        dispatch.comments.getUnreadCommentsError({ error: getErrorFromApi(error) });
      }
    },
    async getUnreadCommentsTotalAsync() {
      dispatch.comments.getUnreadCommentsTotalStarted({});
      try {
        const { count } = await api.comments.getUnreadCommentsTotal();
        dispatch.comments.getUnreadCommentsTotalSuccess({ count });
      } catch (error) {
        dispatch.comments.getUnreadCommentsTotalError({ error: getErrorFromApi(error) });
      }
    },
  }),
});
