import { GalleryRecentBy, ICounters, IRecentGalleries } from "../../models/galleries";
import { GenericState } from "../generic";

export const initialState: IGalleriesState = {
  getRecentGalleries: { status: "Init" },
  getRecentGalleriesByType: { status: "Init" },
  getCounters: { status: "Init" },
};

/** State for galleries page experience. */
export interface IGalleriesState {
  getRecentGalleries: GetRecentGalleriesState;
  getRecentGalleriesByType: GetRecentGalleriesByTypeState;
  getCounters: GetCountersState;
  recentlyModifiedGalleries?: IRecentGalleries;
  activeGalleries?: IRecentGalleries;
}

export type GetRecentGalleriesState = GenericState<IGetRecentGalleriesInput>;
export type GetRecentGalleriesByTypeState = GenericState<IGetRecentGalleriesByTypeInput>;
export type GetCountersState = GenericState<IGetCountersInput, IGetCountersResult>;

export interface IGetCountersInput {}
export interface IGetCountersResult {
  counters: ICounters;
}

export interface IGetRecentGalleriesInput {
  query: IPaginationQuery;
}
export interface IGetRecentGalleriesResult {
  recentlyModifiedGalleries: IRecentGalleries;
  activeGalleries: IRecentGalleries;
}

export interface IPaginationQuery {
  skip: number;
  take: number;
}

export interface IGetRecentGalleriesByTypeInput extends IGetRecentGalleriesInput {
  type: GalleryRecentBy;
}

export interface IGetRecentGalleriesByTypeResult {
  galleries: IRecentGalleries;
}
