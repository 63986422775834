import DashboardUrlManager from "../components/features/Dashboard/UrlManager";
import GalleriesUrlManager from "../components/features/Galleries/UrlManager";
import MoreUrlManager from "../components/features/More/UrlManager";
import NotificationsUrlManager from "../components/features/Notifications/UrlManager";
import OrdersUrlManager from "../components/features/Orders/UrlManager";
import {
  iconNavDashboard,
  iconNavDashboardActive,
  iconNavGalleries,
  iconNavGalleriesActive,
  iconNavMore,
  iconNavMoreActive,
  iconNavNotification,
  iconNavNotificationActive,
  iconNavOrders,
  iconNavOrdersActive,
} from "../icons";
import { ITabData } from "../models/tab";
import { StrictOmit } from "../types";

export enum TAB_IDS {
  ORDERS_TO_APPROVE = 1,
  RESPOND_TO_COMMENTS = 2,
  BOOKINGS_TO_APPROVE = 3,
  UPCOMING_BOOKINGS = 4,
}

export const NOTIFICATION_TABS = [
  { title: "Orders to approve", id: TAB_IDS.ORDERS_TO_APPROVE, path: NotificationsUrlManager.ordersToApproveRoute },
  {
    title: "Respond to comments",
    id: TAB_IDS.RESPOND_TO_COMMENTS,
    path: NotificationsUrlManager.respondToCommentsRoute,
  },
  {
    title: "Bookings to approve",
    id: TAB_IDS.BOOKINGS_TO_APPROVE,
    path: NotificationsUrlManager.bookingsToApproveRoute,
  },
  { title: "Upcoming bookings", id: TAB_IDS.UPCOMING_BOOKINGS, path: NotificationsUrlManager.upcomingBookingsRoute },
];

export const PAPER_PRINT_BLACK_AND_WHITE_NAME = "True B&W";

export const BASE_PRICE_OPTION_ID = "3eefd8a7-13a2-4369-95e5-5c63f1bdcd28";

export const PIXEL_PER_INCH = 96;

export enum OPTION_NAME {
  PAPER_TYPE = "Paper Type",
  FRAME = "Frames",
  MAT = "Mat",
  WRAP_TYPE = "Wrap Style",
}

function buildTab(
  urlManager: { route: string; prefix: string },
  data: StrictOmit<ITabData, "route" | "prefix">
): ITabData {
  return {
    ...data,
    route: urlManager.route,
    prefix: urlManager.prefix,
  };
}

export const TABS_TITLE = {
  DASH_BOARD: "Dashboard",
  ORDERS: "Orders",
  NOTIFICATIONS: "Notifications",
  GALLERIES: "Galleries",
  MORE: "More",
};

export const TabsConfig: ITabData[] = [
  buildTab(DashboardUrlManager, {
    title: TABS_TITLE.DASH_BOARD,
    icon: iconNavDashboard,
    activeIcon: iconNavDashboardActive,
  }),
  buildTab(OrdersUrlManager, {
    title: TABS_TITLE.ORDERS,
    icon: iconNavOrders,
    activeIcon: iconNavOrdersActive,
  }),
  buildTab(NotificationsUrlManager, {
    title: TABS_TITLE.NOTIFICATIONS,
    icon: iconNavNotification,
    activeIcon: iconNavNotificationActive,
    isNotifications: true,
  }),
  buildTab(GalleriesUrlManager, {
    title: TABS_TITLE.GALLERIES,
    icon: iconNavGalleries,
    activeIcon: iconNavGalleriesActive,
  }),
  buildTab(MoreUrlManager, {
    title: TABS_TITLE.MORE,
    icon: iconNavMore,
    activeIcon: iconNavMoreActive,
  }),
];

export const baseTooltipProps = {
  openOnClick: true,
  clickable: true,
};

export const RECENT_GALLERIES_INITIAL_LOAD_SIZE = 18;
export const RECENT_GALLERIES_TO_SHOW_ON_GALLERIES_PAGE = 9;

export const maxGalleryTags = 50;
export const maxGalleryTagLength = 40;
export const maxGalleryNameLength = 255;
export const maxGalleryDescriptionLength = 750;

export const defaultProgressSize = 126;
export const defaultProgressIconSize = 48;
export const defaultProgressBorderWidth = 6;

export const onBoardingPageTitle = "Welcome";

// both classes have the same meaning, but we need both to avoid some issues with concurrency
export const hideScrollClassName = "hideScroll";
export const disableScrollClassName = "disableScroll";

export const REGEX_FOLDER_NAME = /[^<>:"/\\?*|`]/g;
export const REGEX_FOLDER_NAME_ERROR_MESSAGE = 'Please remove invalid characters * | : " < > ? \\ / `';
export const FOLDER_NAME_MAX_LENGTH = 255;

export const LOCAL_STORAGE_HIDE_TRIAL_BANNER_PARAM = "hide_trial_banner";

export const INSTA_UPLOADS_CANCEL_DISCLAIMER =
  "Instagram uploads cannot be canceled and will remain in progress until completion.";
