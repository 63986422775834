import cx from "classnames";
import * as React from "react";
import ReactDOM from "react-dom";

import { useWindowSize } from "@zenfolio/core-components";
import { useLayoutEffect } from "react";
import { useMount, useUpdate } from "react-use";
import closeIcon from "./close.svg";
import styles from "./index.module.scss";
import rotateIcon from "./rotate.svg";

interface ILandscapeWarningProps {
  className?: string;
  style?: React.CSSProperties;
}

function LandscapeWarning(props: ILandscapeWarningProps) {
  const { className, style } = props;

  const windowSize = useWindowSize(0);
  const update = useUpdate();

  useMount(onComponentMount);
  useLayoutEffect(onComponentUpdate);

  function onComponentMount() {
    const observer = new MutationObserver(onMutation);
    observer.observe(document.documentElement, { attributes: true, attributeFilter: ["class"] });

    function onMutation() {
      update();
    }

    return function onComponentUnmount() {
      observer.disconnect();
    };
  }

  function onComponentUpdate() {
    document.documentElement.classList.toggle(landscapeWarningClassName, show);
  }

  function onCloseClick() {
    dismissed = true;
    update();
  }

  const isLandscape = windowSize.width > windowSize.height;
  const landscapeSupported = document.documentElement.classList.contains(landscapeSupportedClassName);
  const show = !dismissed && isLandscape && !landscapeSupported;

  return show
    ? ReactDOM.createPortal(
        <div className={cx(styles.container, className)} style={style}>
          <img src={closeIcon} alt="Dismiss" onClick={onCloseClick} className={styles.close} />
          <div className={styles.body}>
            <img src={rotateIcon} alt="Rotate" />
            <div className={styles.brand}>zenfolio.com</div>
            <div className={styles.message}>is best viewed in portrait mode</div>
          </div>
        </div>,
        document.body
      )
    : null;
}

let dismissed: boolean = false;

/* Should be applied to the document element. */
export const landscapeSupportedClassName: string = "landscape-supported";

/* Should be applied to the document element. */
export const landscapeWarningClassName: string = "landscape-warning";

export default LandscapeWarning;
