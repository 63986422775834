import { RematchDispatch } from "@rematch/core";
import axios, { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import MediaInfoFactory from "mediainfo.js";
import { GetSizeFunc, MediaInfo, ReadChunkFunc, Result, ResultObject } from "mediainfo.js/dist/types";
import moment from "moment";
import { v4 } from "uuid";
import api, { getBearerToken, hasAnyErrorType } from "../../api";
import { mapGalleryUploadNotification } from "../../api/gallery/mappers";
import { IGalleryUploadNotificationDto } from "../../api/gallery/models";
import { IGetVideoUploadUri, IInstagramMediaInfo, IMediaMetadata, IVideoWatermark } from "../../api/upload/models";
import { GalleryMedia, IGalleryUploadNotification, emptyGalleryUrlTemplates } from "../../models/gallery";
import { FeatureCode } from "../../models/session";
import { GtmEventCategory } from "../../models/userEvent";
import { IWatermark } from "../../models/watermarks";
import { isVideoFile } from "../../utilities/files";
import {
  isMediaError as isGalleryMediaError,
  isGalleryVideo,
  isMediaProcessed,
  toGalleryMediaType,
  trackGalleryMedia,
} from "../../utilities/gallery";
import { pushGtmEvent, pushGtmEventAsync, staticGaEventId } from "../../utilities/gtm";
import { isEmptyGuid, retry, stringsAreEqualIgnoreCase } from "../../utilities/helpers";
import { isInstagramVideo } from "../../utilities/instagram";
import * as logging from "../../utilities/logging";
import { MAX_PHOTO_SIZE, MAX_VIDEO_SIZE } from "../../utilities/media";
import { getImpersonateToken, getToken } from "../../utilities/token";
import { RootModel, RootState, getState } from "../store";
import { VALID_PROGRESS_CALCULATION_STATES } from "./constants";
import {
  BackgroundJob,
  IInstagramFile,
  IInstagramJobPayload,
  IInstagramUploadBackgroundJob,
  IJob,
  IMediaError,
  IMediaUploadBackgroundJob,
  IPhotoPayload,
  IThumbnailPayload,
  IUploadFile,
  IUploadJobPayload,
  IVideoPayload,
  JobState,
  JobType,
  MAX_SIMULTANEOUS_UPLOADS,
  MIN_NETWORK_SPEED,
  REMAINING_TIME_UPDATE_FREQUENCY_MS,
  SPEED_MEASUREMENT_WINDOW_MS,
  maxGifPhotoHeight,
  maxGifPhotoWidth,
  maxVideoLength,
  maxVideoRate,
  maxVideoSize1080p,
  maxVideoSize4k,
  photoSizeError,
  unsupportedFileTypeError,
  uploadFailedError,
  uploadingCompleteDescription,
  videoSizeError,
} from "./models";
import { parentUploadJobsSelector } from "./selectors";

export async function createUploadJob(dispatch: RematchDispatch<RootModel>, payload: IUploadJobPayload) {
  const jobId: string = v4();

  if (payload.track) {
    let photosCount = 0;
    let videosCount = 0;

    _.each(payload.files, (file) => {
      if (!file.error) {
        if (isVideoFile(file.file)) {
          videosCount++;
        } else {
          photosCount++;
        }
      }
    });

    trackGalleryMedia(payload.galleryId, photosCount, videosCount, payload.isNew, payload.preset);
  }

  // Don't sort files by name when replacing photo in order detail page
  const sortedFiles = [...payload.files];
  if (_.isEmpty(payload.originalPhotoIds)) {
    const sortIndices = await api.upload.getFilesSortedByNaturalOrder(
      _.map(payload.files, (f) => f.file.name),
      false
    );

    _.each(sortIndices, (sortedIndex, index) => {
      sortedFiles[index] = payload.files[sortedIndex];
    });
  }

  // First, create the gallery upload job
  const galleryMediaFile = sortedFiles[0].file;
  const galleryObjectUrl = URL.createObjectURL(galleryMediaFile);
  const uploadBatchTimestamp = moment().valueOf();

  const galleryJob: IMediaUploadBackgroundJob = {
    id: jobId,
    jobType: JobType.MediaUpload,
    galleryId: payload.galleryId,
    galleryName: payload.galleryName,
    description: buildUploadDescription(payload.galleryName),
    mediaFile: galleryMediaFile,
    objectUrl: galleryObjectUrl,
    state: JobState.Queued,
    progressStatus: 0,
    progressStatusText: "",
    loaded: 0,
    speedText: "",
    speedError: false,
    collectionId: payload.collectionId,
    uploadBatchTimestamp,
    isFirstMedia: false,
    isNew: payload.isNew,
  };

  if (payload.duplicatesHandling !== "allow") {
    await findDuplicates(
      payload.galleryId,
      payload.isNew !== false,
      sortedFiles,
      (f) => {
        f.isDuplicate = true;
      },
      (f) => f.file.name
    );
  }

  filterFilesBySize(sortedFiles);

  dispatch.backgroundJobs.addBackgroundJob({ job: galleryJob });

  let firstMediaSelected = false;

  // Now for each media to upload, create a child media upload job
  _.each(sortedFiles, (file, index) => {
    const mediaJob: IMediaUploadBackgroundJob = {
      id: v4(),
      galleryId: payload.galleryId,
      galleryName: payload.galleryName,
      parentId: jobId,
      description: file.file.name,
      jobType: JobType.MediaUpload,
      loaded: file.isDuplicate || file.error ? file.file.size : 0,
      mediaFile: file.file,
      objectUrl: index === 0 ? galleryObjectUrl : URL.createObjectURL(file.file),
      state: file.isDuplicate ? JobState.Duplicate : file.error ? JobState.Error : JobState.Queued,
      progressStatus: file.isDuplicate || file.error ? 100 : 0,
      progressStatusText: file.isDuplicate ? "Duplicate Skipped" : file.error || "",
      speedText: "",
      speedError: false,
      collectionId: payload.collectionId,
      uploadBatchTimestamp,
      isFirstMedia: false,
      orderId: payload.orderIds?.[index],
      originalMediaId: payload.originalPhotoIds?.[index],
      videoWatermark: file.videoWatermark,
    };

    if (!firstMediaSelected && mediaJob.state === JobState.Queued) {
      mediaJob.isFirstMedia = true;
      firstMediaSelected = true;
    }

    dispatch.backgroundJobs.addBackgroundJob({ job: mediaJob });
  });

  refreshJobs(dispatch, jobId);
}

export function createInstagramJob(dispatch: RematchDispatch<RootModel>, payload: IInstagramJobPayload): string {
  if (payload.isNew) {
    const event = getInstagramEventDetail(payload.files);
    pushGtmEventAsync({
      category: GtmEventCategory.MobileGalleryUpload,
      name: "select photos to add",
      detail: event,
      ...event,
    });
  }

  const job: IInstagramUploadBackgroundJob = {
    id: v4(),
    jobType: JobType.InstagramUpload,
    galleryId: payload.galleryId,
    galleryName: payload.galleryName,
    collectionId: payload.collectionId,
    description: buildUploadDescription(payload.galleryName),
    files: payload.files,
    isNew: payload.isNew,
    speedText: "",
    speedError: false,
    uploadBatchTimestamp: moment().valueOf(),
    ...calculateInstagramJobProgress(payload.files),
  };

  dispatch.backgroundJobs.addBackgroundJob({ job });

  return job.id;
}

export function failInstagramJob(dispatch: RematchDispatch<RootModel>, jobId: string) {
  const instagramJob = _.find(getJobs(), (j) => j.id === jobId);
  if (instagramJob?.jobType !== JobType.InstagramUpload) {
    return;
  }

  const files = _.map(instagramJob.files, (file) =>
    isJobActive(file)
      ? {
          ...file,
          state: JobState.Error,
        }
      : file
  );

  dispatch.backgroundJobs.updateBackgroundJob({
    job: {
      ...instagramJob,
      files,
      ...calculateInstagramJobProgress(files),
    },
  });
}

export function refreshInstagramJobs(dispatch: RematchDispatch<RootModel>, payload: IGalleryUploadNotificationDto) {
  let notification: IGalleryUploadNotification | undefined;

  _.each(getJobs(), (job) => {
    if (job.jobType === JobType.InstagramUpload && job.galleryId === payload.albumId && isJobActive(job)) {
      if (!notification) {
        notification = mapGalleryUploadNotification(payload, emptyGalleryUrlTemplates);
      }

      const files = [...job.files];

      _.chain<GalleryMedia>(notification.updatedPhotos)
        .concat(notification.updatedVideos)
        .each((media) => {
          const fileIndex = _.findIndex(
            files,
            (f) =>
              isJobActive(f) &&
              stringsAreEqualIgnoreCase(f.name, media.file.name) &&
              isInstagramVideo(f.media) === isGalleryVideo(media)
          );

          if (fileIndex >= 0) {
            files[fileIndex] = {
              ...files[fileIndex],
              state: isGalleryMediaError(media)
                ? JobState.Error
                : isMediaProcessed(media)
                ? JobState.Completed
                : JobState.Progress,
            };
          }
        })
        .value();

      dispatch.backgroundJobs.updateBackgroundJob({
        job: {
          ...job,
          files,
          ...calculateInstagramJobProgress(files),
        },
      });
    }
  });
}

export async function cancelJob(dispatch: RematchDispatch<RootModel>, jobId: string) {
  const jobs = getJobs();
  const job = _.find(jobs, (j) => j.id === jobId && !j.parentId);

  await cancelActiveJob(dispatch, job);
}

export async function cancelAllUploadJobs(state: RootState, dispatch: RematchDispatch<RootModel>) {
  const parentUploadJobs = parentUploadJobsSelector(state);
  for (const parentUploadJob of parentUploadJobs) {
    await cancelActiveJob(dispatch, parentUploadJob);
  }
}

export async function cancelJobsByGalleryIds(dispatch: RematchDispatch<RootModel>, galleryIds: string[]) {
  if (_.isEmpty(galleryIds)) {
    return;
  }

  const jobs = getJobs();
  const jobsToCancel = _.filter(
    jobs,
    (j) => _.some(galleryIds, (galleryId) => galleryId === j.galleryId) && !j.parentId
  );

  for (const job of jobsToCancel) {
    await cancelActiveJob(dispatch, job);
  }
}

export async function findDuplicates<T>(
  galleryId: string,
  isNew: boolean,
  sortedFiles: T[],
  setIsDuplicate: (file: T) => void,
  getFileName: (file: T) => string
) {
  const jobs = getJobs();
  const knownFiles: Record<string, boolean> = {};
  const potentialDuplicateStates = [JobState.Queued, JobState.Progress, JobState.Completed, JobState.Duplicate];

  for (const job of jobs) {
    if (
      job.jobType === JobType.MediaUpload &&
      job.galleryId === galleryId &&
      job.mediaFile &&
      job.mediaFile.name &&
      _.includes(potentialDuplicateStates, job.state)
    ) {
      knownFiles[job.mediaFile.name.toLowerCase()] = true;
    }
  }

  const filesForApiCheck: Record<string, T> = {};

  for (const file of sortedFiles) {
    const fileName = getFileName(file);
    if (fileName) {
      const lowerCaseName = fileName.toLowerCase();

      if (knownFiles[lowerCaseName]) {
        setIsDuplicate(file);
      } else {
        knownFiles[lowerCaseName] = true;

        if (!isNew) {
          filesForApiCheck[fileName] = file;
        }
      }
    }
  }

  if (!_.isEmpty(filesForApiCheck)) {
    const duplicatedFileNames = await api.upload.checkGalleryDuplicates(galleryId, _.keys(filesForApiCheck));

    if (!_.isEmpty(duplicatedFileNames)) {
      for (const duplicatedFileName of duplicatedFileNames) {
        const duplicatedFile = filesForApiCheck[duplicatedFileName];
        if (duplicatedFile) {
          setIsDuplicate(duplicatedFile);
        }
      }
    }
  }
}

export function tryPublishGtmUploadEvent(jobs: BackgroundJob[], job: BackgroundJob) {
  if (job.parentId || isJobActive(job) || !isUploadJob(job) || !job.isNew || job.gtmEventPushed) {
    return;
  }

  let event: Record<string, string>;

  if (job.jobType === JobType.InstagramUpload) {
    event = getInstagramEventDetail(job.files);
  } else {
    const childJobs = findChildMediaUploadJobs(job.id, jobs);
    const counts = _.countBy(childJobs, (j) => isVideoFile(j.mediaFile!));
    event = {
      instagram_photos: "0",
      instagram_videos: "0",
      cameraroll_photos: (counts.false ?? 0).toString(),
      cameraroll_videos: (counts.true ?? 0).toString(),
    };
  }

  event["upload_time_seconds"] = Math.max(
    Math.ceil((moment().valueOf() - job.uploadBatchTimestamp) / 1000),
    0
  ).toString();

  job.gtmEventPushed = true;

  pushGtmEventAsync({
    category: GtmEventCategory.MobileGalleryUpload,
    name: "upload selected photos",
    detail: event,
    ...event,
  });
}

function filterFilesBySize(sortedFiles: IUploadFile[]) {
  for (const sortedFile of sortedFiles) {
    const acceptedFile = isVideoFile(sortedFile.file)
      ? {
          maxFileSize: MAX_VIDEO_SIZE,
          errorMessage: videoSizeError,
        }
      : {
          maxFileSize: MAX_PHOTO_SIZE,
          errorMessage: photoSizeError,
        };

    if (!fileMatchSize(sortedFile.file, 0, acceptedFile.maxFileSize)) {
      sortedFile.error = acceptedFile.errorMessage;
    }
  }
}

function refreshJobs(dispatch: RematchDispatch<RootModel>, parentId: string) {
  calculateGalleryJobState(dispatch, parentId);
  processUploadQueue(dispatch, parentId);
}

async function cancelActiveJob(dispatch: RematchDispatch<RootModel>, job?: BackgroundJob) {
  if (job && job.state !== JobState.Canceled && job.state !== JobState.Completed) {
    if (job.jobType === JobType.MediaUpload) {
      cancelUploadJob(dispatch, job);
    } else if (job.jobType === JobType.InstagramUpload) {
      dispatch.backgroundJobs.removeBackgroundJob({ jobId: job.id });
    }
  }
}

const cancelUploadJob = (dispatch: RematchDispatch<RootModel>, job: BackgroundJob) => {
  const childJobs = findChildMediaUploadJobs(job.id);

  for (const childJob of childJobs) {
    if (childJob.state === JobState.Progress) {
      childJob.cancelTokenSource?.cancel();
    } else if (childJob.state !== JobState.Queued) {
      continue;
    }

    dispatch.backgroundJobs.updateBackgroundJob({
      job: {
        ...childJob,
        state: JobState.Canceled,
        progressStatus: 100,
        progressStatusText: "Canceled",
        loaded: childJob.mediaFile!.size,
      },
    });
  }

  dispatch.backgroundJobs.updateBackgroundJob({
    job: {
      ...job,
      state: JobState.Canceled,
      description: "Uploading Canceled!",
      progressStatus: 100,
      speedText: "",
      speedError: false,
    },
  });

  processUploadQueue(dispatch, job.id);
};

function calculateGalleryJobState(dispatch: RematchDispatch<RootModel>, parentId: string, force?: boolean) {
  const jobs = getJobs();
  const job = findMediaUploadJob(parentId, jobs);

  if (!job || job.state === JobState.Canceled) {
    return;
  }

  const childJobs = findChildMediaUploadJobs(parentId, jobs);
  const totalChildJobs = childJobs.length;

  let completedChildJobs: number = 0;
  let totalLoadedBytes: number = 0;
  let totalFileSize: number = 0;
  let processedFileSize: number = 0;

  for (const childJob of childJobs) {
    const fileSize = childJob.mediaFile!.size;

    if (isJobActive(childJob)) {
      processedFileSize += childJob.loaded;
      totalLoadedBytes += childJob.loaded;
    } else {
      completedChildJobs++;
      processedFileSize += fileSize;

      if (childJob.state === JobState.Completed) {
        totalLoadedBytes += fileSize;
      }
    }

    totalFileSize += fileSize;
  }

  const progressStatusText = `${completedChildJobs} of ${childJobs.length}`;

  if (completedChildJobs < totalChildJobs) {
    const currentTime = moment().valueOf();
    const updatedJob = {
      ...job,
      progressStatus: getPercentage(processedFileSize, totalFileSize),
      progressStatusText,
    };

    if (totalLoadedBytes > 0) {
      if (!updatedJob.lastSpeedMeasurementTimestamp) {
        updatedJob.lastSpeedMeasurementTimestamp = currentTime;
      } else {
        const elapsedTimeMs = currentTime - updatedJob.lastSpeedMeasurementTimestamp;
        if (elapsedTimeMs >= SPEED_MEASUREMENT_WINDOW_MS) {
          updatedJob.lastSpeedMeasurementTimestamp = currentTime;
          updatedJob.uploadSpeedInBytesPerSec = Math.max(
            ((totalLoadedBytes - updatedJob.loaded) * 1000) / elapsedTimeMs,
            0
          );
          updatedJob.loaded = totalLoadedBytes;
        }
      }

      if (!updatedJob.lastRemainingTimeUpdateTimestamp) {
        updatedJob.lastRemainingTimeUpdateTimestamp = currentTime;
      } else if (
        updatedJob.uploadSpeedInBytesPerSec != null &&
        currentTime - updatedJob.lastRemainingTimeUpdateTimestamp >= REMAINING_TIME_UPDATE_FREQUENCY_MS
      ) {
        const remainingFileSize = Math.max(totalFileSize - processedFileSize, 0);
        const remainingSec =
          updatedJob.uploadSpeedInBytesPerSec > 0 ? remainingFileSize / updatedJob.uploadSpeedInBytesPerSec : 0;
        const kilobitsPerSec = (updatedJob.uploadSpeedInBytesPerSec * 8) / 1000;
        const speedError = kilobitsPerSec <= MIN_NETWORK_SPEED;

        updatedJob.speedError = speedError;
        updatedJob.lastRemainingTimeUpdateTimestamp = currentTime;
        updatedJob.remainingSec = remainingSec;
        updatedJob.speedText = speedError
          ? "Experiencing network issues"
          : timeRemainingText(remainingSec) + "/" + speedText(kilobitsPerSec);
      }
    }

    dispatch.backgroundJobs.updateBackgroundJob({ job: updatedJob, force });
  } else {
    dispatch.backgroundJobs.updateBackgroundJob({
      job: {
        ...job,
        state: JobState.Completed,
        description: uploadingCompleteDescription,
        progressStatus: 100,
        progressStatusText,
        speedText: "",
        speedError: false,
      },
    });
  }
}

function processUploadQueue(dispatch: RematchDispatch<RootModel>, parentId: string) {
  let inProgressCount = 0;
  const queuedMedia: IMediaUploadBackgroundJob[] = [];
  const jobs = getJobs();

  let failedFirstMediaJob = _.find(
    jobs,
    (j) => j.jobType === JobType.MediaUpload && j.parentId === parentId && j.isFirstMedia && j.state === JobState.Error
  ) as IMediaUploadBackgroundJob | undefined;

  for (const job of jobs) {
    if (job.jobType === JobType.MediaUpload && job.parentId && job.jobType === JobType.MediaUpload) {
      if (job.state === JobState.Queued) {
        queuedMedia.push(job);
      } else if (job.state === JobState.Progress) {
        inProgressCount++;
      }
    }
  }

  const videoJobs = _.filter(queuedMedia, (j) => isVideoFile(j.mediaFile!));
  const photoJobs = _.filter(queuedMedia, (j) => !videoJobs.includes(j));

  const enrichJob = (job: IMediaUploadBackgroundJob): IMediaUploadBackgroundJob => {
    if (failedFirstMediaJob && job.parentId === parentId && !job.isFirstMedia) {
      job = {
        ...job,
        isFirstMedia: true,
      };
      dispatch.backgroundJobs.updateBackgroundJob({ job });
      dispatch.backgroundJobs.updateBackgroundJob({
        job: {
          ...failedFirstMediaJob,
          isFirstMedia: false,
        },
      });

      failedFirstMediaJob = undefined;
    }

    return job;
  };

  for (let i = 0; inProgressCount + i < MAX_SIMULTANEOUS_UPLOADS && i < photoJobs.length; i++) {
    startPhotoUpload(dispatch, enrichJob(photoJobs[i]));
  }

  if (!_.isEmpty(videoJobs) && _.isEmpty(photoJobs) && inProgressCount === 0) {
    const job = _.head(videoJobs);
    startVideoUpload(dispatch, enrichJob(job!));
  }
}

function handleUploadProgress(dispatch: RematchDispatch<RootModel>, jobId: string, loaded: number, force?: boolean) {
  const job = findMediaUploadJob(jobId);

  if (job && job.state !== JobState.Canceled) {
    const fileSize = job.mediaFile!.size;

    dispatch.backgroundJobs.updateBackgroundJob({
      job: {
        ...job,
        loaded,
        progressStatus: fileSize > 0 ? getPercentage(loaded, fileSize) : 0,
      },
      force,
    });

    calculateGalleryJobState(dispatch, job.parentId!, force);
  }
}

function uploadFinished(
  dispatch: RematchDispatch<RootModel>,
  jobId: string,
  mediaId: string,
  isVideo: boolean,
  sanitizedFileName?: string
) {
  const job = findMediaUploadJob(jobId);

  if (job && job.state !== JobState.Canceled) {
    const { name, size } = job.mediaFile!;

    dispatch.backgroundJobs.updateBackgroundJob({
      job: {
        ...job,
        state: JobState.Completed,
        progressStatus: 100,
        progressStatusText: "Completed",
        loaded: size,
        mediaId,
        sanitizedFileName,
      },
    });

    dispatch.gallery.mediaUploadSuccess({
      galleryId: job.galleryId,
      media: { id: mediaId, type: toGalleryMediaType(isVideo) },
      collectionId: getCollectionId(job),
      file: { name, size },
    });

    refreshJobs(dispatch, job.parentId!);
  }
}

function handleUploadError(dispatch: RematchDispatch<RootModel>, jobId: string, error: string) {
  const job = findMediaUploadJob(jobId);

  if (job && job.state !== JobState.Canceled) {
    dispatch.backgroundJobs.updateBackgroundJob({
      job: {
        ...job,
        state: JobState.Error,
        progressStatus: 100,
        progressStatusText: error,
        loaded: job.mediaFile!.size,
      },
    });

    refreshJobs(dispatch, job.parentId!);
  }
}

export function buildPhotoPayload(payload: IPhotoPayload) {
  return JSON.stringify({
    collectionId: payload.collectionId || undefined,
    uploadBatchTimestamp: payload.uploadBatchTimestamp,
    isFirstPhoto: payload.isFirst || undefined,
    orderId: payload.orderId || undefined,
    originalPhotoId: payload.originalPhotoId || undefined,
  });
}

export function buildVideoPayload(payload: IVideoPayload) {
  return JSON.stringify({
    collectionId: payload.collectionId || undefined,
    uploadBatchTimestamp: payload.uploadBatchTimestamp,
    isFirstVideo: payload.isFirst || undefined,
    authorizationHeader: getBearerToken(getToken()),
    impersonationHeader: getBearerToken(getImpersonateToken()),
    hasInitialThumbnail: payload.hasInitialThumbnail || undefined,
    gaEventId: payload.passGaEventId ? staticGaEventId : undefined,
  });
}

export function buildThumbnailPayload(payload: IThumbnailPayload) {
  return JSON.stringify({
    galleryId: payload.galleryId,
    thumbnailId: payload.currentThumbnailId || undefined,
    isInitial: payload.isInitial || undefined,
  });
}

export function buildInstagramMediaInfo(job: { galleryId: string; files: IInstagramFile[]; collectionId?: string }) {
  const uploadBatchTimestamp = moment().valueOf();
  const mediaInfos: IInstagramMediaInfo[] = _.chain(job.files)
    .filter((file) => file.state !== JobState.Duplicate)
    .map(({ media }, index) => {
      const isFirst = index === 0;
      const isVideo = isInstagramVideo(media);
      const collectionId = getCollectionId(job);
      const payload = isVideo
        ? buildVideoPayload({
            uploadBatchTimestamp,
            isFirst,
            collectionId,
            hasInitialThumbnail: !!media.thumbnailUrl,
          })
        : buildPhotoPayload({
            uploadBatchTimestamp,
            isFirst,
            collectionId,
          });
      const thumbnailPayload = buildThumbnailPayload({
        galleryId: job.galleryId,
        currentThumbnailId: media.thumbnailUrl,
        isInitial: true,
      });
      const mediaInfo = {
        media: {
          id: media.mediaUrl,
          payload,
        },
        thumbnail:
          isVideo && !!media.thumbnailUrl
            ? {
                id: media.thumbnailUrl,
                payload: thumbnailPayload,
              }
            : null,
      };
      return mediaInfo;
    })
    .value();

  return mediaInfos;
}

function pushUploadGtmEvent(response: AxiosResponse) {
  pushGtmEvent(
    {
      category: GtmEventCategory.Gallery,
      name: "upload photos",
    },
    response
  );
}

async function startPhotoUpload(dispatch: RematchDispatch<RootModel>, job: IMediaUploadBackgroundJob) {
  if (job.mediaFile) {
    const formData = new FormData();
    formData.append("galleryId", job.galleryId);
    formData.append("galleryTypeCode", "none");
    formData.append("photoTypeCode", "shoot-type-photo");
    formData.append("photoFile", job.mediaFile);

    const payload = buildPhotoPayload({
      collectionId: getCollectionId(job),
      uploadBatchTimestamp: job.uploadBatchTimestamp!,
      isFirst: job.isFirstMedia,
      orderId: job.orderId,
      originalPhotoId: job.originalMediaId,
    });

    formData.append("payload", payload);

    const cancelTokenSource = axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    dispatch.backgroundJobs.updateBackgroundJob({
      job: {
        ...job,
        cancelTokenSource,
        state: JobState.Progress,
      },
    });

    try {
      if (isGifFile(job.mediaFile)) {
        const photoMetadata = await getMetadata(job.mediaFile);

        if (cancelToken.reason) {
          return;
        }

        checkGifPhotoFileLimits(photoMetadata);
      }

      api.upload
        .uploadPhoto(formData, {
          cancelToken: cancelTokenSource.token,
          onUploadProgress: (progressEvent) => {
            if (progressEvent.event?.lengthComputable) {
              handleUploadProgress(dispatch, job.id, progressEvent.loaded);
            }
          },
        })
        .then((res) => {
          pushUploadGtmEvent(res);
          uploadFinished(dispatch, job.id, res.data.photoId, false, res.data.filename);
        })
        .catch((error) => {
          handlePhotoError(dispatch, job, error);
        });
    } catch (error) {
      handlePhotoError(dispatch, job, error);
    }
  }
}

async function startVideoUpload(dispatch: RematchDispatch<RootModel>, job: IMediaUploadBackgroundJob) {
  if (job.mediaFile) {
    const payload = buildVideoPayload({
      collectionId: getCollectionId(job),
      uploadBatchTimestamp: job.uploadBatchTimestamp!,
      isFirst: job.isFirstMedia,
      passGaEventId: true,
    });

    const cancelTokenSource = axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    dispatch.backgroundJobs.updateBackgroundJob({
      job: {
        ...job,
        cancelTokenSource,
        state: JobState.Progress,
      },
    });

    try {
      const videoMetadata = await getMetadata(job.mediaFile, checkVideoResult);
      if (cancelToken.reason) {
        return;
      }

      checkVideoFileLimits(videoMetadata);

      const response = await api.upload.getVideoUploadUri(
        getVideoUploadRequest(job.galleryId, job.mediaFile, videoMetadata, payload, job.videoWatermark)
      );

      pushUploadGtmEvent(response);

      const { uri, videoId } = response.data;

      if (cancelToken.reason) {
        cancelVideoUpload(job, videoId);
        return;
      }

      const headers = Array.isArray(response.data.headers)
        ? response.data.headers.reduce((obj, { key, value }) => ({ ...obj, [key]: value }), {})
        : {};

      retry({
        ...retryConfig,
        logMessage: "Uploading video file " + job.mediaFile.name,
        action: (lastRetry) =>
          new Promise<void>((resolve, reject) => {
            api.upload
              .uploadVideo(uri, job.mediaFile!, {
                cancelToken,
                onUploadProgress: (progressEvent) => {
                  if (progressEvent.event?.lengthComputable) {
                    handleUploadProgress(dispatch, job.id, progressEvent.loaded);
                  }
                },
                headers,
              })
              .then(() => {
                uploadFinished(dispatch, job.id, videoId, true);
                resolve();
              })
              .catch((error) => {
                if (handleVideoError(dispatch, job, error, lastRetry, videoId)) {
                  reject(error);
                } else {
                  resolve();
                }
              });
          }),
      });
    } catch (error) {
      handleVideoError(dispatch, job, error, true);
    }
  }
}

const handlePhotoError = (dispatch: RematchDispatch<RootModel>, job: IMediaUploadBackgroundJob, error: unknown) => {
  let message: string = "";
  if (error) {
    if (axios.isCancel(error)) {
      return;
    }

    if (isMediaError(error)) {
      message = error.message;
    } else {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 413) {
        message = photoSizeError;
      } else if (
        axiosError.response?.status === 400 ||
        hasAnyErrorType(
          axiosError,
          "photo_empty_extension_error",
          "unsupported_photo_extension_error",
          "incorrect_file_signature_error"
        )
      ) {
        message = unsupportedFileTypeError;
      } else if (hasAnyErrorType(axiosError, "storage_quota_exceeded_error")) {
        message = "Failed no storage available";
      } else {
        message = getNetworkErrorMessage(axiosError);
      }
    }
  }

  handleUploadError(dispatch, job.id, message || uploadFailedError);
};

function handleVideoError(
  dispatch: RematchDispatch<RootModel>,
  job: IMediaUploadBackgroundJob,
  error: unknown,
  lastRetry: boolean,
  videoId?: string
) {
  let canRetry = !error;
  let message: string = "";

  if (error) {
    if (axios.isCancel(error)) {
      cancelVideoUpload(job, videoId);
      return false;
    }

    if (isMediaError(error)) {
      message = error.message;
    } else {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 413) {
        message = videoSizeError;
      } else if (axiosError.response?.status === 400) {
        message = unsupportedFileTypeError;
      } else if (hasAnyErrorType(axiosError, "credit_quota_exceeded_error")) {
        message = "Failed no credits available";
      } else {
        canRetry = true;
        message = getNetworkErrorMessage(axiosError);
      }
    }
  }

  if (canRetry && lastRetry) {
    cancelVideoUpload(job, videoId);
  }

  if (canRetry && !lastRetry) {
    handleUploadProgress(dispatch, job.id, 0, true);
  } else {
    handleUploadError(dispatch, job.id, message || uploadFailedError);
  }

  return canRetry;
}

function cancelVideoUpload(job: IMediaUploadBackgroundJob, videoId?: string) {
  if (videoId) {
    retry({
      ...retryConfig,
      logMessage: "Restoring credit for video " + job.mediaFile?.name,
      action: () =>
        new Promise<void>((resolve, reject) => {
          api.upload
            .cancelVideoUpload(job.galleryId, videoId)
            .then(() => resolve())
            .catch((e) =>
              // ignore the error when gallery is already deleted
              (e as AxiosError)?.response?.status === 404 ? resolve() : reject(e)
            );
        }),
    });
  }
}

function calculateInstagramJobProgress(files: IInstagramFile[]) {
  const maxFileProgress = 2;
  const totalProgress = files.length * maxFileProgress;

  let hasErrors = false;
  let hasDuplicates = false;
  let completedCount = 0;
  let currentProgress = 0;

  _.each(files, (file) => {
    switch (file.state) {
      case JobState.Progress:
        currentProgress += maxFileProgress / 2;
        break;
      case JobState.Error:
        hasErrors = true;
        currentProgress += maxFileProgress;
        break;
      case JobState.Completed:
        completedCount++;
        currentProgress += maxFileProgress;
        break;
      case JobState.Duplicate:
        hasDuplicates = true;
        currentProgress += maxFileProgress;
        break;
    }
  });

  const inProgress = currentProgress < totalProgress;

  return {
    state: inProgress
      ? JobState.Progress
      : hasErrors
      ? JobState.Error
      : hasDuplicates
      ? JobState.Duplicate
      : JobState.Completed,
    progressStatus: inProgress ? getPercentage(currentProgress, totalProgress) : 100,
    progressStatusText: `${completedCount} of ${files.length}${inProgress ? "" : " successful"}`,
    completedCount,
    ...(inProgress ? null : { description: uploadingCompleteDescription }),
  };
}

function getVideoWatermarkInfo(watermark?: IWatermark): IVideoWatermark | undefined {
  if (!watermark) {
    return undefined;
  }

  return {
    wmId: watermark.id,
    wmOpacity: watermark.opacity,
    wmPosition: watermark.position,
    wmScale: watermark.scale,
    wmDropShadow: watermark.hasDropShadow,
  };
}

function getVideoUploadRequest(
  galleryId: string,
  file: File,
  metadata: IMediaMetadata,
  payload: string,
  watermark?: IWatermark
): IGetVideoUploadUri {
  return {
    galleryId,
    fileName: file.name,
    contentType: file.type,
    payload,
    ...metadata,
    ...getVideoWatermarkInfo(watermark),
  };
}

async function getMediaInfo() {
  const locateFile = () => "/wasm/MediaInfoModule.wasm";
  return await MediaInfoFactory({ locateFile });
}

function buildMediaError(message: string): IMediaError {
  return {
    message,
    isMediaError: true,
  };
}

function isMediaError(error: unknown): error is IMediaError {
  return (error as IMediaError).isMediaError;
}

function getNetworkErrorMessage(error: AxiosError) {
  const errorMessage = error.message && error.message.toUpperCase();
  if (errorMessage === "NETWORK ERROR") {
    return "Connection lost";
  }

  logging.error("Unexpected error during upload:", error);

  return "";
}

function checkVideoResult(result: ResultObject): IMediaError | undefined {
  if (result.media.track.length < 2) {
    return buildMediaError(unsupportedFileTypeError);
  }
}

function checkGifPhotoFileLimits(metadata: IMediaMetadata) {
  if (metadata.width * metadata.height > maxGifPhotoHeight * maxGifPhotoWidth) {
    throw buildMediaError("GIF is too large");
  }
}

function checkVideoFileLimits(videoMetadata: IMediaMetadata) {
  if (videoMetadata.duration > maxVideoLength * 60) {
    throw buildMediaError(`The video exceeds the ${maxVideoLength}-minute limit`);
  }

  const maxVideoSize = getState().session.userInfo?.features[FeatureCode.UPLOAD_VIDEO_4K]
    ? maxVideoSize4k
    : maxVideoSize1080p;

  let maxWidth: number;
  let maxHeight: number;

  if (videoMetadata.width > videoMetadata.height) {
    maxWidth = maxVideoSize.width;
    maxHeight = maxVideoSize.height;
  } else {
    maxWidth = maxVideoSize.height;
    maxHeight = maxVideoSize.width;
  }

  if (videoMetadata.width > maxWidth) {
    throw buildMediaError(`The video width exceeds the ${maxWidth}-pixel limit`);
  }

  if (videoMetadata.height > maxHeight) {
    throw buildMediaError(`The video height exceeds the ${maxHeight}-pixel limit`);
  }

  if (videoMetadata.videoFrameRate > maxVideoRate) {
    throw buildMediaError(`The video frame rate exceeds ${maxVideoRate} fps`);
  }
}

async function getMetadata(
  video: File,
  checkResult?: (result: ResultObject) => IMediaError | undefined
): Promise<IMediaMetadata> {
  const getSize: GetSizeFunc = () => video.size;
  const readChunk: ReadChunkFunc = (chunkSize, offset) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.error) {
          reject(event.target.error);
        }
        resolve(new Uint8Array(event.target?.result as ArrayBuffer));
      };
      reader.readAsArrayBuffer(video.slice(offset, offset + chunkSize));
    });
  };

  let result: Result | undefined;
  let mediaInfo: MediaInfo | undefined;

  try {
    mediaInfo = await getMediaInfo();
    result = await mediaInfo.analyzeData(getSize, readChunk);
  } finally {
    mediaInfo?.close();
  }

  if (!result || typeof result === "string") {
    throw buildMediaError(unsupportedFileTypeError);
  }

  const mediaError = checkResult?.(result);
  if (mediaError) {
    throw mediaError;
  }

  return {
    fileLength: result.media.track[0].FileSize as string,
    duration: Math.round(result.media.track[0].Duration as number),
    width: Number(result.media.track[1].Width),
    height: Number(result.media.track[1].Height),
    videoContainer: result.media.track[0].Format as string,
    videoFormat: result.media.track[1].Format as string,
    videoBitrate: result.media.track[1].BitRate as string,
    videoFrameRate: Math.floor(result.media.track[1].FrameRate as number),
    videoRotation: Math.round(result.media.track[1].Rotation as number),
    audioCompressionMode: result.media.track[2]?.Compression_Mode as string,
    audioFormat: result.media.track[2]?.Format as string,
    audioBitrate: result.media.track[2]?.BitRate as string,
    audioBitrateMode: result.media.track[2]?.BitRate_Mode as string,
    audioChannel: result.media.track[2]?.Channels as string,
  };
}

function getCollectionId(job: { galleryId: string; collectionId?: string }) {
  return job.galleryId === job.collectionId || isEmptyGuid(job.collectionId) ? null : job.collectionId!;
}

function speedText(kilobitsPerSec: number) {
  if (kilobitsPerSec >= 1000) {
    return Math.floor(kilobitsPerSec / 1000) + "Mbps";
  } else {
    return Math.floor(kilobitsPerSec) + "kbps";
  }
}

export const timeRemainingTextVerbose = (remainingSec: number): string => {
  const pluralize = (word: string, count: number): string => (count > 1 ? `${word}s` : word);

  const seconds = Math.max(Math.floor(remainingSec), 1);
  if (seconds < 60) return `${seconds} ${pluralize("second", seconds)} left..`;
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes} ${pluralize("minute", minutes)} left..`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}  ${pluralize("hour", hours)} left..`;
  const days = Math.floor(hours / 24);
  return `${days} ${pluralize("day", days)} left..`;
};

export function timeRemainingText(remainingSec: number) {
  let reducedTime = Math.max(Math.floor(remainingSec), 1);
  if (reducedTime < 60) return reducedTime + " sec";
  reducedTime = Math.floor(reducedTime / 60);
  if (reducedTime < 60) return reducedTime + " min";
  reducedTime = Math.floor(reducedTime / 60);
  if (reducedTime < 24) return reducedTime + " hr";
  reducedTime = Math.floor(reducedTime / 24);
  return reducedTime + (reducedTime > 1 ? " days" : " day");
}

export function isUploadJob(job: BackgroundJob): job is IMediaUploadBackgroundJob | IInstagramUploadBackgroundJob {
  return job.jobType === JobType.MediaUpload || job.jobType === JobType.InstagramUpload;
}

export function isJobActive(job: IJob) {
  return job.state === JobState.Queued || job.state === JobState.Progress;
}

export function isJobUnsuccessful(job: IJob) {
  return job.state === JobState.Error || job.state === JobState.Duplicate;
}

export function isJobFailed(job: IJob) {
  return job.state === JobState.Error || job.state === JobState.Canceled;
}

function getPercentage(loaded: number, total: number) {
  return Math.min(Math.ceil((loaded * 100) / total), 99);
}

function isGifFile(file: File) {
  return file.type?.toLowerCase() === "image/gif";
}

function buildUploadDescription(galleryName: string) {
  return `Uploading ‘${galleryName}’`;
}

function fileMatchSize(file: File, minSize: number, maxSize: number) {
  return file.size >= minSize && file.size <= maxSize;
}

function getJobs() {
  return getState().backgroundJobs.jobs;
}

function findMediaUploadJob(jobId: string, jobs?: BackgroundJob[]) {
  return _.find(jobs ?? getJobs(), (j) => j.id === jobId) as IMediaUploadBackgroundJob | undefined;
}

function findChildMediaUploadJobs(parentId: string, jobs?: BackgroundJob[]) {
  return _.filter(jobs ?? getJobs(), (j) => j.parentId === parentId) as IMediaUploadBackgroundJob[];
}

function getInstagramEventDetail(files: IInstagramFile[]) {
  const counts = _.countBy(files, (file) => isInstagramVideo(file.media));
  return {
    instagram_photos: (counts.false ?? 0).toString(),
    instagram_videos: (counts.true ?? 0).toString(),
    cameraroll_photos: "0",
    cameraroll_videos: "0",
  };
}

const retryDelays = _.map([1, 2, 3, 5, 8, 13], (delay) => delay * 1000);

const retryConfig = {
  retries: retryDelays.length,
  delay: (retry: number) => retryDelays[retry - 1],
};

export const totalUploadProgress = (uploadJobs: BackgroundJob[]) => {
  const inProgressAndCompletedUploadJobs = uploadJobs.filter(({ state }) =>
    VALID_PROGRESS_CALCULATION_STATES.has(state)
  );

  if (inProgressAndCompletedUploadJobs.length < 1) {
    return 100;
  }

  const uploadJobProgressSum = inProgressAndCompletedUploadJobs.reduce(
    (sum, job) => sum + (job.progressStatus || 0),
    0
  );

  return uploadJobProgressSum / inProgressAndCompletedUploadJobs.length;
};
