import { GalleryMedia } from "./gallery";

export enum OnboardingStep {
  BusinessNameAndPassword = "password",
  ShootTypes = "shoot-types",
  PrimaryShootType = "primary-shoot-type",
  Goals = "goals",
  SelectPhotos = "select-photos",
  UploadPhotos = "upload-photos",
  GalleryOrWebsite = "select-intent",
  ChooseSite = "choose-website",
}

export interface IStep {
  id: OnboardingStep;
  isAnswered: boolean;
  stepAnswerOptions: IStepAnswerOption[];
  stepAnswers: IStepAnswerValue[];
}

export interface IStepAnswerOption {
  id: string;
  value: string;
  order: number;
}

export interface IStepAnswerValue {
  answerOptionId: string;
  value: string;
}

export interface IOnBoardingProcess {
  steps: IStep[];
  isPasswordChanged: boolean;
  isCompleted: boolean;
  isSocial: boolean;
}

export interface IStepAnswer {
  stepId: OnboardingStep;
  password?: string;
  answers: {
    id: string;
    value: string;
  }[];
}

export const maxUploadCount = 30;

export interface IInitialGallery {
  id: string;
  name: string;
  medias: GalleryMedia[];
  photoUrlTemplate: string;
  videoUrlTemplate: string;
  thumbnailUrlTemplate: string;
  isSample: boolean;
}

export interface IInitialGalleriesInfo {
  rootId: string;
  gallery?: IInitialGallery;
}
