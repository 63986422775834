import classNames from "classnames";
import * as React from "react";
import colors from "../../../utilities/colors";
import styles from "./index.module.scss";

const colorsSchemes = {
  white: colors.white,
  orange: colors.primaryOrange,
  brown: "#9c8a71",
};

interface ISpinnerProps {
  className?: string;
  style?: React.CSSProperties;
  colorScheme?: keyof typeof colorsSchemes;
  size: number;
}

const Spinner: React.FC<ISpinnerProps> = (props) => {
  const { className, style, size, colorScheme = "white" } = props;

  const color = colorsSchemes[colorScheme];

  return (
    <svg
      style={style}
      className={classNames(styles.spinner, className)}
      width={size}
      height={size}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M41.181 46.605A17.145 17.145 0 0 1 32 49.248c-3.323 0-6.496-.938-9.229-2.673l-6.787 6.735A26.551 26.551 0 0 0 32 58.656a26.551 26.551 0 0 0 15.977-5.317"
          fillOpacity=".4"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M47.522 47.522c8.573-8.572 8.573-22.472 0-31.044-8.572-8.573-22.472-8.573-31.044 0-8.573 8.572-8.573 22.472 0 31.044"
          stroke={color}
          strokeWidth="9.408"
        />
      </g>
    </svg>
  );
};

export default Spinner;
