import { locationApi } from "..";
import { IAutoAddressDto, IPlaceDto } from "./model";

export interface IApiLocations {
  getAddresses: (
    query: string,
    lat: number,
    lng: number,
    radius: number,
    country: string,
    address?: boolean
  ) => Promise<IAutoAddressDto>;
  getPlace: (placeId: string) => Promise<IPlaceDto>;
  getLocationFromIp: () => Promise<IPlaceDto>;
}

const locations: IApiLocations = {
  getAddresses: (query: string, lat: number, lng: number, radius: number, country: string, address?: boolean) =>
    locationApi.get("address/auto-complete", {
      params: {
        query,
        lat,
        lng,
        radius,
        country,
        address: !!address,
      },
    }),
  getPlace: (placeId: string) =>
    locationApi.get("address/places", {
      params: { placeId },
    }),
  getLocationFromIp: () => locationApi.get("address/geocode/ip"),
};

export default locations;
