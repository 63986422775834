import { useCallback, useEffect, useRef } from "react";

export type UseTimeoutReturnValue = [React.MutableRefObject<NodeJS.Timeout | undefined>, () => void];

export function useTimeout(): UseTimeoutReturnValue {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const clearTimeoutInternal = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  useEffect(onMount, [clearTimeoutInternal]);

  function onMount() {
    return function onUnmount() {
      clearTimeoutInternal();
    };
  }

  return [timeoutRef, clearTimeoutInternal];
}

export type UseSimpleTimeoutReturnValue = [
  (...args: Parameters<typeof setTimeout>) => ReturnType<typeof setTimeout>,
  () => void
];

/** A simple version of *useTimeout*. Doesn't expose timeout ref and instead provides analog of native
 * *setTimeout* function that automatically clears previous timeout and keeps track of current timeout
 * in order to clean it when component unmounts (similarly to *useTimeout*).
 */
export function useSimpleTimeout(): UseSimpleTimeoutReturnValue {
  const [timeoutRef, clearTimeout] = useTimeout();

  function setTimeoutInternal(...args: Parameters<typeof setTimeout>): ReturnType<typeof setTimeout> {
    clearTimeout();
    timeoutRef.current = setTimeout(...args);
    return timeoutRef.current;
  }

  return [setTimeoutInternal, clearTimeout];
}
