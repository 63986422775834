import IIConProps from "../IIconProps";

const IconSupport = (props: IIConProps) => {
  const color = props.color || "#1E1F22";
  const width = props.width || 24;
  const height = props.width || 24;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M12.357 14.029c0-.459.077-.83.232-1.112.155-.283.352-.523.592-.72.24-.197.496-.384.768-.56.271-.176.527-.384.767-.624s.438-.538.592-.896c.155-.357.232-.823.232-1.4 0-.831-.312-1.492-.936-1.983C13.981 6.244 13.13 6 12.053 6c-.821 0-1.517.095-2.087.287a6.51 6.51 0 0 0-1.512.72l.864 1.664c.138-.107.306-.208.504-.304.197-.096.402-.181.615-.256a4.66 4.66 0 0 1 .648-.176c.219-.043.419-.064.6-.064.576 0 .973.104 1.192.312.218.208.328.499.328.872 0 .266-.078.493-.232.68a3.303 3.303 0 0 1-.576.535c-.23.171-.472.35-.728.536a3.31 3.31 0 0 0-.696.696 3.15 3.15 0 0 0-.488 1.024c-.117.405-.144.906-.08 1.504h1.952zm-.896 3.567c.427 0 .768-.118 1.024-.352.256-.235.384-.539.384-.912 0-.395-.128-.704-.384-.928-.256-.224-.597-.336-1.024-.336-.416 0-.752.112-1.008.336-.256.224-.383.533-.383.928 0 .373.127.677.383.912.256.234.592.352 1.008.352z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M11.975 1.375c2.928 0 5.578 1.187 7.496 3.105a10.568 10.568 0 0 1 3.105 7.495c0 2.928-1.186 5.578-3.105 7.496a10.567 10.567 0 0 1-7.496 3.105A10.568 10.568 0 0 1 4.48 19.47a10.567 10.567 0 0 1-3.105-7.496C1.375 9.05 2.562 6.4 4.48 4.48a10.567 10.567 0 0 1 7.495-3.105z"
          stroke={color}
          strokeWidth="1.75"
        />
      </g>
    </svg>
  );
};

export default IconSupport;
