import { AxiosPromise, AxiosRequestConfig } from "axios";
import { foldersApi } from "..";
import { IInstagramDto } from "./models";

export interface IApiSocialMedia {
  getInstagramMedia: (
    queryString: string,
    config?: AxiosRequestConfig
  ) => AxiosPromise<IInstagramDto>;
  getInstagramToken: (
    config?: AxiosRequestConfig
  ) => Promise<string>;
}

const socialMedia: IApiSocialMedia = {
  getInstagramMedia: (queryString: string, config?: AxiosRequestConfig) =>
    foldersApi.get<IInstagramDto>(`socialMedia/instagram/media${queryString}`, config),
  getInstagramToken: async(config?: AxiosRequestConfig) =>
    (await foldersApi.get<string>("socialMedia/instagram/access-token", config)).data,
};

export default socialMedia;
