import _ from "lodash";
import { ActionStatus, IError } from "./common";

export function stateMatchesPayload<P extends object>(state: Partial<P>, payload: P) {
  return _.every(payload, (val, key) => {
    const stateValue = state[key as keyof P];
    return stateValue === undefined || _.isEqual(val, stateValue);
  });
}

export function stateMatchesActionResult<P extends object>(state: Partial<P> & { status: ActionStatus }, payload: P) {
  return state.status === "Pending" && stateMatchesPayload(state, payload);
}

export type GenericState<TInput extends object = {}, TResult extends object = {}> = {
  status: ActionStatus;
} & Partial<TInput & TResult & { error: IError }>;

export type GenericReducers<TAction extends string, TState, TInput extends object = {}, TResult extends object = {}> = {
  [K in TAction as `${string & K}Started`]: (state: TState, payload: TInput) => TState;
} & {
  [K in TAction as `${string & K}Success`]: (state: TState, payload: TInput & TResult) => TState;
} & {
  [K in TAction as `${string & K}Error`]: (state: TState, payload: TInput & { error: IError }) => TState;
};

export interface IStateCustomizers<TState extends object, TInput extends object = {}, TResult extends object = {}> {
  started?: (state: TState, payload: TInput) => Partial<TState>;
  success?: (state: TState, payload: TInput & TResult) => Partial<TState>;
  error?: (state: TState, payload: TInput & { error: IError }) => Partial<TState>;
}

export function getGenericReducers<TAction extends string>(
  action: TAction
): <
  TState extends { [K in TAction]: GenericState<TInput, TResult> },
  TInput extends object = {},
  TResult extends object = {}
>(
  stateCustomizers?: IStateCustomizers<TState, TInput, TResult>
) => GenericReducers<TAction, TState, TInput, TResult> {
  return <
    TState extends { [K in TAction]: GenericState<TInput, TResult> },
    TInput extends object = {},
    TResult extends object = {}
  >(
    stateCustomizers?: IStateCustomizers<TState, TInput, TResult>
  ) => {
    return {
      [`${action}Started`]: (state: TState, payload: TInput) => {
        return {
          ...state,
          [action]: { status: "Pending", ...payload },
          ...stateCustomizers?.started?.(state, payload),
        };
      },
      [`${action}Success`]: (state: TState, payload: TInput & TResult) => {
        return stateMatchesActionResult(state[action], payload)
          ? {
              ...state,
              [action]: { ...state[action], status: "Success", ...payload },
              ...stateCustomizers?.success?.(state, payload),
            }
          : state;
      },
      [`${action}Error`]: (state: TState, payload: TInput & { error: IError }) => {
        return stateMatchesActionResult(state[action], payload)
          ? {
              ...state,
              [action]: { ...state[action], status: "Error", error: payload.error },
              ...stateCustomizers?.error?.(state, payload),
            }
          : state;
      },
    } as GenericReducers<TAction, TState, TInput, TResult>;
  };
}
