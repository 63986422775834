import TagManager from "react-gtm-module";

export function gtmInit() {
  const gtmId = process.env.REACT_APP_GTM_ID;
  if (gtmId) {
    TagManager.initialize({
      gtmId,
    });
  }
}
