import { FC } from "react";
import { IFRAME_PAGE_TITLE } from "../../../../models/iframe";
import FullScreenSiteEditorIFrame from "../../../ui/FullScreenSiteEditorIFrame";

interface IProps {
  onLogout: () => void;
  onPurchaseSuccess: () => void;
  onExtendTrial: () => void;
}

const ExpiredTrialIFrame: FC<IProps> = (props) => {
  const { onLogout, onPurchaseSuccess, onExtendTrial } = props;

  return (
    <FullScreenSiteEditorIFrame
      path={"/expired-trial"}
      title={IFRAME_PAGE_TITLE.EXPIRED_TRIAL}
      onCustomMessage={(message) => {
        if (message.type === "logout") {
          onLogout();
        } else if (message.type === "purchase-success") {
          onPurchaseSuccess();
        } else if (message.type === "extend-trial-success") {
          onExtendTrial();
        }
      }}
    />
  );
};

export default ExpiredTrialIFrame;
