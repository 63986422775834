export enum SegmentAccountEventName {
  LOGIN = "LOGIN",
  DIRECT_TO_PAID = "DIRECT TO PAID",
  SIGNUP = "SIGNUP",
  TRIAL = "trial",
  SUBSCRIBE_TRIAL_BANNER = "click subscribe",
}

export enum CheckoutViewFrom {
  TRIAL_BANNER = "click on trial banner",
  WIDGET = "click view plan from widget",
}

export enum TrackingInteractPlanModalActions {
  Select = "select plan",
  ChangePeriod = "change plan period",
  ComparePlan = "click compare all plans",
  Scroll = "scroll",
  Close = "close plans model",
}

interface CommonEvent {
  coupon_code?: string;
  discount_type?: string;
  discount_amount?: number;
  has_extended_trial: boolean;
  number_of_days: number;
}
export interface ShowTrialBanner extends CommonEvent {
  actionName: "in app trial banner loaded";
}

export interface InteractPlanOffer {
  actionName: "interact with plans modal";
  action: TrackingInteractPlanModalActions;
  has_extended_trial?: boolean;
  number_of_days?: number;
}
