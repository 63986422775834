import classNames from "classnames";
import * as React from "react";
import { iconModalClose } from "../../../../icons";
import styles from "./index.module.scss";

interface ICloseProps {
  className?: string;
  onClick?: () => void;
}

const Close: React.FC<ICloseProps> = (props) => {
  const { className, onClick } = props;

  return <img className={classNames(styles.close, className)} src={iconModalClose} onClick={onClick} alt="close" />;
};

export default Close;
