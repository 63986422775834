import { createModel } from "@rematch/core";
import _ from "lodash";
import { IToast, ToastCreator, toastDefaults } from "../../models/toast";
import { newGuid } from "../../utilities/helpers";
import { RootModel } from "../store";

export type ToastState = { toast?: IToast };

export const toast = createModel<RootModel>()({
  state: {} as ToastState,
  reducers: {
    showToast(_state, payload: { toast: ToastCreator }) {
      return {
        toast: _.defaults(payload.toast, toastDefaults, { id: newGuid() }),
      };
    },
    hideToast() {
      return {};
    },
  },
});
