import classNames from "classnames";
import * as React from "react";
import { StrictOmit } from "../../../../types";
import Button, { IButtonProps } from "../../Button";
import Base, { IBaseProps } from "../Base";
import Close from "../Close";
import styles from "./index.module.scss";

interface IBottomPanelProps extends IBaseProps {
  header?: React.ReactNode;
  buttonText?: React.ReactNode;
  buttonPending?: boolean;
  buttonProps?: StrictOmit<IButtonProps, "onClick" | "children" | "pending">;
  headerClassName?: string;
  contentClassName?: string;
  footer?: React.ReactNode;
  footerClassName?: string;
  closeClassName?: string;
  style?: React.CSSProperties;
  showClose?: boolean;
  onButtonClick?: () => void;
  onContentScroll?: React.UIEventHandler<HTMLDivElement>;
}

const BottomPanel: React.FC<IBottomPanelProps> = (props) => {
  const {
    header,
    footer,
    buttonText,
    buttonPending,
    buttonProps,
    onButtonClick,
    onContentScroll,
    onClose,
    children,
    className,
    headerClassName,
    contentClassName,
    footerClassName,
    closeClassName,
    style,
    showClose = true,
    ...otherProps
  } = props;

  return (
    <Base className={classNames(styles.modal, className)} onClose={onClose} {...otherProps}>
      {showClose && <Close className={closeClassName} onClick={onClose} />}
      {header && <div className={classNames(styles.header, headerClassName)}>{header}</div>}
      <div className={classNames(styles.content, contentClassName)} onScroll={onContentScroll}>
        {children}
      </div>
      {(buttonText || footer) && (
        <div className={classNames(styles.footer, footerClassName)}>
          {footer || (
            <Button type="primary" {...buttonProps} onClick={onButtonClick} pending={buttonPending}>
              {buttonText}
            </Button>
          )}
        </div>
      )}
    </Base>
  );
};

export default BottomPanel;
