import { ITrialExtendTasks } from "../../models/account";
import { IInitialGalleriesInfo } from "../../models/onBoarding";
import { GenericState } from "../generic";

export const initialState: IDashboardState = {
  getTrialExtendTasks: {
    status: "Init",
  },
  getInitialGalleriesInfo: { status: "Init" },
};

/** State for the dashboard information. */
export interface IDashboardState {
  trialExtendTasks?: ITrialExtendTasks;
  initialGalleriesInfo?: IInitialGalleriesInfo;
  getTrialExtendTasks: TrialExtendTasksState;
  getInitialGalleriesInfo: GetInitialGalleriesInfoState;
}

export type TrialExtendTasksState = GenericState;
export type GetInitialGalleriesInfoState = GenericState<{}, IGetInitialGalleriesInfoResult>;

export interface IGetTrialExtendTasksResult {
  trialExtendTasks: ITrialExtendTasks;
}

export interface IGetInitialGalleriesInfoResult {
  galleriesInfo: IInitialGalleriesInfo;
}
