import cx from "classnames";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "..";
import { iconAccountSettings, iconArrowExpand } from "../../../../icons";
import { IFRAME_BACK_TO } from "../../../../models/iframe";
import { Dispatch } from "../../../../store/store";
import { buildIframePath } from "../../../../utilities/iframe";
import { buildPathAndQuery } from "../../../../utilities/query";
import AccountUrlManager from "../../../features/Account/UrlManager";
import useModal from "../../../hooks/useModal";
import Button from "../../Button";
import styles from "./index.module.scss";

interface IAccountSettingsProps {
  handleClose: () => void;
  isOpen: boolean;
  backTo: IFRAME_BACK_TO;
}

const AccountSettings: FC<IAccountSettingsProps> = ({ handleClose, isOpen, backTo }) => {
  const dispatch = useDispatch<Dispatch>();
  const [loggingOut, setLoggingOut] = useState(false);
  const [openLogout, onCloseLogout, onOpenLogout] = useModal();
  const navigate = useNavigate();
  const location = useLocation();

  const onLogout = () => {
    setLoggingOut(true);
    dispatch.session.logoutAsync();
  };

  const onClosePanel = () => {
    if (!loggingOut) {
      onCloseLogout();
    }
  };

  const onAccountSettings = () => {
    navigate(
      AccountUrlManager.generateDynamicRoute(
        backTo,
        buildPathAndQuery(location),
        buildIframePath("", { [AccountUrlManager.activeTabParam]: "account", subTab: "profile" })
      )
    );
  };

  return (
    <>
      <Modal.BottomPanel
        className={cx([styles.accountSettings, styles.modal])}
        overlayClassName={cx([styles.settingsOverlay, styles.transparent])}
        contentClassName={styles.settingsContent}
        onClose={handleClose}
        open={isOpen}
        showClose={false}
        buttonText="Log Out"
        buttonProps={{
          type: "error",
          className: styles.logout,
        }}
        onButtonClick={onOpenLogout}
        footerClassName={styles.footer}
      >
        <div className={styles.settingsOption} onClick={onAccountSettings}>
          <div className={styles.icon}>
            <img src={iconAccountSettings} alt="account settings" />
          </div>
          <p className={styles.label}>Account Settings</p>
          <div className={styles.arrowIcon}>
            <img src={iconArrowExpand} alt="arrow" />
          </div>
        </div>
      </Modal.BottomPanel>
      <Modal.BottomPanel
        className={cx([styles.logout, styles.modal])}
        overlayClassName={cx([styles.settingsOverlay, styles.transparent])}
        onClose={onClosePanel}
        open={openLogout}
        showClose={false}
      >
        <p className={styles.subTitle}>Log Out</p>
        <p className={styles.title}>Are you sure you want to log out?</p>
        <Button onClick={onLogout} pending={loggingOut} className={styles.button}>
          Yes
        </Button>
        <Button onClick={onClosePanel} className={styles.button} type="secondary">
          No
        </Button>
      </Modal.BottomPanel>
    </>
  );
};

export default AccountSettings;
