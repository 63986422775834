import contentDisposition from "content-disposition";
import _ from "lodash";
import { isIOS } from "react-device-detect";
import { checkIsPWAMode } from "./helpers";

/** Urls for downloading media that come from APIs are not ready to use. This function finalizes them. */
export function finalizeMediaDownloadUrl(url: string, isVideo: boolean) {
  url = `${_.trimEnd(
    isVideo ? process.env.REACT_APP_VIDEO_STREAMING_API_URL : process.env.REACT_APP_MEDIA_CDN_HOST,
    "/"
  )}/${_.trimStart(url, "/")}`;

  return url;
}

/** This function expects finalized url, see *finalizeMediaDownloadUrl*. */
export function downloadMedia(url: string) {
  function clickLink(url: string, filename?: string) {
    const a = document.createElement("a");
    a.href = url;
    if (filename) {
      a.download = filename;
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  if (checkIsPWAMode() && isIOS) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = this.response;
        const filename = contentDisposition.parse(this.getResponseHeader("Content-Disposition")!).parameters!.filename;
        url = window.URL.createObjectURL(blob);
        clickLink(url, filename);
        window.URL.revokeObjectURL(url);
      }
    };
    xhr.send();
  } else {
    clickLink(url);
  }
}
