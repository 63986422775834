import { ActionStatus } from "@zenfolio/core-components/dist/models/models";
import { IError as ICommonError } from "@zenfolio/core-components/dist/utilities/helpers";
import _ from "lodash";
import { toProblemDetails } from "../api";
import * as logging from "../utilities/logging";
import { getState } from "./store";

interface IError extends ICommonError {
  status?: number;
}

export function getErrorFromApi(error: unknown, noLog?: boolean): IError {
  const problemDetails = toProblemDetails(error, noLog);
  const { type, title, status, detail, exception, errors, ...extras } = problemDetails;

  if (exception && !noLog) {
    logging.error("Unhandled API exception:", exception);
  }

  const message = !_.isEmpty(errors) ? _.chain(errors).values().flatten().join("\n").value() : detail || title;

  return {
    ...extras,
    type,
    title,
    message,
    status,
  };
}

export function updateState<S extends object>(state: S | undefined, updater: (state: S) => Partial<S>): S | undefined {
  return state === undefined ? undefined : { ...state, ...updater(state) };
}

export type { ActionStatus, IError };

export const getResetPasswordError = (_error: IError, message: string) => {
  const error = getErrorFromApi(_error);
  error.message = message;
  return error;
};

export const getSignupDate = () => {
  return getState()?.session.userInfo?.identitySegment.signupDate;
};
