import { AxiosPromise } from "axios";
import { accountsApi, salesTaxApi } from "..";
import { IActivePlans, IPromotionCampaign, PromotionHistoryType } from "../../models/subscription";
import {
  IActivePlansDto,
  IPromotionCampaignDto,
  IPromotionCampaignWrapperDto,
  IUserSubscriptionDto,
  RegionType,
} from "./models";
import { BundleType, IBundleInfo } from "../../models/addon";
import type { ISubscriptionAPI } from "@zenfolio/core-components/dist/components/CheckoutForm";
import { getAccountId } from "../../utilities/token";
import Cookies from "js-cookie";

export interface IApiSubscription extends Omit<ISubscriptionAPI, 'applyPromoCode'> {
  getUserSubscription: (email: string) => AxiosPromise<IUserSubscriptionDto>;
  getActivePromotionCampaign: (isTrialUser?: boolean) => Promise<IPromotionCampaign[]>;
  getActivePlans: (currentPlanCode: string) => Promise<IActivePlans>;
  getBundleInfoByType: (
    bundleType: BundleType,
    planCode?: string,
    countryCode?: string,
    regionType?: RegionType
  ) => Promise<IBundleInfo[]>;
  applyPromoCode: (
    promotionCode: string,
    planCode: string,
    bundleCode: string,
    chargePeriod: number,
    email: string,
    payType: PromotionHistoryType,
    userId?: string
  ) => AxiosPromise<unknown>;
}

const subscription: IApiSubscription = {
  getUserSubscription: (email: string) => accountsApi.get(`users/subscription/${email}`),
  getActivePromotionCampaign: (isTrialUser?: boolean) => {
    return accountsApi
      .get<IPromotionCampaignDto | IPromotionCampaignWrapperDto>(`promotions/campaign`, { params: { isTrialUser } })
      .then((response) => {
        const data: IPromotionCampaign[] = Object(response.data).hasOwnProperty("promotionCampaigns")
          ? (response.data as IPromotionCampaignWrapperDto).promotionCampaigns || []
          : response.data
          ? [response.data as IPromotionCampaignDto]
          : [];

        return data;
      });
  },
  getActivePlans: (currentPlanCode: string) => {
    const params = {
      status: 1,
      pageNumber: 0,
      pageSize: 10000,
      currentPlanCode,
      isSignup: true,
    };
    return accountsApi
      .get<IActivePlansDto>("plan/search", {
        params,
      })
      .then((response) => response.data);
  },
  getTax: (total, address) =>
    salesTaxApi
      .post("/tax/nextzen/calculate", {
        toCountry: address.toCountry,
        toState: address.toState,
        toZip: address.toZip,
        amount: total,
        productTaxType: "subscription",
      })
      .then((res) => res.data),
  getBundleInfoByType: (bundleType, planCode, countryCode, regionType) =>
    accountsApi
      .get(`bundle/type`, {
        params: { bundleType, planCode, countryCode, regionType },
      })
      .then((res) => res.data),
  getChangePlanEstimation: async (request) => {
    const validCodes = request.promoCodeForm.id ? request.promoCodeForm.validCodes : undefined;
    const autoLoadBundles =
      request.promoCodeForm.id && request.promoCodeForm.autoLoadBundles
        ? request.promoCodeForm.autoLoadBundles.map((b) => ({
            bundleId: b.bundleId,
            bundlePeriodId: b.bundlePeriodId,
          }))
        : undefined;

    const payload = {
      userId: getAccountId(),
      changePlanId: request.changePlanId,
      ChangePlanPeriodId: request.changePlanPeriodId,
      TaxRate: request.taxRate,
      promotionAmount: request.promoCodeForm.amount,
      promotionType: request.promoCodeForm.type,
      keepBookMe: request.keepBookme,
      validCodes,
      autoLoadBundles,
      changeAtPeriodEnd: request.chargeAtPeriodEnd,
    };
    const response = await accountsApi.post("user/change/plan/estimation", payload);
    const estimation = response.data
    if (estimation) {
      return {
        ...estimation,
        currency: estimation.currency? estimation.currency.toUpperCase(): "",
      }
    }
  },
  subscribeUserBundle: (addOnPurchase) => accountsApi.post(`user/subscribe/bundle`, addOnPurchase),
  subscribePlan: (plan, isUpGrade) => {
    const path = isUpGrade === true ? "user/upgrade/plan" : "user/subscribe/plan";
    return accountsApi.post(path, {
      promotionId: plan.promotionId,
      promotionCode: plan.promotionCode,
      promotionAmount: plan.promotionAmount,
      planId: plan.planId,
      planPeriodId: plan.planPeriodId,
      street: plan.street,
      city: plan.city,
      state: plan.state,
      country: plan.country,
      zip: plan.zip,
      planAmount: plan.planAmount,
      total: plan.total,
      taxRate: plan.taxRate,
      taxAmount: plan.taxAmount,
      stripeCardToken: plan.stripeCardToken,
      addressLine2: plan.addressLine2,
      currency: plan.currency,
      paySource: plan.paySource,
      affiliateCookie: Cookies.get("AffiliateCookie"),
      proratedAmount: plan.proratedAmount,
      removeRenewalPromotion: isUpGrade ? true : undefined,
    });
  },
  changeNextRenewalPlan: (reqPayload) => {
    return accountsApi.patch(`user/subscription/renewal-plan`, {
      ...reqPayload,
      removeRenewalPromotion: true,
    });
  },
  applyPromoCode: (promotionCode, planCode, bundleCode, chargePeriod, email, payType, userId) =>
    accountsApi.post("promotions/available", {
      promotionCode,
      planCode,
      bundleCode,
      chargePeriod,
      email,
      payType,
      userId,
    }),
  getIsExistOutstandingInvoices: () => accountsApi.get("user/outstanding-invoices-exist"),
  bookMeAbandon: (abandonedLocation) => accountsApi.patch(`user/bookme-abandon?abandonedLocation=${abandonedLocation}`),
};

export default subscription;
