import { IActivePlansDto, IPromotionCampaignDto, ISubscriptionPlanDto } from "../api/subscription/models";
import { BundleType } from "./addon";

export const MIN_TIER_INCLUDE_BOOKME_FEATURE = 3;

export enum ChargePeriod {
  Day = 0,
  Week = 1,
  Month = 2,
  Year = 3,
}

export const PlanPeriodNameMapping: Record<string, string> = {
  [ChargePeriod.Day]: 'Daily',
  [ChargePeriod.Week]: 'Weekly',
  [ChargePeriod.Month]: 'Monthly',
  [ChargePeriod.Year]: 'Annually',
};

export const defaultChargePeriod = ChargePeriod.Day;

export interface ISubscription {
  subscriptionId: string;
  planPeriod: IPlanPeriod;
  bundlePeriod: IPlanPeriod;
  dateCreated: Date;
  tierName?: string;
  trialDays: number;
  productName: string;
  isBundle: boolean;
  isTrialUser: boolean;
  renewalDate: Date;
  storage: number;
  bundleType: BundleType;
  videoStorage: number;
  totalVideoUsageStorage: number;
  totalUsageStorage: number;
  isFree?: boolean;
  renewalPlanId?: string | null;
}

export interface IPlanPeriod {
  id: string;
  chargePeriod: ChargePeriod;
  periodMultiplier: number;
  currencyId: string;
  currency: string;
  charge: number;
  nextPeriodId?: string | null;
}

export interface IPromotionCampaign extends IPromotionCampaignDto {}

export interface IActivePlans extends IActivePlansDto {
  plans: ISubscriptionPlan[];
}

export interface ISubscriptionPlan extends ISubscriptionPlanDto {}

export interface IPromotion {
  id: string;
  name: string;
  code: string;
  description: string;
  amount: number;
  currency: string;
  promotionType: number;
  applicablePlanPromotions: ISubscriptionPlan[];
  promotionValidities: IPromotionValidity[];
}

export interface IPromotionValidity {
  id: string;
  type: number;
  description: string;
}

export enum ValidityPromotionType {
  None = 0,
  Signup = 1,
  Renewals = 2,
  Upgrade = 3,
  MonthSubscription = 4,
  YearlySubscription = 5,
}

export enum PromotionHistoryType {
  None = 0,
  D2P = 1,
  T2P = 2,
  Upgrade = 3,
}
