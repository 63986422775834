import * as signalR from "@microsoft/signalr";
import * as logging from "../utilities/logging";
import { getImpersonateToken, getToken } from "../utilities/token";

interface IConfirmConnectionMessage {
  connectionId: string;
}

interface INotificationMessage {
  type: string;
  payload: unknown;
}

export interface ICallbacks {
  onConnect?: (hubUrl: string) => void;
  onReconnect?: (hubUrl: string) => void;
  onError?: (hubUrl: string) => void;
  onNotificationReceived?: (type: string, payload: unknown) => void;
}

interface ILockResolver {
  resolve: () => void;
  locked?: true;
}

export class ConnectionManager {
  private static readonly instances: {
    [hubUrl: string]: ConnectionManager;
  } = {};
  private static readonly maxReconnectTimeout: number = 5000;

  private readonly hubUrl: string;

  private activeHubConnection: signalR.HubConnection | null;
  private connectionIdValue: string;
  private isConnected: boolean;
  private hadErrors: boolean;
  private lockResolver: ILockResolver | null;
  private lastStartTime: number | null;

  private constructor(hubUrl: string) {
    this.hubUrl = hubUrl;
    this.activeHubConnection = null;
    this.connectionIdValue = "";
    this.isConnected = false;
    this.hadErrors = false;
    this.lockResolver = null;
    this.lastStartTime = null;
  }

  public get connectionId(): string {
    if (!this.connectionEstablished) {
      throw new Error("Connection is not established yet.");
    }

    return this.connectionIdValue;
  }

  private get connectionEstablished(): boolean {
    return (this.connectionIdValue && this.isConnected) || false;
  }

  public static getInstance(hubUrl: string): ConnectionManager {
    if (!hubUrl) {
      throw new Error("Hub URL is required.");
    }

    return this.instances[hubUrl] || (this.instances[hubUrl] = new ConnectionManager(hubUrl));
  }

  public connect(callbacks?: ICallbacks): void {
    if (this.activeHubConnection) {
      throw new Error("Connect should only be called once.");
    }

    // see: https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client#bsleep
    if (typeof navigator !== "undefined" && navigator.locks?.request) {
      const promise = new Promise<void>((resolve) => {
        this.lockResolver = {
          resolve,
        };
      });

      const { lockResolver } = this;

      navigator.locks.request("signalr_connection_manager_lock", { mode: "shared" }, () => {
        if (this.lockResolver === lockResolver && lockResolver) {
          lockResolver.locked = true;
          logging.log("Acquired shared connection manager lock.");
        }

        return promise;
      });
    }

    let { hubUrl } = this;
    const impersonateToken = getImpersonateToken();

    if (impersonateToken) {
      const urlBuilder = new URL(hubUrl);
      urlBuilder.searchParams.set("impersonation_token", `Bearer ${impersonateToken}`);

      hubUrl = urlBuilder.href;
    }

    const logger: signalR.ILogger = {
      log: (logLevel, message) => {
        if (this.activeHubConnection !== hubConnection) {
          return;
        }

        if (logLevel < signalR.LogLevel.Information) {
          return;
        }

        // https://github.com/dotnet/aspnetcore/blob/0899b0aab473d3138ae4c024f4fa484a829217f0/src/SignalR/clients/ts/signalr/src/Utils.ts#L181
        const logMessage = `[${new Date().toISOString()}] ${signalR.LogLevel[logLevel]}: ${message}`;

        if (logLevel < signalR.LogLevel.Error) {
          logging.log(logMessage);
        } else {
          logging.error(logMessage);
        }
      },
    };

    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => getToken() || "",
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(logger)
      .build();

    hubConnection.on("ConfirmConnectionAsync", (message: IConfirmConnectionMessage) => {
      if (this.activeHubConnection !== hubConnection) {
        return;
      }

      this.connectionIdValue = message.connectionId;
      this.whenConnected(callbacks);
    });

    hubConnection.on("NotifyUserAsync", (message: INotificationMessage) => {
      if (this.activeHubConnection !== hubConnection) {
        return;
      }

      logging.log("Received notification with type:", message.type, ", payload:", message.payload);

      if (callbacks && callbacks.onNotificationReceived) {
        callbacks.onNotificationReceived(message.type, message.payload);
      }
    });

    hubConnection.onclose((error) => {
      this.whenError(hubConnection, callbacks, "Connection closed with error:", error);
    });

    this.activeHubConnection = hubConnection;

    this.start(hubConnection, callbacks);
  }

  private static getMillisecondsSinceEpoch() {
    return new Date().getTime();
  }

  private start(hubConnection: signalR.HubConnection, callbacks?: ICallbacks): void {
    if (this.activeHubConnection !== hubConnection) {
      return;
    }

    this.lastStartTime = ConnectionManager.getMillisecondsSinceEpoch();

    this.resetState();

    hubConnection
      .start()
      .then(() => {
        if (this.activeHubConnection !== hubConnection) {
          return;
        }

        logging.log("Connected to:", this.hubUrl);
        this.isConnected = true;
        this.whenConnected(callbacks);
      })
      .catch((error) => {
        this.whenError(hubConnection, callbacks, "Connection attempt failed with error:", error);
      });
  }

  private whenConnected(callbacks?: ICallbacks): void {
    if (this.connectionEstablished) {
      logging.log("Connection fully established with id:", this.connectionIdValue);

      if (this.hadErrors) {
        if (callbacks && callbacks.onReconnect) {
          callbacks.onReconnect(this.hubUrl);
        }
      } else {
        if (callbacks && callbacks.onConnect) {
          callbacks.onConnect(this.hubUrl);
        }
      }
    }
  }

  private whenError(
    hubConnection: signalR.HubConnection,
    callbacks?: ICallbacks,
    ...params: Parameters<typeof logging.error>
  ): void {
    if (this.activeHubConnection !== hubConnection) {
      return;
    }

    logging.error(...params);
    this.hadErrors = true;

    this.resetState();

    if (callbacks && callbacks.onError) {
      callbacks.onError(this.hubUrl);
    }

    const elapsedSinceLastStart = Math.abs(ConnectionManager.getMillisecondsSinceEpoch() - this.lastStartTime!);
    const timeout = Math.max(ConnectionManager.maxReconnectTimeout - elapsedSinceLastStart, 0);

    setTimeout(() => this.start(hubConnection, callbacks), timeout);
  }

  private resetState(): void {
    this.connectionIdValue = "";
    this.isConnected = false;
  }

  public disconnect(): void {
    const { connectionIdValue } = this;

    this.resetState();
    this.hadErrors = false;

    const { activeHubConnection } = this;
    if (activeHubConnection) {
      this.activeHubConnection = null;

      activeHubConnection.stop().then(() => {
        if (connectionIdValue && !this.activeHubConnection) {
          logging.log("Disconnected from connection id:", connectionIdValue);
        }
      });
    }

    const { lockResolver } = this;
    if (lockResolver) {
      this.lockResolver = null;
      lockResolver.resolve();

      if (lockResolver.locked) {
        logging.log("Released shared connection manager lock.");
      }
    }
  }
}
