import _ from "lodash";
import moment from "moment";
import {
  defaultFocalPoint,
  IGallery,
  IGalleryCollection,
  IGalleryDeleteInfo,
  IGalleryDetails,
  IGalleryMedia,
  IGalleryMediaDescriptor,
  IGalleryParentShootType,
  IGalleryPhoto,
  IGalleryShootTypes,
  IGalleryUploadNotification,
  IGalleryUrlTemplates,
  IGalleryVideo,
} from "../../models/gallery";
import { StrictOmit } from "../../types";
import { parseDate } from "../../utilities/date";
import { findShootType } from "../../utilities/gallery";
import {
  ICollectionInfoDto,
  IGalleryBaseDto,
  IGalleryCoversDto,
  IGalleryDeleteInfoDto,
  IGalleryDetailsDto,
  IGalleryDto,
  IGalleryMediaDto,
  IGalleryPhotoDto,
  IGalleryShootTypesResponse,
  IGalleryUploadNotificationDto,
  IGalleryUrlTemplatesDto,
  IGalleryVideoDto,
  IMediaIdDto,
} from "./models";

export function mapMedia(gallery: IGalleryUrlTemplatesDto, media: IGalleryMediaDto) {
  return media.isVideo
    ? mapGalleryVideo(media as IGalleryVideoDto, gallery.videoUrlTemplate, gallery.thumbnailUrlTemplate)
    : mapGalleryPhoto(media as IGalleryPhotoDto, gallery.photoUrlTemplate);
}

export function mapMedias(gallery: IGalleryBaseDto) {
  return _.chain<IGalleryMediaDto>(gallery.photos)
    .concat(gallery.videos)
    .sortBy((media) => media.sortIndex)
    .map((media) => mapMedia(gallery, media))
    .value();
}

export function mapGalleryCover(galleryData: IGalleryCoversDto & IGalleryUrlTemplatesDto) {
  const coverMedia = galleryData.coverPhoto ?? galleryData.coverVideo;

  return coverMedia
    ? {
        ...mapMedia(galleryData, coverMedia),
        focalPoint: _.defaults(
          { x: galleryData.coverPhotoFocalPointX, y: galleryData.coverPhotoFocalPointY },
          defaultFocalPoint
        ),
      }
    : undefined;
}

export function mapGalleryUrlTemplates(urlTemplates: IGalleryUrlTemplatesDto): IGalleryUrlTemplates {
  return {
    photoUrlTemplate: urlTemplates.photoUrlTemplate,
    videoUrlTemplate: urlTemplates.videoUrlTemplate,
    thumbnailUrlTemplate: urlTemplates.thumbnailUrlTemplate,
  };
}

export function mapGallery(gallery: IGalleryDto): IGallery {
  return removeNullish({
    id: gallery.id,
    name: gallery.name,
    dateCreated: parseDate(gallery.dateCreated),
    shootDate: gallery.shootDate ? parseDate(gallery.shootDate, false) : undefined,
    cover: mapGalleryCover(gallery),
    medias: mapMedias(gallery),
    collections: mapGalleryCollections(gallery.collections),
    shareEnabled: gallery.shareEnabled,
    active: gallery.expiration.active,
    expirationDate: gallery.expiration.expirationDate ? parseDate(gallery.expiration.expirationDate) : undefined,
    alias: gallery.albumAlias,
    ...mapGalleryUrlTemplates(gallery),
  });
}

export function mapGalleryMediaDescriptor(media: IMediaIdDto): IGalleryMediaDescriptor {
  return { id: media.id, type: media.isVideo ? "video" : "photo" };
}

export function mapGalleryMediaDescriptors(medias: IMediaIdDto[]): IGalleryMediaDescriptor[] {
  return medias.map(mapGalleryMediaDescriptor);
}

function mapGalleryMediaBase(media: IGalleryMediaDto): StrictOmit<IGalleryMedia, "type" | "version" | "urlTemplate"> {
  return removeNullish({
    id: media.id,
    dateCreated: parseDate(media.dateCreated),
    file: { name: media.fileName!, size: media.size },
    name: media.name!,
    title: media.title,
    error: media.error,
  });
}

export function mapGalleryPhoto(photo: IGalleryPhotoDto, urlTemplate: string): IGalleryPhoto {
  return removeNullish({
    ...mapGalleryMediaBase(photo),
    type: "photo",
    version: photo.photoVersion || 1,
    urlTemplate,
    size: photo.width != null && photo.height != null ? { width: photo.width, height: photo.height } : undefined,
    watermarkVersion: photo.watermarkVersion,
  });
}

export function mapGalleryVideo(
  video: IGalleryVideoDto,
  urlTemplate: string,
  thumbnailUrlTemplate: string
): IGalleryVideo {
  return removeNullish({
    ...mapGalleryMediaBase(video),
    type: "video",
    version: video.videoVersion || 1,
    urlTemplate,
    duration: video.durationMilliseconds,
    thumbnail:
      video.thumbnailId && !video.processingWatermark
        ? {
            id: video.thumbnailId,
            size: { width: video.thumbnailWidth!, height: video.thumbnailHeight! },
            urlTemplate: thumbnailUrlTemplate,
            isCustom: video.hasCustomThumbnail,
          }
        : undefined,
  });
}

export function mapGalleryCollection(collection: ICollectionInfoDto): IGalleryCollection {
  return {
    id: collection.collectionId,
    name: collection.name,
    medias: mapGalleryMediaDescriptors(collection.mediaSortIndices),
  };
}

export function mapGalleryCollections(collections: ICollectionInfoDto[]): IGalleryCollection[] {
  return collections.map(mapGalleryCollection);
}

export function mapGalleryUploadNotification(
  notification: IGalleryUploadNotificationDto,
  urlTemplates: IGalleryUrlTemplates
): IGalleryUploadNotification {
  return {
    id: notification.albumId,
    medias: mapGalleryMediaDescriptors(notification.albumMediaIds),
    cover: mapGalleryCover({ ...notification, ...urlTemplates }),
    updatedPhotos: _.map(notification.updatedPhotos, (photo) => mapGalleryPhoto(photo, urlTemplates.photoUrlTemplate)),
    updatedVideos: _.map(notification.updatedVideos, (video) =>
      mapGalleryVideo(video, urlTemplates.videoUrlTemplate, urlTemplates.thumbnailUrlTemplate)
    ),
    collections: mapGalleryCollections(notification.collections),
  };
}

export function reverseMapGalleryMediaDescriptor(media: IGalleryMediaDescriptor): IMediaIdDto {
  return { id: media.id, isVideo: media.type === "video" };
}

export function reverseMapGalleryMediaDescriptors(medias: IGalleryMediaDescriptor[]): IMediaIdDto[] {
  return medias.map(reverseMapGalleryMediaDescriptor);
}

export function mapGalleryDetails(details: IGalleryDetailsDto): IGalleryDetails {
  return {
    dateCreated: parseDate(details.dateCreated),
    dateModified: parseDate(details.dateModified || details.dateCreated),
    accessSettings: details.accessSettings,
    description: details.description || "",
    shootTypeId: details.shootTypeId,
    tags: details.tags || [],
  };
}

export function mapGalleryDeleteInfo(deleteInfo: IGalleryDeleteInfoDto): IGalleryDeleteInfo {
  return {
    lastClientActivity: deleteInfo.lastClientActivity ? parseDate(deleteInfo.lastClientActivity) : undefined,
    hasScheduledEmails: deleteInfo.scheduledEmailsExist,
    clientsCount: _.size(deleteInfo.clientsInfo),
  };
}

export function mapShootTypes(shootTypes: IGalleryShootTypesResponse): IGalleryShootTypes {
  const allShootTypes: IGalleryParentShootType[] = _.chain(shootTypes.yourShootTypes)
    .concat(shootTypes.moreShootTypes)
    .uniqBy((st) => st.id)
    .map((parent) => ({
      id: parent.id,
      name: parent.name,
      children: _.map(parent.subShootTypes, (child) => ({
        id: child.id,
        name: child.name,
      })),
    }))
    .value();

  return {
    topShootTypes: _.chain(shootTypes.mostUsedShootTypes)
      .map((st) => st.id)
      .filter((id) => !!findShootType(allShootTypes, id))
      .value(),
    yourShootTypes: _.map(shootTypes.yourShootTypes, (st) => st.id),
    allShootTypes,
  };
}

export function mapShootDate(shootDate?: Date) {
  return shootDate ? moment(shootDate).utc(true).toDate() : null;
}

function removeNullish<T extends object>(obj: T): T {
  for (const key in obj) {
    if (_.isNil(obj[key])) {
      delete obj[key];
    }
  }
  return obj;
}
