import { useEffect, useState } from "react";
import Segment from "../../plugins/segment/tracking/common";

type BROADCAST_NAME = "click push notification";

const useListenSWToPushSegment = (broadcastName: BROADCAST_NAME) => {
  const [payloadEvent, setPayload] = useState(null);
  const broadcast = new BroadcastChannel(broadcastName);
  broadcast.onmessage = (event) => {
    if (event.data.payload) {
      setPayload(JSON.parse(event.data.payload));
    }
  };
  useEffect(() => {
    if (payloadEvent) {
      Segment.trackAction(broadcastName, payloadEvent);
      setPayload(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payloadEvent]);
};
export default useListenSWToPushSegment;
