import _ from "lodash";
import Flagsmith, { FLAGS } from "./flagsmithClass";
import { getStorageData, removeStorageData, setStorageData } from "./session";
import { validateTokens } from "./token";

export type UploadPhotosMethod = "instagram" | "camera roll" | "sample gallery";

interface IUploadPhotosInfo {
  method: UploadPhotosMethod;
  photosSelected: number;
  photosUploaded: number;
  startedAt: number;
  finishedAt: number;
}

const uploadPhotosInfoStorageKey = "upload_photos_info";

export function removeUploadPhotosInfo() {
  sessionStorage.removeItem(uploadPhotosInfoStorageKey);
}

export function getUploadPhotosInfo() {
  return getStorageData<IUploadPhotosInfo>(uploadPhotosInfoStorageKey, false);
}

export function updateUploadPhotosInfo(update: Partial<IUploadPhotosInfo>) {
  const current = getUploadPhotosInfo();
  setStorageData(uploadPhotosInfoStorageKey, current ? { ...current, ...update } : update, false);
}

export function getDirection(isBack: boolean) {
  return isBack ? "back" : "next";
}

export function enableAiWebsiteCreation() {
  return (
    Flagsmith.isEnabled(FLAGS.ENABLE_AI_MOBILE_ONBOARDING_WEBSITE_CREATION) ||
    validateTokens(true, "user_name").endsWith("ai@zenfolio.com") ||
    sitesGenerationEnabled()
  );
}

function getSitesGenerationStorageKey() {
  return `sites_generation_${validateTokens(true)}`;
}

export interface ISitesGenerationData {
  startedAt?: number;
  chooseSiteShownAt?: number;
  firstLoadedAt?: number;
  allLoadedAt?: number;
}

export function clearSitesGenerationData() {
  removeStorageData(getSitesGenerationStorageKey());
}

export function getSitesGenerationDataValue(key: keyof ISitesGenerationData): number {
  const storageKey = getSitesGenerationStorageKey();
  const data = getStorageData<ISitesGenerationData>(getSitesGenerationStorageKey()) || {};

  let result = data[key];
  if (result != null) {
    return result;
  }

  data[key] = result = Date.now();
  setStorageData(storageKey, data);

  return result;
}

const sitesGenerationEnabledStorageKey = "sites_generation_enabled";

export function enableSitesGeneration(userId: string) {
  const enabledUserIds = getStorageData<string[]>(sitesGenerationEnabledStorageKey) || [];
  if (!_.includes(enabledUserIds, userId)) {
    enabledUserIds.push(userId);
    setStorageData(sitesGenerationEnabledStorageKey, enabledUserIds);
  }
}

function sitesGenerationEnabled() {
  const userId = validateTokens(true);
  const enabledUserIds = getStorageData<string[]>(sitesGenerationEnabledStorageKey);

  return _.includes(enabledUserIds, userId);
}

export const d2pEventSignUpKey = "D2P_EVENT_SIGNUP";
