import { createModel } from "@rematch/core";
import { IMarkShippingOrderPayload } from "@zenfolio/nz-core-components/dist/model/order";
import api from "../../api";
import { IConfirmOrdersDto, IOrdersDto } from "../../api/orders/models";
import { IError, getErrorFromApi } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import { removeCompletedOrders } from "./helpers";
import { IMarkAsShippedParams, INotificationsState, IOrderSearchParams, initialState } from "./models";

export const notifications = createModel<RootModel>()({
  state: initialState,
  reducers: {
    getOrdersToApproveStarted(state) {
      return {
        ...state,
        getOrdersToApprove: { status: "Pending" },
      };
    },
    getOrdersToApproveSuccess(state, payload: { ordersToApprove: IOrdersDto }) {
      return state.getOrdersToApprove.status === "Pending"
        ? {
            ...state,
            ordersApprove: payload.ordersToApprove,
            getOrdersToApprove: { status: "Success" },
          }
        : state;
    },
    getOrdersToApproveError(state, payload: { error: IError }) {
      return state.getOrdersToApprove.status === "Pending"
        ? {
            ...state,
            getOrdersToApprove: { status: "Error", error: payload.error },
          }
        : state;
    },
    updateOrdersToApprove(state, payload: { newOrdersApprove: IOrdersDto }) {
      return {
        ...state,
        ordersApprove: payload.newOrdersApprove,
      };
    },

    cancelOrdersStarted(state) {
      return { ...state, cancelOrders: { status: "Pending" } };
    },
    cancelOrdersSuccess(state) {
      return state.cancelOrders.status === "Pending" ? { ...state, cancelOrders: { status: "Success" } } : state;
    },
    cancelOrdersError(state, payload: { error: IError }) {
      return state.cancelOrders.status === "Pending"
        ? { ...state, cancelOrders: { status: "Error", error: payload.error } }
        : state;
    },

    approveOrdersStarted(state) {
      return { ...state, approveOrders: { status: "Pending" } };
    },
    approveOrdersSuccess(state) {
      return state.approveOrders.status === "Pending" ? { ...state, approveOrders: { status: "Success" } } : state;
    },
    approveOrdersError(state, payload: { error: IError }) {
      return state.approveOrders.status === "Pending"
        ? { ...state, approveOrders: { status: "Error", error: payload.error } }
        : state;
    },

    markOrdersShippedStarted(state) {
      return { ...state, markOrderAsShipped: { status: "Pending" } };
    },
    markOrdersShippedSuccess(state) {
      return state.markOrderAsShipped.status === "Pending"
        ? { ...state, markOrderAsShipped: { status: "Success" } }
        : state;
    },
    markOrdersShippedError(state, payload: { error: IError }) {
      return state.markOrderAsShipped.status === "Pending"
        ? { ...state, markOrderAsShipped: { status: "Error", error: payload.error } }
        : state;
    },
    ...getGenericReducers("getOrdersToApproveTotal")<INotificationsState, {}, { total: number }>({
      success: (_state, payload) => {
        return {
          ordersApprove: {
            ..._state.ordersApprove,
            totalItems: payload.total,
          },
        };
      },
    }),
    resetNotifications() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    async getOrdersListAsync(searchParams: IOrderSearchParams, rootState) {
      dispatch.notifications.getOrdersToApproveStarted();
      try {
        const ordersToApprove: IOrdersDto = await api.orders.getOrders(searchParams);
        let newOrdersToApprove = [...ordersToApprove.orders];
        if (searchParams.pageNumber > 1) {
          newOrdersToApprove = rootState.notifications.ordersApprove.orders.concat(newOrdersToApprove);
        }
        dispatch.notifications.getOrdersToApproveSuccess({
          ordersToApprove: { ...ordersToApprove, orders: newOrdersToApprove },
        });
      } catch (error) {
        dispatch.notifications.getOrdersToApproveError({ error: getErrorFromApi(error) });
      }
    },
    async cancelOrderAsync(params: { orderIds: string[]; isNotRefreshList?: boolean }, rootState) {
      dispatch.notifications.cancelOrdersStarted();
      try {
        const { orderIds, isNotRefreshList } = params;
        const currentOrdersApprove = rootState.notifications.ordersApprove;
        const { orders, totalItems } = currentOrdersApprove;
        const canceledOrdersResponse: IConfirmOrdersDto[] = await api.orders.cancelOrder(orderIds);
        if (canceledOrdersResponse && !isNotRefreshList) {
          const newOrders = removeCompletedOrders(orders, orderIds);
          const newOrdersApprove = {
            ...currentOrdersApprove,
            orders: newOrders,
            totalItems: totalItems - orderIds.length,
          };
          this.updateOrdersToApprove({ newOrdersApprove });
        }
        dispatch.notifications.cancelOrdersSuccess();
        return canceledOrdersResponse;
      } catch (error) {
        dispatch.notifications.cancelOrdersError({ error: getErrorFromApi(error) });
        throw error;
      }
    },
    async approveOrderAsync(params: { orderIds: string[]; isNotRefreshList?: boolean }, rootState) {
      dispatch.notifications.approveOrdersStarted();
      try {
        const { orderIds, isNotRefreshList } = params;
        const currentOrdersApprove = rootState.notifications.ordersApprove;
        const { orders, totalItems } = currentOrdersApprove;
        const approvedOrdersResponse: IConfirmOrdersDto[] = await api.orders.approveOrder(orderIds);
        if (!isNotRefreshList && approvedOrdersResponse) {
          const newOrders = removeCompletedOrders(orders, orderIds);
          const newOrdersApprove = {
            ...currentOrdersApprove,
            orders: newOrders,
            totalItems: totalItems - orderIds.length,
          };
          this.updateOrdersToApprove({ newOrdersApprove });
        }
        dispatch.notifications.approveOrdersSuccess();
        return approvedOrdersResponse;
      } catch (error) {
        dispatch.notifications.approveOrdersError({ error: getErrorFromApi(error) });
        return {
          success: false,
          message: error?.response?.data?.title,
        };
      }
    },
    async markOrderAsShipped(
      paramsPayload: { params: IMarkAsShippedParams; isNotRefreshList?: boolean },
      rootState,
      callback?: (markShippingOrderPayload: IMarkShippingOrderPayload) => void
    ) {
      dispatch.notifications.markOrdersShippedStarted();
      try {
        const { params, isNotRefreshList } = paramsPayload;
        const currentOrdersApprove = rootState.notifications.ordersApprove;
        const { orders, totalItems } = currentOrdersApprove;
        const { orderIds, isSFFLab } = params;
        const newParams = { ...params };
        delete newParams.isSFFLab;
        const markOrdersShippedResponse: IConfirmOrdersDto[] = await api.orders.markAsShipped(newParams);
        if (markOrdersShippedResponse) {
          if (!isNotRefreshList) {
            const newOrders = removeCompletedOrders(orders, orderIds);
            const newOrdersApprove = {
              ...currentOrdersApprove,
              orders: newOrders,
              totalItems: totalItems - orderIds.length,
            };
            this.updateOrdersToApprove({ newOrdersApprove });
          }
          dispatch.notifications.markOrdersShippedSuccess();
          if (callback) {
            const { trackingUrl, isSentEmail } = params;
            const payload: IMarkShippingOrderPayload = {
              isSuccess: true,
              trackingUrl: trackingUrl,
              isSentEmail,
              errorMessage: "",
              isSFFLab: isSFFLab,
            };
            callback(payload);
          }
        }
      } catch (error) {
        dispatch.notifications.markOrdersShippedError({ error: getErrorFromApi(error) });
        if (callback) {
          const { params } = paramsPayload;
          const { trackingUrl, isSentEmail, isSFFLab } = params;
          const payload: IMarkShippingOrderPayload = {
            isSuccess: false,
            trackingUrl: trackingUrl,
            isSentEmail,
            errorMessage: error || error[0].message || "Error marking order as shipped, please try again later.",
            isSFFLab: isSFFLab,
          };
          callback(payload);
        }
      }
    },
    async getOrdersToApproveTotalAsync() {
      try {
        dispatch.notifications.getOrdersToApproveTotalStarted({});
        const res = await api.orders.getOrdersToApproveTotal();
        const total = res["need Approval"] || 0;
        dispatch.notifications.getOrdersToApproveTotalSuccess({ total });
      } catch (error) {
        dispatch.notifications.getOrdersToApproveTotalError({ error: getErrorFromApi(error) });
      }
    },
  }),
});
