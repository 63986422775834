import { IGalleryMediaDto, IGalleryUrlTemplatesDto } from "../gallery/models";

export interface ILastViewedResponseDto {
  items: ITopResultInfoDto[];
}

export interface ITopResultInfoDto {
  id: string;
  name: string;
  parentId?: string | null;
  parentName?: string;
  type: TopResultType;
}

export enum TopResultType {
  Photo = "photo",
  Gallery = "gallery",
  Folder = "folder",
  Video = "video",
}

export interface IFindTopResultsByTextDto {
  count: number;
  items: ITopResultInfoDto[];
}

export enum SortOrderTypeDto {
  CreationDateAsc = "creationDateAsc",
  CreationDateDesc = "creationDateDesc",
  AlphabeticalAsc = "alphabeticalAsc",
  AlphabeticalDesc = "alphabeticalDesc",
  ShootDateAsc = "shootDateAsc",
  ShootDateDesc = "shootDateDesc",
  Relevance = "relevance",
}

export interface IFilteredGalleriesResponseDto {
  count: number;
  galleries: IFilteredGalleryPreviewDto[];
  haveShootDates: boolean;
  effectiveGallerySortingType: SortOrderTypeDto;
}

export interface IFilteredGalleryPreviewDto extends IGalleryUrlTemplatesDto {
  id: string;
  name: string;
  topMedia: IGalleryMediaDto[];
  photoCount: number;
  videoCount: number;
  active: boolean;
}
