import moment from "moment";
import * as logging from "./logging";
import { ISubscription } from "../models/subscription";
import { store } from "../store/store";

export const checkToShowCountdown = (createdAt: string, start?: number, end?: number) => {
  if (!start || !end) return true;
  const daysGone = getDaysGone(createdAt);
  return daysGone >= start && daysGone <= end;
};

export const getDaysGone = (createdAt: string) => {
  if (!createdAt) return 0;
  try {
    const dateCreated = moment.utc(createdAt).startOf("day");
    const daysGone = moment.utc().startOf("day").diff(dateCreated, "days");
    return daysGone + 1;
  } catch (error) {
    logging.error(error);
  }
  return 0;
};

export const getTierNumber = (tier: string): number => {
  const prefix = "tier";
  let result = 0;

  if (tier && tier.toLowerCase().startsWith(prefix)) {
    const txtNumber = tier.substring(prefix.length);
    result = Number(txtNumber) || 0;
  }

  return result;
};

export const isFreeSubscription = (subscriptionOverview: ISubscription[] | null | undefined) => {
  if (!subscriptionOverview) return false;
  const subscription = subscriptionOverview.find((o) => o.isBundle === false);
  return subscription?.isFree;
};

export const getCurrentSubscription = () => {
  const currentState = store.getState();
  const userSettings = currentState.session.userInfo;
  const sub =
    userSettings &&
    userSettings.subscriptionOverview &&
    userSettings.subscriptionOverview.filter((o) => o.isBundle === false)?.[0];
  return sub || null;
};
