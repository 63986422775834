import { FC } from "react";

interface IProps {
  size: number;
  color: string;
}

const ProcessingFinishIcon: FC<IProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd" stroke={props.color} transform="translate(-.4 -.4)">
        <circle cx="16.4" cy="16.4" r="13" fillRule="nonzero" strokeWidth="1.5" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.4 18.66L14.534 22.4 22.4 11.4" />
      </g>
    </svg>
  );
};

export default ProcessingFinishIcon;
