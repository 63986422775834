import { GenericState } from "../generic";

export const initialState: IResetPasswordState = {
  sendEmail: {
    status: "Init",
  },
  error: "",
  emailRecovery: null,
  isSent: false,
  validateTicket: {
    status: "Init",
  },
  ticket: "",
  validateTicketError: "",
  resetPasswordError: "",
  resetPassword: {
    status: "Init",
  },
};

export interface IResetPasswordState {
  sendEmail: GenericState;
  error: string;
  emailRecovery: IEmailRecovery | null;
  isSent: boolean;
  validateTicket: GenericState;
  ticket: string;
  validateTicketError: string;
  resetPasswordError: string;
  resetPassword: GenericState;
}

export interface IResetPassword {
  ticket: string;
  newPassword: string;
  passwordConfirmation: string;
}

export interface IEmailRecovery {
  userName: string;
  appCode?: string;
  isClassic?: boolean;
  isMobile?: boolean;
}

export interface IValidateTicket {
  ticket: string;
  isFetching: boolean;
  error: string;
  isSuccess: boolean;
}

export interface ISendEmailRecoveryParams {
  email: string;
  isClassic: boolean;
}
