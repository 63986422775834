import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { usePrevious } from "react-use";
import styles from "./index.module.scss";

type AnimationMode = "none" | "skip-initial" | "full";
type ModalState = "open" | "opening" | "closing" | "closed";

export interface IOpenable {
  open: boolean;
  onClose: () => void;
  onModalAnimated?: (open: boolean) => void;
}

export interface IBaseProps extends IOpenable {
  className?: string;
  style?: React.CSSProperties;
  overlayClassName?: string;
  children?: React.ReactNode;
  closeOnOverlayClick?: boolean;
  animationMode?: AnimationMode;
  alwaysRender?: boolean;
  onAnimationEnd?: (open: boolean) => void;
}

const Base: React.FC<IBaseProps> = (props) => {
  const {
    open,
    className,
    style,
    overlayClassName,
    children,
    closeOnOverlayClick = true,
    animationMode = "full",
    alwaysRender,
    onClose,
    onModalAnimated,
  } = props;

  const [modalState, setModalState] = useState<ModalState>(
    open ? (animationMode === "full" ? "opening" : "open") : "closed"
  );

  const prevOpen = usePrevious(open);
  useEffect(onComponentUpdate);

  function onOverlayClick(event: React.MouseEvent<HTMLDivElement>) {
    if (event.target === event.currentTarget) {
      onClose();
    }
  }

  function onAnimationEnd() {
    updateModalState();
    props.onAnimationEnd?.(open);
  }

  function onComponentUpdate() {
    if (prevOpen != null && prevOpen !== open) {
      if (animationMode === "none") {
        updateModalState();
      } else {
        setModalState(open ? "opening" : "closing");
      }
    }
  }

  function updateModalState() {
    setModalState(open ? "open" : "closed");
    onModalAnimated?.(open);
  }

  const withAnimation = animationMode !== "none";

  return modalState === "closed" && !alwaysRender
    ? null
    : ReactDOM.createPortal(
        <div
          className={classNames(
            styles.overlay,
            {
              [styles.hidden]: modalState === "closed" && alwaysRender,
            },
            overlayClassName
          )}
          style={style}
          onClick={closeOnOverlayClick ? onOverlayClick : undefined}
        >
          <div
            className={classNames(
              {
                [styles.noAnimation]: !withAnimation || modalState === "open",
                [styles.closing]: modalState === "closing",
              },
              className
            )}
            onAnimationEnd={withAnimation ? onAnimationEnd : undefined}
          >
            {children}
          </div>
        </div>,
        document.body
      );
};

export default Base;
