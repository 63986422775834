import React from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Initializing from "./components/features/Initializing";
import { render } from "./components/features/Lazy/helpers";
import Loading from "./components/features/Loading";
import PublicApiUrlManager from "./components/features/PublicApi/UrlManager";
import useAppStartup from "./components/hooks/useAppStartup";
import useHidePendo from "./components/hooks/useHidePendo";
import usePublicApiParams from "./components/hooks/usePublicApiParams";
import { Dispatch, RootState } from "./store/store";
import { toLocation, tryStartPublicApiSession } from "./utilities/publicApi";
import { clearSession } from "./utilities/session";

const Login = React.lazy(() => import("./components/features/PublicApi/Login"));
const Accept = React.lazy(() => import("./components/features/PublicApi/Accept"));

const mapState = (state: RootState) => ({
  userInfo: state.session.userInfo,
});

const mapDispatch = (dispatch: Dispatch) => ({
  getApplicationAsync: (id: string) => dispatch.publicApi.getApplicationAsync(id),
});

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = StateProps & DispatchProps;

function PublicApiApp(props: Props) {
  const { userInfo, getApplicationAsync } = props;
  const params = usePublicApiParams();

  const initialized = useAppStartup(userInfo, PublicApiUrlManager.socialRoute, undefined, false, async () => {
    if (tryStartPublicApiSession()) {
      clearSession();
    }

    if (!params.applicationId) {
      return false;
    }

    const application = await getApplicationAsync(params.applicationId);
    return !!application;
  });

  useHidePendo(true);

  function renderRoutes(defaultRoute: string, component: ReturnType<typeof React.lazy>) {
    return (
      <Routes>
        <Route path={defaultRoute} element={render(component)} />
        <Route path="*" element={<Navigate to={{ ...toLocation(params), pathname: defaultRoute }} replace={true} />} />
      </Routes>
    );
  }

  return (
    <>
      <Loading />
      {initialized ? (
        userInfo ? (
          renderRoutes(PublicApiUrlManager.acceptRoute, Accept)
        ) : (
          renderRoutes(PublicApiUrlManager.route, Login)
        )
      ) : (
        <Initializing />
      )}
    </>
  );
}

export default connect(mapState, mapDispatch)(PublicApiApp);
