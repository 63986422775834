import UrlManager from "../components/features/More/UrlManager";
import { NewCheckoutModalProps } from "../components/ui/Modal/CheckoutModal/CheckoutForm";

export enum IFRAME_PAGE_TITLE {
  SELLING = "Selling",
  REPORTS = "Reports",
  DASHBOARD = "Dashboard",
  CRM = "CRM",
  BOOKING = "Booking",
  BOOKINGS = "Bookings",
  WEBSITE = "Website",
  BLOG = "Blog",
  ACCOUNT = "Account",
  PREVIEW = "Preview",
  SHARE = "Share",
  SETTINGS = "Settings",
  DESIGN = "Design",
  ACTIVITY = "Activity",
  VIEW_PLANS = "View Plans",
  ADDONS = "Add-Ons",
  SUPPORT = "Support",
  EXPIRED_TRIAL = "Expired Trial",
  CLIENT_VIEW = "Preview",
}

export enum IFRAME_BACK_TO {
  DASHBOARD = "dashboard",
  NOTIFICATIONS = "notifications",
  ORDERS = "orders",
  ORDER = "order",
  GALLERY_PREVIEW = "preview",
  GALLERY_SHARE = "share",
  GALLERY_SETTINGS = "settings",
  GALLERY_DESIGN = "design",
  GALLERY_ACTIVITY = "activity",
  GENERIC = "generic",
  MORE_MENU = "more",
}

export const iframeParams = {
  root: "root",
  path: "path",
  backTo: "backTo",
  backUrl: "backUrl",
  token: "token",
  impersonateToken: "impersonateToken",
  fromMobileAppUrl: "fromMobileAppUrl",
  antiForgery: "antiForgery",
  errorDescription: "errorDescription",
  deviceId: "deviceId",
  isPWAMode: "isPWAMode",
};

export interface IAreaPaths {
  mobilePath: string;
  iframePaths: string[];
  title: IFRAME_PAGE_TITLE;
}

type MobileWebMessageCommon =
  | { type: "redirect"; url: string; newTab?: boolean }
  | { type: "page-loaded" }
  | { type: "page-changed"; path: string; newTab?: boolean }
  | { type: "path-changed"; path: string };

export type MobileWebMessageCustom =
  | { type: "gallery-loaded" }
  | { type: "preview-loaded" }
  | { type: "share-loaded" }
  | { type: "gallery-share-settings-changed" }
  | { type: "gallery-download-settings-changed" }
  | { type: "gallery-sharing-changed"; shareEnabled: boolean }
  | { type: "gallery-privacy-settings-changed" }
  | {
      type: "gallery-expiration-changed";
      galleryActive: boolean;
      expirationDate?: Date;
    }
  | { type: "change-height-iframe"; height: string }
  | {
      type: "gallery-cover-changed";
      mediaId: string;
      isVideo: boolean;
      focalPointX: number;
      focalPointY: number;
    }
  | { type: "close-view-plans" }
  | {
      type: "purchase-success";
    }
  | {
      type: "logout";
    }
  | {
      type: "extend-trial-success";
    }
  | {
      type: "booking-modal-closed";
    }
  | {
      type: "modal-changed";
      modal?: ModalChangedData;
      parentModal?: ModalChangedData;
    }
  | {
      type: "site-engine-preview-loaded";
    }
  | {
      type: "subscribe-notification";
    }
  | {
      type: "unSubscribe-notification";
    }
  | {
      type: "open-checkout-modal";
      props: Partial<NewCheckoutModalProps>
    };

export type ModalChangedData =
  | { action: "open" | "close"; type: string }
  | { action: "change"; type: string; previousType: string };

export type MobileWebMessage = MobileWebMessageCommon | MobileWebMessageCustom;

export const areaPaths: IAreaPaths[] = [
  {
    mobilePath: UrlManager.selling,
    iframePaths: [UrlManager.selling],
    title: IFRAME_PAGE_TITLE.SELLING,
  },
  {
    mobilePath: UrlManager.reports,
    iframePaths: [UrlManager.reports],
    title: IFRAME_PAGE_TITLE.REPORTS,
  },
  {
    mobilePath: UrlManager.crm,
    iframePaths: [UrlManager.crmIframe],
    title: IFRAME_PAGE_TITLE.CRM,
  },
  {
    mobilePath: UrlManager.bookings,
    iframePaths: [UrlManager.bookingsIframe],
    title: IFRAME_PAGE_TITLE.BOOKINGS,
  },
  {
    mobilePath: UrlManager.website,
    iframePaths: [UrlManager.website],
    title: IFRAME_PAGE_TITLE.WEBSITE,
  },
  {
    mobilePath: UrlManager.blog,
    iframePaths: [UrlManager.blog],
    title: IFRAME_PAGE_TITLE.BLOG,
  },
  {
    mobilePath: UrlManager.addons,
    iframePaths: [UrlManager.addonsIframe, UrlManager.toolsIframe],
    title: IFRAME_PAGE_TITLE.ADDONS,
  },
];
