import { CancelTokenSource } from "axios";
import { GalleryCoverMedia } from "../../models/gallery";
import { IPresetInfo } from "../../models/presets";
import { IInstagramMedia } from "../../models/socialMedia";
import { IWatermark } from "../../models/watermarks";
import { BYTES_IN_GB, BYTES_IN_MB, MAX_PHOTO_SIZE, MAX_VIDEO_SIZE } from "../../utilities/media";

export const initialState: IBackgroundJobsState = {
  jobs: [],
};

/** State for background jobs shown in the activity monitor. */
export interface IBackgroundJobsState {
  jobs: BackgroundJob[];
}

/** Less than 50 kbps is considered as having network issues. */
export const MIN_NETWORK_SPEED = 50;
export const MAX_SIMULTANEOUS_UPLOADS = 5;
export const SPEED_MEASUREMENT_WINDOW_MS = 3000;
export const REMAINING_TIME_UPDATE_FREQUENCY_MS = 1000;
export const MIN_TIME_BETWEEN_SPEED_UPDATES_MS = 500;

export const photoSizeError = `Image exceeds ${MAX_PHOTO_SIZE / BYTES_IN_MB}MB`;
export const videoSizeError = `Video exceeds ${MAX_VIDEO_SIZE / BYTES_IN_GB}GB`;
export const unsupportedFileTypeError = "Unsupported file type";
export const uploadFailedError = "Upload failed";
export const uploadingCompleteDescription = "Uploading Complete!";

export const maxVideoLength = 60;
export const maxVideoRate = 60;
export const maxGifPhotoHeight = 2000;
export const maxGifPhotoWidth = 2000;
export const maxVideoSize4k = { width: 3840, height: 2160 };
export const maxVideoSize1080p = { width: 1920, height: 1080 };

export enum JobState {
  Queued = 1,
  Progress = 2,
  Error = 3,
  Completed = 4,
  Canceled = 5,
  Duplicate = 6,
}

export enum JobType {
  MediaUpload = 1,
  FaceRecognition = 2,
  InstagramUpload = 3,
}

export type DuplicatesHandling = "skip" | "allow";

export interface IJob {
  state: JobState;
}

interface IBackgroundJobBase extends IJob {
  id: string;
  parentId?: string;
  galleryId: string;
  galleryName: string;
  description: string;
  jobType: JobType;
  progressStatus: number;
  progressStatusText: string;
  speedText: string;
  speedError: boolean;
  remainingSec?: number;
  gtmEventPushed?: boolean;
}

export interface IMediaUploadBackgroundJob extends IBackgroundJobBase {
  jobType: JobType.MediaUpload;
  loaded: number;
  mediaFile?: File;
  objectUrl?: string;
  sanitizedFileName?: string;
  uploadSpeedInBytesPerSec?: number;
  lastSpeedMeasurementTimestamp?: number;
  lastRemainingTimeUpdateTimestamp?: number;
  collectionId?: string;
  uploadBatchTimestamp: number;
  cancelTokenSource?: CancelTokenSource;
  isFirstMedia: boolean;
  mediaId?: string;
  orderId?: string;
  originalMediaId?: string;
  videoWatermark?: IWatermark;
  isNew?: boolean;
}

export interface IFaceRecognitionBackgroundJob extends IBackgroundJobBase {
  jobType: JobType.FaceRecognition;
  cover?: GalleryCoverMedia;
}

export interface IInstagramUploadBackgroundJob extends IBackgroundJobBase {
  jobType: JobType.InstagramUpload;
  files: IInstagramFile[];
  completedCount: number;
  collectionId?: string;
  uploadBatchTimestamp: number;
  isNew: boolean;
}

export type BackgroundJob = IMediaUploadBackgroundJob | IFaceRecognitionBackgroundJob | IInstagramUploadBackgroundJob;

export interface IUploadFile {
  file: File;
  isDuplicate?: boolean;
  error?: string;
  videoWatermark?: IWatermark;
}

export interface IInstagramFile {
  state: JobState;
  media: IInstagramMedia;
  name: string;
}

export interface IMediaError {
  message: string;
  isMediaError: boolean;
}

export interface IUploadJobPayload {
  galleryId: string;
  galleryName: string;
  files: IUploadFile[];
  isNew?: boolean;
  duplicatesHandling: DuplicatesHandling;
  collectionId?: string;
  orderIds?: string[];
  originalPhotoIds?: string[];
  track?: boolean;
  preset?: IPresetInfo;
}

export interface IInstagramJobPayload {
  galleryId: string;
  galleryName: string;
  collectionId?: string;
  isNew: boolean;
  files: IInstagramFile[];
}

interface IMediaPayload {
  collectionId?: string | null;
  uploadBatchTimestamp: number;
  isFirst: boolean;
}

export interface IPhotoPayload extends IMediaPayload {
  orderId?: string | null;
  originalPhotoId?: string | null;
}

export interface IVideoPayload extends IMediaPayload {
  hasInitialThumbnail?: boolean;
  passGaEventId?: boolean;
}

export interface IThumbnailPayload {
  galleryId: string;
  currentThumbnailId?: string | null;
  isInitial?: boolean;
}
