import { contactApi } from "..";
import { ISearchContactByEmailDto } from "./model";

export interface IApiContact {
  searchContactByEmail: (email: string) => Promise<ISearchContactByEmailDto>;
}

const contact: IApiContact = {
  searchContactByEmail: (email: string) =>
    contactApi
      .get<ISearchContactByEmailDto>("contacts/email", {
        params: {
          email,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        return error;
      }),
};

export default contact;
