import classNames from "classnames";
import _ from "lodash";
import pluralize from "pluralize";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Tooltip } from "react-tooltip";
import { isJobActive, isJobUnsuccessful, isUploadJob } from "../../../store/backgroundJobs/helpers";
import { JobState, JobType } from "../../../store/backgroundJobs/models";
import { Dispatch, RootState } from "../../../store/store";
import colors from "../../../utilities/colors";
import {
  baseTooltipProps,
  defaultProgressBorderWidth,
  defaultProgressIconSize,
  defaultProgressSize,
} from "../../../utilities/constants";
import useModal from "../../hooks/useModal";
import FaceRecognitionIcon from "../../ui/Icons/FaceRecognitionIcon";
import MediaUploadIcon from "../../ui/Icons/MediaUploadIcon";
import ProcessingFinishIcon from "../../ui/Icons/ProcessingFinishIcon";
import ProcessingIcon from "../../ui/Icons/ProcessingIcon";
import Modal from "../../ui/Modal";
import ProgressCircle from "../../ui/ProgressCircle";
import { childJobTooltipId } from "./ChildJob";
import ParentJob from "./ParentJob";
import styles from "./index.module.scss";

const mapState = (state: RootState) => ({
  galleryId: state.gallery.gallery?.id,
  jobs: state.backgroundJobs.jobs,
});

const mapDispatch = (dispatch: Dispatch) => ({
  cancelJobAsync: (jobId: string) => dispatch.backgroundJobs.cancelJobAsync({ jobId }),
  clearJobs: () => dispatch.backgroundJobs.clearJobs(),
});

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = StateProps & DispatchProps;

function ActivityMonitor(props: Props) {
  const { galleryId, jobs, cancelJobAsync, clearJobs } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [expandedViewMounted, setExpandedViewMounted] = useState(false);
  const [expandedViewOpen, onExpandedViewClose, onExpandedViewOpen] = useModal();
  const [errorJobs, setErrorJobs] = useState<Record<string, boolean>>({});
  const [expandedJobs, setExpandedJobs] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (expandedViewOpen) {
      setExpandedViewMounted(true);
    }
  }, [expandedViewOpen]);

  const onClose = useCallback(() => {
    clearJobs();
    setErrorJobs({});
    setExpandedJobs({});
    setTooltipOpen(false);
    setExpandedViewMounted(expandedViewOpen);
  }, [clearJobs, expandedViewOpen]);

  const onCloseClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onClose();
    },
    [onClose]
  );

  const {
    parentJobs,
    hasParentJobs,
    hasActiveJobs,
    progress,
    hasFaceRecognitionJobs,
    hasUploadJobs,
    hasActiveMediaUploadJobs,
    canAutoClose,
  } = useMemo(() => {
    const parentJobs = _.filter(jobs, (j) => !j.parentId);
    const activeJobs = _.filter(parentJobs, (j) => isJobActive(j));
    const hasParentJobs = !_.isEmpty(parentJobs);
    const hasActiveJobs = !_.isEmpty(activeJobs);
    const progress = hasParentJobs ? _.sumBy(parentJobs, (j) => j.progressStatus) / parentJobs.length : 0;
    const hasFaceRecognitionJobs = _.some(parentJobs, (j) => j.jobType === JobType.FaceRecognition);
    const hasUploadJobs = _.some(parentJobs, (j) => isUploadJob(j));
    const hasActiveMediaUploadJobs = _.some(activeJobs, (j) => j.jobType === JobType.MediaUpload);
    const canAutoClose =
      hasParentJobs &&
      !hasActiveJobs &&
      !_.some(jobs, (j) => isJobUnsuccessful(j)) &&
      !!galleryId &&
      _.every(parentJobs, (j) => j.galleryId === galleryId);

    return {
      parentJobs,
      hasParentJobs,
      hasActiveJobs,
      progress,
      hasFaceRecognitionJobs,
      hasUploadJobs,
      hasActiveMediaUploadJobs,
      canAutoClose,
    };
  }, [jobs, galleryId]);

  useEffect(() => {
    if (!canAutoClose || expandedViewMounted) {
      return;
    }

    let timer: NodeJS.Timeout | null = setTimeout(() => {
      timer = null;
      onClose();
    }, 5000);

    return () => {
      if (timer != null) {
        clearTimeout(timer);
      }
    };
  }, [canAutoClose, expandedViewMounted, onClose]);

  useEffect(() => {
    if (!hasActiveMediaUploadJobs) {
      return;
    }

    function preventClose(event: BeforeUnloadEvent) {
      event.preventDefault();
      return (event.returnValue = "Are you sure you want to close?");
    }

    window.addEventListener("beforeunload", preventClose);

    return () => {
      window.removeEventListener("beforeunload", preventClose);
    };
  }, [hasActiveMediaUploadJobs]);

  useEffect(() => {
    const newErrorJobs: Record<string, boolean> = {};

    _.each(parentJobs, (parentJob) => {
      if (!errorJobs[parentJob.id] && _.some(jobs, (j) => j.parentId === parentJob.id && j.state === JobState.Error)) {
        newErrorJobs[parentJob.id] = true;
      }
    });

    if (!_.isEmpty(newErrorJobs)) {
      setErrorJobs((current) => ({ ...current, ...newErrorJobs }));
      setExpandedJobs((current) => ({ ...current, ...newErrorJobs }));
      onExpandedViewOpen();
    }
  }, [jobs, parentJobs, errorJobs, onExpandedViewOpen]);

  const countsInfo = useMemo((): [string, boolean?] => {
    if (!hasActiveJobs && !_.isEmpty(errorJobs)) {
      const childErrorsCount = _.sumBy(jobs, (j) =>
        j.parentId && errorJobs[j.parentId] && j.state === JobState.Error ? 1 : 0
      );

      return [pluralize("Error", childErrorsCount, true), true];
    }

    if (hasFaceRecognitionJobs) {
      return [pluralize("activity", parentJobs.length, true)];
    }

    let current = 0,
      total = 0;

    _.each(jobs, (job) => {
      if (job.parentId) {
        total++;

        if (!isJobActive(job) && job.state !== JobState.Canceled) {
          current++;
        }
      } else if (job.jobType === JobType.InstagramUpload) {
        total += job.files.length;
        current += job.completedCount;
      }
    });

    return [`${current} of ${total}`];
  }, [jobs, parentJobs, errorJobs, hasActiveJobs, hasFaceRecognitionJobs]);

  const onJobExpand = useCallback((jobId: string, expanded: boolean) => {
    setExpandedJobs((current) => ({ ...current, [jobId]: expanded }));
  }, []);

  const onAnimationEnd = useCallback((open: boolean) => {
    if (!open) {
      setExpandedViewMounted(false);
    }
  }, []);

  const onTooltipOpen = useCallback((open: boolean) => {
    setTooltipOpen(open);
  }, []);

  const onContentScroll = useCallback(() => {
    if (tooltipOpen) {
      setTooltipOpen(false);
    }
  }, [tooltipOpen]);

  function getProgressStatus(): [FC<{ size: number; color: string }>, string] {
    if (!hasActiveJobs) {
      return [ProcessingFinishIcon, "Completed!"];
    }

    if (hasFaceRecognitionJobs && hasUploadJobs) {
      return [ProcessingIcon, "Processing"];
    }

    if (hasUploadJobs) {
      return [MediaUploadIcon, "Adding"];
    }

    return [FaceRecognitionIcon, "Scanning"];
  }

  function renderCollapsed() {
    const size = defaultProgressSize;
    const iconSize = defaultProgressIconSize;
    const borderWidth = defaultProgressBorderWidth;
    const [Icon, status] = getProgressStatus();
    const [counts, error] = countsInfo;

    return ReactDOM.createPortal(
      <div
        className={styles.collapsed}
        onClick={onExpandedViewOpen}
        style={{
          width: size,
          height: size,
          borderWidth,
        }}
      >
        <ProgressCircle className={styles.ring} progress={progress} size={size} borderWidth={borderWidth} />
        <Icon size={iconSize} color={colors.fontDark} />
        <div className={styles.status}>{status}</div>
        <div className={classNames(styles.counts, error && styles.error)}>{counts}</div>
        {!hasActiveJobs && <button className={styles.close} onClick={onCloseClick} />}
      </div>,
      document.body
    );
  }

  function renderExpanded() {
    return (
      <>
        <Modal.BottomPanel
          open={expandedViewOpen}
          showClose={false}
          closeOnOverlayClick={false}
          onClose={_.noop}
          onAnimationEnd={onAnimationEnd}
          onContentScroll={onContentScroll}
          className={styles.expanded}
          overlayClassName={styles.overlay}
          headerClassName={styles.header}
          contentClassName={styles.content}
          header={
            <>
              Activity Monitor
              <button className={classNames(styles.button, styles.collapse)} onClick={onExpandedViewClose} />
              <button className={classNames(styles.button, styles.close)} disabled={hasActiveJobs} onClick={onClose} />
              <Tooltip
                {...baseTooltipProps}
                id={childJobTooltipId}
                className={styles.tooltip}
                offset={7}
                isOpen={tooltipOpen}
                setIsOpen={onTooltipOpen}
              />
            </>
          }
        >
          {_.map(parentJobs, (job) => (
            <ParentJob
              key={job.id}
              job={job}
              jobs={jobs}
              expanded={!!expandedJobs[job.id]}
              onCancel={cancelJobAsync}
              onExpand={onJobExpand}
            />
          ))}
        </Modal.BottomPanel>
      </>
    );
  }

  if (!hasParentJobs) {
    return null;
  }

  return expandedViewMounted ? renderExpanded() : renderCollapsed();
}

export default connect(mapState, mapDispatch)(ActivityMonitor);
