import { IWatermark } from "../../models/watermarks";
import { IWatermarkDto } from "./models";

export function mapWatermark(watermark: IWatermarkDto): IWatermark {
  return {
    id: watermark.watermarkId,
    name: watermark.name,
    opacity: watermark.opacity,
    position: watermark.position,
    scale: watermark.scale,
    isDefault: watermark.isDefault,
    hasDropShadow: watermark.hasDropShadow,
  };
}
