import { useMedia, useTitle } from "react-use";

/* eslint-disable react-hooks/rules-of-hooks */
export default function usePageTitle(title: string, disabled: boolean = false, restoreOnUnmount: boolean = false) {
  if (!disabled) {
    // see: https://web.dev/add-manifest/#name
    const isStandalone = useMedia("(display-mode: standalone)");

    useTitle(isStandalone ? title : `Zenfolio${title ? ` | ${title}` : ""}`, { restoreOnUnmount });
  }
}
