import { IFilteredGalleriesRequest, IFilteredGalleryPreview, ITopResultInfo } from "../../models/search";
import { GenericState } from "../generic";

export const initialState: ISearchState = {
  getLastViewed: { status: "Init" },
  updateSearchText: { status: "Init", searchText: "" },
  searchGalleries: { status: "Init" },
};

/** State for search related actions. */
export interface ISearchState {
  getLastViewed: GetLastViewedState;
  updateSearchText: UpdateSearchTextState;
  searchGalleries: SearchGalleriesState;
  galleries?: IFilteredGalleryPreview[];
}

export type GetLastViewedState = GenericState<IGetLastViewedInput, Partial<IGetLastViewedResult>>;
export type UpdateSearchTextState = GenericState<IUpdateSearchTextInput, IUpdateSearchTextResult>;
export type SearchGalleriesState = GenericState<ISearchGalleriesInput, Omit<ISearchGalleriesResult, "galleries">>;

export interface IGetLastViewedInput {}
export interface IGetLastViewedResult {
  items: ITopResultInfo[];
}

export interface IUpdateSearchTextInput {
  searchText: string;
}
export interface IUpdateSearchTextResult {
  items: ITopResultInfo[];
  count: number;
}

export interface ISearchGalleriesInput {
  request: IFilteredGalleriesRequest;
  reset?: boolean;
}
export interface ISearchGalleriesResult {
  galleries: IFilteredGalleryPreview[];
  count: number;
}
