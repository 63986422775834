import _ from "lodash";
import { bookMeApi } from "..";
import { IBookingBase, IBookingStatus } from "../../models/booking";
import {
  IApproveBookingParams,
  IBookingsParams,
  ICancelBookingParams,
  IDeclineBookingParams,
  IUpdateBookingParams,
} from "../../store/bookings/models";
import { IApproveBookingDto, IBookingsDto, IBookingsTotalDto, IBookmeStateDto, IUpdateBookingDto } from "./model";

export interface IApiBookings {
  getBookings: (params?: IBookingsParams) => Promise<IBookingsDto>;
  approveBooking: (params: IApproveBookingParams) => Promise<IBookingStatus>;
  declineBooking: (params: IDeclineBookingParams) => Promise<void>;
  updateBooking: (params: IUpdateBookingParams) => Promise<IUpdateBookingDto>;
  cancelBooking: (params: ICancelBookingParams) => Promise<void>;
  getBookmeState: () => Promise<IBookmeStateDto>;
  getBookingsTotal: () => Promise<IBookingsTotalDto>;
  getBookingDetail: (bookingId: string) => Promise<IBookingBase>;
}

const bookings: IApiBookings = {
  getBookings: async (params?: IBookingsParams) =>
    (await bookMeApi.get<IBookingsDto>("photographer/bookings", { params })).data,
  approveBooking: async ({ bookingId, note }: IApproveBookingParams) =>
    (await bookMeApi.post<IApproveBookingDto>("photographer/bookings/approve", { bookingId, reason: note })).data
      .status,
  declineBooking: ({ bookingId, note }: IDeclineBookingParams) =>
    bookMeApi.post("photographer/bookings/decline", { bookingId, reason: note }),
  updateBooking: async ({ bookingId, countryCode, updater }: IUpdateBookingParams) => {
    return (
      await bookMeApi.put<IUpdateBookingDto>("photographer/bookings", {
        bookingId,
        bookingData: _.omit(updater, "customer"),
        customerData: updater.customer,
        countryCode,
      })
    ).data;
  },
  cancelBooking: ({ bookingId, notifyClient, note }: ICancelBookingParams) =>
    bookMeApi.delete("photographer/bookings", {
      data: { id: bookingId, notifyClient, canceledNote: note },
    }),
  getBookmeState: async () => (await bookMeApi.get<IBookmeStateDto>("photographer/state")).data,
  getBookingsTotal: async () => (await bookMeApi.get<IBookingsTotalDto>("photographer/bookings/count")).data,
  getBookingDetail: async (bookingId: string) =>
    (await bookMeApi.get<IBookingBase>(`photographer/bookings/${bookingId}`)).data,
};

export default bookings;
