import classNames from "classnames";
import { iconInfo } from "../../../../icons";
import { isJobFailed } from "../../../../store/backgroundJobs/helpers";
import { BackgroundJob, JobType, unsupportedFileTypeError } from "../../../../store/backgroundJobs/models";
import { isVideoFile } from "../../../../utilities/files";
import Preview from "../Preview";
import Progress from "../Progress";
import styles from "./index.module.scss";

interface IChildJobProps {
  job: BackgroundJob;
}

export const childJobTooltipId = "child-job-tooltip";

function ChildJob(props: IChildJobProps) {
  const { job } = props;

  const hasError = isJobFailed(job);

  function getUnsupportedFileTypeTooltip() {
    if (job.jobType === JobType.MediaUpload && job.mediaFile && isVideoFile(job.mediaFile)) {
      return "Supported video formats: MOV, MP4";
    }

    return "Supported file formats: JPEG, HEIF,<br /> PNG, GIF";
  }

  return (
    <div className={styles.container}>
      <Preview className={styles.preview} size={32} job={job} />
      <div className={styles.details}>
        <div className={styles.title}>
          <div className={styles.description}>{job.description}</div>
          <div className={classNames(styles.status, hasError && styles.error)}>{job.progressStatusText}</div>
          {job.progressStatusText === unsupportedFileTypeError && (
            <img
              src={iconInfo}
              alt="info"
              data-tooltip-id={childJobTooltipId}
              data-tooltip-html={getUnsupportedFileTypeTooltip()}
            />
          )}
        </div>
        <Progress progress={job.progressStatus} hasError={hasError} />
      </div>
    </div>
  );
}

export default ChildJob;
