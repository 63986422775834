import { IUnreadComment } from "../../models/comments";
import { defaultFocalPoint } from "../../models/gallery";
import { mapGalleryPhoto, mapGalleryVideo } from "../gallery/mappers";
import { ICommentDto } from "./models";

export const mapCommentMedias = (comments: ICommentDto[]): IUnreadComment[] => {
  return comments.map((comment) => {
    if (comment.photo) {
      const photo = mapGalleryPhoto(comment.photo, comment.photoUrlTemplate);
      return {
        ...comment,
        photo: {
          ...photo,
          focalPoint: defaultFocalPoint,
        },
        video: null,
      };
    } else {
      const video = mapGalleryVideo(comment.video, comment.videoUrlTemplate, comment.thumbnailUrlTemplate);
      return {
        ...comment,
        video: {
          ...video,
          focalPoint: defaultFocalPoint,
        },
        photo: null,
      };
    }
  });
};
