import { IUserInfo, PhotographerStatusCode, defaultUserStatus } from "../models/session";
import { ChargePeriod, defaultChargePeriod } from "../models/subscription";

export function getUserType(userInfo: IUserInfo) {
  return userInfo.isTrial ? "Trial" : "Paid";
}

const planPeriodNouns: Record<ChargePeriod, string> = {
  [ChargePeriod.Day]: "Day",
  [ChargePeriod.Week]: "Week",
  [ChargePeriod.Month]: "Month",
  [ChargePeriod.Year]: "Year",
};

const planPeriodAdjectives: Record<ChargePeriod, string> = {
  [ChargePeriod.Day]: "Daily",
  [ChargePeriod.Week]: "Weekly",
  [ChargePeriod.Month]: "Monthly",
  [ChargePeriod.Year]: "Annual",
};

export function getPlanPeriodNoun(userInfo: IUserInfo) {
  return planPeriodNouns[userInfo.chargePeriod] || planPeriodNouns[defaultChargePeriod];
}

export function getPlanPeriodAdjective(userInfo: IUserInfo) {
  return planPeriodAdjectives[userInfo.chargePeriod] || planPeriodAdjectives[defaultChargePeriod];
}

const userStatuses: Record<PhotographerStatusCode, string> = {
  [PhotographerStatusCode.None]: "None",
  [PhotographerStatusCode.Active]: "Active",
  [PhotographerStatusCode.Closed]: "Closed",
  [PhotographerStatusCode.Purged]: "Purged",
  [PhotographerStatusCode.Suspended]: "Suspended",
  [PhotographerStatusCode.SuspendedExtend]: "Suspended Extend",
};

export function getUserStatus(userInfo: IUserInfo): string {
  return userStatuses[userInfo.status] || userStatuses[defaultUserStatus];
}

export const experience = "new mobile";
