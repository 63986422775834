import _ from "lodash";
import { IUserProperties } from "../models/account";
import { IUserInfo } from "../models/session";
import { defaultIdentity, isPendoEnabled } from "../plugins/pendo";
import { buildDiff, getFullName } from "./helpers";
import * as logging from "./logging";

interface IPendoVisitor {
  id: string;
  email: string;
  fullName: string;
  [key: string]: string | number | boolean | string[] | null;
}

interface IPendoAccount {
  id: string;
  planLevel: string;
  monthly_value?: number | undefined;
  [key: string]: string | number | boolean | string[] | null | undefined;
}

interface IPendoUserProperties extends IPendoVisitor, IPendoAccount {}

class PendoClass {
  private properties: IPendoUserProperties | undefined;

  public identify(userInfo: IUserInfo, userProperties?: IUserProperties) {
    try {
      if (isPendoEnabled) {
        const properties = this.buildProperties(userInfo);
        const identity = this.buildIdentity(properties, userProperties);

        pendo.identify(identity);
        this.properties = properties;
      }
    } catch (error) {
      logging.error("Pendo.identify has error:", error);
    }
  }

  public update(userInfo: IUserInfo) {
    try {
      if (isPendoEnabled && this.properties) {
        const properties = this.buildProperties(userInfo);
        const diff = buildDiff(this.properties, properties);

        if (!_.isEmpty(diff)) {
          const identity = this.buildIdentity(properties);

          pendo.updateOptions(identity);
          this.properties = properties;
        }
      }
    } catch (error) {
      logging.error("Pendo.update has error:", error);
    }
  }

  public clear() {
    try {
      if (isPendoEnabled && this.properties) {
        pendo.clearSession();
        pendo.updateOptions(defaultIdentity);
        this.properties = undefined;
      }
    } catch (error) {
      logging.error("Pendo.clear has error:", error);
    }
  }

  private buildProperties(userInfo: IUserInfo): IPendoUserProperties {
    return {
      id: userInfo.id,
      fullName: getFullName(userInfo),
      businessName: userInfo.businessName,
      email: userInfo.email,
      planLevel: userInfo.planCode,
      is_paying: !userInfo.isUserTrial,
      ...(userInfo.identitySegment?.accountValue && { monthly_value: userInfo.identitySegment?.accountValue }),
    };
  }

  private buildIdentity(properties: IPendoUserProperties, userProperties?: IUserProperties) {
    return {
      visitor: {
        id: properties.id,
        email: properties.email,
        full_name: properties.fullName,
      },
      account: {
        ...userProperties,
        id: properties.id,
        name: properties.businessName || properties.fullName,
        is_paying: properties.is_paying,
        planLevel: properties.planLevel,
        monthly_value: properties.monthly_value,
      },
    };
  }
}

const Pendo = new PendoClass();

export default Pendo;
