import {
  IInitialGalleriesInfo,
  IInitialGallery,
  IOnBoardingProcess,
  IStep,
  IStepAnswer,
  IStepAnswerOption,
  OnboardingStep,
} from "../../models/onBoarding";
import { mapGalleryUrlTemplates, mapMedias } from "../gallery/mappers";
import {
  IGalleryInitialInfoDto,
  IInitialGalleriesInfoDto,
  IStepAnswerRequest,
  OnBoardingProcessDto,
  QuestionDto,
} from "./models";

export function map_Onboarding_Process(data: OnBoardingProcessDto): IOnBoardingProcess {
  return {
    steps: filter_And_Sort_Steps(data.questions.map(map_Question_To_Step)),
    isPasswordChanged: data.isPasswordChanged,
    isCompleted: data.isCompleted,
    isSocial: data.isSocial,
  };
}

export function map_Question_To_Step(question: QuestionDto): IStep {
  const otherShootTypeId = "a1619cc1-68d1-494f-9674-e1212370292c";

  const stepAnswerOptions: IStepAnswerOption[] = question.questionAnswers.map((qa, i) => ({
    id: qa.id,
    value: qa.content,
    order: i + 1,
  }));

  const otherShootTypeIndex = stepAnswerOptions.findIndex((o) => o.id === otherShootTypeId);
  const otherShootType = stepAnswerOptions.splice(otherShootTypeIndex, 1)[0];
  stepAnswerOptions.push(otherShootType);

  return {
    id: map_QuestionId_To_OnBoardingStep(question.id)!,
    isAnswered: question.isAnswered,
    stepAnswerOptions: stepAnswerOptions,
    stepAnswers: question.userQuestionAnswers.map((a) => ({
      id: a.id,
      answerOptionId: a.questionAnswerId,
      value: a.answerValue,
    })),
  };
}

const filter_And_Sort_Steps = (steps: IStep[]) => {
  const stepsOrder = Object.values(OnboardingStep);

  const filteredSteps = steps.filter((s) => s.id); // Don't include unnecessary steps
  const sortedSteps = filteredSteps.sort((a, b) => stepsOrder.indexOf(a.id) - stepsOrder.indexOf(b.id));

  return sortedSteps;
};

export const map_QuestionId_To_OnBoardingStep = (id: string): OnboardingStep | undefined => {
  switch (id) {
    case "9e84d6cd-fb61-41ac-91c7-2000cd1a3ff7":
      return OnboardingStep.BusinessNameAndPassword;
    case "113d60e5-5c9c-4c0e-abe3-ed086af6bac2":
      return OnboardingStep.ShootTypes;
    case "39a93b18-9479-4aae-9a73-9e437111d3d4":
      return OnboardingStep.PrimaryShootType;
    case "3baf9f8d-8590-4af2-9165-2b209fc22879":
      return OnboardingStep.Goals;
  }
};

export const map_StepAnswer_To_StepAnswerRequest = (stepAnswer: IStepAnswer): IStepAnswerRequest => {
  return {
    isMobile: true,
    questionId: map_OnBoardingStep_To_QuestionId(stepAnswer.stepId)!,
    password: stepAnswer.password || undefined, // Make sure to pass undefined if empty string
    questionAnswers: stepAnswer.answers.map((a) => ({
      questionAnswerId: a.id,
      answerValue: a.value,
      answerExtraValue: "",
    })),
  };
};

export const map_OnBoardingStep_To_QuestionId = (stepId: OnboardingStep): string | undefined => {
  switch (stepId) {
    case OnboardingStep.BusinessNameAndPassword:
      return "9e84d6cd-fb61-41ac-91c7-2000cd1a3ff7";
    case OnboardingStep.ShootTypes:
      return "113d60e5-5c9c-4c0e-abe3-ed086af6bac2";
    case OnboardingStep.PrimaryShootType:
      return "39a93b18-9479-4aae-9a73-9e437111d3d4";
    case OnboardingStep.Goals:
      return "3baf9f8d-8590-4af2-9165-2b209fc22879";
  }
};

export const mapInitialGallery = (gallery: IGalleryInitialInfoDto, isSample: boolean): IInitialGallery => {
  return {
    id: gallery.id,
    name: gallery.name,
    medias: mapMedias(gallery),
    isSample,
    ...mapGalleryUrlTemplates(gallery),
  };
};

export const mapInitialGalleriesInfo = (info: IInitialGalleriesInfoDto): IInitialGalleriesInfo => {
  const isSample = !!info.sampleGallery?.isManual;
  const gallery = isSample ? info.sampleGallery : info.ownGallery;

  return {
    rootId: info.rootId!,
    gallery: gallery ? mapInitialGallery(gallery, isSample) : undefined,
  };
};
