import { FC } from "react";

interface IProps {
  size: number;
  color: string;
}

const MediaUploadIcon: FC<IProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" stroke={props.color}>
        <path d="M10.25 13h1.25c1.933 0 3.5-1.567 3.5-3.5S13.433 6 11.5 6c-.35 0-.686.051-1.004.146" />
        <path d="M10.695 6.6c-.069-.19-.15-.695-.24-.873C9.627 4.108 7.943 3 6 3 3.239 3 1 5.239 1 8s2.239 5 5 5" />
        <g strokeLinecap="round">
          <path d="M2 6L2 1" transform="translate(6 7.5)" />
          <path strokeLinejoin="round" d="M0 2L2 0 4 2" transform="translate(6 7.5)" />
        </g>
      </g>
    </svg>
  );
};

export default MediaUploadIcon;
