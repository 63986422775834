import { useCallback, useState } from "react";

export default function useModal(initialOpen: boolean = false): [boolean, () => void, () => void] {
  const [open, setOpen] = useState(initialOpen);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return [open, onClose, onOpen];
}
