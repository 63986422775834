import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ISubscription } from "../../../../models/subscription";
import { selectPromotion4Display } from "../../../../store/session/subscription/selectors";
import { Dispatch, RootState } from "../../../../store/store";
import { LOCAL_STORAGE_HIDE_TRIAL_BANNER_PARAM } from "../../../../utilities/constants";
import { getStorageData, setStorageData } from "../../../../utilities/session";
import { isFreeSubscription } from "../../../../utilities/subscription";
import ExpiredTrialIFrame from "../ExpiredTrialIFrame";
import TrialBanner from "../TrialBanner";

interface IProps {}

const TrialValidator: FC<Props> = (props) => {
  const {
    userInfo,
    hideTrialBanner,
    showTrialBanner,
    promotionCampaigns,
    getPromotionCampaign,
    setShowTrialBanner,
    logoutAsync,
    getActivePlans,
    activePlans,
  } = props;
  const { isTrial, trialDays, expiredDays, id: userId, subscriptionOverview, identitySegment } = userInfo;

  const [showExpiredTrial, setShowExpiredTrial] = useState(false);

  useEffect(() => {
    const isFree = isFreeSubscription(userInfo.subscriptionOverview);
    if (isTrial && !isFree) {
      if (expiredDays > 0) {
        if (!shouldHideTrialBanner(userId, trialDays < 2)) {
          setShowTrialBanner();
        }
      } else {
        setShowExpiredTrial(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrial, trialDays, expiredDays, setShowTrialBanner, userId]);

  // Fetch promo & active plans
  useEffect(() => {
    if (isTrial) {
      getPromotionCampaign(isTrial);
      getActivePlans(userInfo.planCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseTrialBanner = (isLastDay: boolean) => {
    setHideTrialBanner(userId, isLastDay);
    hideTrialBanner();
  };

  const onPurchaseSuccess = () => {
    setShowExpiredTrial(false);
    window.location.reload();
  };

  if (showExpiredTrial) {
    return (
      <ExpiredTrialIFrame
        onLogout={logoutAsync}
        onPurchaseSuccess={onPurchaseSuccess}
        onExtendTrial={onPurchaseSuccess}
      />
    );
  }

  if (showTrialBanner) {
    const displayPromotion = selectPromotion4Display(
      activePlans || [],
      userInfo.planTier,
      subscriptionOverview,
      isTrial,
      promotionCampaigns || []
    );

    return (
      <TrialBanner
        trialDaysLeft={trialDays}
        onCloseTrialBanner={onCloseTrialBanner}
        promotion={displayPromotion}
        subscriptionOverview={subscriptionOverview}
        identitySegment={identitySegment}
      />
    );
  }

  return null;
};

const mapState = (state: RootState) => ({
  userInfo: state.session.userInfo!,
  showTrialBanner: state.session.showTrialBanner,
  getPromotionCampaignState: state.session.getPromotionCampaigns,
  promotionCampaigns: state.session.promotionCampaigns,
  activePlans: state.session.activePlans,
});

const mapDispatch = (dispatch: Dispatch) => ({
  hideTrialBanner: () => dispatch.session.hideTrialBanner(),
  getPromotionCampaign: (isTrialUser: boolean) => dispatch.session.getPromotionCampaignsAsync({ isTrialUser }),
  getActivePlans: (currentPlanCode: string) => dispatch.session.getActivePlansAsync({ currentPlanCode }),
  setShowTrialBanner: () => dispatch.session.setShowTrialBanner(),
  logoutAsync: () => dispatch.session.logoutAsync(),
});

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = StateProps & DispatchProps & IProps;

export default connect(mapState, mapDispatch)(TrialValidator);

export const getSubscriptionDateCreated = (subscriptionOverview?: ISubscription[] | null) => {
  const subscription = subscriptionOverview?.find((subs) => !!subs.planPeriod);
  return subscription ? subscription.dateCreated.toString() : "";
};

interface IHideTrialBannerObject {
  [key: string]: IHideTrialBannerSetting | undefined;
}

interface IHideTrialBannerSetting {
  hide?: boolean;
  hideLastDay?: boolean;
}

const getHideTrialBannerObject = () => {
  const hideTrialBannerObject = getStorageData<IHideTrialBannerObject>(LOCAL_STORAGE_HIDE_TRIAL_BANNER_PARAM);
  return hideTrialBannerObject;
};

const getHideTrialBannerSetting = (account: string): IHideTrialBannerSetting | undefined => {
  const hideTrialBannerObject = getHideTrialBannerObject();
  return hideTrialBannerObject?.[account];
};

const shouldHideTrialBanner = (account: string, lastDay: boolean) => {
  const hideTrialBannerSetting = getHideTrialBannerSetting(account);
  if (hideTrialBannerSetting) {
    const hide = hideTrialBannerSetting[lastDay ? "hideLastDay" : "hide"];
    return !!hide;
  } else {
    return false;
  }
};

const setHideTrialBanner = (account: string, lastDay: boolean) => {
  const hideTrialBannerObject = getHideTrialBannerObject() || {};
  let hideTrialBannerSetting = hideTrialBannerObject[account];

  if (hideTrialBannerSetting) {
    hideTrialBannerSetting = { ...hideTrialBannerSetting, [lastDay ? "hideLastDay" : "hide"]: true };
  } else {
    hideTrialBannerSetting = {
      [lastDay ? "hideLastDay" : "hide"]: true,
    };
    hideTrialBannerObject[account] = hideTrialBannerSetting;
  }

  setStorageData(LOCAL_STORAGE_HIDE_TRIAL_BANNER_PARAM, hideTrialBannerObject);
};
