import { IFRAME_BACK_TO, iframeParams } from "../../../models/iframe";
import { addQueryParams } from "../../../utilities/query";

/** Url manager for account. */
export default class UrlManager {
  public static generateDynamicRoute(
    backTo?: IFRAME_BACK_TO | null,
    backUrl?: string | null,
    iframePath?: string | null
  ): string {
    return addQueryParams(this.prefix, {
      [iframeParams.backTo]: backTo,
      [iframeParams.backUrl]: backUrl,
      [iframeParams.path]: iframePath,
    });
  }

  public static readonly prefix = "/account";
  public static readonly route = this.prefix;

  public static readonly activeTabParam = "activeTab";
  public static readonly subTabParam = "subTab";
}
