import _ from "lodash";

export const buildPathAndQuery = (location: { pathname: string; search: string; hash: string }) => {
  return `${location.pathname}${location.search}${location.hash}`;
};

export const getPathAndQuery = () => {
  return buildPathAndQuery(window.location);
};

export const addQueryParams = (pathAndQuery: string, params?: Record<string, string | null | undefined>) => {
  const urlBuilder = new URL(window.location.origin + pathAndQuery);

  _.each(params, (value, key) => {
    if (value) {
      urlBuilder.searchParams.set(key, value);
    }
  });

  return buildPathAndQuery(urlBuilder);
};

export const createUrlBuilder = (pathAndQuery: string) => {
  return new URL(window.location.origin + pathAndQuery);
};

export const removeQueryParams = (pathAndQuery: string, ...params: string[]) => {
  const urlBuilder = createUrlBuilder(pathAndQuery);

  _.each(params, (key) => {
    urlBuilder.searchParams.delete(key);
  });

  return buildPathAndQuery(urlBuilder);
};

export const tryRemoveQueryParam = (url: URL, param: string) => {
  const value = url.searchParams.get(param);
  url.searchParams.delete(param);

  return value;
};
