import IIConProps from "../IIconProps";

const IconDeliveredStatus = (props: IIConProps) => {
  const color = props.color || "#606060";
  const width = props.width || 32;
  const height = props.width || 32;
  return (
    <svg
      width={width}
      height={height}
      className={props.className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke={color} strokeWidth="1.5">
          <path d="M17 7h2a2 2 0 0 1 2 2v14h-9m-5.37-.543A1.994 1.994 0 0 1 6 21v-3" strokeLinecap="round" />
          <path d="M21 11h2l4.707 4.707a1 1 0 0 1 .293.707V17h-7v-6z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M22 23h-1v-6h8v6h-2" />
          <circle cx="24.5" cy="24.5" r="2.5" />
          <circle cx="9.5" cy="24.5" r="2.5" />
          <path strokeLinecap="round" d="M27 23h3M12 23h9M4 23h3" />
        </g>
        <path
          d="M15 9c0 3.867-3.133 7-7 7s-7-3.133-7-7 3.133-7 7-7 7 3.133 7 7z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path d="m4.5 9 2.625.875L8 12.5l3.5-7-7 3.5z" fill={color} />
      </g>
    </svg>
  );
};

export default IconDeliveredStatus;
