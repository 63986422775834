import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { useMount } from "react-use";

export function useSearchParamsRemoval(...params: string[]): ReturnType<typeof useSearchParams> {
  const [searchParams, setSearchParams] = useSearchParams();

  useMount(() => {
    if (_.some(params, (param) => searchParams.has(param))) {
      setSearchParams(
        (current) => {
          const result = new URLSearchParams(current);
          _.each(params, (param) => result.delete(param));
          return result;
        },
        { replace: true }
      );
    }
  });

  return [searchParams, setSearchParams];
}
