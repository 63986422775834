import { iframeParams } from "../../../models/iframe";
import { addQueryParams } from "../../../utilities/query";

/** Url manager for client view. */
export default class UrlManager {
  public static generateDynamicRoute(iframePath?: string | null): string {
    return addQueryParams(this.prefix, {
      [iframeParams.path]: iframePath,
    });
  }

  public static readonly prefix = "/clientview";
  public static readonly route = `${this.prefix}/*`;
}
