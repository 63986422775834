import {
  IDownloadSettings,
  IPrivacySettings,
  IShareSettings,
} from "@zenfolio/core-components/dist/utilities/galleryConflicts";
import { GenericState } from "../generic";

export const initialState: IGallerySettingsState = {
  getPrivacySettings: { status: "Init" },
  getShareSettings: { status: "Init" },
  getDownloadSettings: { status: "Init" },
};

export interface IGallerySettingsState {
  getPrivacySettings: GetPrivacySettingsState;
  getShareSettings: GetShareSettingsState;
  getDownloadSettings: GetDownloadSettingsState;
  privacy?: IPrivacySettings;
  share?: IShareSettings;
  download?: IDownloadSettings;
}

export type GetPrivacySettingsState = GenericState<IGetPrivacySettingsInput>;
export type GetShareSettingsState = GenericState<IGetShareSettingsInput>;
export type GetDownloadSettingsState = GenericState<IGetDownloadSettingsInput>;

export interface IGetPrivacySettingsInput {
  galleryId: string;
}

export interface IGetPrivacySettingsResult {
  privacySettings: IPrivacySettings;
}

export interface IGetShareSettingsInput {
  galleryId: string;
}

export interface IGetShareSettingsResult {
  shareSettings: IShareSettings;
}

export interface IGetDownloadSettingsInput {
  galleryId: string;
}

export interface IGetDownloadSettingsResult {
  downloadSettings: IDownloadSettings;
}
