import { createModel } from "@rematch/core";
import _ from "lodash";
import api from "../../api";
import { TrackFoldersProperties } from "../../api/folders/models";
import { mapGalleryUploadNotification } from "../../api/gallery/mappers";
import { IGalleryUploadNotificationDto } from "../../api/gallery/models";
import { getPhotoDownloadResolution, getVideoDownloadResolution } from "../../models/downloadMedia";
import { defaultFocalPoint } from "../../models/gallery";
import { GtmEventCategory, UserEventDetail } from "../../models/userEvent";
import { downloadMedia, finalizeMediaDownloadUrl } from "../../utilities/downloadMedia";
import { trackEditGalleryInfo, updateGalleryMedias } from "../../utilities/gallery";
import { pushGtmEventAsync } from "../../utilities/gtm";
import { getErrorFromApi, updateState } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import { buildCover, buildEmptyMedia, expireProcessingMedia, updateCover, updateGalleryStateCover } from "./helpers";
import {
  IChangeGalleryExpirationInput,
  IChangeGallerySharingInput,
  IDeleteGalleryByIdInput,
  IDeleteGalleryMediasInput,
  IDeleteGalleryMediasResult,
  IDownloadGalleryMediasAutoInput,
  IDownloadGalleryMediasInput,
  IExpireProcessingMediasInput,
  IGalleryState,
  IGetDeleteGalleryInfoInput,
  IGetDeleteGalleryInfoResult,
  IGetGalleryByIdInput,
  IGetGalleryByIdResult,
  IGetGalleryDetailsByIdInput,
  IGetGalleryDetailsByIdResult,
  IGetGalleryMediaDownloadUrlInput,
  IGetGalleryMediaDownloadUrlResult,
  IMediaUploadSuccessInput,
  IRenameGalleryInput,
  IRenameGalleryResult,
  ISetGalleryCoverMediaInput,
  IUpdateGalleryDescriptionInput,
  IUpdateGalleryShootDateInput,
  IUpdateGalleryShootTypeInput,
  IUpdateGalleryTagsInput,
  initialState,
} from "./models";

export const gallery = createModel<RootModel>()({
  state: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    ...getGenericReducers("getGalleryById")<IGalleryState, IGetGalleryByIdInput, IGetGalleryByIdResult>({
      started: () => ({ gallery: undefined }),
      success: (_state, payload) => ({ gallery: payload.gallery }),
    }),
    ...getGenericReducers("deleteGalleryById")<IGalleryState, IDeleteGalleryByIdInput>(),
    ...getGenericReducers("renameGallery")<IGalleryState, IRenameGalleryInput, IRenameGalleryResult>({
      success: (state, payload) => ({
        gallery: updateState(state.gallery, (gallery) => ({
          name: payload.newName,
          details: updateState(gallery.details, (details) => ({
            ...details,
            dateModified: new Date(),
          })),
        })),
      }),
    }),
    ...getGenericReducers("updateGalleryDescription")<IGalleryState, IUpdateGalleryDescriptionInput>({
      success: (state, payload) => ({
        gallery: updateState(state.gallery, (gallery) => ({
          details: updateState(gallery.details, (details) => ({
            ...details,
            description: payload.description,
            dateModified: new Date(),
          })),
        })),
      }),
    }),
    ...getGenericReducers("updateGalleryShootType")<IGalleryState, IUpdateGalleryShootTypeInput>({
      success: (state, payload) => ({
        gallery: updateState(state.gallery, (gallery) => ({
          details: updateState(gallery.details, (details) => ({
            ...details,
            shootTypeId: payload.shootTypeId,
            dateModified: new Date(),
          })),
        })),
      }),
    }),
    ...getGenericReducers("updateGalleryShootDate")<IGalleryState, IUpdateGalleryShootDateInput>({
      success: (state, payload) => ({
        gallery: updateState(state.gallery, (gallery) => ({
          shootDate: payload.shootDate,
          details: updateState(gallery.details, (details) => ({
            ...details,
            dateModified: new Date(),
          })),
        })),
      }),
    }),
    ...getGenericReducers("updateGalleryTags")<IGalleryState, IUpdateGalleryTagsInput>({
      success: (state, payload) => ({
        gallery: updateState(state.gallery, (gallery) => ({
          details: updateState(gallery.details, (details) => ({
            ...details,
            tags: payload.tags,
            dateModified: new Date(),
          })),
        })),
      }),
    }),
    ...getGenericReducers("getGalleryDetailsById")<
      IGalleryState,
      IGetGalleryDetailsByIdInput,
      IGetGalleryDetailsByIdResult
    >({
      started: (state) => ({
        gallery: updateState(state.gallery, () => ({
          details: undefined,
        })),
      }),
      success: (state, payload) => ({
        gallery: updateState(state.gallery, (gallery) => ({
          details: gallery.id === payload.galleryId ? payload.details : gallery.details,
        })),
      }),
    }),
    ...getGenericReducers("setGalleryCoverMedia")<IGalleryState, ISetGalleryCoverMediaInput>({
      success: (state, payload) => updateGalleryStateCover(state, payload),
    }),
    ...getGenericReducers("deleteGalleryMedias")<IGalleryState, IDeleteGalleryMediasInput, IDeleteGalleryMediasResult>({
      success: (state, payload) => ({
        gallery: updateState(state.gallery, (gallery) => ({
          medias: _.differenceBy(gallery.medias, payload.medias, (media) => media.id),
          cover: updateCover(gallery.cover, buildCover(gallery, payload.coverId, defaultFocalPoint)),
          collections: gallery.collections.map((collection) => ({
            ...collection,
            medias: _.differenceBy(collection.medias, payload.medias, (media) => media.id),
          })),
          details: updateState(gallery.details, (details) => ({
            ...details,
            dateModified: new Date(),
          })),
        })),
      }),
    }),
    ...getGenericReducers("getGalleryMediaDownloadUrl")<
      IGalleryState,
      IGetGalleryMediaDownloadUrlInput,
      IGetGalleryMediaDownloadUrlResult
    >(),
    ...getGenericReducers("downloadGalleryMedias")<IGalleryState, IDeleteGalleryMediasInput>(),
    ...getGenericReducers("getDeleteGalleryInfo")<
      IGalleryState,
      IGetDeleteGalleryInfoInput,
      IGetDeleteGalleryInfoResult
    >(),
    processUploadNotification(state, payload: IGalleryUploadNotificationDto) {
      const { gallery } = state;

      if (gallery?.id !== payload.albumId) {
        return state;
      }

      const notification = mapGalleryUploadNotification(payload, gallery);
      const newMedias = updateGalleryMedias(gallery.medias, notification);

      return newMedias
        ? {
            ...state,
            gallery: {
              ...gallery,
              cover: updateCover(gallery.cover, notification.cover),
              medias: newMedias,
              collections: gallery.collections.map(
                (collection) => notification.collections.find((c) => c.id === collection.id) || collection
              ),
              details: updateState(gallery.details, (details) => ({
                ...details,
                dateModified: new Date(),
              })),
            },
          }
        : state;
    },
    mediaUploadSuccess(state, payload: IMediaUploadSuccessInput) {
      const { gallery } = state;

      if (gallery?.id !== payload.galleryId || _.some(gallery.medias, (media) => media.id === payload.media.id)) {
        return state;
      }

      return {
        ...state,
        gallery: {
          ...gallery,
          medias: [...gallery.medias, buildEmptyMedia(gallery, payload.media, payload.file)],
          collections: gallery.collections.map((collection) =>
            collection.id === payload.collectionId
              ? {
                  ...collection,
                  medias: [...collection.medias, payload.media],
                }
              : collection
          ),
          details: updateState(gallery.details, (details) => ({
            ...details,
            dateModified: new Date(),
          })),
        },
      };
    },
    expireProcessingMedias(state, payload: IExpireProcessingMediasInput) {
      return {
        ...state,
        gallery: updateState(state.gallery, (gallery) => ({
          medias: gallery.medias.map((m) => expireProcessingMedia(m, payload.mediaIds)),
          cover: gallery.cover ? expireProcessingMedia(gallery.cover, payload.mediaIds) : gallery.cover,
        })),
      };
    },
    changeGalleryCover(state, payload: ISetGalleryCoverMediaInput) {
      return {
        ...state,
        ...updateGalleryStateCover(state, payload),
      };
    },
    changeGallerySharing(state, payload: IChangeGallerySharingInput) {
      return {
        ...state,
        gallery: updateState(state.gallery, (gallery) => ({
          shareEnabled: payload.shareEnabled,
          details: updateState(gallery.details, (details) => ({
            ...details,
            dateModified: new Date(),
          })),
        })),
      };
    },
    changeGalleryExpiration(state, payload: IChangeGalleryExpirationInput) {
      return {
        ...state,
        gallery: updateState(state.gallery, () => ({
          active: payload.galleryActive,
          expirationDate: payload.expirationDate,
        })),
      };
    },
    resetGalleryDetails(state) {
      return {
        ...state,
        gallery: updateState(state.gallery, () => ({ details: undefined })),
        getGalleryDetailsById: { status: "Init" },
      };
    },
    setFromBookingId(state, payload: { bookingId: string }) {
      return {
        ...state,
        fromBookingId: payload.bookingId,
      };
    },
  },
  effects: (dispatch) => ({
    async getGalleryByIdAsync(payload: IGetGalleryByIdInput) {
      dispatch.gallery.getGalleryByIdStarted(payload);
      try {
        const gallery = await api.gallery.getById(payload.galleryId);
        dispatch.gallery.getGalleryByIdSuccess({ ...payload, gallery });
      } catch (error) {
        dispatch.gallery.getGalleryByIdError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async deleteGalleryByIdAsync(payload: IDeleteGalleryByIdInput) {
      dispatch.gallery.deleteGalleryByIdStarted(payload);
      try {
        const result = await api.gallery.deleteById(payload.galleryId);
        await Promise.all([
          dispatch.backgroundJobs.cancelJobsByGalleryIdsAsync({ galleryIds: [payload.galleryId] }),
          api.folders.trackFolders({
            eventName: "delete gallery",
            parentId: result.parentId,
            childId: payload.galleryId,
            trackProperties: TrackFoldersProperties.GalleryFull,
            extraProperties: {
              category: "gallery",
              child_category: "gallery actions",
            },
          }),
        ]);
        dispatch.gallery.deleteGalleryByIdSuccess(payload);
      } catch (error) {
        dispatch.gallery.deleteGalleryByIdError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async renameGalleryAsync(payload: IRenameGalleryInput) {
      dispatch.gallery.renameGalleryStarted(payload);
      try {
        const newName = await api.gallery.rename(payload.galleryId, payload.name);
        await trackEditGalleryInfo(payload.galleryId, "title");
        dispatch.gallery.renameGallerySuccess({ ...payload, newName });
      } catch (error) {
        dispatch.gallery.renameGalleryError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async updateGalleryDescriptionAsync(payload: IUpdateGalleryDescriptionInput) {
      dispatch.gallery.updateGalleryDescriptionStarted(payload);
      try {
        await api.gallery.updateDescription(payload.galleryId, payload.description);
        await trackEditGalleryInfo(payload.galleryId, "description");
        dispatch.gallery.updateGalleryDescriptionSuccess(payload);
      } catch (error) {
        dispatch.gallery.updateGalleryDescriptionError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async updateGalleryShootTypeAsync(payload: IUpdateGalleryShootTypeInput) {
      dispatch.gallery.updateGalleryShootTypeStarted(payload);
      try {
        await api.gallery.updateShootType(payload.galleryId, payload.shootTypeId);
        await trackEditGalleryInfo(payload.galleryId, "shoot type");
        dispatch.gallery.updateGalleryShootTypeSuccess(payload);
      } catch (error) {
        dispatch.gallery.updateGalleryShootTypeError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async updateGalleryShootDateAsync(payload: IUpdateGalleryShootDateInput) {
      dispatch.gallery.updateGalleryShootDateStarted(payload);
      try {
        await api.gallery.updateShootDate(payload.galleryId, payload.shootDate);
        await trackEditGalleryInfo(payload.galleryId, "shoot date");
        dispatch.gallery.updateGalleryShootDateSuccess(payload);
      } catch (error) {
        dispatch.gallery.updateGalleryShootDateError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async updateGalleryTagsAsync(payload: IUpdateGalleryTagsInput) {
      dispatch.gallery.updateGalleryTagsStarted(payload);
      try {
        await api.gallery.updateTags(payload.galleryId, payload.tags);
        await trackEditGalleryInfo(payload.galleryId, "tags");
        dispatch.gallery.updateGalleryTagsSuccess(payload);
      } catch (error) {
        dispatch.gallery.updateGalleryTagsError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async getGalleryDetailsByIdAsync(payload: IGetGalleryDetailsByIdInput) {
      dispatch.gallery.getGalleryDetailsByIdStarted(payload);
      try {
        const details = await api.gallery.getDetailsById(payload.galleryId);
        dispatch.gallery.getGalleryDetailsByIdSuccess({ ...payload, details });
      } catch (error) {
        dispatch.gallery.getGalleryDetailsByIdError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async setGalleryCoverMediaAsync(payload: ISetGalleryCoverMediaInput) {
      dispatch.gallery.setGalleryCoverMediaStarted(payload);
      try {
        await api.gallery.setCoverMedia(payload.galleryId, payload.media);
        const event = {
          cover_media_medium: payload.media.type === "video" ? "video" : "image",
        };
        pushGtmEventAsync({
          category: GtmEventCategory.MobileGallery,
          name: "set cover media",
          detail: event,
          ...event,
        });
        dispatch.gallery.setGalleryCoverMediaSuccess(payload);
      } catch (error) {
        dispatch.gallery.setGalleryCoverMediaError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async deleteGalleryMediasAsync(payload: IDeleteGalleryMediasInput) {
      dispatch.gallery.deleteGalleryMediasStarted(payload);
      try {
        const coverId = await api.gallery.deleteMedias(payload.galleryId, payload.medias);
        await api.folders.trackFolders({
          eventName: "delete media",
          childId: payload.galleryId,
          trackProperties: TrackFoldersProperties.GalleryFull,
          extraProperties: {
            category: "gallery",
            child_category: "gallery content",
            photos_deleted: _.sumBy(payload.medias, (m) => (m.type === "photo" ? 1 : 0)),
            videos_deleted: _.sumBy(payload.medias, (m) => (m.type === "video" ? 1 : 0)),
          },
        });
        dispatch.gallery.deleteGalleryMediasSuccess({ ...payload, coverId });
      } catch (error) {
        dispatch.gallery.deleteGalleryMediasError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async getGalleryMediaDownloadUrlAsync(payload: IGetGalleryMediaDownloadUrlInput) {
      dispatch.gallery.getGalleryMediaDownloadUrlStarted(payload);
      try {
        let downloadUrl = await api.gallery.getMediaDownloadUrl(payload.galleryId, payload.media, payload.resolution);
        downloadUrl = finalizeMediaDownloadUrl(downloadUrl, payload.media.type === "video");
        dispatch.gallery.getGalleryMediaDownloadUrlSuccess({ ...payload, downloadUrl });
        return downloadUrl;
      } catch (error) {
        dispatch.gallery.getGalleryMediaDownloadUrlError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async downloadGalleryMediasAsync(payload: IDownloadGalleryMediasInput) {
      dispatch.gallery.downloadGalleryMediasStarted(payload);
      try {
        await api.gallery.downloadMedias(payload.galleryId, payload.medias, payload.resolutions);
        dispatch.gallery.downloadGalleryMediasSuccess(payload);
      } catch (error) {
        dispatch.gallery.downloadGalleryMediasError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    /** Automatically chooses download strategy depending on the number of medias. */
    async downloadGalleryMediasAutoAsync(payload: IDownloadGalleryMediasAutoInput) {
      const counts = _.countBy(payload.medias, (m) => m.type === "video");
      const event = {
        photo_resolution:
          payload.resolutions.photo != null ? getPhotoDownloadResolution(payload.resolutions.photo).name : null,
        video_resolution:
          payload.resolutions.video != null ? getVideoDownloadResolution(payload.resolutions.video).name : null,
        photos_count: counts.false?.toString() || null,
        videos_count: counts.true?.toString() || null,
      };

      const gtmEvent: UserEventDetail = {
        category: GtmEventCategory.MobileGallery,
        name: "download media",
        detail: event,
        ...event,
      };

      if (payload.medias.length === 1) {
        const url = await dispatch.gallery.getGalleryMediaDownloadUrlAsync({
          galleryId: payload.galleryId,
          media: payload.medias[0],
          resolution: payload.medias[0].type === "photo" ? payload.resolutions.photo! : payload.resolutions.video!,
        });
        if (url) {
          await pushGtmEventAsync(gtmEvent);
          downloadMedia(url);
        }
      } else if (payload.medias.length > 1) {
        await dispatch.gallery.downloadGalleryMediasAsync(payload);
        pushGtmEventAsync(gtmEvent);
      }
    },
    async getDeleteGalleryInfoAsync(payload: IGetDeleteGalleryInfoInput) {
      dispatch.gallery.getDeleteGalleryInfoStarted(payload);
      try {
        const galleryDeleteInfo = await api.gallery.getDeleteInfoById(payload.galleryId);
        dispatch.gallery.getDeleteGalleryInfoSuccess({ ...payload, galleryDeleteInfo });
      } catch (error) {
        dispatch.gallery.getDeleteGalleryInfoError({ ...payload, error: getErrorFromApi(error) });
      }
    },
  }),
});
