import { AxiosPromise } from "axios";
import { toolsApi } from "..";

export interface IApiTools {
  getFeatures: (project: number, userId?: string) => AxiosPromise<unknown>;
  identityFeature: (project: number, data: unknown) => AxiosPromise<unknown>;
}

const tools: IApiTools = {
  getFeatures: (project: number, userId?: string) => toolsApi.get(`features/${project}?userId=${userId}`),
  identityFeature: (project: number, data: unknown) => toolsApi.post(`feature/${project}/identity`, data),
};

export default tools;
