import _ from "lodash";
import { IGeneratedPage, IGeneratedSite } from "../../models/siteEngine";
import { parseDate } from "../../utilities/date";
import { BuildState, IGeneratedPageDto, IGeneratedSiteDto } from "./models";

export function mapSite(site: IGeneratedSiteDto): IGeneratedSite {
  return {
    id: site.id,
    layout: site.layout,
    ready: !!(site.hasTheme && site.hasHeader && site.hasFooter),
    backgroundColor: site.backgroundColor,
    pages: _.map(site.pages, mapPage),
    dateModified: site.dateModified ? parseDate(site.dateModified) : parseDate(site.dateCreated),
  };
}

function mapPage(page: IGeneratedPageDto): IGeneratedPage {
  return {
    id: page.id,
    name: page.name,
    ready: page.state === BuildState.Completed,
    dateModified: page.dateModified ? parseDate(page.dateModified) : parseDate(page.dateCreated),
  };
}
