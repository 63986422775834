import { IRecentGalleries, IRecentGallery } from "../../models/galleries";
import { mapMedia } from "../gallery/mappers";
import { IRecentGalleriesDto, IRecentGalleryDto } from "./models";

export function map_IRecentGalleriesDto_To_IRecentGalleries(recentGalleries: IRecentGalleriesDto): IRecentGalleries {
  return {
    ...recentGalleries,
    galleries: recentGalleries.galleries.map((rg) => map_IRecentGalleryDto_To_IRecentGallery(rg)),
  };
}

export function map_IRecentGalleryDto_To_IRecentGallery(rg: IRecentGalleryDto): IRecentGallery {
  return {
    ...rg,
    topMedia: rg.topMedia.map((tm) => mapMedia(rg, tm)),
  };
}
