import _ from "lodash";
import { siteEngineApi } from "..";
import { IGeneratedSite, IGenerationParams } from "../../models/siteEngine";
import { getFullName } from "../../utilities/helpers";
import * as logging from "../../utilities/logging";
import { mapSite } from "./mappers";
import { IGeneratedSiteDto } from "./models";

/** API for site generation. */
export interface IApiSiteEngine {
  getSites: () => Promise<IGeneratedSite[] | undefined>;
  buildSites: (params: IGenerationParams) => Promise<void>;
  selectSite: (id: string) => Promise<void>;
  cancelSites: () => Promise<void>;
}

const siteEngine: IApiSiteEngine = {
  getSites: async () => {
    let sites: IGeneratedSite[] | undefined;

    try {
      const response = await siteEngineApi.get<IGeneratedSiteDto[]>("/sites");
      sites = _.map(response.data, mapSite);
    } catch (e) {
      logging.error("Getting generated sites failed with error:", e);
    }

    return _.isEmpty(sites) ? undefined : sites;
  },
  buildSites: (params: IGenerationParams) =>
    siteEngineApi.post("/sites", {
      variants: params.sitesCount,
      userContext: {
        fullName: getFullName(params.userInfo),
        businessName: params.userInfo.businessName,
        businessServices: [params.primaryShootType],
        ...params.location,
      },
    }),
  selectSite: (id: string) => siteEngineApi.post(`/site/${id}`),
  cancelSites: () => siteEngineApi.delete(`/sites`),
};

export default siteEngine;
