import classNames from "classnames";
import React, { FC, useEffect, useMemo, useState } from "react";
import { matchRoutes, useNavigate } from "react-router-dom";
import {
  IFRAME_BACK_TO,
  IFRAME_PAGE_TITLE,
  MobileWebMessage,
  MobileWebMessageCustom,
  areaPaths,
  iframeParams,
} from "../../../models/iframe";
import { checkIsPWAMode } from "../../../utilities/helpers";
import { buildIframePath } from "../../../utilities/iframe";
import { addQueryParams, removeQueryParams, tryRemoveQueryParam } from "../../../utilities/query";
import { getImpersonateToken, getToken } from "../../../utilities/token";
import { openInNewTab } from "../../../utilities/url";
import AccountUrlManager from "../../features/Account/UrlManager";
import ClientViewUrlManager from "../../features/ClientView/UrlManager";
import DashboardUrlManager from "../../features/Dashboard/UrlManager";
import GalleryUrlManager from "../../features/Gallery/UrlManager";
import MoreUrlManager from "../../features/More/UrlManager";
import BookingUrlManager from "../../features/Notifications/Bookings/UrlManager";
import { useSearchParamsRemoval } from "../../hooks/useSearchParamsRemoval";
import { useSetPageLoaded } from "../../hooks/useSetPageLoaded";
import { useSetPageLoading } from "../../hooks/useSetPageLoading";
import styles from "./siteEditorIframe.module.scss";
import CheckoutModal from "../Modal/CheckoutModal";
import { NewCheckoutModalProps } from "../Modal/CheckoutModal/CheckoutForm";

export interface ISiteEditorIframeProps {
  className?: string;
  style?: React.CSSProperties;
  title: IFRAME_PAGE_TITLE;
  path: string | string[];
  backTo?: IFRAME_BACK_TO | null;
  backUrl?: string | null;
  children?: React.ReactNode;
  hideScroll?: boolean;
  onPageLoad?: () => void;
  onCustomMessage?: (message: MobileWebMessageCustom) => void;
  iframeId?: string;
  deviceId?: string | undefined;
}

const desktopSite = process.env.REACT_APP_TEST_SITE || "";

const SiteEditorIframe: FC<ISiteEditorIframeProps> = ({
  className,
  style,
  title,
  path,
  backTo,
  backUrl,
  children,
  hideScroll,
  onPageLoad,
  onCustomMessage,
  iframeId,
  deviceId,
}) => {
  const navigate = useNavigate();
  const setPageLoaded = useSetPageLoaded();
  const setPageLoading = useSetPageLoading();
  const [searchParams, setSearchParams] = useSearchParamsRemoval(
    iframeParams.antiForgery,
    iframeParams.errorDescription
  );
  const [iframeHeight, setIframeHeight] = useState<string>("100%");
  const [showCheckoutModalParams, setShowCheckoutModalParams] = useState<Partial<NewCheckoutModalProps> | null>(null);

  const customPageLoad = !!onPageLoad;

  useEffect(() => {
    if (!customPageLoad) {
      setPageLoading();
    }
  }, [customPageLoad, setPageLoading]);

  const paths = useMemo(() => {
    return typeof path === "string" ? [path] : path;
  }, [path]);

  useEffect(() => {
    const receiveMessage = (e: MessageEvent) => {
      if (desktopSite !== e.origin) {
        return;
      }

      const message = e.data as MobileWebMessage;
      if (!message?.type) {
        return;
      }

      switch (message.type) {
        case "redirect": {
          if (message.newTab) {
            openInNewTab(message.url);
          } else {
            window.location.href = message.url;
          }
          break;
        }
        case "page-loaded": {
          (onPageLoad ?? setPageLoaded)();
          break;
        }
        case "page-changed": {
          const pagePath = buildPagePath(message.path, backTo, backUrl);
          if (message.newTab) {
            openInNewTab(pagePath);
          } else {
            navigate(pagePath);
          }
          break;
        }
        case "path-changed": {
          setSearchParams(
            (params) => {
              const result = new URLSearchParams(params);
              for (let i = 0; i < paths.length; i++) {
                const path = paths[i];
                if (message.path.startsWith(path)) {
                  const iframePath = cleanupIframePath(path, message.path);
                  if (iframePath) {
                    result.set(iframeParams.path, iframePath);
                  } else {
                    result.delete(iframeParams.path);
                  }

                  if (i > 0) {
                    result.set(iframeParams.root, path);
                  } else {
                    result.delete(iframeParams.root);
                  }
                }
              }
              return result;
            },
            { replace: true }
          );
          break;
        }
        case "purchase-success": {
          window.location.href = "/";
          break;
        }
        case "open-checkout-modal": {
          setShowCheckoutModalParams(message.props || {});
          break;
        }
        case "change-height-iframe": {
          setIframeHeight(message?.height);
          break;
        }
        default:
          onCustomMessage?.(message);
          break;
      }
    };

    window.addEventListener("message", receiveMessage);

    return () => window.removeEventListener("message", receiveMessage);
  }, [navigate, setSearchParams, paths, backTo, backUrl, onPageLoad, onCustomMessage, setPageLoaded]);

  const iframeSrc = useMemo(() => {
    const url = new URL(window.location.href);
    const antiForgery = tryRemoveQueryParam(url, iframeParams.antiForgery);
    const errorDescription = tryRemoveQueryParam(url, iframeParams.errorDescription);

    return (
      process.env.REACT_APP_TEST_SITE +
      addQueryParams((searchParams.get(iframeParams.root) || paths[0]) + (searchParams.get(iframeParams.path) || ""), {
        [iframeParams.token]: getToken(),
        [iframeParams.impersonateToken]: getImpersonateToken(),
        [iframeParams.fromMobileAppUrl]: url.href,
        [iframeParams.antiForgery]: antiForgery,
        [iframeParams.errorDescription]: errorDescription,
        [iframeParams.deviceId]: deviceId || undefined,
        [iframeParams.isPWAMode]: `${checkIsPWAMode()}`,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paths]);

  const iframeAttributes = hideScroll ? { scrolling: "no" } : undefined;

  return (
    <div className={classNames(styles.container, className, !!showCheckoutModalParams && styles.hidden)} style={style}>
      <iframe id={iframeId} title={title} width="100%" height={iframeHeight} src={iframeSrc} {...iframeAttributes} />
      {children}
      <CheckoutModal
        open={!!showCheckoutModalParams}
        onClose={() => setShowCheckoutModalParams(null)}
        params={showCheckoutModalParams}
      />
    </div>
  );
};

function cleanupIframePath(pagePath: string, iframePath: string) {
  return buildIframePath(
    removeQueryParams(
      iframePath.substring(pagePath.length),
      iframeParams.token,
      iframeParams.impersonateToken,
      iframeParams.fromMobileAppUrl
    )
  );
}

function getBasePagePath(path: string, route: string) {
  const match = matchRoutes([{ path: route }], path);
  return match?.[0]?.pathnameBase;
}

function getDefaultPathAndBackTitle(): [IFRAME_BACK_TO, string] | [] {
  switch (window?.location.pathname) {
    case DashboardUrlManager.route:
      return [IFRAME_BACK_TO.DASHBOARD, DashboardUrlManager.route];
    default:
      return [];
  }
}

function buildPagePath(path: string, backTo?: IFRAME_BACK_TO | null, backUrl?: string | null): string {
  const [defaultBackto, defaultBackPath] = getDefaultPathAndBackTitle();
  const accountPath = getBasePagePath(path, AccountUrlManager.route);
  if (accountPath) {
    return AccountUrlManager.generateDynamicRoute(backTo, backUrl, cleanupIframePath(accountPath, path));
  }

  const clientViewPath = getBasePagePath(path, ClientViewUrlManager.route);
  if (clientViewPath) {
    return ClientViewUrlManager.generateDynamicRoute(cleanupIframePath(clientViewPath, path));
  }

  const galleryPath = getBasePagePath(path, GalleryUrlManager.galleryPath);
  if (galleryPath) {
    return addQueryParams(galleryPath, { [iframeParams.path]: cleanupIframePath(galleryPath, path) });
  }

  const dashboardPath = getBasePagePath(path, DashboardUrlManager.route);
  if (dashboardPath) {
    return DashboardUrlManager.prefix;
  }

  const bookingDesktopPath = getBasePagePath(path, BookingUrlManager.desktopRoute);
  if (bookingDesktopPath) {
    return bookingDesktopPath.split(BookingUrlManager.desktopPrefix)[1];
  }

  for (const areaPath of areaPaths) {
    for (let i = 0; i < areaPath.iframePaths.length; i++) {
      const iframePath = areaPath.iframePaths[i];
      if (path.startsWith(iframePath)) {
        return MoreUrlManager.generateDynamicRoute(
          areaPath.mobilePath,
          backTo || defaultBackto,
          backUrl || defaultBackPath,
          cleanupIframePath(iframePath, path),
          i > 0 ? iframePath : null
        );
      }
    }
  }

  return "/";
}

export default SiteEditorIframe;
