import "./styles/root.scss";

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import MainApp from "./MainApp";
import PublicApiApp from "./PublicApiApp";
import ErrorBoundary from "./components/features/ErrorBoundary";
import { fullStoryInit } from "./plugins/fullStory";
import { gtmInit } from "./plugins/gtm";
import { pendoInit } from "./plugins/pendo";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import * as serviceWorkerRegistration from "./sw/serviceWorkerRegistration";
import Flagsmith from "./utilities/flagsmithClass";
import NewRelic from "./utilities/newRelic";
import { isPublicApiMode } from "./utilities/publicApi";
import { tryRedirectToDesktopSite } from "./utilities/url";

const publicApiMode = isPublicApiMode();

if (!publicApiMode) {
  tryRedirectToDesktopSite();
}

Flagsmith.getFromLocalStore();
NewRelic.init();
fullStoryInit();
gtmInit();
pendoInit();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>{publicApiMode ? <PublicApiApp /> : <MainApp />}</BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (!publicApiMode) {
  serviceWorkerRegistration.registerSWWorkBox();
}
