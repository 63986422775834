import { foldersApi } from "..";
import { IPresetBase } from "../../models/presets";
import { executeCancellableRequest } from "../../utilities/helpers";
import { mapPresetsBase } from "./mappers";
import { IPresetsDto } from "./models";

/** API for gallery presets experience. */
export interface IApiPresets {
  load: (signal?: AbortSignal) => Promise<IPresetBase[] | undefined>;
}

const presets: IApiPresets = {
  load: (signal?: AbortSignal) =>
    executeCancellableRequest(
      async (signal) => mapPresetsBase((await foldersApi.get<IPresetsDto>("/presets", { signal })).data),
      "Getting presets",
      signal
    ),
};

export default presets;
