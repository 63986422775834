import { union } from "lodash";
import { IPromotionCampaign, ISubscription, ISubscriptionPlan } from "../../../models/subscription";
import { getTierNumber } from "../../../utilities/subscription";
import { getApplicableCampaignsForPlan, getBestCampaign } from "./helpers";

export const selectPromotion4Display = (
  activePlans: ISubscriptionPlan[],
  planTier: string,
  subscriptionOverview: ISubscription[] | null,
  isTrialUser: boolean,
  promotionCampaigns: IPromotionCampaign[]
) => {
  const applicablePlans = isTrialUser
    ? activePlans
    : activePlans.filter((p) => getTierNumber(p.tier) > getTierNumber(planTier));

  const applicablePromotions =
    promotionCampaigns.length === 0
      ? []
      : union(
          ...applicablePlans.map((plan) =>
            getApplicableCampaignsForPlan(isTrialUser, subscriptionOverview, promotionCampaigns, plan)
          )
        );
  return getBestCampaign(applicablePromotions);
};
