import IIConProps from "../IIconProps";

const IconAddOns = (props: IIConProps) => {
  const color = props.color || "#1E1F22";
  const width = props.width || 24;
  const height = props.width || 24;
  return (
    <svg
      width={width}
      height={height}
      className={props.className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 16.8A1.2 1.2 0 0 1 7.2 18v4.8A1.2 1.2 0 0 1 6 24H1.2A1.2 1.2 0 0 1 0 22.8V18a1.2 1.2 0 0 1 1.2-1.2H6zm8.4 0a1.2 1.2 0 0 1 1.2 1.2v4.8a1.2 1.2 0 0 1-1.2 1.2H9.6a1.2 1.2 0 0 1-1.2-1.2V18a1.2 1.2 0 0 1 1.2-1.2h4.8zm8.4 0A1.2 1.2 0 0 1 24 18v4.8a1.2 1.2 0 0 1-1.2 1.2H18a1.2 1.2 0 0 1-1.2-1.2V18a1.2 1.2 0 0 1 1.2-1.2h4.8zM5.7 18.299H1.5V22.5h4.2v-4.201zm8.4 0H9.9V22.5h4.2v-4.201zm8.4 0h-4.2V22.5h4.2v-4.201zM6 8.4a1.2 1.2 0 0 1 1.2 1.2v4.8A1.2 1.2 0 0 1 6 15.6H1.2A1.2 1.2 0 0 1 0 14.4V9.6a1.2 1.2 0 0 1 1.2-1.2H6zm8.4 0a1.2 1.2 0 0 1 1.2 1.2v4.8a1.2 1.2 0 0 1-1.2 1.2H9.6a1.2 1.2 0 0 1-1.2-1.2V9.6a1.2 1.2 0 0 1 1.2-1.2h4.8zm8.4 0A1.2 1.2 0 0 1 24 9.6v4.8a1.2 1.2 0 0 1-1.2 1.2H18a1.2 1.2 0 0 1-1.2-1.2V9.6A1.2 1.2 0 0 1 18 8.4h4.8zM5.7 9.9H1.5v4.2h4.2V9.9zm8.4 0H9.9v4.2h4.2V9.9zm8.4 0h-4.2v4.2h4.2V9.9zM6 0a1.2 1.2 0 0 1 1.2 1.2V6A1.2 1.2 0 0 1 6 7.2H1.2A1.2 1.2 0 0 1 0 6V1.2A1.2 1.2 0 0 1 1.2 0H6zm8.4 0a1.2 1.2 0 0 1 1.2 1.2V6a1.2 1.2 0 0 1-1.2 1.2H9.6A1.2 1.2 0 0 1 8.4 6V1.2A1.2 1.2 0 0 1 9.6 0h4.8zm6.85 0v2.75H24v1.5h-2.75V7h-1.5V4.25H17v-1.5h2.75V0h1.5zM5.7 1.5H1.5v4.2h4.2V1.5zm8.4 0H9.9v4.2h4.2V1.5z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default IconAddOns;
