import { getPropertyFromToken, getUserIdFromToken } from "@zenfolio/core-components";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import * as logging from "./logging";

const tokenName = "token";
const impersonateTokenName = "impersonate_token";
const antiForgeryToken = "antiforgery_token";

export const getToken = () => localStorage.getItem(tokenName) || "";
export const getImpersonateToken = () => localStorage.getItem(impersonateTokenName) || "";

const setToken = (name: string, token?: string) => {
  if (token) {
    localStorage.setItem(name, token);
  } else {
    localStorage.removeItem(name);
  }
};

export const setTokens = (token?: string, impersonateToken?: string) => {
  setToken(tokenName, token);
  setToken(impersonateTokenName, impersonateToken);
};

const toleranceSeconds = 60;

const validateToken = (trusted: boolean, token: string, requiredScope: string) => {
  const decodedToken: Record<string, string> = jwtDecode(token);

  if (!trusted) {
    if (!_.chain(getPropertyFromToken(decodedToken, "scope")).split(" ").includes(`scope:${requiredScope}`).value()) {
      return undefined;
    }

    const expiration = parseInt(getPropertyFromToken(decodedToken, "exp") ?? "");
    if (isNaN(expiration) || expiration - new Date().getTime() / 1000 <= toleranceSeconds) {
      return undefined;
    }
  }

  return decodedToken;
};

export const validateTokens = (trusted: boolean, property?: string) => {
  try {
    const token = getToken();
    const impersonateToken = getImpersonateToken();
    const effectiveToken = impersonateToken || token;

    if (effectiveToken) {
      const decodedToken = validateToken(trusted, effectiveToken, "nzPhotographer");
      if (decodedToken && (trusted || !impersonateToken || validateToken(false, token, "csEditor"))) {
        return property ? getPropertyFromToken(decodedToken, property) : getUserIdFromToken(decodedToken);
      }
    }
  } catch (e) {
    logging.error("Tokens validation failed with error:", e);
  }

  return "";
};

export const getAntiForgeryToken = () => localStorage.getItem(antiForgeryToken) || "";

export const setAntiForgeryToken = (token: string) => localStorage.setItem(antiForgeryToken, token);

export const removeAntiForgeryToken = () => localStorage.removeItem(antiForgeryToken);

export const getAccountId = () => {
  try {
    const token: string | null = getImpersonateToken() || getToken();
    if (!token) {
      return "";
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedToken: any = jwtDecode(token);
    const accountId = getUserIdFromToken(decodedToken);
    return accountId;
  } catch(e) {
    logging.error(`getAccountId has error: ${e}`);
  }
  return "";
}
