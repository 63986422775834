import { omit } from "lodash";
import { deviceDetect } from "react-device-detect";
import { IUserInfo } from "../../../../models/session";
import { checkIsPWAMode } from "../../../../utilities/helpers";
import FullStory from "../../../../utilities/fullStoryClass";
import * as logging from "../../../../utilities/logging";
import { generateLoginLink } from "../../../../utilities/url";
import { getUserType } from "../../../../utilities/user";
import Segment, { ISegmentProperties } from "../common";
import { TrackingSegmentCategories } from "../constants";
import { InteractPlanOffer, SegmentAccountEventName, ShowTrialBanner } from "./model";

const signupStorageKey = "signup_segment";

class AccountSegment {
  private static isIdentified = true;

  static trackAccountAction(actionName: SegmentAccountEventName, data: ISegmentProperties) {
    let fullstory_session_url;
    if (actionName === SegmentAccountEventName.SUBSCRIBE_TRIAL_BANNER) {
      fullstory_session_url = FullStory.getCurrentSessionURL();
    }
    Segment.trackAction(actionName, {
      category: TrackingSegmentCategories.Account,
      fullstory_session_url,
      ...data,
    });
  }

  static trackTrialBanner(action: ShowTrialBanner) {
    const userAgent = deviceDetect(window.navigator.userAgent)?.userAgent;
    Segment.trackAction(action.actionName, {
      category: TrackingSegmentCategories.Account,
      sub_category: "conversion",
      userAgent,
      ...omit(action, "actionName"),
    });
  }

  static trackInteractPlanOfferForTrial(action: InteractPlanOffer) {
    const userAgent = deviceDetect(window.navigator.userAgent)?.userAgent;
    const isPWAMode = checkIsPWAMode();

    Segment.trackAction(action.actionName, {
      category: TrackingSegmentCategories.Account,
      sub_category: "conversion",
      userAgent,
      isPWAMode,
      ...omit(action, "actionName"),
    });
  }
  static trial(data: ISegmentProperties) {
    const fullstory_session_url = FullStory.getCurrentSessionURL();
    Segment.trackAction(SegmentAccountEventName.TRIAL, {
      category: "account",
      fullstory_session_url,
      ...data,
    });
  }

  static trackSignUpEvent(userInfo: IUserInfo) {
    try {
      const userId = Segment.getSegmentUserId();
      if (!userId || userInfo.id === localStorage.getItem(signupStorageKey)) return;

      const subscriptionOverview = userInfo.subscriptionOverview?.find((s) => s.isBundle === false);
      const payload = {
        type: getUserType(userInfo),
        planName: subscriptionOverview?.productName,
        planCode: userInfo.planCode,
        firstName: userInfo.firstName,
        email: userInfo.email,
        userId,
        domain: userInfo.photographerAlias
          ? `${userInfo.photographerAlias}.${process.env.REACT_APP_SUB_NAKEDDOMAIN}`
          : null,
        signupMethod: userInfo.connectionMethod,
        ...generateLoginLink(userInfo),
      };
      Segment.trackAction(SegmentAccountEventName.SIGNUP, payload);
      localStorage.setItem(signupStorageKey, userInfo.id);
    } catch (e) {
      logging.error("AccountSegment.trackSignUpEvent has error:", e);
    }
  }
  static reset() {
    try {
      if (this.isIdentified) {
        window.analytics.reset();
        this.isIdentified = false;
      }
    } catch (e) {
      logging.error("AccountSegment.reset has error:", e);
    }
  }
  static trackIdentify(userInfo: IUserInfo) {
    Segment.trackGroup(userInfo);
    Segment.trackIdentify(userInfo);
    this.isIdentified = true;
  }

  static trackUpdateIdentify(userInfo: IUserInfo) {
    Segment.trackIdentify(userInfo);
  }

  static clickSubscribeBanner(data: ISegmentProperties) {
    this.trackAccountAction(SegmentAccountEventName.SUBSCRIBE_TRIAL_BANNER, data);
  }
}

export default AccountSegment;
