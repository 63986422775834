import Segment, { ISegmentProperties } from "../common";
import { TrackingSegmentCategories } from "../constants";
import { SegmentOnBoardingEventName } from "./model";

class OnBoardingSegment {
  static trackOnBoardingAction(actionName: SegmentOnBoardingEventName, data?: ISegmentProperties) {
    Segment.trackAction(actionName, {
      category: TrackingSegmentCategories.MobileOnBoarding,
      ...data,
    });
  }

  static personalizeAccount(data: ISegmentProperties) {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.personalizeAccount, data);
  }

  static typesOfPhotography(data: ISegmentProperties) {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.typesOfPhotography, data);
  }

  static primaryShootType(data: ISegmentProperties) {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.primaryShootType, data);
  }

  static whatAreYourGoals(data: ISegmentProperties) {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.whatAreYourGoals, data);
  }

  static selectPhotos(data: ISegmentProperties) {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.selectPhotos, data);
  }

  static uploadPhotos(data: ISegmentProperties) {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.uploadPhotos, data);
  }

  static galleryOrWebsiteFork(data: ISegmentProperties) {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.galleryOrWebsiteFork, data);
  }

  static chooseSitePreview(data: ISegmentProperties) {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.chooseSitePreview, data);
  }

  static chooseSiteSelect(data: ISegmentProperties) {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.chooseSiteSelect, data);
  }

  static redirectToWebsiteBuilder() {
    this.trackOnBoardingAction(SegmentOnBoardingEventName.redirectToWebsiteBuilder);
  }
}

export default OnBoardingSegment;
