import { AxiosPromise, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import _ from "lodash";
import { accountsApi, addGaEventIdHeader, foldersApi } from "..";
import { IInitialGalleriesInfo, IInitialGallery, IOnBoardingProcess, IStepAnswer } from "../../models/onBoarding";
import { retry } from "../../utilities/helpers";
import { map_UserInfoDto_To_IUserInfo } from "../account/mappers";
import { IUserInfoDto } from "../account/models";
import {
  mapInitialGalleriesInfo,
  mapInitialGallery,
  map_Onboarding_Process,
  map_StepAnswer_To_StepAnswerRequest,
} from "./mappers";
import { IGalleryInitialInfoDto, IInitialGalleriesInfoDto, OnBoardingProcessDto } from "./models";

export interface IApiOnBoarding {
  getInitialGalleriesInfo: () => Promise<IInitialGalleriesInfo>;
  addOrUpdateSampleGallery: (shootTypeId: number) => Promise<IInitialGallery>;
  getOnboardingProcess: () => Promise<IOnBoardingProcess>;
  saveAnswer: (answer: IStepAnswer) => AxiosPromise<void>;
  startTrialOnBoarding: () => Promise<void>;
  completeOnBoarding: (id: string) => Promise<ReturnType<typeof map_UserInfoDto_To_IUserInfo> | undefined>;
}

const onBoarding: IApiOnBoarding = {
  getInitialGalleriesInfo: async () => {
    const getError = () => new Error("Account is not ready yet.");

    const info = await retry({
      retries: 100,
      delay: (retry) => (Math.floor(retry / 10) + 1) * 500,
      logMessage: "Loading initial galleries info",
      action: async () => {
        const data = (await foldersApi.get<IInitialGalleriesInfoDto>("/albums/initial")).data;
        if (!data.rootId) {
          throw getError();
        }

        return data;
      },
    });

    if (!info) {
      throw getError();
    }

    return mapInitialGalleriesInfo(info);
  },
  addOrUpdateSampleGallery: async (shootTypeId: number) => {
    return mapInitialGallery(
      (await foldersApi.put<IGalleryInitialInfoDto>("/albums/sample", { shootTypeId })).data,
      true
    );
  },
  getOnboardingProcess: () =>
    accountsApi.get<OnBoardingProcessDto>("/user/wizard/question?isMobile=true").then((res) => {
      return map_Onboarding_Process(res.data);
    }),
  saveAnswer: (answer: IStepAnswer) => {
    const request = map_StepAnswer_To_StepAnswerRequest(answer);
    return accountsApi.post("/user/wizard/answer", request, addGaEventIdHeader(buildGtmTouchRequestConfig()));
  },
  startTrialOnBoarding: () => {
    return accountsApi.post("/user/first-touch?isMobile=true", undefined, buildGtmTouchRequestConfig());
  },
  completeOnBoarding: (id: string) => {
    return accountsApi
      .post<IUserInfoDto | void>("/user/welcome?refreshSettings=true")
      .then((response) => (response.data ? map_UserInfoDto_To_IUserInfo(id, response) : undefined));
  },
};

function buildGtmTouchRequestConfig(): AxiosRequestConfig | undefined {
  const headers: Record<string, string> = {};
  const getFirstTouchUrl = Cookies.get("gtm_first_touch_url");
  const getLastTouchUrl = Cookies.get("gtm_last_touch_url");

  if (getFirstTouchUrl) {
    headers["gtm-first-touch-url"] = getFirstTouchUrl;
  }

  if (getLastTouchUrl) {
    headers["gtm-last-touch-url"] = getLastTouchUrl;
  }

  if (_.isEmpty(headers)) {
    return undefined;
  }

  return { headers };
}

export default onBoarding;
