import { createModel } from "@rematch/core";
import { RootModel } from "../store";

export type ActivityMonitorVisibilityState = "visible" | "hidden";

export const activityMonitorVisibility = createModel<RootModel>()({
  state: "hidden" as ActivityMonitorVisibilityState,
  reducers: {
    "backgroundJobs/addBackgroundJob": () => "visible" as ActivityMonitorVisibilityState,
    hide: () => "hidden" as ActivityMonitorVisibilityState,
  },
});
