import Cookies from "js-cookie";
import identities from "../api/identities";
import { IPresetInfo } from "../models/presets";
import { IInstagramMedia } from "../models/socialMedia";
import { IWatermark } from "../models/watermarks";
import { DuplicatesHandling } from "../store/backgroundJobs/models";
import { getStorageData, removeStorageData, setStorageData } from "./session";

const antiForgeryStorageKey = "instagram_anti_forgery";

export async function onConnectInstagram(path: string) {
  const url = window.location.origin + path;
  const res = await identities.authorizeInstagram(url);
  sessionStorage.setItem(antiForgeryStorageKey, res.antiForgery);
  return res.externalProviderUri;
}

export function tryRemoveAntiForgeryFromStorage() {
  const result = sessionStorage.getItem(antiForgeryStorageKey);
  sessionStorage.removeItem(antiForgeryStorageKey);

  return result;
}

export interface IInstagramUploadInfo {
  isNew: boolean;
  galleryName: string;
  duplicatesHandling: DuplicatesHandling;
  videoWatermark?: IWatermark;
  collectionId?: string;
  preset?: IPresetInfo;
}

function buildInstagramInfoStorageKey(galleryId: string) {
  return `instagram_info_${galleryId}`;
}

export function setInstagramInfo(galleryId: string, info: IInstagramUploadInfo) {
  setStorageData(buildInstagramInfoStorageKey(galleryId), info);
}

export function getInstagramInfo(galleryId: string) {
  return getStorageData<IInstagramUploadInfo>(buildInstagramInfoStorageKey(galleryId));
}

export function removeInstagramInfo(galleryId: string) {
  return removeStorageData(buildInstagramInfoStorageKey(galleryId));
}

export const isInstagramUploadEnabled =
  process.env.REACT_APP_ENABLE_INSTAGRAM_UPLOAD === "true" || Cookies.get("instagram_enable") === "true";

export const isInstagramVideosEnabled =
  process.env.REACT_APP_ENABLE_INSTAGRAM_VIDEOS_UPLOAD === "true" || Cookies.get("instagram_videos_enable") === "true";

const initialGalleryId = "initial_gallery_id";

export function setInitialGalleryId(id: string) {
  localStorage.setItem(initialGalleryId, id);
}

export function getInitialGalleryId() {
  return localStorage.getItem(initialGalleryId) || "";
}

export function removeInitialGalleryId() {
  return localStorage.removeItem(initialGalleryId);
}

function buildInstagramPhotosStorageKey(galleryId: string) {
  return `instagram_${galleryId}`;
}

export function setInstagramPhotoNames(galleryId: string, photoNames: string[]) {
  localStorage.setItem(buildInstagramPhotosStorageKey(galleryId), JSON.stringify(photoNames));
}

export function getInstagramPhotoNames(galleryId: string): string[] {
  const photoNames = localStorage.getItem(buildInstagramPhotosStorageKey(galleryId));
  return photoNames ? JSON.parse(photoNames) : [];
}

export function removeInstagramPhotoNames(galleryId: string) {
  localStorage.removeItem(buildInstagramPhotosStorageKey(galleryId));
}

export function isInstagramVideo(media: IInstagramMedia) {
  return media.mediaType === "VIDEO";
}
