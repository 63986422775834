import * as fullStory from "@fullstory/browser";
import _ from "lodash";
import { IUserInfo } from "../models/session";
import { isFullStoryEnabled } from "../plugins/fullStory";
import { buildDiff, getFullName } from "./helpers";
import * as logging from "./logging";
import { getPlanPeriodNoun, getUserType } from "./user";

interface IUserProperties {
  displayName: string;
  businessName: string;
  email: string;
  planCode: string;
  planPeriod: string;
  userType: string;
  country: string;
  signUpDate: string;
}

export const fullStoryIgnoreDeadClick = "fs-ignore-dead-clicks";

class FullStoryClass {
  private properties: IUserProperties | undefined;

  public identify(userInfo: IUserInfo) {
    try {
      if (isFullStoryEnabled) {
        const properties = this.buildProperties(userInfo);

        fullStory.identify(userInfo.id, properties);
        this.properties = properties;
      }
    } catch (error) {
      logging.error("FullStory.identify has error:", error);
    }
  }

  public update(userInfo: IUserInfo) {
    try {
      if (isFullStoryEnabled && this.properties) {
        const properties = this.buildProperties(userInfo);
        const diff = buildDiff(this.properties, properties);

        if (!_.isEmpty(diff)) {
          fullStory.setUserVars(diff);
          this.properties = properties;
        }
      }
    } catch (error) {
      logging.error("FullStory.update has error:", error);
    }
  }

  public clear() {
    try {
      if (isFullStoryEnabled && this.properties) {
        fullStory.anonymize();
        this.properties = undefined;
      }
    } catch (error) {
      logging.error("FullStory.clear has error:", error);
    }
  }

  private buildProperties(userInfo: IUserInfo): IUserProperties {
    return {
      displayName: getFullName(userInfo),
      businessName: userInfo.businessName,
      email: userInfo.email,
      planCode: userInfo.planCode,
      planPeriod: getPlanPeriodNoun(userInfo),
      country: userInfo.countryCode,
      signUpDate: userInfo.signUpDate,
      userType: getUserType(userInfo),
    };
  }

  public getCurrentSessionURL() {
    try {
      const sessionURL = fullStory.getCurrentSessionURL(true);
      return sessionURL;
    } catch (error) {
      logging.error(`FullStory.getSession has error: ${error}`);
    }
  }
}

const FullStory = new FullStoryClass();

export default FullStory;
