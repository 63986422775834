export function setFullHeightBeforeTransition(
  nodeRef?: React.RefObject<HTMLElement>,
  varName: string = "--full-height"
) {
  function onEnter(node?: HTMLElement) {
    setFullHeight((nodeRef?.current ?? node)!);
  }

  function onExit(node?: HTMLElement) {
    setFullHeight((nodeRef?.current ?? node)!);
  }

  function setFullHeight(node: HTMLElement) {
    node.style.setProperty(varName, `${node.scrollHeight}px`);
  }

  return { onEnter, onExit };
}
