import classNames from "classnames";
import * as React from "react";
import { StrictOmit } from "../../../../types";
import Button, { IButtonProps } from "../../Button";
import Base, { IBaseProps } from "../Base";
import styles from "./index.module.scss";

interface IDialogProps extends IBaseProps {
  header?: React.ReactNode;
  okText?: React.ReactNode;
  cancelText?: React.ReactNode;
  okPending?: boolean;
  okProps?: StrictOmit<IButtonProps, "onClick" | "children" | "pending">;
  cancelProps?: StrictOmit<IButtonProps, "onClick" | "children">;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  onOk?: () => void;
}

const Dialog: React.FC<IDialogProps> = (props) => {
  const {
    header,
    okText,
    cancelText,
    okPending,
    okProps,
    cancelProps,
    onOk,
    onClose,
    children,
    overlayClassName,
    className,
    headerClassName,
    contentClassName,
    footerClassName,
    ...otherProps
  } = props;

  return (
    <Base
      overlayClassName={classNames(styles.overlay, overlayClassName)}
      className={classNames(styles.modal, className)}
      onClose={onClose}
      {...otherProps}
    >
      {header && <div className={classNames(styles.header, headerClassName)}>{header}</div>}
      <div className={classNames(styles.content, contentClassName)}>{children}</div>
      {(okText || cancelText) && (
        <div className={classNames(styles.footer, footerClassName)}>
          {okText && (
            <Button
              type="primary"
              {...okProps}
              className={classNames(styles.button, okProps?.className)}
              onClick={onOk}
              pending={okPending}
            >
              {okText}
            </Button>
          )}
          {cancelText && (
            <Button
              type="secondary"
              {...cancelProps}
              className={classNames(styles.button, cancelProps?.className)}
              onClick={onClose}
            >
              {cancelText}
            </Button>
          )}
        </div>
      )}
    </Base>
  );
};

export default Dialog;
