import { AnimationEventHandler } from "react";
import IIConProps from "../IIconProps";

interface IProps extends IIConProps {
  onAnimationIteration?: AnimationEventHandler<SVGSVGElement>;
}

const IconWebsite = (props: IProps) => {
  const color = props.color || "#1E1F22";
  const width = props.width || 30;
  const height = props.height || 24;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 24"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      onAnimationIteration={props.onAnimationIteration}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.97.25c1.615 0 2.948 1.212 3.041 2.758l.006.173v8.816a.75.75 0 0 1-1.493.102l-.007-.102V6.826l-.015.001H2.016v13.986c0 .736.607 1.355 1.397 1.425l.15.006h10.81a.75.75 0 0 1 .1 1.493l-.1.007H3.562c-1.614 0-2.948-1.212-3.04-2.758l-.006-.173V3.181c0-1.57 1.276-2.838 2.868-2.926L3.563.25H19.97zm0 1.5H3.563c-.811 0-1.465.575-1.54 1.295l-.006.136-.001 2.146h19.501V3.182c0-.736-.606-1.355-1.397-1.425l-.15-.006zm-15.406 1c.409 0 .74.335.74.75 0 .413-.331.749-.74.749a.745.745 0 0 1-.74-.75c0-.414.332-.75.74-.75zm2.837 0c.409 0 .74.335.74.75 0 .413-.331.749-.74.749a.745.745 0 0 1-.74-.75c0-.414.332-.75.74-.75zm2.837 0c.409 0 .74.335.74.75 0 .413-.331.749-.74.749a.745.745 0 0 1-.74-.75c0-.414.332-.75.74-.75z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="m21.507 21.997-3.504 1 1.001-3.5 7.51-7.5 2.502 2.5-7.51 7.5h0zm3.005-8 2.502 2.499m-8.01 3.001 2.503 2.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.517 9.816a8.745 8.745 0 0 1 7.475 4.2l-1.1 1.097-.115-.206a7.256 7.256 0 0 0-.279-.438c-.358.222-.717.425-1.077.608.158.363.289.735.394 1.116l-1.268 1.266-.036-.235a6.987 6.987 0 0 0-.466-1.55 10.38 10.38 0 0 1-2.778.613v1.53h2.74l-.5 1.5h-2.24v3.682h-1.5v-3.683l-3.416.002c.111 1.219.493 2.446 1.15 3.682H7.83c-.56-1.22-.888-2.449-.983-3.683h-1.54A7.214 7.214 0 0 0 6.78 23H5.007l-.001.057a8.75 8.75 0 0 1 7.511-13.241zM6.535 14.47l-.122.183a7.206 7.206 0 0 0-1.108 3.164h1.541c.078-.978.31-1.905.694-2.778-.335-.171-.67-.361-1.005-.57zm2.376 1.178a7.047 7.047 0 0 0-.559 2.168h3.415v-1.528a10.477 10.477 0 0 1-2.856-.64zm2.855-3.447c-.826.63-1.5 1.302-2.028 2.02l-.072.104a8.925 8.925 0 0 0 2.101.457v-2.581zm1.502.07v2.511a8.907 8.907 0 0 0 2.028-.433c-.52-.74-1.195-1.432-2.028-2.079zm1.7-.53.09.082a10.455 10.455 0 0 1 1.65 1.933c.266-.133.535-.28.804-.442a7.234 7.234 0 0 0-2.544-1.573zm-5.055.058-.188.074a7.258 7.258 0 0 0-2.204 1.44c.246.147.493.284.739.41.447-.68.999-1.32 1.653-1.924z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default IconWebsite;
