import _ from "lodash";
import api, { identityApi } from "../api";
import { IAntiForgery, SocialMediaTypes, SocialParams } from "../models/socialMedia";
import { GtmEventCategory } from "../models/userEvent";
import { DecodeBase64, EncodeBase64 } from "./base64";
import { getEventId, getSocialConnectionMethod } from "./gtm";
import * as logging from "./logging";
import { setLoginEventId } from "./session";
import { getAntiForgeryToken, removeAntiForgeryToken, setAntiForgeryToken, setTokens } from "./token";

export const socialLoginError = "social_login_error";

export enum LoginErrors {
  EmailVerificationError = "Verify your email address is correct",
  LoginAttemptsError = "Too many login attempts. Please try later.",
  NotFoundError = "User with id not found.",
  LoginCredentialsError = "The email address or password is incorrect. Please try again.",
  ConnectionError = "Connection timed out",
}

export enum SocialLoginErrors {
  InactiveSubscription = "account doesn't have an active subscription. Please select a different option.",
  NoSocialLogin = "This account is not using social login. Please use your email and password to log into your account.",
  MissingPermissions = "We couldn't log you in because of missing permissions. Please select a different option.",
  InvalidAntiForgery = "The Url has invalid anti forgery token",
}

export const handleSocialLogin = async (query: URLSearchParams) => {
  const antiForgery = query.get(SocialParams.antiForgery);
  const localAntiForgery = getAntiForgeryToken();
  removeAntiForgeryToken();
  if (antiForgery !== localAntiForgery) {
    sessionStorage.setItem(socialLoginError, SocialLoginErrors.InvalidAntiForgery);
  } else {
    const base64AntiForgery = DecodeBase64(antiForgery || "");
    const SocialAuthPayload = JSON.parse(base64AntiForgery);
    const antiForgeryModel: IAntiForgery = JSON.parse(SocialAuthPayload.payload);
    const token = query.get(SocialParams.token);
    const base64Email = query.get(SocialParams.email);
    const email = DecodeBase64(base64Email || "");
    if (antiForgeryModel.redirectFrom === "LOGIN") {
      await handleCheckSubscription(token || "", email, query);
    }
  }
};

const handleCheckSubscription = async (_token: string, email: string, query: URLSearchParams) => {
  try {
    if (_token && email) {
      const subscriptionResponse = await api.subscription.getUserSubscription(email);
      const { isSubscriptionExisted, token, subscriptionId } = subscriptionResponse.data;
      if (isSubscriptionExisted) {
        setTokens(token || _token);

        const socialConnection = getSocialConnection(query);
        if (socialConnection) {
          const userEventResponse = await api.userEvent.pushEvent({
            category: GtmEventCategory.Account,
            name: "login",
            detail: {
              method: getSocialConnectionMethod(socialConnection),
              subscriptionId,
            },
          });

          setLoginEventId(getEventId(userEventResponse));
        }
      } else {
        // cannot login
        handleSocialLoginFailed(query);
      }
    } else {
      handleSocialLoginFailed(query);
    }
  } catch (error) {
    logging.error(error);
  }
};

const getSocialConnection = (query: URLSearchParams) => {
  const socialConnection = query.get(SocialParams.SocialConnection) || "";
  if (socialConnection && socialConnection !== "null") {
    return socialConnection.split("-")[0].toLowerCase();
  }

  return undefined;
};

const handleSocialLoginFailed = (query: URLSearchParams) => {
  const socialConnection = getSocialConnection(query);
  const preventLoginSSO = query.has(SocialParams.PreventLoginSSO);
  let error = "";
  if (preventLoginSSO) {
    error = SocialLoginErrors.NoSocialLogin;
  } else if (socialConnection) {
    error = `Your ${_.upperFirst(socialConnection)} ${SocialLoginErrors.InactiveSubscription}`;
  } else {
    error = SocialLoginErrors.MissingPermissions;
  }
  sessionStorage.setItem(socialLoginError, error);
};

const userType: string = "nzPhotographer";

export const onSocialLoginClick = async (socialMedia: SocialMediaTypes, redirectPath: string) => {
  const antiForgeryModel: IAntiForgery = {
    type: "",
    urlParams: "",
    redirectFrom: "LOGIN",
    source: "NextZen",
    redirectUrl: window.location.origin + redirectPath,
  };

  const antiForgery = await EncodeBase64(JSON.stringify(antiForgeryModel));

  try {
    const response = await identityApi.get(
      `${socialMedia.toLowerCase()}/login/${userType.toLowerCase()}?authPayload=${encodeURIComponent(antiForgery)}`
    );

    if (response.data.externalProviderUri) {
      setAntiForgeryToken(response.data.antiForgery);
      window.location.href = response.data.externalProviderUri;
      return true;
    }
  } catch (error) {
    logging.error(error);
  }

  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLoginError = (response: any) => {
  if (!response?.loginSuccess) {
    if (response?.status === 429) {
      return LoginErrors.LoginAttemptsError;
    }

    return response?.loginErrorMessage === LoginErrors.NotFoundError ||
      response?.loginErrorMessage === LoginErrors.LoginCredentialsError
      ? LoginErrors.LoginCredentialsError
      : LoginErrors.ConnectionError;
  }

  return "";
};
