/* eslint-disable no-console */

import _ from "lodash";
import { SettingSocialConnectionTypes } from "../api/account/models";
import { IUserInfo } from "../models/session";

enum LogLevel {
  NONE,
  ERROR,
  DEBUG,
}

const logLevelName = (
  localStorage.getItem("log_level") || process.env.REACT_APP_LOG_LEVEL
)?.toUpperCase() as keyof typeof LogLevel;

const logLevel = (logLevelName && LogLevel[logLevelName]) || LogLevel.NONE;

export const log: (...params: Parameters<typeof console.log>) => void =
  logLevel >= LogLevel.DEBUG ? console.log.bind(window.console) : _.noop;

export const error: (...params: Parameters<typeof console.error>) => void =
  logLevel >= LogLevel.ERROR ? console.error.bind(window.console) : _.noop;

export enum LOGIN_METHOD {
  "facebook" = "sso-facebook",
  "google" = "sso-google",
  "email" = "email",
  "unknow" = "unknow",
}

export type LoginMethod = keyof typeof LOGIN_METHOD;

export const getLoginMethodForSegment = (settings: {
  socialConnection: SettingSocialConnectionTypes;
  isSocial: boolean;
}) => {
  settings = settings || ({} as IUserInfo);
  if (!settings.isSocial) {
    return "email";
  }
  if (Object.keys(LOGIN_METHOD).includes(settings.socialConnection as string)) {
    return LOGIN_METHOD[settings.socialConnection as LoginMethod];
  }
  return "unknow";
};
