import {
  IDownloadSettings,
  IPrivacySettings,
  IShareSettings,
} from "@zenfolio/core-components/dist/utilities/galleryConflicts";

export function mapShareSettings(shareSettings: IShareSettings): IShareSettings {
  const { shareAudience, isSharingGallery, isActive } = shareSettings;
  return {
    shareAudience,
    isSharingGallery,
    isActive,
  };
}

export function mapDownloadSettings(downloadSettings: IDownloadSettings): IDownloadSettings {
  const {
    isActive,
    isMultipleDownloadActive,
    multipleDownloadAudience,
    multipleDownloadType,
    multipleDownloadAmount,
    multipleDownloadConfigurations,
    isSingleDownloadActive,
    singleDownloadAudience,
    singleDownloadResolution,
    isMultipleDownloadActiveForVideo,
    multipleDownloadAudienceForVideo,
    multipleDownloadTypeForVideo,
    multipleDownloadAmountForVideo,
    isSingleDownloadActiveForVideo,
    singleDownloadAudienceForVideo,
    singleDownloadResolutionForVideo,
    multipleDownloadConfigurationsForVideo,
    isDigitalLicenseDownloadActive,
  } = downloadSettings;
  return {
    isActive,
    isMultipleDownloadActive,
    multipleDownloadAudience,
    multipleDownloadType,
    multipleDownloadAmount,
    multipleDownloadConfigurations,
    isSingleDownloadActive,
    singleDownloadAudience,
    singleDownloadResolution,
    isMultipleDownloadActiveForVideo,
    multipleDownloadAudienceForVideo,
    multipleDownloadTypeForVideo,
    multipleDownloadAmountForVideo,
    isSingleDownloadActiveForVideo,
    singleDownloadAudienceForVideo,
    singleDownloadResolutionForVideo,
    multipleDownloadConfigurationsForVideo,
    isDigitalLicenseDownloadActive,
  };
}

export function mapPrivacySettings(privacySettings: IPrivacySettings): IPrivacySettings {
  const { isSignInRequired, isVisitorAllowed, userTypes } = privacySettings;

  return {
    isSignInRequired,
    isVisitorAllowed,
    userTypes,
  };
}
