import _ from "lodash";
import { isMobileOnly } from "react-device-detect";
import { matchRoutes, NavigateFunction } from "react-router-dom";
import AccountUrlManager from "../components/features/Account/UrlManager";
import DashboardUrlManager from "../components/features/Dashboard/UrlManager";
import GalleriesUrlManager from "../components/features/Galleries/UrlManager";
import GalleryUrlManager, { mobileOnlyTabs } from "../components/features/Gallery/UrlManager";
import LoginUrlManager from "../components/features/Login/UrlManager";
import MoreUrlManager from "../components/features/More/UrlManager";
import BookingsUrlManager from "../components/features/Notifications/Bookings/UrlManager";
import OnBoardingUrlManager from "../components/features/OnBoarding/UrlManager";
import OrdersUrlManager from "../components/features/Orders/UrlManager";
import { areaPaths, IFRAME_BACK_TO, iframeParams } from "../models/iframe";
import { IUserInfo } from "../models/session";
import { isDevelopment, postForm } from "./helpers";
import { buildPathAndQuery, createUrlBuilder, getPathAndQuery } from "./query";
import { getImpersonateToken, getToken } from "./token";

export const openInNewTab = (url: string) => {
  const win = window.open(url, "_blank");
  if (win) {
    win.focus();
  }
};

const rootPath = "/";
const initialUrlStorageKey = "initial_url";

export const verifyInitialUrl = (initialUrl?: string | null) => {
  if (
    initialUrl &&
    _.startsWith(initialUrl, rootPath) &&
    initialUrl !== rootPath &&
    !matchRoutes(
      [{ path: LoginUrlManager.route }, { path: LoginUrlManager.socialRoute }, { path: LoginUrlManager.resetPassword }],
      initialUrl
    )
  ) {
    return initialUrl;
  }

  return undefined;
};

export const getInitialUrl = (clear: boolean) => {
  const initialUrl = sessionStorage.getItem(initialUrlStorageKey);

  if (clear) {
    sessionStorage.removeItem(initialUrlStorageKey);
  }

  return verifyInitialUrl(initialUrl);
};

export const storeInitialUrl = () => {
  if (!getInitialUrl(false)) {
    const initialUrl = verifyInitialUrl(getPathAndQuery());
    if (initialUrl) {
      sessionStorage.setItem(initialUrlStorageKey, initialUrl);
    }
  }
};

export const setInitialUrl = (url: string) => {
  sessionStorage.setItem(initialUrlStorageKey, url);
};

export const defaultMobileUrl = DashboardUrlManager.prefix;
const dashboardDesktopUrl = "/dashboard";
const websiteDesktopUrl = "/website";
const onBoardingDesktopUrl = "/welcome";

export type InitialPageType = "auto" | "preview" | "website";

const mapInitialUrlToDesktop = (initialUrl: string) => {
  const urlBuilder = createUrlBuilder(initialUrl);
  const iframePath = urlBuilder.searchParams.get(iframeParams.path) || "";

  if (matchRoutes([{ path: OnBoardingUrlManager.routeV2 }], initialUrl)) {
    return OnBoardingUrlManager.prefixV2;
  }

  if (matchRoutes([{ path: OrdersUrlManager.route }], initialUrl)) {
    const orderId = matchRoutes([{ path: OrdersUrlManager.orderDetailPath }], initialUrl)?.[0].params[
      OrdersUrlManager.orderIdParam
    ];

    if (orderId) {
      return "/selling/orders/" + orderId;
    }

    return "/selling?activeTab=orders";
  }

  const galleryMatch = matchRoutes([{ path: GalleryUrlManager.galleryPath }], initialUrl)?.[0];
  if (galleryMatch) {
    const galleryId = galleryMatch.params[GalleryUrlManager.galleryIdParam];
    const tabOrMediaId = galleryMatch.params[GalleryUrlManager.tabOrMediaIdParam];

    return `/photos/album/${galleryId}${
      tabOrMediaId && !_.includes(mobileOnlyTabs, tabOrMediaId) ? `/${tabOrMediaId}` : ""
    }${iframePath}`;
  }

  if (matchRoutes([{ path: GalleriesUrlManager.route }], initialUrl)) {
    if (matchRoutes([{ path: GalleriesUrlManager.searchResultPagePath }], initialUrl)) {
      const query = urlBuilder.searchParams.get(GalleriesUrlManager.searchTextQueryParam);
      if (query) {
        const desktopUrl = createUrlBuilder("/search?tab=galleries");
        desktopUrl.searchParams.set("query", query);

        return buildPathAndQuery(desktopUrl);
      }
    }

    if (
      matchRoutes([{ path: GalleriesUrlManager.galleriesPagePath }], initialUrl)?.[0].params[
        GalleriesUrlManager.actionParam
      ] === GalleriesUrlManager.uploadActionParam
    ) {
      return "/photos/add";
    }

    return "/photos";
  }

  if (matchRoutes([{ path: BookingsUrlManager.route }], initialUrl)) {
    urlBuilder.searchParams.delete(iframeParams.path);

    return `/bookme${buildPathAndQuery(urlBuilder)}`;
  }

  if (matchRoutes([{ path: AccountUrlManager.route }], initialUrl)) {
    return `/account${iframePath}`;
  }

  if (matchRoutes([{ path: MoreUrlManager.route }], initialUrl)) {
    const iframeRoot = urlBuilder.searchParams.get(iframeParams.root);

    for (const areaPath of areaPaths) {
      if (matchRoutes([{ path: MoreUrlManager.generateDynamicRoute(areaPath.mobilePath) }], initialUrl)) {
        return (iframeRoot || areaPath.iframePaths[0]) + iframePath;
      }
    }
  }

  return dashboardDesktopUrl;
};

const redirectWorkerPath = process.env.REACT_APP_REDIRECT_WORKER_PATH;

export const redirectToDesktopSite = (pageType: InitialPageType, initialUrl?: string, force?: boolean) => {
  if (force || (!isDevelopment && !isMobileOnly)) {
    const url =
      pageType === "preview"
        ? `${dashboardDesktopUrl}?mobile=true`
        : pageType === "website"
        ? websiteDesktopUrl
        : initialUrl
        ? mapInitialUrlToDesktop(initialUrl)
        : onBoardingDesktopUrl;

    if (redirectWorkerPath) {
      postForm(process.env.REACT_APP_TEST_SITE + redirectWorkerPath, {
        token: getToken(),
        impersonateToken: getImpersonateToken(),
        pathAndQuery: url,
      });
    } else {
      window.location.href = process.env.REACT_APP_TEST_SITE + url;
    }
  }
};

const mobileSiteStatus = process.env.REACT_APP_MOBILE_SITE_STATUS;

export const redirectToInitialUrl = (
  navigate: NavigateFunction,
  pageType: InitialPageType,
  initialUrl?: string,
  force?: boolean
) => {
  if (force || mobileSiteStatus !== "disabled") {
    const url =
      pageType === "website"
        ? MoreUrlManager.generateDynamicRoute(
            MoreUrlManager.website,
            IFRAME_BACK_TO.DASHBOARD,
            DashboardUrlManager.prefix
          )
        : initialUrl || defaultMobileUrl;

    if (getPathAndQuery() !== url) {
      navigate(url);
    }
  } else {
    redirectToDesktopSite(pageType, initialUrl, true);
  }
};

export const logoutFromDesktopAsync = (): Promise<void> => {
  return new Promise((resolve) => {
    if (!redirectWorkerPath) {
      resolve();
    } else {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("style", "position: absolute; opacity: 0;");

      let ready = false;
      let timeout: NodeJS.Timeout | null = null;

      const onload = () => {
        if (timeout != null) {
          clearTimeout(timeout);
          timeout = null;
        }

        if (!ready) {
          ready = true;

          iframe.remove();
          resolve();
        }
      };

      iframe.onload = onload;

      timeout = setTimeout(() => {
        timeout = null;
        onload();
      }, 2000);

      iframe.src = process.env.REACT_APP_TEST_SITE + redirectWorkerPath;

      document.body.appendChild(iframe);
    }
  });
};

export const tryRedirectToDesktopSite = () => {
  redirectToDesktopSite("auto", verifyInitialUrl(getPathAndQuery()));
};

// Transforms an storage relative url to an absolute url for local env
// storage relative mapping exists on test env & above
export function processStaticImageUrl(url: string) {
  return isDevelopment && url.startsWith("/storage")
    ? url.replace("/storage", `${process.env.REACT_APP_TEST_SITE}/storage`)
    : url;
}

export function getImageScaledUrl(
  imageBaseUrl: string,
  extension?: string,
  minRatio: number = 1,
  maxRatio: number = 3
) {
  const pixelRatio = Math.min(Math.max(window.devicePixelRatio || minRatio, minRatio), maxRatio);
  const suffix = pixelRatio > 2 ? "_x3" : pixelRatio > 1 ? "_x2" : "";

  return `${imageBaseUrl}${suffix}.${extension || "jpg"}`;
}

export function generateLoginLink(userInfo: IUserInfo) {
  const loginToken = userInfo.identitySegment?.loginToken;
  if (!loginToken) {
    return null;
  }

  const url = new URL(process.env.REACT_APP_TEST_SITE!);
  url.searchParams.set("login_token", loginToken);

  return { login_link: url.href.replace("/?", "?") };
}

export const widgetQueryParam = "widget";
