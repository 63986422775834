import { Stripe } from "@stripe/stripe-js";
import { IUserProperties } from "../../models/account";
import { IBundleInfo } from "../../models/addon";
import { ISellingWelcomeStep } from "../../models/orders";
import { IUserInfo } from "../../models/session";
import { IActivePlans, IPromotionCampaign, ISubscriptionPlan } from "../../models/subscription";
import { isPublicApiMode } from "../../utilities/publicApi";
import { initialized } from "../../utilities/session";
import { GenericState } from "../generic";

export const initialState: ISessionState = {
  pageLoadingState: isPublicApiMode() || initialized() ? "secondary" : "initial",
  siteEditorHubConnected: false,
  getUserInfo: {
    status: "Init",
  },
  login: {
    status: "Init",
  },
  welcomeSellingTasks: [],
  getSellingTask: {
    status: "Init",
  },
  showTrialBanner: false,
  getPromotionCampaigns: {
    status: "Init",
  },
  getActivePlans: {
    status: "Init",
  },
  subscribeOrUnSubscribeNotification: {
    status: "Init",
  },
  getBookmeBundleInfo: {
    status: "Init",
  },
  getStripeProvider: {
    status: "Init",
  },
};

/** State for the current user session information. */
export interface ISessionState {
  pageLoadingState: PageLoadingState;
  siteEditorHubConnected: boolean;
  userInfo?: IUserInfo;
  getUserInfo: GetUserInfoState;
  userProperties?: IUserProperties;
  login: LoginState;
  welcomeSellingTasks: ISellingWelcomeStep[];
  getSellingTask: GenericState;
  showTrialBanner: boolean;
  promotionCampaigns?: IPromotionCampaign[];
  getPromotionCampaigns: GetPromotionCampaignsState;
  activePlans?: ISubscriptionPlan[];
  getActivePlans: GetActivePlansState;
  deviceId?: string;
  subscribeOrUnSubscribeNotification: GenericState;
  getBookmeBundleInfo: GenericState;
  bookmeBundleInfo?: IBundleInfo;
  getStripeProvider: GenericState;
  stripeProvider?: Stripe;
}

export type PageLoadingState = "initial" | "secondary" | "none";

export type GetUserInfoState = GenericState;
export type LoginState = GenericState;

export interface IGetUserInfoResult {
  userInfo: IUserInfo;
}

export interface ILoginParams {
  userName: string;
  password: string;
  disableFlagship?: boolean;
}

export interface IGetSellingTaskResult {
  welcomeSellingTasks: ISellingWelcomeStep[];
}

export interface ILoginNextZenParams {
  token: string;
  eventId: string;
}

export type GetPromotionCampaignsState = GenericState<IGetPromotionCampaignsInput, IGetPromotionCampaignsResult>;
export interface IGetPromotionCampaignsInput {
  isTrialUser: boolean;
}
export interface IGetPromotionCampaignsResult {
  promotionCampaigns: IPromotionCampaign[];
}

export type GetActivePlansState = GenericState<IGetActivePlansInput, IGetActivePlansResult>;

export interface IGetActivePlansResult {
  activePlans: IActivePlans;
}

export interface IGetActivePlansInput {
  currentPlanCode: string;
}

export interface IUpdateUserInfoInput {
  /** Segment is already updated when the URL changes, so sometimes it's not needed to update it twice. */
  updateSegment: boolean;
  userInfo: Partial<IUserInfo>;
}

export interface IGetBookmeBundleInfoResult {
  bundleInfo?: IBundleInfo;
}

export interface IGetStripeResult {
  stripeProvider?: Stripe;
}
