import _ from "lodash";
import React from "react";
import { PartialRecord } from "../types";

/** Returns configured property comparison function. Config specifies props that need to be compared (others are
 * ignored), and whether the comparison of the prop values should be shallow (*false*) or deep (*true*). */
export function configureArePropsEqual<P extends object>(
  config: PartialRecord<keyof P, boolean>
): (prevProps: Readonly<P>, nextProps: Readonly<P>) => boolean {
  return _.partialRight(arePropsEqual, config);
}

/** Checks if the props are equal. Config specifies props that need to be compared (others are ignored), and
 * whether the comparison of the prop values should be shallow (*false*) or deep (*true*). */
function arePropsEqual<P extends object>(
  prevProps: Readonly<P>,
  nextProps: Readonly<P>,
  config: Record<keyof P, boolean>
) {
  for (const propName in config) {
    const deep = config[propName];
    const propChanged =
      (deep && !_.isEqual(prevProps[propName], nextProps[propName])) ||
      (!deep && !Object.is(prevProps[propName], nextProps[propName]));
    if (propChanged) {
      return false;
    }
  }
  return true;
}

/** Returns element index that was previously stored in the data attribute or null if not parsed. */
export function getEventTargetIndex(target: EventTarget) {
  const index = parseInt((target as HTMLElement)?.dataset?.index ?? "");
  return isNaN(index) ? null : index;
}

// taken from github.com/gregberge/react-flatten-children
type ReactChildArray = ReturnType<typeof React.Children.toArray>;
export function flattenReactChildren(children: React.ReactNode): ReactChildArray {
  const childrenArray = React.Children.toArray(children);
  return childrenArray.reduce((flatChildren: ReactChildArray, child) => {
    if ((child as React.ReactElement).type === React.Fragment) {
      return flatChildren.concat(flattenReactChildren((child as React.ReactElement).props.children));
    }
    flatChildren.push(child);
    return flatChildren;
  }, []);
}
