import { TransitionEventHandler } from "react";

interface IProgressCircleProps {
  className?: string;
  progress: number;
  size: number;
  borderWidth: number;
  onCircleTransitionEnd?: TransitionEventHandler<SVGCircleElement>;
}

function ProgressCircle(props: IProgressCircleProps) {
  const { className, progress, size, borderWidth, onCircleTransitionEnd } = props;
  const diameter = size - borderWidth;
  const circumference = diameter * Math.PI;
  const strokeDashoffset = circumference * (1 - progress / 100);

  return (
    <svg className={className} height={size} width={size} style={{ top: -borderWidth, left: -borderWidth }}>
      <circle
        onTransitionEnd={onCircleTransitionEnd}
        strokeDasharray={circumference + " " + circumference}
        strokeWidth={borderWidth}
        style={{ strokeDashoffset }}
        r={diameter / 2}
        cx={size / 2}
        cy={size / 2}
      />
    </svg>
  );
}

export default ProgressCircle;
