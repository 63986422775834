export interface IInstagramMedia {
  id: number;
  mediaType: string;
  mediaUrl: string;
  caption: string;
  thumbnailUrl: string;
}

export enum SocialParams {
  token = "token",
  isNZUserSignedUp = "isNZUserSignedUp",
  antiForgery = "antiForgery",
  fromSocialSignup = "isFromSocial",
  email = "email",
  isEmailPrivate = "isEmailPrivate",
  SocialLoginFailed = "socialLoginFailed",
  SocialConnection = "socialConnection",
  PreventLoginSSO = "preventLoginSSO",
}

export interface IAntiForgery {
  type: string; // paid, trial
  urlParams: string;
  redirectFrom: RedirectFrom;
  source: string;
  redirectUrl: string;
}

export type RedirectFrom = "SIGN_UP" | "LOGIN";

export enum SocialMediaTypes {
  Facebook = "Facebook",
  Google = "Google",
}
