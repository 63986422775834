import { formatErrorType, isErrorType } from "@zenfolio/core-components/dist/utilities/helpers";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import _ from "lodash";
import { dispatch } from "../store/store";
import { staticGaEventId } from "../utilities/gtm";
import * as logging from "../utilities/logging";
import { getImpersonateToken, getToken, setTokens } from "../utilities/token";
import account, { IApiAccount } from "./account";
import bookings, { IApiBookings } from "./bookings";
import comments, { IApiComments } from "./comments";
import contact, { IApiContact } from "./contact";
import folders, { IApisFolders } from "./folders";
import galleries, { IApiGalleries } from "./galleries";
import gallery, { IApiGallery } from "./gallery";
import gallerySettings, { IApiGallerySettings } from "./gallerySettings";
import identities, { IApiIdentities } from "./identities";
import locations, { IApiLocations } from "./location";
import mediaDownload, { IApiMediaDownload } from "./mediaDownload";
import notifications, { IApiNotifications } from "./notification";
import onBoarding, { IApiOnBoarding } from "./onBoarding";
import orders, { IApiOrders } from "./orders";
import presets, { IApiPresets } from "./presets";
import publicApi, { IApiPublicApi } from "./publicApi";
import search, { IApiSearch } from "./search";
import siteEngine, { IApiSiteEngine } from "./siteEngine";
import socialMedia, { IApiSocialMedia } from "./socialMedia";
import subscription, { IApiSubscription } from "./subscription";
import tools, { IApiTools } from "./tools";
import upload, { IApiUpload } from "./upload";
import userEvent, { IApiUserEvent } from "./userEvent";
import watermarks, { IApiWatermarks } from "./watermarks";

export interface IProblemDetails {
  type: string;
  title: string;
  status?: number;
  detail?: string;
  exception?: string;
  errors?: { [property: string]: string[] };
  [key: string]: unknown;
}

const serviceUnavailableError: IProblemDetails = {
  type: formatErrorType("service_unavailable_error"),
  title: "Service unavailable, please try again.",
};

export function isAuthError(error: AxiosError) {
  const status = error?.response?.status;

  return status === 401 || status === 403;
}

export function toProblemDetails(error: unknown, noLog?: boolean): IProblemDetails {
  const axiosError = error as AxiosError<IProblemDetails>;

  if (axiosError?.response?.data?.type) {
    return axiosError.response.data;
  }

  if (!noLog) {
    logging.error("Unexpected API error:", error);
  }

  return serviceUnavailableError;
}

export function hasAnyErrorType(error: unknown, ...errorTypes: string[]) {
  const problemDetails = toProblemDetails(error, true);

  return _.some(errorTypes, (errorType) => isErrorType(problemDetails.type, errorType));
}

export function addGaEventIdHeader(config?: AxiosRequestConfig): AxiosRequestConfig {
  const result: AxiosRequestConfig = config || {};

  if (!result.headers) {
    result.headers = {};
  }

  result.headers["ga-event-id"] = staticGaEventId;

  return result;
}

const authorizationHeader = "Authorization";
const impersonationHeader = "Impersonation";
const appCodeHeader = "X-APP-CODE";
const accessTokenHeader = "x-access-token";

export function getBearerToken(token: string) {
  return token ? `Bearer ${token}` : undefined;
}

function addBearerToken(token: string, header: string, headers: AxiosRequestHeaders) {
  if (token && !headers[header]) {
    headers[header] = getBearerToken(token);
  }
}

const setupApiClient = (apiUrl: string): AxiosInstance => {
  const axiosWrapper = axios.create({
    baseURL: apiUrl,
  });

  axiosWrapper.interceptors.request.use((config) => {
    addBearerToken(getToken(), authorizationHeader, config.headers);
    addBearerToken(getImpersonateToken(), impersonationHeader, config.headers);

    if (!config.headers[appCodeHeader]) {
      config.headers[appCodeHeader] = "NzPhotographer";
    }

    return config;
  });

  axiosWrapper.interceptors.response.use(
    (response) => {
      let newToken = response.headers[accessTokenHeader];
      if (newToken && !getImpersonateToken()) {
        newToken = newToken.replace(/^"|"$/g, "");
        setTokens(newToken);
      }

      return response;
    },
    async (error: AxiosError) => {
      if (isAuthError(error) && (error.response?.data as { code: string })?.code === "account_locked") {
        await dispatch.session.logoutAsync("account is locked");
      }

      throw error;
    }
  );

  return axiosWrapper;
};

export const identityApi = setupApiClient(process.env.REACT_APP_IDENTITY_API_URL!);

export const accountsApi = setupApiClient(process.env.REACT_APP_ACCOUNTS_API_URL!);

export const userEventApi = setupApiClient(process.env.REACT_APP_USER_EVENT_API_URL!);

export const foldersApi = setupApiClient(process.env.REACT_APP_FOLDERS_API_URL!);

export const mediaHubApi = setupApiClient(process.env.REACT_APP_MEDIA_HUB_API_URL!);

export const ecOrderApi = setupApiClient(process.env.REACT_APP_ECORDER_API_URL!);

export const bookMeApi = setupApiClient(process.env.REACT_APP_BOOKME_API_URL!);

export const commentingApi = setupApiClient(process.env.REACT_APP_COMMENTING_API_URL!);

export const searchApi = setupApiClient(process.env.REACT_APP_SEARCH_API_URL!);

export const mediaUploadApi = setupApiClient(process.env.REACT_APP_MEDIA_UPLOAD_API_URL!);

export const videoUploadApi = setupApiClient(process.env.REACT_APP_VIDEO_UPLOAD_API_URL!);

export const mediaDeliveryApi = setupApiClient(process.env.REACT_APP_MEDIA_DELIVERY_API_URL!);

export const videoStreamingApi = setupApiClient(process.env.REACT_APP_VIDEO_STREAMING_API_URL!);

export const ecProductCatalogApi = setupApiClient(process.env.REACT_APP_ECPRODUCTCATALOG_API_URL!);

export const locationApi = setupApiClient(process.env.REACT_APP_LOCATION_URL!);

export const mediaDownloadApi = setupApiClient(process.env.REACT_APP_MEDIA_DOWNLOAD_API_URL!);

export const contactApi = setupApiClient(process.env.REACT_APP_CONTACT_API_URL!);

export const toolsApi = setupApiClient(process.env.REACT_APP_TOOLS_API_URL!);

export const siteEngineApi = setupApiClient(process.env.REACT_APP_SITE_ENGINE_API_URL!);

export const notificationApi = setupApiClient(process.env.REACT_APP_NOTIFICATION_URL!);

export const salesTaxApi = setupApiClient(process.env.REACT_APP_TAX_URL!);

export const publicApiApi = setupApiClient(process.env.REACT_APP_PUBLIC_API_URL!);

export interface IApiServices {
  gallery: IApiGallery;
  galleries: IApiGalleries;
  gallerySettings: IApiGallerySettings;
  orders: IApiOrders;
  upload: IApiUpload;
  onBoarding: IApiOnBoarding;
  account: IApiAccount;
  search: IApiSearch;
  socialMedia: IApiSocialMedia;
  subscription: IApiSubscription;
  identities: IApiIdentities;
  userEvent: IApiUserEvent;
  bookings: IApiBookings;
  locations: IApiLocations;
  mediaDownload: IApiMediaDownload;
  folders: IApisFolders;
  contact: IApiContact;
  presets: IApiPresets;
  watermarks: IApiWatermarks;
  comments: IApiComments;
  tools: IApiTools;
  siteEngine: IApiSiteEngine;
  notifications: IApiNotifications;
  publicApi: IApiPublicApi;
}

const api: IApiServices = {
  gallery,
  galleries,
  gallerySettings,
  orders,
  upload,
  onBoarding,
  account,
  socialMedia,
  subscription,
  identities,
  userEvent,
  bookings,
  locations,
  mediaDownload,
  folders,
  search,
  contact,
  presets,
  watermarks,
  comments,
  tools,
  siteEngine,
  notifications,
  publicApi,
};

export default api;
