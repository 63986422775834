import { createModel } from "@rematch/core";
import api from "../../api";
import { IOrdersDto } from "../../api/orders/models";
import { IError, getErrorFromApi } from "../common";
import { IOrderSearchParams } from "../notifications/models";
import { RootModel } from "../store";
import { initialState } from "./models";

export const orders = createModel<RootModel>()({
  state: initialState,
  reducers: {
    getOrdersStarted(state) {
      return {
        ...state,
        getOrders: { status: "Pending" },
      };
    },
    getOrdersSuccess(state, payload: { ordersList: IOrdersDto }) {
      return state.getOrders.status === "Pending"
        ? {
            ...state,
            ordersList: payload.ordersList,
            getOrders: { status: "Success" },
          }
        : state;
    },
    getOrdersError(state, payload: { error: IError }) {
      return state.getOrders.status === "Pending"
        ? {
            ...state,
            getOrders: { status: "Error", error: payload.error },
          }
        : state;
    },
  },
  effects: (dispatch) => ({
    async getOrdersListAsync(searchParams: IOrderSearchParams) {
      dispatch.orders.getOrdersStarted();
      try {
        const ordersList: IOrdersDto = await api.orders.getOrders(searchParams);
        dispatch.orders.getOrdersSuccess({ordersList});
      } catch (error) {
        dispatch.orders.getOrdersError({ error: getErrorFromApi(error) });
      }
    },
    async getMoreOrdersAsync(searchParams: IOrderSearchParams, rootState) {
      dispatch.orders.getOrdersStarted();
      try {
        const ordersList: IOrdersDto = await api.orders.getOrders(searchParams);
        let newOrders = [...ordersList.orders];
        if (searchParams.pageNumber > 1) {
          newOrders = [
            ...rootState.orders.ordersList.orders,
            ...newOrders,
          ]
        }
        dispatch.orders.getOrdersSuccess({
          ordersList: {
            ...ordersList,
            orders: newOrders 
          }
        });
      } catch (error) {
        dispatch.orders.getOrdersError({ error: getErrorFromApi(error) });
      }
    },
  }),
});
