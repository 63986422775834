export const bookMeAddOnCode = (process.env.REACT_APP_BOOKME_CODE as string).split(";");

export enum BundleType {
  Storage = 1,
  BookMe = 2,
  VideoCredit = 3,
  Optyx = 4,
}

export interface IBundleInfo {
  id: string;
  userCreated: string;
  name: string;
  displayName: string;
  code: string;
  description: string;
  bundleType: number;
  autoRenew: boolean;
  referralCredit: number;
  status: boolean;
  bundlePeriods: IBundlePeriod[];
  quotas: IQuota[];
}

export interface IBundlePeriod {
  id: string;
  chargePeriod: number;
  periodMultiplier: number;
  currencyId: string;
  currency: string;
  charge: number;
  isSubscribe: boolean;
  iterations?: number;
  nextPeriodId?: string;
}

export interface IQuota {
  quotaId: string;
  quotaCode: number;
  quotaLimit: number;
  quotaUnit: number;
}
