import { compareVersions } from "compare-versions";
import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchRoutes } from "react-router-dom";
import { IUserInfo } from "../../models/session";
import { Dispatch, RootState } from "../../store/store";
import { handleSocialLogin } from "../../utilities/login";
import { clearSession, getAppVersion, initSessionAsync, setAppVersion } from "../../utilities/session";
import { validateTokens } from "../../utilities/token";
import useActionCompleted from "./useActionCompleted";
import useActionSuccessfullyCompleted from "./useActionSuccessfullyCompleted";
import { useSearchParamsRemoval } from "./useSearchParamsRemoval";
import { useSetPageLoading } from "./useSetPageLoading";

const versionParam = "app-version";

function tryUpdateAppVersion(newVersion?: string) {
  if (newVersion) {
    const currentVersion = getAppVersion();
    if (!currentVersion) {
      setAppVersion(newVersion);

      return false;
    }

    if (compareVersions(newVersion, currentVersion) === 1) {
      setAppVersion(newVersion);

      const url = new URL(window.location.href);
      url.searchParams.set(versionParam, newVersion);
      window.location.href = url.href;

      return true;
    }
  }

  return false;
}

export default function useAppStartup(
  userInfo: IUserInfo | undefined,
  socialRoute: string,
  resetPasswordRoute: string | undefined,
  refreshOnLogin: boolean,
  onInit: () => Promise<boolean>,
  onValidate?: (userId: string, url: URL) => void,
  onLoaded?: (userInfo: IUserInfo) => void
) {
  const [initialized, setInitialized] = useState(false);
  const setPageLoading = useSetPageLoading();
  const loginStatus = useSelector((state: RootState) => state.session.login.status);
  const getUserInfoStatus = useSelector((state: RootState) => state.session.getUserInfo.status);
  const userProperties = useSelector((state: RootState) => state.session.userProperties);
  useSearchParamsRemoval(versionParam);
  const {
    session: { getUserInfoAsync },
  } = useDispatch<Dispatch>();

  useLayoutEffect(() => {
    const handleError = () => {
      clearSession();
      setInitialized(true);
    };

    const handleGetUserInfo = async () => {
      if (!(await onInit())) {
        handleError();
        return;
      }

      const url = new URL(window.location.href);
      if (matchRoutes([{ path: socialRoute }], url.pathname)) {
        const query = new URLSearchParams(url.search);
        await handleSocialLogin(query);
      }

      if (resetPasswordRoute) {
        if (matchRoutes([{ path: resetPasswordRoute }], url.pathname)) {
          clearSession();
        }
      }

      const userId = validateTokens(false);
      if (userId) {
        onValidate?.(userId, url);
        getUserInfoAsync(userId);
      } else {
        handleError();
      }
    };

    handleGetUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useActionSuccessfullyCompleted(loginStatus, () => {
    const userId = validateTokens(true);
    if (userId) {
      if (refreshOnLogin) {
        setPageLoading();
        setInitialized(false);
      }

      getUserInfoAsync(userId);
    } else {
      clearSession();
    }
  });

  useActionCompleted(getUserInfoStatus, async () => {
    if (userInfo) {
      if (tryUpdateAppVersion(userInfo.version)) {
        return;
      }

      await initSessionAsync(userInfo, userProperties);
      onLoaded?.(userInfo);
    } else {
      clearSession();
    }

    setInitialized(true);
  });

  return initialized;
}
