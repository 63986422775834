import {
  IDownloadSettings,
  IPrivacySettings,
  IShareSettings,
} from "@zenfolio/core-components/dist/utilities/galleryConflicts";
import { foldersApi } from "..";
import { mapDownloadSettings, mapPrivacySettings, mapShareSettings } from "./mappers";

export interface IApiGallerySettings {
  getPrivacySettings: (id: string) => Promise<IPrivacySettings>;
  getShareSettings: (id: string) => Promise<IShareSettings>;
  getDownloadSettings: (id: string) => Promise<IDownloadSettings>;
}

const gallerySettings: IApiGallerySettings = {
  getShareSettings: async (id: string) =>
    mapShareSettings((await foldersApi.get<IShareSettings>(`folders/${id}/settings/share`)).data),
  getDownloadSettings: async (id: string) =>
    mapDownloadSettings((await foldersApi.get<IDownloadSettings>(`folders/${id}/settings/download`)).data),
  getPrivacySettings: async (id: string) =>
    mapPrivacySettings(
      (
        await foldersApi.get<IPrivacySettings>(`folders/${id}/settings/privacy`, {
          params: { excludeUsers: true },
        })
      ).data
    ),
};

export default gallerySettings;
