import { ICountersDto, IRecentGalleriesDto, IRecentGalleryDto } from "../api/galleries/models";
import { GalleryMedia } from "./gallery";

export interface IRecentGalleries extends Omit<IRecentGalleriesDto, "galleries"> {
  galleries: IRecentGallery[];
}

export interface IRecentGallery extends Omit<IRecentGalleryDto, "topMedia"> {
  topMedia: GalleryMedia[];
}

export interface ICounters extends ICountersDto {}

export interface IGetRecentGalleriesQuery {
  type: GalleryRecentBy;
  skip: number;
  take: number;
}

export enum GalleryRecentBy {
  DateModified = "dateModified",
  LastActivityDate = "lastActivityDate",
}
