import { IFRAME_BACK_TO } from "../models/iframe";
import { addQueryParams } from "./query";

export function getBackTitle(backTo?: IFRAME_BACK_TO | null) {
  let title: string;

  switch (backTo) {
    case IFRAME_BACK_TO.DASHBOARD:
      title = "Dashboard";
      break;
    case IFRAME_BACK_TO.NOTIFICATIONS:
      title = "Notifications";
      break;
    case IFRAME_BACK_TO.ORDERS:
      title = "Orders";
      break;
    case IFRAME_BACK_TO.ORDER:
      title = "Order";
      break;
    case IFRAME_BACK_TO.GALLERY_PREVIEW:
      title = "Gallery Preview";
      break;
    case IFRAME_BACK_TO.GALLERY_SHARE:
      title = "Gallery Share";
      break;
    case IFRAME_BACK_TO.GALLERY_SETTINGS:
      title = "Gallery Settings";
      break;
    case IFRAME_BACK_TO.GALLERY_DESIGN:
      title = "Gallery Design";
      break;
    case IFRAME_BACK_TO.GALLERY_ACTIVITY:
      title = "Gallery Activity";
      break;
    case IFRAME_BACK_TO.GENERIC:
      return "Back";
    default:
      title = "More Menu";
      break;
  }

  return `Back to ${title}`;
}

export const buildIframePath = (pathAndQuery: string, params?: Record<string, string | null | undefined>) => {
  const result = addQueryParams(pathAndQuery, params);

  // remove empty path
  if (/^\/([?#]|$)/.test(result)) {
    return result.substring(1);
  }

  return result;
};
