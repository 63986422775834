// ATTENTION: should match with cs-user-events service
export enum GtmEventCategory {
  Account = 0,
  Dashboard = 5,
  Gallery = 7,
  MobileOnBoarding = 12,
  MobileDashboard = 13,
  Bookmark = 15,
  Notifications = 16,
  OrdersTab = 17,
  MobileGalleryUpload = 18,
  MobileGallery = 19,
}

export type GtmEvent =
  | {
      category: GtmEventCategory.Account;
      name: "trial" | "login";
    }
  | {
      category: GtmEventCategory.Dashboard;
      name: "clicked through on getting started widget";
    }
  | {
      category: GtmEventCategory.Gallery;
      name: "upload photos" | "create a gallery";
    }
  | {
      category: GtmEventCategory.MobileOnBoarding;
      name:
        | "personalize account"
        | "types of photography"
        | "primary shoot type"
        | "what are your goals"
        | "select photos"
        | "upload photos"
        | "gallery or website fork"
        | "choose a site - preview"
        | "choose a site - select"
        | "redirect to website builder";
    }
  | {
      category: GtmEventCategory.MobileDashboard;
      name: "preview gallery" | "start watch video" | "video engagement";
    }
  | {
      category: GtmEventCategory.Bookmark;
      name: "click add to home screen" | "added to home screen";
    }
  | {
      category: GtmEventCategory.MobileGalleryUpload;
      name:
        | "clicks galleries"
        | "clicks add gallery"
        | "name new gallery"
        | "gallery preset"
        | "gallery location"
        | "add media"
        | "select photos to add"
        | "upload selected photos";
    }
  | {
      category: GtmEventCategory.MobileGallery;
      name:
        | "click to open gallery"
        | "click add media"
        | "click preview"
        | "share gallery"
        | "download media"
        | "set cover media"
        | "start search";
    }
  | {
      category: GtmEventCategory.Notifications;
      name:
        | "clicks open notifications"
        | "clicks open orders to approve"
        | "clicks orders to approve - cancel"
        | "clicks orders to approve - approve"
        | "clicks orders to approve - view"
        | "clicks to respond to comment"
        | "clicks to reply to comment"
        | "clicks open bookings to approve"
        | "clicks bookings to approve - decline"
        | "clicks bookings to approve - approve"
        | "clicks bookings to approve - view"
        | "clicks bookings to approve - upgrade now"
        | "clicks open upcoming bookings"
        | "clicks upcoming bookings - call"
        | "clicks upcoming bookings - directions"
        | "clicks upcoming bookings - view"
        | "clicks upcoming bookings - upgrade now";
    }
  | {
      category: GtmEventCategory.OrdersTab;
      name:
        | "clicks open orders"
        | "orders - active"
        | "orders - completed"
        | "orders - started a search"
        | "orders - clicks to view order details";
    };

export type UserEventDetail = GtmEvent & {
  detail?: Record<string, string | null>;
};
