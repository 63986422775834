import { AxiosResponse } from "axios";
import _ from "lodash";
import TagManager from "react-gtm-module";
import api from "../api";
import { GtmEvent, GtmEventCategory, UserEventDetail } from "../models/userEvent";
import { getState } from "../store/store";
import * as logging from "./logging";
import { experience, getPlanPeriodAdjective, getUserStatus, getUserType } from "./user";

export const staticGaEventId = "f843d233-b294-47a7-a076-6b92e8c3ebbb";

function getEmailDomain(email: string | null | undefined) {
  let domain = email;
  if (domain) {
    const parts = _.split(domain, "@");
    if (parts.length > 1) {
      domain = parts[1];
    }
  }

  return domain;
}

const categoryNames: Record<GtmEventCategory, string> = {
  [GtmEventCategory.Account]: "account",
  [GtmEventCategory.Dashboard]: "dashboard",
  [GtmEventCategory.Gallery]: "gallery",
  [GtmEventCategory.MobileOnBoarding]: "mobile onboarding",
  [GtmEventCategory.MobileDashboard]: "mobile dashboard",
  [GtmEventCategory.Bookmark]: "bookmark",
  [GtmEventCategory.Notifications]: "Notifications",
  [GtmEventCategory.OrdersTab]: "Orders Tab",
  [GtmEventCategory.MobileGalleryUpload]: "Mobile Gallery Upload",
  [GtmEventCategory.MobileGallery]: "Mobile Gallery",
};

export function getCategoryName(category: GtmEventCategory) {
  return categoryNames[category];
}

export function getSocialConnectionMethod(method: string) {
  return `sso-${method}`;
}

export function getEventId(response: AxiosResponse) {
  return (
    (response.status >= 200 && response.status < 300 && (response.headers["x-correlation-id"] as string)) || undefined
  );
}

export function formatGtmArray(array: string[]) {
  return _.isEmpty(array) ? null : _.join(array, ",");
}

export function pushGtmEvent<T extends GtmEvent>(event: T, responseOrEventId: AxiosResponse | string) {
  const userInfo = getState().session.userInfo;
  if (!userInfo) {
    return;
  }

  const eventId = typeof responseOrEventId === "string" ? responseOrEventId : getEventId(responseOrEventId);
  if (!eventId) {
    return;
  }

  const { category, name, ...eventData } = event;
  const categoryName = getCategoryName(category);

  const dataLayer = {
    event: name,
    category: categoryName,
    experience,
    dl_version: "ga4",
    user_id: userInfo.id,
    subscription_id: userInfo.subscriptionId,
    plan_family: userInfo.planFamily,
    plan_period: getPlanPeriodAdjective(userInfo).toLowerCase(),
    primary_shoot_type: userInfo.primaryShootType || null,
    shoot_types: _.isEmpty(userInfo.shootTypes) ? null : userInfo.shootTypes,
    event_id: eventId,
    email_domain: getEmailDomain(userInfo.email),
    ecommerce: null,
    user_type: getUserType(userInfo),
    user_status: getUserStatus(userInfo),
    _clear: true,
    ...eventData,
  };

  logging.log(`GA event data layer [${categoryName}]:`, dataLayer);

  TagManager.dataLayer({
    dataLayer,
  });
}

export async function pushGtmEventAsync<T extends UserEventDetail>(event: T) {
  const { detail, ...others } = event;

  const response = await api.userEvent.pushEvent({
    category: event.category,
    name: event.name,
    detail,
  } as UserEventDetail);

  pushGtmEvent(others as GtmEvent, response);
}
