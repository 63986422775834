import { foldersApi } from "..";
import { ICreateFolderResponse, IFolderContent } from "../../models/folders";
import { executeCancellableRequest } from "../../utilities/helpers";
import * as logging from "../../utilities/logging";
import { experience } from "../../utilities/user";
import {
  map_ICreateFolderResponseDto_To_ICreateFolderResponse,
  map_IFolderContentDto_To_IFolderContent,
} from "./mappers";
import { ICreateFolderResponseDto, IFolderContentDto, ITrackFoldersRequest, TrackFoldersProperties } from "./models";

export interface IApisFolders {
  getFolderContent: (
    folderId: string | undefined,
    includeGalleries: boolean,
    signal?: AbortSignal
  ) => Promise<IFolderContent | void>;
  createFolder: (parentId: string | undefined, name: string) => Promise<ICreateFolderResponse>;
  trackFolders: (request: ITrackFoldersRequest) => Promise<void>;
}

const folders: IApisFolders = {
  getFolderContent: (folderId: string | undefined, includeGalleries: boolean, signal?: AbortSignal) =>
    executeCancellableRequest(
      async (signal) =>
        map_IFolderContentDto_To_IFolderContent(
          (
            await foldersApi.get<IFolderContentDto>("folders/content", {
              params: {
                folderId,
                includeGalleries,
              },
              signal,
            })
          ).data
        ),
      "Getting folder content",
      signal
    ),
  createFolder: async (parentId: string | undefined, name: string) => {
    const response = await foldersApi.post<ICreateFolderResponseDto>("folders", {
      parentId,
      name,
    });

    await trackFolders({
      eventName: "create folder",
      parentId,
      childId: response.data.id,
      trackProperties: TrackFoldersProperties.FolderWithSettings,
      extraProperties: {
        category: "folder",
        child_category: "folder actions",
      },
    });

    return map_ICreateFolderResponseDto_To_ICreateFolderResponse(response.data);
  },
  trackFolders,
};

async function trackFolders(request: ITrackFoldersRequest) {
  try {
    logging.log("Folders tracked:", request);
    await foldersApi.post<void>("folders/track", {
      ...request,
      extraProperties: {
        ...request.extraProperties,
        experience,
      },
    });
  } catch (e) {
    logging.error("Folders tracking failed:", e);
  }
}

export default folders;
