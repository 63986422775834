import { generatePath } from "react-router-dom";
import { OnboardingStep } from "../../../models/onBoarding";

/** Url manager for onboarding steps. */
export default class UrlManager {
  public static buildPath(stepId: OnboardingStep): string {
    let result = generatePath(UrlManager.route, {
      [UrlManager.stepIdParam]: stepId,
    });

    return result;
  }

  public static readonly stepIdParam = "stepId";

  public static readonly prefix = "/welcome";
  public static readonly prefixV2 = "/signup/welcome";

  public static readonly route = `${UrlManager.prefix}/:${UrlManager.stepIdParam}?`;
  public static readonly routeV2 = `${UrlManager.prefixV2}/:${UrlManager.stepIdParam}?`;
}
