import ExpandedActivityMonitor, {
  ActivityMonitorTabs,
} from "@zenfolio/core-components/dist/components/ExpandedActivityMonitor";
import { IActivityMonitorTabProps } from "@zenfolio/core-components/dist/components/ExpandedActivityMonitor/ActivityMonitorTabs/ActivityMonitorTabs";
import MinimizedActivityMonitor from "@zenfolio/core-components/dist/components/MinimizedActivityMonitor";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { timeRemainingTextVerbose } from "../../../store/backgroundJobs/helpers";
import {
  cancelableItemsCountSelector,
  completedItemsCountSelector,
  inProgressItemsCountSelector,
  totalItemCountSelector,
  totalProgressSelector,
  totalTimeRemainingSelector,
  uncancelableItemsCountSelector,
} from "../../../store/backgroundJobs/selectors";
import { Dispatch, RootState } from "../../../store/store";
import { INSTA_UPLOADS_CANCEL_DISCLAIMER } from "../../../utilities/constants";
import styles from "./index.module.scss";
import { completedJobsSelector, failedJobsSelector, queuedJobsSelector } from "./selectors";

const ConnectedActivityMonitorTabs = ({
  handleMenuClick,
  isMobile,
}: Pick<IActivityMonitorTabProps, "handleMenuClick" | "isMobile">) => {
  const queuedJobs = useSelector(queuedJobsSelector);
  const completedJobs = useSelector(completedJobsSelector);
  const failedJobs = useSelector(failedJobsSelector);

  return (
    <ActivityMonitorTabs
      nestedQueuedJobs={queuedJobs}
      nestedCompletedJobs={completedJobs}
      failedJobs={failedJobs}
      handleMenuClick={handleMenuClick}
      isMobile={isMobile}
    />
  );
};

const ActivityMonitorNew = () => {
  const dispatch = useDispatch<Dispatch>();
  const [minimized, setMinimized] = useState(false);
  const totalProgress = useSelector(totalProgressSelector);
  const totalTimeRemaining = useSelector(totalTimeRemainingSelector);
  const totalItemsCount = useSelector(totalItemCountSelector);
  const totalInProgressItemsCount = useSelector(inProgressItemsCountSelector);
  const totalCompletedItemsCount = useSelector(completedItemsCountSelector);
  const cancelableItemsCount = useSelector(cancelableItemsCountSelector);
  const uncancelableItemsCount = useSelector(uncancelableItemsCountSelector);
  const jobsCount = useSelector((state: RootState) => state.backgroundJobs.jobs.length);
  const activityMonitorVisibility = useSelector((state: RootState) => state.activityMonitorVisibility);

  const handleClose = () => {
    dispatch.backgroundJobs.clearJobs();
    dispatch.activityMonitorVisibility.hide();
  };

  return activityMonitorVisibility === "visible" ? (
    <>
      {minimized ? (
        <div className={styles.minimizedActivityMonitorContainer}>
          <MinimizedActivityMonitor
            totalProgress={totalProgress}
            totalItemsCount={totalItemsCount}
            totalCompletedItemsCount={totalCompletedItemsCount}
            handleClose={handleClose}
            handleClick={() => setMinimized(false)}
          />
        </div>
      ) : (
        <div className={styles.expandedActivityMonitorContainer}>
          <button
            tabIndex={-1}
            onClick={() => setMinimized(true)}
            className={styles.hiddenCloseScreen}
            aria-label="close"
          ></button>
          <ExpandedActivityMonitor
            handleMinimize={() => setMinimized(true)}
            handleClose={handleClose}
            handleCancelAll={() => {
              dispatch.backgroundJobs.cancelAllUploadJobsAsync({});
            }}
            handleClearAll={() => {
              dispatch.backgroundJobs.clearJobs();
            }}
            totalTimeRemaining={timeRemainingTextVerbose(totalTimeRemaining)}
            totalItemsCount={totalItemsCount}
            totalInProgressItemsCount={totalInProgressItemsCount}
            totalCompletedItemsCount={totalCompletedItemsCount}
            cancelableItemsCount={cancelableItemsCount}
            jobsCount={jobsCount}
            cancelDisclaimer={uncancelableItemsCount > 0 ? INSTA_UPLOADS_CANCEL_DISCLAIMER : undefined}
            handleCancelJob={(jobId: string) => dispatch.backgroundJobs.cancelJobAsync({ jobId: jobId })}
            isMobile={true}
          >
            {({ handleMenuClick, isMobile }) => (
              <ConnectedActivityMonitorTabs handleMenuClick={handleMenuClick} isMobile={isMobile} />
            )}
          </ExpandedActivityMonitor>
        </div>
      )}
    </>
  ) : null;
};

export default ActivityMonitorNew;
