import classNames from "classnames";
import {
  backgroundGalleryOrWebsitePng,
  backgroundGalleryOrWebsiteWebp,
  backgroundGradientPng,
  backgroundGradientWebp,
} from "../../../icons";
import styles from "./index.module.scss";

type BackgroundType = "gradient" | "galleryOrWebsite";

interface IBackgroundUrl {
  png: string;
  webp: string;
}

interface IBackgroundProps {
  className?: string;
  type?: BackgroundType;
}

const backgroundUrls: Record<BackgroundType, IBackgroundUrl> = {
  gradient: {
    png: backgroundGradientPng,
    webp: backgroundGradientWebp,
  },
  galleryOrWebsite: {
    png: backgroundGalleryOrWebsitePng,
    webp: backgroundGalleryOrWebsiteWebp,
  },
};

function Background(props: IBackgroundProps) {
  const { className, type = "gradient" } = props;
  const urls = backgroundUrls[type];

  return (
    <picture className={classNames(styles.container, className)}>
      <source srcSet={urls.webp} type="image/webp" />
      <img src={urls.png} alt="background" />
    </picture>
  );
}

export default Background;
