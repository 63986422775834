export interface IGeneratedSiteDto {
  id: string;
  layout: string;
  hasTheme?: boolean;
  hasHeader?: boolean;
  hasFooter?: boolean;
  backgroundColor?: string;
  dateCreated: string;
  dateModified?: string;
  pages?: IGeneratedPageDto[];
}

export interface IGeneratedPageDto {
  id: string;
  name: string;
  state: BuildState;
  dateCreated: string;
  dateModified?: string;
}

export enum BuildState {
  None = 0,
  Started = 1,
  Completed = 2,
  Failed = 3,
}

export interface ISiteLayoutCreatedNotificationDto {
  siteId: string;
  siteLayout: string;
}

export interface IPageCreatedNotificationDto {
  pageId: string;
  siteId: string;
  siteLayout: string;
  pageName: string;
}
