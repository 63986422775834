import {
  IFilteredGalleriesResponseDto,
  IFilteredGalleryPreviewDto,
  IFindTopResultsByTextDto,
  ILastViewedResponseDto,
  ITopResultInfoDto,
} from "../api/search/models";
import { GalleryMedia } from "./gallery";

export interface IFindTopResultsByText extends IFindTopResultsByTextDto {}

export interface ILastViewedResponse extends ILastViewedResponseDto {}
export interface ITopResultInfo extends ITopResultInfoDto {}

export interface IFilteredGalleriesResponse extends Omit<IFilteredGalleriesResponseDto, "galleries"> {
  galleries: IFilteredGalleryPreview[];
}

export interface IFilteredGalleryPreview extends Omit<IFilteredGalleryPreviewDto, "topMedia"> {
  topMedia: GalleryMedia[];
}

export enum SearchItemType {
  Galleries = 1,
  GalleriesAndFolders = 3,
}

export interface IFilteredGalleriesRequest extends IGalleryFilter {}

export interface IGalleryFilter extends IBaseFilter {
  shootTypeId?: number;
  orderType: SortOrderType;
  expiration?: ActiveFilterType;
}

interface IBaseFilter {
  query: string;
  fromCreatedDateUtc?: string;
  toCreatedDateUtc?: string;
  skip: number;
  take: number;
}

export enum SortOrderType {
  CreationDateAsc = "creationDateAsc",
  CreationDateDesc = "creationDateDesc",
  AlphabeticalAsc = "alphabeticalAsc",
  AlphabeticalDesc = "alphabeticalDesc",
  ShootDateAsc = "shootDateAsc",
  ShootDateDesc = "shootDateDesc",
  Relevance = "relevance",
}

export enum ActiveFilterType {
  All = "all",
  Active = "active",
  Inactive = "inactive",
}
