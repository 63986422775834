const colors = {
  white: "#ffffff",
  black: "#000000",
  darkGrey: "#1e1f22",
  lightGrey: "#cccccc",
  primaryOrange: "#ff5a00",
  warmGrey: "#f6f5f3",
  fontDark: "#606060",
  fontGrey: "#757575",
  zenTeal: "#068389",
};

export default colors;
