import IIConProps from "../IIconProps";

const BookingIcon = (props: IIConProps) => {
  const color = props.color || "#1E1F22";
  const width = props.width || 24;
  const height = props.width || 24;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23.994 23.994"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M16.996.25a.75.75 0 0 1 .743.648l.007.102-.001.999h2.75a3 3 0 0 1 3 3v15.996a3 3 0 0 1-3 3H5.999a.75.75 0 0 1-.102-1.494l.102-.007h14.496a1.5 1.5 0 0 0 1.493-1.355l.007-.144-.001-12.248H1.999v12.498l.01.105c.023.142.09.312.19.475l6.305-4.674-1.752-.772c-.554-.245-.585-.98-.136-1.296l.096-.058.109-.044 7.233-2.378a.75.75 0 0 1 .895 1.066l-3.598 6.71c-.327.61-1.236.49-1.391-.185l-.445-1.926-6.92 5.129a.75.75 0 0 1-.697.104.754.754 0 0 1-.388-.182C.895 22.774.5 21.95.5 21.245V4.999a3 3 0 0 1 3-3h2.748v-1A.75.75 0 0 1 7.741.899L7.748 1v.999h8.498v-1a.75.75 0 0 1 .75-.75zm.313 9.997a1.313 1.313 0 1 1 0 2.627 1.313 1.313 0 0 1 0-2.627zM6.248 3.5H3.499a1.5 1.5 0 0 0-1.493 1.355L2 5l-.001 2.249h19.995v-2.25a1.5 1.5 0 0 0-1.355-1.492l-.144-.007h-2.75v1.5a.75.75 0 0 1-1.492.102l-.007-.102v-1.5H7.748v1.5a.75.75 0 0 1-1.493.102l-.007-.102v-1.5z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

export default BookingIcon;
