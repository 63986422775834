import IIConProps from "../IIconProps";

const IconBlog = (props: IIConProps) => {
  const color = props.color || "#1E1F22";
  const width = props.width || 30;
  const height = props.width || 24;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 24"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M14 23H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h11l5 5v8"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path stroke={color} strokeWidth="1.5" strokeLinecap="round" d="M5.5 12.5h11M5.5 15.5h11M5.5 18.5h8" />
        <path
          d="M8.861 6.902c.362.097.642.272.841.528.199.255.298.567.298.936 0 .51-.191.91-.574 1.2-.382.29-.909.434-1.579.434H5V4h2.758c.652 0 1.164.138 1.535.413.37.275.556.66.556 1.153 0 .318-.088.593-.263.825a1.498 1.498 0 0 1-.725.511zm-2.74-.374h1.45c.789 0 1.183-.281 1.183-.843 0-.284-.097-.494-.293-.63-.196-.136-.492-.204-.89-.204h-1.45v1.677zm1.601 2.62c.416 0 .718-.07.908-.212.19-.142.285-.363.285-.664 0-.3-.097-.524-.29-.672-.192-.148-.493-.221-.903-.221H6.121v1.77h1.601zM14.75 1v5.25H20v1.5h-6a.75.75 0 0 1-.743-.648L13.25 7V1h1.5z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M20.504 22 17 23l1.001-3.5 7.51-7.5 2.502 2.5-7.51 7.5h0zm3.005-8 2.502 2.498m-8.01 3.002 2.503 2.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IconBlog;
