import IIConProps from "../IIconProps";

const IconCRM = (props: IIConProps) => {
  const color = props.color || "#1E1F22";
  const width = props.width || 24;
  const height = props.width || 24;
  const fill = props.fill || "none";
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g stroke={color} strokeWidth="1.5" fill={fill} fillRule="evenodd" strokeLinejoin="round">
        <path d="M23.5 1.5.5 11l9 3.5z" />
        <path d="m23.5 1.5-4 18.5-10-5.5zM9.5 14.5v8l3.5-6" />
      </g>
    </svg>
  );
};

export default IconCRM;
