import { generatePath } from "react-router-dom";
import { addQueryParams } from "../../../utilities/query";
import { widgetQueryParam } from "../../../utilities/url";

/** Url manager for all galleries and search experience. */
export default class UrlManager {
  public static searchResultPage(searchText: string): string {
    return `${this.searchResultPagePath}?${this.searchTextQueryParam}=${searchText}`;
  }

  public static galleriesPage(action?: string, widget?: boolean): string {
    return addQueryParams(
      generatePath(this.galleriesPagePath, {
        [this.actionParam]: action,
      }),
      {
        [widgetQueryParam]: widget?.toString(),
      }
    );
  }

  public static get searchResultPagePath() {
    return `${this.prefix}/search`;
  }

  public static get galleriesPagePath() {
    return `${this.prefix}/:${this.actionParam}?`;
  }

  public static readonly searchTextQueryParam = "query";

  public static readonly actionParam = "action";
  public static readonly uploadActionParam = "upload";

  public static readonly prefix = `/photos`;
  public static readonly route = `${UrlManager.prefix}/*`;
}
