import { IApplicationInfo } from "../../models/publicApi";
import { GenericState } from "../generic";

export const initialState: IPublicApiState = { connectApplicationById: { status: "Init" } };

/** State for connecting to public API. */
export interface IPublicApiState {
  application?: IApplicationInfo;
  connectApplicationById: ConnectApplicationByIdState;
}

export type ConnectApplicationByIdState = GenericState<IConnectApplicationByIdInput, IConnectApplicationByIdResult>;

export interface IConnectApplicationByIdInput {
  id: string;
  validate: boolean;
}

export interface IConnectApplicationByIdResult {
  apiKey: string;
}
