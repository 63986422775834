import IIConProps from "../IIconProps";

const IconSentToLabStatus = (props: IIConProps) => {
  const color = props.color || "#606060";
  const width = props.width || 32;
  const height = props.width || 32;
  return (
    <svg
      width={width}
      height={height}
      className={props.className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M9 19v9c.037.748.354.962 1 1h16a1 1 0 0 0 1-1V12.619h-3.79a1 1 0 0 1-1-1V8H19"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M22 8h1l4 4v2" />
        <g transform="translate(13.5 15)">
          <path
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m0 7.5 2.813-6 1.687 4 1.688-2L9 7.5z"
          />
          <circle fill={color} cx="6" cy="1" r="1" />
        </g>
        <g stroke={color} strokeLinejoin="round" strokeWidth="1.5">
          <path d="M16 9c0 3.867-3.133 7-7 7s-7-3.133-7-7 3.133-7 7-7 7 3.133 7 7z" />
          <path strokeLinecap="round" d="m10.5 6.5-2 5-2-2" />
        </g>
      </g>
    </svg>
  );
};

export default IconSentToLabStatus;
