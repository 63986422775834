import { IGalleryCoversDto, IGalleryUrlTemplatesDto } from "../gallery/models";

export interface IFolderContentDto {
  folders: IFolderContent_FolderDto[];
  galleries: IFolderContent_GalleryDto[];
  galleriesCount: number;
  id: string;
  name: string;
  parentId: null | string;
}

export interface IFolderContent_FolderDto extends IGalleryCoversDto, IGalleryUrlTemplatesDto {
  id: string;
  name: string;
  foldersCount: number;
  galleriesCount: number;
}

export interface IFolderContent_GalleryDto extends IGalleryUrlTemplatesDto, IGalleryCoversDto {
  id: string;
  name: string;
  photosCount: number;
  videosCount: number;
}

export interface ICreateFolderResponseDto {
  name: string;
  id: string;
}

export enum TrackFoldersProperties {
  None = 0,
  FolderBase = 1 << 0,
  FolderModified = 1 << 1,
  FolderSettings = 1 << 2,
  FolderContent = 1 << 3,
  GalleryBase = 1 << 4,
  GalleryModified = 1 << 5,
  GalleryContacts = 1 << 6,
  FolderWithSettings = FolderBase | FolderSettings,
  FolderFull = FolderWithSettings | FolderModified,
  FolderFullWithContent = FolderFull | FolderContent,
  GalleryFull = GalleryBase | GalleryModified | GalleryContacts,
}

export interface ITrackFoldersRequest {
  eventName: string;
  parentId?: string;
  childId?: string;
  trackProperties: TrackFoldersProperties;
  extraProperties: (
    | {
        category: "folder";
        child_category: "folder actions";
      }
    | {
        category: "gallery";
        child_category:
          | "gallery actions"
          | "gallery info"
          | "gallery content"
          | "gallery contacts"
          | "gallery settings";
      }
  ) & {
    [otherProperties: string]: string | number | null;
  };
}
