import { BookingStatusCode, IBooking, IBookingUpdater, IBookmeState } from "../../models/booking";
import { ISearchContact } from "../../models/contact";
import { GenericState } from "../generic";

export const initialState: IBookingsState = {
  getBookings: { status: "Init" },
  getBooking: { status: "Init" },
  getUpcomingBookings: { status: "Init" },
  getBookingsToApprove: { status: "Init" },
  getBookingsTotal: { status: "Init" },
  approveBooking: { status: "Init" },
  declineBooking: { status: "Init" },
  updateBooking: { status: "Init" },
  cancelBooking: { status: "Init" },
  searchContactByEmail: { status: "Init" },
  getBookmeState: { status: "Init" },
  contact: null,
  upcomingBookings: {
    total: 0,
    bookings: [],
    hasNextPage: true,
  },
  bookingsToApprove: {
    total: 0,
    bookings: [],
    hasNextPage: true,
  },
  bookmeState: {
    welcomeClosed: false,
    welcomeCompleted: false,
  },
};

export interface IBookingsState {
  getBookings: GenericState;
  getBooking: GenericState;
  getUpcomingBookings: GenericState;
  getBookingsToApprove: GenericState;
  getBookingsTotal: GenericState;
  approveBooking: GenericState;
  declineBooking: GenericState;
  updateBooking: GenericState;
  cancelBooking: GenericState;
  searchContactByEmail: GenericState;
  contact: ISearchContact | null;
  getBookmeState: GenericState;
  upcomingBookings: IBookingsData;
  bookingsToApprove: IBookingsData;
  bookmeState: IBookmeState;
}

export interface IBookingsData {
  total: number;
  bookings: IBooking[];
  hasNextPage: boolean;
}

export interface IBookingResult {
  booking: IBooking;
}

export interface IAllBookingsResult {
  upcomingBookings: IBooking[];
  bookingsToApprove: IBooking[];
  totalUpcomingBookings: number;
  totalBookingsToApprove: number;
}

export interface IBookingsParams {
  status: BookingStatusCode;
  name?: string;
  id?: string;
  take?: number;
  skip?: number;
}

export interface IApproveBookingParams {
  bookingId: string;
  note?: string;
}

export interface IDeclineBookingParams {
  bookingId: string;
  note: string;
}

export interface IUpdateBookingParams {
  bookingId: string;
  countryCode: string;
  updater: IBookingUpdater;
}

export interface ICancelBookingParams {
  bookingId: string;
  notifyClient: boolean;
  note?: string;
}

export interface IGetBookingsParams {
  take: number;
  skip: number;
  pageNumber: number;
}
