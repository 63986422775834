import IIConProps from "../IIconProps";

const IconWarning = (props: IIConProps) => {
  const color = props.color || "#F8A645";
  const width = props.width || 16;
  const height = props.width || 16;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14.166"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M13.898 14.166H2.105A2.12 2.12 0 0 1 .28 13.131a2.043 2.043 0 0 1 0-2.068L6.174 1.035A2.12 2.12 0 0 1 7.998 0c.749 0 1.448.396 1.823 1.035l5.827 9.915c.23.339.352.739.352 1.148 0 1.131-.937 2.06-2.103 2.068z"
          fill={color}
        />
        <path
          d="M7.627 9.151h.747a.23.23 0 0 0 .23-.226l.46-5.307a.23.23 0 0 0-.07-.161.226.226 0 0 0-.16-.065H7.165a.227.227 0 0 0-.23.226l.461 5.308c0 .125.103.225.23.225zM8 9.928c-.618 0-1.12.493-1.12 1.1 0 .609.502 1.102 1.12 1.102.619 0 1.121-.493 1.121-1.101S8.619 9.928 8 9.928z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default IconWarning;
