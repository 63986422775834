import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IUserInfo } from "../../models/session";
import AccountSegment from "../../plugins/segment/tracking/account";
import Segment from "../../plugins/segment/tracking/common";

export default function usePageTracker(userInfo: IUserInfo) {
  const { pathname } = useLocation();

  useEffect(() => {
    Segment.trackPageEvent({
      title: pathname,
    });
    AccountSegment.trackUpdateIdentify(userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
}
