import { FC } from "react";

interface IProps {
  size: number;
  color: string;
}

const ProcessingIcon: FC<IProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 32 32">
      <g
        fill="none"
        fillRule="evenodd"
        stroke={props.color}
        strokeLinecap="round"
        strokeWidth="2"
        transform="translate(0.1, -0.1)"
      >
        <path strokeLinejoin="round" d="M21.333 9.333L24.667 13.333 28 9.333" transform="translate(2 5)" />
        <path
          strokeLinejoin="round"
          d="M0 9.333L3.333 13.333 6.667 9.333"
          transform="translate(2 5) rotate(-180 3.333 11.333)"
        />
        <path
          fillRule="nonzero"
          d="M3.2 11.2c0 5.891 4.776 10.667 10.667 10.667 3.72 0 6.996-1.905 8.905-4.793m1.761-5.874c0-5.891-4.775-10.667-10.666-10.667-3.743 0-7.035 1.927-8.938 4.843"
          transform="translate(2 5)"
        />
      </g>
    </svg>
  );
};

export default ProcessingIcon;
