import * as logging from "../utilities/logging";

export const isPendoEnabled = process.env.REACT_APP_ENABLE_PENDO === "true";

export const defaultIdentity = {
  visitor: {
    id: "visitor",
  },
};

export const pendoInit = () => {
  const API_KEY = process.env.REACT_APP_PENDO_API_KEY;

  try {
    if (isPendoEnabled && API_KEY) {
      logging.log("Pendo: start initializing");
      pendo.initialize(defaultIdentity);
    }
  } catch (error) {
    logging.error("Pendo.initialize has error:", error);
  }
};
