import {
  ICreateFolderResponse,
  IFolderContent,
  IFolderContent_Folder,
  IFolderContent_Gallery,
} from "../../models/folders";
import { mapGalleryCover } from "../gallery/mappers";
import {
  ICreateFolderResponseDto,
  IFolderContentDto,
  IFolderContent_FolderDto,
  IFolderContent_GalleryDto,
} from "./models";

export function map_IFolderContentDto_To_IFolderContent(data: IFolderContentDto): IFolderContent {
  return {
    folders: data.folders.map((f) => map_IFolderContent_FolderDto_To_IFolderContent_Folder(f)),
    galleries: data.galleries.map((g) => map_IFolderContent_GalleryDto_To_IFolderContent_Gallery(g)),
    galleriesCount: data.galleriesCount,
    id: data.id,
    name: data.name,
    parentId: data.parentId || undefined,
  };
}

export function map_IFolderContent_FolderDto_To_IFolderContent_Folder(
  folder: IFolderContent_FolderDto
): IFolderContent_Folder {
  const { coverPhoto, coverVideo, ...rest } = folder;

  return {
    ...rest,
    coverMedia: mapGalleryCover(folder),
  };
}

export function map_IFolderContent_GalleryDto_To_IFolderContent_Gallery(
  gallery: IFolderContent_GalleryDto
): IFolderContent_Gallery {
  const { coverPhoto, coverVideo, ...rest } = gallery;

  return {
    ...rest,
    coverMedia: mapGalleryCover(gallery),
  };
}

export function map_ICreateFolderResponseDto_To_ICreateFolderResponse(
  data: ICreateFolderResponseDto
): ICreateFolderResponse {
  return {
    id: data.id,
    name: data.name,
  };
}
