import _ from "lodash";
import { useLayoutEffect, useRef } from "react";

// currently doesn't react to "variables" change, injects variables before first render and cleans up on unmount
export default function useCssVariables(variables: Record<string, string | number>): void {
  const variablesSet = useRef<boolean>(false);
  const originalVariables = useRef<Record<string, string>>({});
  const style = document.documentElement.style;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(onComponentMount, []);

  function onComponentMount() {
    return function onComponentUnmount() {
      restoreVariables();
    };
  }

  function setVariables() {
    if (variablesSet.current) {
      return;
    }

    for (const name in variables) {
      originalVariables.current[name] = style.getPropertyValue(name);
      const rawValue = variables[name];
      const value = _.isNumber(rawValue) && rawValue !== 0 ? `${rawValue}px` : String(rawValue);
      style.setProperty(name, value);
    }

    variablesSet.current = true;
  }

  function restoreVariables() {
    for (const name in originalVariables.current) {
      const value = originalVariables.current[name];
      value ? style.setProperty(name, value) : style.removeProperty(name);
    }
  }

  setVariables();
}
