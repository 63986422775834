import { IFilteredGalleriesResponse, IFilteredGalleryPreview, IFindTopResultsByText } from "../../models/search";
import { mapMedia } from "../gallery/mappers";
import {
  IFilteredGalleriesResponseDto,
  IFilteredGalleryPreviewDto,
  IFindTopResultsByTextDto,
  TopResultType,
} from "./models";

export const map_IFindTopResultsByTextDto_To_IFindTopResultsByText = (
  data: IFindTopResultsByTextDto
): IFindTopResultsByText => {
  return {
    ...data,
    items: data.items.filter((i) => i.type === TopResultType.Gallery),
  };
};

export function map_IFilteredGalleriesResponseDto_To_IFilteredGalleriesResponse(
  data: IFilteredGalleriesResponseDto
): IFilteredGalleriesResponse {
  return {
    ...data,
    galleries: data.galleries.map((g) => map_IFilteredGalleryPreviewDto_To_IFilteredGalleryPreview(g)),
  };
}

export function map_IFilteredGalleryPreviewDto_To_IFilteredGalleryPreview(
  g: IFilteredGalleryPreviewDto
): IFilteredGalleryPreview {
  return {
    ...g,
    topMedia: g.topMedia.map((tm) => mapMedia(g, tm)),
  };
}
