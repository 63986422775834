import classNames from "classnames";
import * as React from "react";
import Spinner from "../Spinner";
import styles from "./index.module.scss";

type ButtonSize = "normal" | "small" | "large";
type ButtonBackground = "light" | "dark";
type ButtonType = "primary" | "secondary" | "plain" | "caution" | "blue" | "error";

const defaultButtonSize = "normal";
const defaultButtonBackground = "light";
const defaultButtonType = "primary";

export interface IButtonProps {
  className?: string;
  disabled?: boolean;
  pending?: boolean;
  canHover?: boolean;
  style?: React.CSSProperties;
  size?: ButtonSize;
  background?: ButtonBackground;
  type?: ButtonType;
  children?: React.ReactNode;
  onClick?: () => void;
}

const spinnerSizes: Record<ButtonSize, number> = {
  small: 21,
  normal: 28,
  large: 32,
};

const Button: React.FC<IButtonProps> = (props) => {
  const {
    className,
    disabled,
    pending,
    canHover,
    style,
    size = defaultButtonSize,
    background = defaultButtonBackground,
    type = defaultButtonType,
    children,
    onClick,
  } = props;

  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles[size]]: size !== defaultButtonSize,
          [styles[background]]: background !== defaultButtonBackground,
          [styles[type]]: type !== defaultButtonType,
          [styles.pending]: pending,
          [styles.canHover]: canHover,
        },
        className
      )}
      disabled={disabled || pending}
      style={style}
      type="button"
      onClick={onClick}
    >
      {pending ? <Spinner size={spinnerSizes[size]} /> : children}
    </button>
  );
};

export default Button;
