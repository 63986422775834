import _ from "lodash";
import { hasAnyErrorType, mediaDeliveryApi } from "..";
import { GalleryWatermark, IWatermark } from "../../models/watermarks";
import { executeCancellableRequest } from "../../utilities/helpers";
import { mapWatermark } from "./mappers";
import { IWatermarkDto } from "./models";

/** API for media watermarks experience. */
export interface IApiWatermarks {
  load: (signal?: AbortSignal) => Promise<IWatermark[] | undefined>;
  getByGalleryId: (galleryId: string, signal?: AbortSignal) => Promise<GalleryWatermark | undefined>;
  updateGalleryWatermark: (galleryId: string, watermarkId: string, position: string) => Promise<void>;
}

const watermarks: IApiWatermarks = {
  load: (signal?: AbortSignal) =>
    executeCancellableRequest(
      async (signal) =>
        _.map((await mediaDeliveryApi.get<IWatermarkDto[]>("/watermarks", { signal })).data, mapWatermark),
      "Getting watermarks",
      signal
    ),
  getByGalleryId: (galleryId: string, signal?: AbortSignal) =>
    executeCancellableRequest<GalleryWatermark>(
      async (signal) => {
        const { data } = await mediaDeliveryApi.get<IWatermarkDto | void>(`/album/${galleryId}/watermark/info`, {
          signal,
        });

        return data ? mapWatermark(data) : "none";
      },
      "Getting gallery watermark",
      signal,
      (e) => (hasAnyErrorType(e, "gallery_not_found_error") ? "default" : undefined)
    ),
  updateGalleryWatermark: async (galleryId: string, watermarkId: string, position: string) => {
    await mediaDeliveryApi.post<void>(`/album/${galleryId}/watermark`, {
      watermarkId,
      position,
      galleryTypeCode: "none",
    });
  },
};

export default watermarks;
