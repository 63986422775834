import { allImageSizes } from "@zenfolio/core-components/dist/utilities/constant";
import {
  IPhotoBase,
  IThumbnailBase,
  IVideoBase,
  MESizeCodes,
  MESizeMethod,
  formatPhotoUrl as formatPhotoUrlCore,
  formatThumbnailUrl as formatVideoThumbnailUrlCore,
} from "@zenfolio/core-components/dist/utilities/getImgUrl";
import _ from "lodash";
import { ISize } from "../models/common";
import { IRenderPhoto, IRenderVideo, IRenderVideoThumbnail } from "../models/renderMedia";

//#region Media Render URLs
export function getPhotoUrl(photo: IRenderPhoto, sizeCode: MESizeCodes, sizeMethod: MESizeMethod): string;
export function getPhotoUrl(photo: IRenderPhoto, areaSize: ISize, sizeMethod: MESizeMethod): string;
export function getPhotoUrl(
  photo: IRenderPhoto,
  sizeCodeOrAreaSize: MESizeCodes | ISize,
  sizeMethod: MESizeMethod
): string {
  return getPhotoUrlImpl(photo, sizeCodeOrAreaSize, sizeMethod);
}

export function getVideoThumbnailUrl(
  videoThumbnail: IRenderVideoThumbnail,
  sizeCode: MESizeCodes,
  sizeMethod: MESizeMethod
): string;
export function getVideoThumbnailUrl(
  videoThumbnail: IRenderVideoThumbnail,
  areaSize: ISize,
  sizeMethod: MESizeMethod
): string;
export function getVideoThumbnailUrl(
  videoThumbnail: IRenderVideoThumbnail,
  sizeCodeOrAreaSize: MESizeCodes | ISize,
  sizeMethod: MESizeMethod
): string {
  return getVideoThumbnailUrlImpl(videoThumbnail, sizeCodeOrAreaSize, sizeMethod);
}

export function getPhotoUrlContain(photo: IRenderPhoto, sizeCode: MESizeCodes): string;
export function getPhotoUrlContain(photo: IRenderPhoto, areaSize: ISize): string;
export function getPhotoUrlContain(photo: IRenderPhoto, sizeCodeOrAreaSize: MESizeCodes | ISize): string {
  return getPhotoUrlImpl(photo, sizeCodeOrAreaSize, MESizeMethod.Contain);
}

export function getPhotoUrlCover(photo: IRenderPhoto, sizeCode: MESizeCodes): string;
export function getPhotoUrlCover(photo: IRenderPhoto, areaSize: ISize): string;
export function getPhotoUrlCover(photo: IRenderPhoto, sizeCodeOrAreaSize: MESizeCodes | ISize): string {
  return getPhotoUrlImpl(photo, sizeCodeOrAreaSize, MESizeMethod.Cover);
}

export function getVideoThumbnailUrlContain(videoThumbnail: IRenderVideoThumbnail, sizeCode: MESizeCodes): string;
export function getVideoThumbnailUrlContain(videoThumbnail: IRenderVideoThumbnail, areaSize: ISize): string;
export function getVideoThumbnailUrlContain(
  videoThumbnail: IRenderVideoThumbnail,
  sizeCodeOrAreaSize: MESizeCodes | ISize
): string {
  return getVideoThumbnailUrlImpl(videoThumbnail, sizeCodeOrAreaSize, MESizeMethod.Contain);
}

export function getVideoThumbnailUrlCover(videoThumbnail: IRenderVideoThumbnail, sizeCode: MESizeCodes): string;
export function getVideoThumbnailUrlCover(videoThumbnail: IRenderVideoThumbnail, areaSize: ISize): string;
export function getVideoThumbnailUrlCover(
  videoThumbnail: IRenderVideoThumbnail,
  sizeCodeOrAreaSize: MESizeCodes | ISize
): string {
  return getVideoThumbnailUrlImpl(videoThumbnail, sizeCodeOrAreaSize, MESizeMethod.Cover);
}

function getPhotoUrlImpl(
  photo: IRenderPhoto,
  sizeCodeOrAreaSize: MESizeCodes | ISize,
  sizeMethod: MESizeMethod
): string {
  const coreRenderPhoto = getCoreRenderPhoto(photo);
  const sizeCode = _.isString(sizeCodeOrAreaSize)
    ? sizeCodeOrAreaSize
    : getSizeCodeForImageInArea(photo.size, sizeCodeOrAreaSize, sizeMethod);
  return formatPhotoUrlCore(photo.urlTemplate, coreRenderPhoto, sizeCode, sizeMethod);
}

function getVideoThumbnailUrlImpl(
  videoThumbnail: IRenderVideoThumbnail,
  sizeCodeOrAreaSize: MESizeCodes | ISize,
  sizeMethod: MESizeMethod
): string {
  const coreVideoThumbnail = getCoreRenderVideoThumbnail(videoThumbnail);
  const sizeCode = _.isString(sizeCodeOrAreaSize)
    ? sizeCodeOrAreaSize
    : getSizeCodeForImageInArea(videoThumbnail.size, sizeCodeOrAreaSize, sizeMethod);
  return formatVideoThumbnailUrlCore(videoThumbnail.urlTemplate, coreVideoThumbnail, sizeCode, sizeMethod);
}
//#endregion

//#region Core Rendering Objects
export function getCoreRenderPhoto(photo: IRenderPhoto): IPhotoBase {
  return {
    id: photo.id,
    name: photo.name,
    photoVersion: photo.version,
    watermarkVersion: photo.watermarkVersion,
  };
}

export function getCoreRenderVideo(video: IRenderVideo): IVideoBase {
  return {
    id: video.id,
    name: video.name,
    thumbnailId: video.thumbnailId,
    durationMilliseconds: video.duration,
    videoVersion: video.version,
  };
}

export function getCoreRenderVideoThumbnail(videoThumbnail: IRenderVideoThumbnail): IThumbnailBase {
  return {
    thumbnailId: videoThumbnail.id,
    name: videoThumbnail.name,
    videoVersion: videoThumbnail.version,
    durationMilliseconds: videoThumbnail.videoDuration,
  };
}
//#endregion

//#region Size Calculations
export function getSizeCodeForImageInAreaContain(imageSize: ISize, areaSize: ISize): MESizeCodes {
  return getSizeCodeForImageInArea(imageSize, areaSize, MESizeMethod.Contain);
}

export function getSizeCodeForImageInAreaCover(imageSize: ISize, areaSize: ISize): MESizeCodes {
  return getSizeCodeForImageInArea(imageSize, areaSize, MESizeMethod.Cover);
}

export function getSizeCodeForImageInArea(imageSize: ISize, areaSize: ISize, sizeMethod: MESizeMethod): MESizeCodes {
  if (sizeMethod === MESizeMethod.MaxPixels) {
    return _.last(allImageSizes)!.code;
  }

  areaSize = getAreaSizeForDevice(areaSize);

  for (const { code: sizeCode } of allImageSizes) {
    const imageSizeForSizeCode = getImageSizeForSizeCode(imageSize, sizeCode);

    const imageIsEqualOrBiggerThanArea =
      (sizeMethod === MESizeMethod.Contain &&
        (imageSizeForSizeCode.width >= areaSize.width || imageSizeForSizeCode.height >= areaSize.height)) ||
      (sizeMethod === MESizeMethod.Cover &&
        imageSizeForSizeCode.width >= areaSize.width &&
        imageSizeForSizeCode.height >= areaSize.height);

    if (imageIsEqualOrBiggerThanArea) {
      return sizeCode;
    }
  }

  return _.last(allImageSizes)!.code;
}

export function getAreaSizeForDevice(areaSize: ISize): ISize {
  const ratio = (typeof window !== "undefined" && window.devicePixelRatio) || 1;
  return { width: areaSize.width * ratio, height: areaSize.height * ratio };
}

export function getImageSizeForSizeCode(imageSize: ISize, sizeCode: MESizeCodes): ISize {
  const size = allImageSizes.find((sc) => sc.code === sizeCode)!;
  return getImageSizeInAreaContain(imageSize, size);
}

export function getImageSizeInAreaContain(imageSize: ISize, areaSize: ISize): ISize {
  return getImageSizeInArea(imageSize, areaSize, MESizeMethod.Contain);
}

export function getImageSizeInAreaCover(imageSize: ISize, areaSize: ISize): ISize {
  return getImageSizeInArea(imageSize, areaSize, MESizeMethod.Cover);
}

export function getImageSizeInArea(
  imageSize: ISize,
  areaSize: ISize,
  sizeMethod: MESizeMethod.Contain | MESizeMethod.Cover
): ISize {
  const imageRatio = imageSize.width / imageSize.height;
  const areaRatio = areaSize.width / areaSize.height;
  const limitedByWidth = sizeMethod === MESizeMethod.Contain ? imageRatio > areaRatio : imageRatio < areaRatio;

  return limitedByWidth
    ? { width: areaSize.width, height: Math.round(areaSize.width / imageRatio) }
    : { height: areaSize.height, width: Math.round(areaSize.height * imageRatio) };
}
//#endregion
