import moment from "moment";
import { FC } from "react";
import { IPromotionCampaign, ISubscription } from "../../../../models/subscription";
import styles from "./index.module.scss";

interface IProps {
  promo: IPromotionCampaign;
  isTrial: boolean;
  subscriptionOverview: ISubscription[] | null;
}

const PromoText: FC<IProps> = (props) => {
  const { promo, isTrial, subscriptionOverview } = props;

  const text = (promo.mobileDescription || promo.description).replaceAll(
    "<X>",
    getDaysLeft(promo, isTrial, subscriptionOverview).toString()
  );

  return (
    <div className={styles.root}>
      {text.split("**").map((item, index) => {
        if (index % 2 === 0) return item;
        return (
          <span key={index} className={styles.underline}>
            {item}
          </span>
        );
      })}
    </div>
  );
};

export default PromoText;

const getDaysLeft = (promo: IPromotionCampaign, isTrialUser: boolean, subscriptionOverview: ISubscription[] | null) => {
  const subscription = subscriptionOverview?.find((subs) => !!subs.planPeriod);
  if (!subscription) return 0;

  const now = moment.utc().startOf("days");
  const created = moment.utc(subscription.dateCreated.toString()).startOf("days");
  const renew = moment.utc(subscription.renewalDate.toString()).startOf("days");

  if (isTrialUser) {
    if (promo.from && promo.to) {
      const daysGone = now.diff(created, "days");
      return promo.to - daysGone;
    }
    return renew.diff(now, "days");
  }

  return moment.utc(promo.endDate).startOf("days").diff(now, "days");
};
