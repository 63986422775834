import useHideToast from "../../hooks/useHideToast";
import useToast from "../../hooks/useToast";
import Toast from "../../ui/Toast";

export default function RootToast() {
  const toast = useToast();
  const hideToast = useHideToast();

  function onToastHide() {
    hideToast();
  }

  return toast ? <Toast key={toast.id} {...toast} onHide={onToastHide} /> : null;
}
