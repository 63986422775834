import { useLayoutEffect } from "react";
import { usePrevious } from "react-use";
import { ActionStatus } from "../../store/common";
import { isActionCompleted } from "../../utilities/helpers";

export default function useActionCompleted(status: ActionStatus, onActionCompleted: () => void) {
  const previousStatus = usePrevious(status);

  function effect() {
    if (previousStatus === "Pending" && isActionCompleted(status)) {
      onActionCompleted();
    }
  }

  useLayoutEffect(effect);
}
