import { MESizeMethod } from "@zenfolio/core-components";
import classNames from "classnames";
import { iconPhotoFiles } from "../../../icons";
import { GalleryCoverMedia } from "../../../models/gallery";
import Thumbnail from "../../features/Gallery/GalleryPage/Media/Thumbnail";
import styles from "./index.module.scss";

export interface ICoverProps {
  cover?: GalleryCoverMedia;
  size: number;
  className?: string;
  style?: React.CSSProperties;
}

function Cover(props: ICoverProps) {
  const { cover, size, className, style } = props;
  const areaSize = { width: size, height: size };

  if (!cover) {
    return (
      <div
        className={classNames(styles.empty, className)}
        style={{ ...areaSize, ...style, backgroundImage: `url(${iconPhotoFiles})` }}
      />
    );
  }

  return (
    <Thumbnail media={cover} areaSize={areaSize} sizeMethod={MESizeMethod.Cover} className={className} style={style} />
  );
}

export default Cover;
