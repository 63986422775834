import { ActionStatus } from "@zenfolio/core-components/dist/models";
import { ChangeEventHandler, FC } from "react";
import { useLocation, useNavigate } from "react-router";
import Modal from "..";
import NotificationsUrlManager from "../../../features/Notifications/UrlManager";
import styles from "./index.module.scss";

interface IApproveAndDeclineBookingProps {
  isApproveModalOpen: boolean;
  closeApproveModal: () => void;
  onApproveBooking: () => void;
  approveBookingStatus: ActionStatus;
  approveNote: string;
  onChangeApproveNote: ChangeEventHandler<HTMLTextAreaElement>;
  isDeclineModalOpen: boolean;
  closeDeclineModal: () => void;
  onDeclineBooking: () => void;
  declineBookingStatus: ActionStatus;
  declineNote: string;
  onChangeDeclineNote: ChangeEventHandler<HTMLTextAreaElement>;
  fullName: string;
}

const ApproveAndDeclineBooking: FC<IApproveAndDeclineBookingProps> = ({
  isApproveModalOpen,
  closeApproveModal,
  onApproveBooking,
  approveBookingStatus,
  approveNote,
  onChangeApproveNote,
  isDeclineModalOpen,
  closeDeclineModal,
  onDeclineBooking,
  declineBookingStatus,
  declineNote,
  onChangeDeclineNote,
  fullName,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onApproveModalClose = (isApproveModalOpen: boolean) => {
    if (
      pathname !== NotificationsUrlManager.bookingsToApproveRoute &&
      approveBookingStatus === "Success" &&
      !isApproveModalOpen
    ) {
      navigate(-1);
    }
  };

  const onDeclineModalClose = (isDeclineModalOpen: boolean) => {
    if (
      pathname !== NotificationsUrlManager.bookingsToApproveRoute &&
      declineBookingStatus === "Success" &&
      !isDeclineModalOpen
    ) {
      navigate(-1);
    }
  };

  return (
    <>
      <Modal.Dialog
        header="Approve Booking"
        okText="Confirm"
        cancelText="Cancel"
        open={isApproveModalOpen}
        onClose={closeApproveModal}
        onOk={onApproveBooking}
        cancelProps={{ type: "plain" }}
        okPending={approveBookingStatus === "Pending"}
        onAnimationEnd={onApproveModalClose}
      >
        <div className={styles.approveContent}>
          <p className={styles.title}>Great news!</p>
          <p className={styles.subTitle}>Approving this request will add it to your calendar.</p>
          <p className={styles.noteTitle}>{"Note to client (optional)"}</p>
          <textarea
            className={styles.note}
            value={approveNote}
            onChange={onChangeApproveNote}
            placeholder="Add a note to you client…"
          />
        </div>
      </Modal.Dialog>
      <Modal.Dialog
        header="Decline Booking"
        okText="Confirm"
        cancelText="Cancel"
        open={isDeclineModalOpen}
        onClose={closeDeclineModal}
        onOk={onDeclineBooking}
        okProps={{
          disabled: !declineNote,
        }}
        okPending={declineBookingStatus === "Pending"}
        cancelProps={{ type: "plain" }}
        onAnimationEnd={onDeclineModalClose}
      >
        <div className={styles.cancelContent}>
          <p className={styles.subTitle}>This will decline your photoshoot request from {fullName}</p>
          <p className={styles.noteTitle}>{"Note to client"}</p>
          <textarea
            className={styles.note}
            value={declineNote}
            onChange={onChangeDeclineNote}
            placeholder="Add a note to you client…"
          />
        </div>
      </Modal.Dialog>
    </>
  );
};

export default ApproveAndDeclineBooking;
