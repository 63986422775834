import { ISize } from "./common";

export enum PhotoDownloadResolutionCode {
  Original = 0,
  High10MP = 3872,
  High8MP = 3264,
  Medium5MP = 2560,
  Medium3MP = 2048,
  Low1MP = 1280,
  Low05MP = 800,
  Low03MP = 640,
}

export enum VideoDownloadResolutionCode {
  Original = 1,
  FullHD1080p = 0,
}

export type MediaDownloadResolutionCode = PhotoDownloadResolutionCode | VideoDownloadResolutionCode;

export interface IDownloadResolutionsCodes {
  photo?: PhotoDownloadResolutionCode;
  video?: VideoDownloadResolutionCode;
}

export interface IPhotoDownloadResolution {
  code: PhotoDownloadResolutionCode;
  name: string;
  megapixels: number;
  size: number /* of both dimensions in pixels */;
}

export interface IVideoDownloadResolution {
  code: VideoDownloadResolutionCode;
  name: string;
  size: ISize;
}

export interface IDownloadResolutions {
  photo?: IPhotoDownloadResolution;
  video?: IVideoDownloadResolution;
}

export type MediaDownloadResolution = IPhotoDownloadResolution | IVideoDownloadResolution;

export function getPhotoDownloadResolution(code: PhotoDownloadResolutionCode): IPhotoDownloadResolution {
  return photoDownloadResolutions.find((resolution) => resolution.code === code)!;
}

export function getVideoDownloadResolution(code: VideoDownloadResolutionCode): IVideoDownloadResolution {
  return videoDownloadResolutions.find((resolution) => resolution.code === code)!;
}

function getPhotoDownloadResolutionName(size: number) {
  return size === 0
    ? "Original Size"
    : `${
        size <= PhotoDownloadResolutionCode.Low1MP
          ? "Small"
          : size <= PhotoDownloadResolutionCode.Medium5MP
          ? "Medium"
          : "High"
      } (up to ${size} x ${size})`;
}

function getVideoDownloadResolutionName(size: ISize) {
  return size.width === 0 ? "Original Size" : `${size.height}p (${size.width} x ${size.height})`;
}

export const photoDownloadResolutions: IPhotoDownloadResolution[] = [
  { code: PhotoDownloadResolutionCode.Low03MP, megapixels: 0.3 },
  { code: PhotoDownloadResolutionCode.Low05MP, megapixels: 0.5 },
  { code: PhotoDownloadResolutionCode.Low1MP, megapixels: 1 },
  { code: PhotoDownloadResolutionCode.Medium3MP, megapixels: 3 },
  { code: PhotoDownloadResolutionCode.Medium5MP, megapixels: 5 },
  { code: PhotoDownloadResolutionCode.High8MP, megapixels: 8 },
  { code: PhotoDownloadResolutionCode.High10MP, megapixels: 10 },
  { code: PhotoDownloadResolutionCode.Original, megapixels: 0 },
].map((v) => ({ ...v, size: v.code, name: getPhotoDownloadResolutionName(v.code) }));

export const videoDownloadResolutions: IVideoDownloadResolution[] = [
  { code: VideoDownloadResolutionCode.FullHD1080p, size: { width: 1920, height: 1080 } },
  { code: VideoDownloadResolutionCode.Original, size: { width: 0, height: 0 } },
].map((v) => ({ ...v, name: getVideoDownloadResolutionName(v.size) }));
