import { foldersApi } from "..";
import { ICounters, IGetRecentGalleriesQuery, IRecentGalleries } from "../../models/galleries";
import { map_IRecentGalleriesDto_To_IRecentGalleries } from "./mappers";
import { ICountersDto, IRecentGalleriesDto } from "./models";

/** API for galleries page experience. */
export interface IApiGalleries {
  getRecentGalleries: (query: IGetRecentGalleriesQuery) => Promise<IRecentGalleries>;
  getCounters: () => Promise<ICounters>;
}

const galleries: IApiGalleries = {
  getRecentGalleries: (query: IGetRecentGalleriesQuery) => {
    return foldersApi
      .get<IRecentGalleriesDto>(`/galleries/recent?type=${query.type}&skip=${query.skip}&take=${query.take}`)
      .then((response) => map_IRecentGalleriesDto_To_IRecentGalleries(response.data));
  },
  getCounters: () => foldersApi.get<ICountersDto>("total").then((response) => response.data),
};

export default galleries;
