import { useLayoutEffect } from "react";
import { usePrevious } from "react-use";
import { ActionStatus } from "../../store/common";

export default function useActionSuccessfullyCompleted(
  status: ActionStatus,
  onActionSuccessfullyCompleted: () => void
) {
  const previousStatus = usePrevious(status);

  function effect() {
    if (previousStatus === "Pending" && status === "Success") {
      onActionSuccessfullyCompleted();
    }
  }

  useLayoutEffect(effect);
}
