import { Utilities } from "@zenfolio/core-components";
import cx from "classnames";
import _ from "lodash";
import { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { useMount } from "react-use";
import { IToast, toastDefaults } from "../../../models/toast";
import { StrictOmit } from "../../../types";
import useCssVariables from "../../hooks/useCssVariables";
import { useSimpleTimeout } from "../../hooks/useTimeout";
import styles from "./index.module.scss";

export type ToastProps = StrictOmit<IToast, "id"> & {
  onHide?: () => void;
};

export default function Toast(props: ToastProps) {
  const { content, type, duration, className, style } = _.defaults(props, toastDefaults);

  useCssVariables({ "--zf-toast-transition-duration": `${ui.transitionDuration}ms` });

  const containerRef = useRef<HTMLDivElement>(null);
  const [hide, setHide] = useState<boolean>(false);
  const [setHideToastTimeout] = useSimpleTimeout();

  useMount(onComponentMount);

  function onComponentMount() {
    setHideToastTimeout(() => setHide(true), duration);
  }

  function onContainerTransitionExited() {
    props.onHide?.();
  }

  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={containerRef}
      in={!hide}
      appear={true}
      mountOnEnter={true}
      unmountOnExit={true}
      timeout={ui.transitionDuration}
      classNames={Utilities.getTransitionClassNames(styles, "fade")}
      onExited={onContainerTransitionExited}
    >
      <div ref={containerRef} className={cx(styles.container, styles[type], styles.fade, className)} style={style}>
        {content}
      </div>
    </CSSTransition>,
    document.body
  );
}

const ui = { transitionDuration: 200 };
