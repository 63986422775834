import { MESizeMethod, NzVideo } from "@zenfolio/core-components";
import { IAnalyticParams } from "@zenfolio/core-components/dist/components/NzVideo/Player";
import { IVideoPlayer } from "@zenfolio/core-components/dist/components/Video/Player";
import { ImagePlacement } from "@zenfolio/core-components/dist/components/Video/Thumbnail";
import { memo } from "react";
import { cautionRed } from "../../../../../../icons";
import { ISize } from "../../../../../../models/common";
import { GalleryMedia, IFocusable, IGalleryVideo } from "../../../../../../models/gallery";
import { IRenderPhoto, IRenderVideoThumbnail } from "../../../../../../models/renderMedia";
import colors from "../../../../../../utilities/colors";
import { configureArePropsEqual } from "../../../../../../utilities/components";
import {
  getGalleryPhotoRender,
  getGalleryVideoRender,
  getGalleryVideoThumbnailRender,
  isGalleryPhoto,
  isGalleryVideo,
  isMediaProcessed,
  resolveGalleryMediaName,
} from "../../../../../../utilities/gallery";
import {
  getCoreRenderVideo,
  getCoreRenderVideoThumbnail,
  getImageSizeInAreaContain,
  getPhotoUrlContain,
  getSizeCodeForImageInArea,
} from "../../../../../../utilities/renderMedia";

export interface IThumbnailProps {
  media: GalleryMedia & Partial<IFocusable>;
  areaSize: ISize;
  sizeMethod?: MESizeMethod.Contain | MESizeMethod.Cover;
  videoPlayable?: boolean;
  videoAnalyticsParams?: IAnalyticParams; // this should be set if `videoPlayable` is `true`
  className?: string;
  style?: React.CSSProperties;
  videoPlayerRef?: React.Ref<IVideoPlayer>;
  onClick?: () => void;
  onVideoStart?: () => void;
  onVideoStop?: () => void;
}

function Thumbnail(props: IThumbnailProps) {
  const { media, areaSize, sizeMethod = MESizeMethod.Contain, videoPlayable, videoAnalyticsParams } = props;
  const { className, style, videoPlayerRef } = props;

  const isPhoto = isGalleryPhoto(media);
  const mediaProcessed = isMediaProcessed(media);
  if (!mediaProcessed) {
    return isPhoto || media.error ? (
      <div
        className={className}
        style={{
          ...areaSize,
          backgroundColor: colors.warmGrey,
          ...(media.error
            ? { backgroundImage: `url(${cautionRed})`, backgroundPosition: "center", backgroundRepeat: "no-repeat" }
            : {}),
          ...style,
        }}
        onClick={props.onClick}
      />
    ) : (
      <NzVideo.Thumbnail
        thumbnail={{ name: resolveGalleryMediaName(media) }}
        className={className}
        style={{ ...areaSize, ...style }}
        onClick={props.onClick}
      />
    );
  }

  const renderPhoto: IRenderPhoto | undefined = isPhoto ? getGalleryPhotoRender(media) : undefined;
  const renderVideoThumbnail: IRenderVideoThumbnail | undefined = isGalleryVideo(media)
    ? getGalleryVideoThumbnailRender(media)
    : undefined;
  const imageSize = (renderPhoto ?? renderVideoThumbnail)!.size;
  const sizeCode = getSizeCodeForImageInArea(imageSize, areaSize, sizeMethod);
  const renderSize = sizeMethod === MESizeMethod.Contain ? getImageSizeInAreaContain(imageSize, areaSize) : areaSize;
  const focalPoint = "focalPoint" in media ? media.focalPoint : undefined;

  if (renderPhoto) {
    return (
      <img
        src={getPhotoUrlContain(renderPhoto, sizeCode)}
        alt={resolveGalleryMediaName(media)}
        className={className}
        style={{
          ...renderSize,
          ...style,
          ...(sizeMethod === MESizeMethod.Cover ? { objectFit: "cover", objectPosition: "center" } : {}),
          ...(sizeMethod === MESizeMethod.Cover && focalPoint
            ? { objectPosition: `${focalPoint.x}% ${focalPoint.y}%` }
            : {}),
        }}
        onClick={props.onClick}
      />
    );
  } else {
    const thumbnailOverlay = {
      duration:
        renderSize.width >= videoThumbnailOverlayDurationSizeThreshold &&
        renderSize.height >= videoThumbnailOverlayDurationSizeThreshold,
    };
    const commonProps = {
      thumbnailUrlTemplate: renderVideoThumbnail!.urlTemplate,
      imagePlacement: (focalPoint
        ? { focalPoint }
        : sizeMethod === MESizeMethod.Contain
        ? "contain"
        : "cover") as ImagePlacement,
      forcedSizeCode: sizeCode,
      forcedSizeMethod: focalPoint ? MESizeMethod.Contain : undefined,
      className,
      style: { ...renderSize, ...style },
    };
    return videoPlayable ? (
      <NzVideo
        {...commonProps}
        ref={videoPlayerRef}
        video={getCoreRenderVideo(getGalleryVideoRender(media as IGalleryVideo))}
        videoUrlTemplate={media.urlTemplate}
        thumbnailOverlay={thumbnailOverlay}
        playable={true}
        analyticsParams={videoAnalyticsParams}
        onThumbnailClick={props.onClick}
        onVideoClick={props.onClick}
        onStart={props.onVideoStart}
        onStop={props.onVideoStop}
      />
    ) : (
      <NzVideo.Thumbnail
        {...commonProps}
        thumbnail={getCoreRenderVideoThumbnail(renderVideoThumbnail!)}
        overlay={thumbnailOverlay}
        onClick={props.onClick}
      />
    );
  }
}

const videoThumbnailOverlayDurationSizeThreshold: number = 96;

const arePropsEqual = configureArePropsEqual<IThumbnailProps>({
  media: false,
  areaSize: true,
  sizeMethod: false,
  videoPlayable: false,
  videoAnalyticsParams: true,
  className: false,
  videoPlayerRef: false,
  style: true,
  onClick: false,
  onVideoStart: false,
  onVideoStop: false,
});

export default memo(Thumbnail, arePropsEqual);
