import { identityApi } from "..";
import { IEmailRecovery, IResetPassword } from "../../store/resetPassword/models";
import { IAuthorizeInstagramDto, ICheckEmailPBExistsDto, IAppExistsAccountsDto, IResetPasswordDto } from "./models";

export interface IApiIdentities {
  getAppAccountExists: (email: IEmailRecovery) => Promise<IAppExistsAccountsDto>;
  getAppAccountExistsPB: (email: IEmailRecovery) => Promise<IAppExistsAccountsDto>;
  checkEmailPBExists: (email: string) => Promise<ICheckEmailPBExistsDto>;
  sendEmailRecovery: (emailRecovery: IEmailRecovery) => Promise<string>;
  validateTicket: (email: string) => Promise<string>;
  resetPassword: (data: IResetPassword) => Promise<IResetPasswordDto>;
  authorizeInstagram: (redirectUri: string) => Promise<IAuthorizeInstagramDto>
}

const identities: IApiIdentities = {
  getAppAccountExists: async(emailRecovery: IEmailRecovery) => {
    return (await identityApi.post("account/email/check", emailRecovery)).data;
  },
  getAppAccountExistsPB: (emailRecovery: IEmailRecovery) => {
    const config = {
      headers: {
        "X-APP-CODE": "Photographer",
      },
    };
    return identityApi.post("account/email/check", emailRecovery, config);
  },
  checkEmailPBExists: async (email: string) => {
    const config = {
      headers: {
        "X-APP-CODE": "Photographer",
      },
    };
    return (await identityApi.get(`email/check/${email}`, config)).data;
  },
  sendEmailRecovery: (emailRecovery: IEmailRecovery) => {
    return identityApi.post("password/recovery/manual", emailRecovery);
  },
  validateTicket: (ticket: string) => {
    return identityApi.get(`password/ticket/${ticket}`)
  },
  resetPassword: (data: IResetPassword) => {
    return identityApi.post("password/reset", data);
  },
  authorizeInstagram: async(redirectUri: string) => {
    return (await identityApi.post("instagram/auth", {
      redirectUri,
      applicationType: "nextZen",
    })).data;
  },
};

export default identities;
