import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { addGaEventIdHeader, foldersApi, mediaHubApi, mediaUploadApi, videoUploadApi } from "..";
import * as logging from "../../utilities/logging";
import {
  ICheckGalleryDuplicatesDto,
  IFilesSortedByNaturalOrderDto,
  IGetVideoUploadUri,
  IGetVideoUploadUriDto,
  IInstagramUploadRequest,
  IUploadPhotoDto,
} from "./models";

/** API for media upload experience. */
export interface IApiUpload {
  getFilesSortedByNaturalOrder: (fileNames: string[], descending: boolean) => Promise<number[]>;
  checkGalleryDuplicates: (galleryId: string, fileNames: string[]) => Promise<string[]>;
  uploadPhoto: (formData: FormData, config: AxiosRequestConfig) => AxiosPromise<IUploadPhotoDto>;
  uploadVideo: (uri: string, file: File, config: AxiosRequestConfig) => AxiosPromise<void>;
  cancelVideoUpload: (galleryId: string, videoId: string) => AxiosPromise<void>;
  getVideoUploadUri: (request: IGetVideoUploadUri) => AxiosPromise<IGetVideoUploadUriDto>;
  uploadInstagramPhotos: (request: IInstagramUploadRequest) => AxiosPromise<string>;
}

const upload: IApiUpload = {
  getFilesSortedByNaturalOrder: async (fileNames: string[], descending: boolean) => {
    try {
      const response = await foldersApi.put<IFilesSortedByNaturalOrderDto>("/album/sort/files", {
        fileNames,
        descending,
      });
      return response.data.sortIndices;
    } catch (e) {
      logging.error("Getting files sorted by natural order failed with error:", e);
      return [];
    }
  },
  checkGalleryDuplicates: async (galleryId: string, fileNames: string[]) => {
    try {
      const response = await foldersApi.put<ICheckGalleryDuplicatesDto>("/albums/duplicates", {
        albumId: galleryId,
        fileNames,
      });
      return response.data.duplicatedFileNames;
    } catch (e) {
      logging.error("Checking gallery duplicates failed with error:", e);
      return [];
    }
  },
  uploadPhoto: (formData: FormData, config: AxiosRequestConfig) => {
    config.headers = {
      ...config.headers,
      "Content-Type": "multipart/form-data",
    };
    return mediaUploadApi.post<IUploadPhotoDto>("/photo", formData, addGaEventIdHeader(config));
  },
  uploadVideo: (uri: string, file: File, config: AxiosRequestConfig) => {
    const http = axios.create({
      headers: {
        "Content-Type": file.type,
      },
    });

    return http.put(uri, file, config);
  },
  cancelVideoUpload: (galleryId: string, videoId: string) => {
    return foldersApi.post("/videos/cancel", { galleryId, videoId });
  },
  getVideoUploadUri: (request: IGetVideoUploadUri) => {
    return videoUploadApi.post<IGetVideoUploadUriDto>("/video/uploadUri", request);
  },
  uploadInstagramPhotos: (request: IInstagramUploadRequest) => {
    return mediaHubApi.post<string>("/transport", request);
  },
};

export default upload;
