import { useDispatch } from "react-redux";
import { Dispatch } from "../../store/store";

export default function useHideToast() {
  const dispatch = useDispatch<Dispatch>();

  function hideToast() {
    dispatch.toast.hideToast();
  }

  return hideToast;
}
