import { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "../../../store/store";

const mapDispatch = (dispatch: Dispatch) => ({
  setPageLoading: () => dispatch.session.setPageLoading(),
});

type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = DispatchProps & ILazyProps;

interface ILazyProps {
  Component: React.ComponentType;
}

function Lazy(props: Props) {
  const { Component, setPageLoading } = props;
  const [mountedComponent, setMountedComponent] = useState<React.ComponentType>();

  useEffect(() => {
    setPageLoading();
    setMountedComponent(Component);
  }, [setPageLoading, Component]);

  return <Suspense>{mountedComponent === Component && <Component />}</Suspense>;
}

export default connect(null, mapDispatch)(Lazy);
