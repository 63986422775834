import { FC } from "react";

interface IProps {
  size: number;
  color: string;
}

const FaceRecognitionIcon: FC<IProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 16 16">
      <path
        fill={props.color}
        d="M14.875 10.9c.232 0 .424.169.454.387l.004.061v1.914c0 .743-.59 1.352-1.339 1.401l-.098.004h-1.957c-.254 0-.459-.201-.459-.449 0-.227.173-.414.397-.444l.062-.004h1.957c.265 0 .484-.194.516-.445l.004-.063v-1.914c0-.247.205-.448.459-.448zm-13.75 0c.233 0 .425.169.455.387l.004.061v1.914c0 .259.198.472.455.504l.065.004h1.957c.254 0 .459.2.459.448 0 .227-.173.415-.397.445l-.062.004H2.104c-.76 0-1.383-.578-1.434-1.31l-.003-.095v-1.914c0-.247.205-.448.458-.448zm6.792-8.428c2.53 0 4.595 1.95 4.708 4.396l.004.211v1.957c0 2.544-2.11 4.606-4.712 4.606-2.53 0-4.595-1.949-4.707-4.395l-.005-.211V7.079c0-2.544 2.11-4.607 4.712-4.607zM9.32 5.703c-.532.537-.344 2.11-3.847 1.131-.534-.26-.961-.208-1.281.157l-.07.088v1.957c0 2.049 1.7 3.71 3.795 3.71 2.028 0 3.685-1.556 3.79-3.513l.005-.197v-1.65c-.946.189-1.743-.372-2.392-1.683zm.156 4.107c.253 0 .459.201.459.449 0 .835-.908 1.427-1.96 1.427-1.053 0-1.96-.592-1.96-1.427 0-.248.205-.449.459-.449.232 0 .424.17.454.388l.004.06c0 .246.437.53 1.043.53.57 0 .99-.251 1.038-.485l.004-.044c0-.248.206-.449.46-.449zm-2.991-2.2c.27 0 .49.219.49.489v.49c0 .27-.22.488-.49.488h-.023c-.27 0-.489-.219-.489-.489v-.49c0-.27.22-.488.49-.488h.022zm3.002 0c.27 0 .49.219.49.489v.49c0 .27-.22.488-.49.488h-.022c-.27 0-.49-.219-.49-.489v-.49c0-.27.22-.488.49-.488h.022zM4.061 1.333c.254 0 .459.201.459.449 0 .227-.173.414-.397.444l-.062.004H2.104c-.265 0-.484.194-.516.445l-.004.063v1.914c0 .247-.205.448-.459.448-.232 0-.424-.169-.454-.387l-.004-.061V2.738c0-.743.59-1.352 1.339-1.401l.098-.004h1.957zm9.835 0c.76 0 1.383.578 1.434 1.31l.003.095v1.914c0 .247-.205.448-.458.448-.233 0-.425-.169-.455-.387l-.004-.061V2.738c0-.259-.198-.472-.455-.504l-.065-.004h-1.957c-.254 0-.459-.2-.459-.448 0-.227.173-.415.397-.445l.062-.004h1.957z"
      />
    </svg>
  );
};

export default FaceRecognitionIcon;
