import _ from "lodash";
import { IPresetBase } from "../../models/presets";
import { parseDate } from "../../utilities/date";
import { IPresetBaseDto, IPresetsDto } from "./models";

function mapPresetBase(preset: IPresetBaseDto): IPresetBase {
  return {
    id: preset.id,
    name: preset.name,
    lastUpdated: parseDate(preset.lastUpdated),
    shootTypeId: preset.shootTypeId,
    isDefault: preset.isDefault,
    isCustom: preset.isCustom,
  };
}

export function mapPresetsBase(response: IPresetsDto): IPresetBase[] {
  return _.map(response.presets, mapPresetBase);
}
