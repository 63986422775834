import { createModel } from "@rematch/core";
import _ from "lodash";
import moment from "moment";
import api from "../../api";
import { mapGalleryUploadNotification, mapGalleryUrlTemplates } from "../../api/gallery/mappers";
import { IGalleryUploadNotificationDto } from "../../api/gallery/models";
import { IPageCreatedNotificationDto, ISiteLayoutCreatedNotificationDto } from "../../api/siteEngine/models";
import { IInitialGalleriesInfo, IInitialGallery, IStep, OnboardingStep } from "../../models/onBoarding";
import { IGeneratedSite } from "../../models/siteEngine";
import { GtmEventCategory } from "../../models/userEvent";
import OnBoardingSegment from "../../plugins/segment/tracking/onBoarding";
import { getDefaultBusinessName } from "../../utilities/businessName";
import { updateGalleryMedias } from "../../utilities/gallery";
import { pushGtmEventAsync } from "../../utilities/gtm";
import {
  clearSitesGenerationData,
  enableAiWebsiteCreation,
  getSitesGenerationDataValue,
  getUploadPhotosInfo,
  removeUploadPhotosInfo,
} from "../../utilities/onBoarding";
import { getErrorFromApi } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import {
  ICreateInitialGalleryInput,
  ICreateInitialGalleryResult,
  IGetOnBoardingProcessResult,
  IOnBoardingState,
  IStepsData,
  initialState,
  virtualSteps,
} from "./models";

export const onBoarding = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...getGenericReducers("getOnBoardingProcess")<IOnBoardingState, {}, IGetOnBoardingProcessResult>({
      success: (state, payload) => {
        const { onBoardingProcess, userInfo, sites } = payload;

        return state.getOnBoardingProcess.status === "Pending"
          ? initSitesGenerationStartedAt({
              ...state,
              getOnBoardingProcess: { status: "Success" },
              onBoardingProcess: {
                ...onBoardingProcess,
                steps: [
                  ...onBoardingProcess.steps,
                  ...state.onBoardingProcess.steps.filter((s) => virtualSteps.includes(s.id)),
                ],
              },
              sites,
              stepsData: {
                businessName:
                  onBoardingProcess.steps.find((s) => s.id === OnboardingStep.BusinessNameAndPassword)?.stepAnswers[0]
                    ?.value || getDefaultBusinessName(userInfo),
                password: "",
                shootTypes: getShootTypes(onBoardingProcess.steps),
                goals: onBoardingProcess.steps
                  .find((s) => s.id === OnboardingStep.Goals)!
                  .stepAnswers.map((sa) => sa.answerOptionId),
                primaryShootTypeId: getValidPrimaryShootType(onBoardingProcess.steps),
              },
            })
          : state;
      },
    }),
    ...getGenericReducers("createInitialGallery")<
      IOnBoardingState,
      ICreateInitialGalleryInput,
      ICreateInitialGalleryResult
    >({
      success: (state, payload) => ({ galleriesInfo: { ...state.galleriesInfo!, gallery: payload.gallery } }),
    }),
    setStepsData(state, stepsData: IStepsData) {
      return {
        ...state,
        stepsData,
      };
    },
    setStepIsAnswered(state: IOnBoardingState, payload: { stepId: OnboardingStep; isAnswered: boolean }) {
      const steps = state.onBoardingProcess.steps.map((s) => {
        if (s.id === payload.stepId) {
          return {
            ...s,
            isAnswered: payload.isAnswered,
          };
        } else {
          return s;
        }
      });

      return {
        ...state,
        onBoardingProcess: {
          ...state.onBoardingProcess,
          steps,
        },
      };
    },
    setInitialGalleryInfo(state, galleriesInfo: IInitialGalleriesInfo) {
      return {
        ...state,
        galleriesInfo,
      };
    },
    initSites(state) {
      return !state.sites
        ? initSitesGenerationStartedAt({
            ...state,
            sites: [],
          })
        : state;
    },
    clearSites(state) {
      clearSitesGenerationData();

      return {
        ...state,
        sites: undefined,
        sitesGenerationStartedAt: 0,
      };
    },
    siteLayoutCreated(state, payload: ISiteLayoutCreatedNotificationDto) {
      const siteIndex = _.findIndex(state.sites, (s) => s.id === payload.siteId);
      if (siteIndex >= 0 && state.sites![siteIndex].ready) {
        return state;
      }

      const update = { ready: true, dateModified: new Date() };

      const sites = state.sites ? [...state.sites] : [];

      if (siteIndex >= 0) {
        sites[siteIndex] = { ...sites[siteIndex], ...update };
      } else {
        sites.push({ id: payload.siteId, layout: payload.siteLayout, pages: [], ...update });
      }

      return initSitesGenerationStartedAt({
        ...state,
        sites,
      });
    },
    pageCreated(state, payload: IPageCreatedNotificationDto) {
      const siteIndex = _.findIndex(state.sites, (s) => s.id === payload.siteId);
      const pageIndex =
        siteIndex >= 0 ? _.findIndex(state.sites![siteIndex].pages, (p) => p.id === payload.pageId) : -1;

      if (pageIndex >= 0 && state.sites![siteIndex].pages[pageIndex].ready) {
        return state;
      }

      const siteUpdate = { dateModified: new Date() };
      const pageUpdate = { ready: true, name: payload.pageName, ...siteUpdate };

      let site: IGeneratedSite;
      const sites = state.sites ? [...state.sites] : [];

      if (siteIndex >= 0) {
        site = { ...sites[siteIndex], pages: [...sites[siteIndex].pages], ...siteUpdate };
        sites[siteIndex] = site;
      } else {
        site = { id: payload.siteId, layout: payload.siteLayout, ready: false, pages: [], ...siteUpdate };
        sites.push(site);
      }

      if (pageIndex >= 0) {
        site.pages[pageIndex] = { ...site.pages[pageIndex], ...pageUpdate };
      } else {
        site.pages.push({ id: payload.pageId, ...pageUpdate });
      }

      return initSitesGenerationStartedAt({
        ...state,
        sites,
      });
    },
    processUploadNotification(state, payload: IGalleryUploadNotificationDto) {
      const gallery = state.galleriesInfo?.gallery;

      if (gallery?.id !== payload.albumId) {
        return state;
      }

      const notification = mapGalleryUploadNotification(payload, gallery);
      const newMedias = updateGalleryMedias(gallery.medias, notification);

      return newMedias
        ? {
            ...state,
            galleriesInfo: {
              ...state.galleriesInfo!,
              gallery: { ...gallery, medias: newMedias },
            },
          }
        : state;
    },
  },
  effects: (dispatch) => ({
    async createInitialGalleryAsync(payload: ICreateInitialGalleryInput, state) {
      dispatch.onBoarding.createInitialGalleryStarted(payload);
      try {
        let gallery: IInitialGallery;

        if (payload.isSample) {
          gallery = await api.onBoarding.addOrUpdateSampleGallery(payload.shootTypeId);
        } else {
          const result = await api.gallery.create({
            parentId: state.onBoarding.galleriesInfo!.rootId,
            name: "My Gallery",
            tags: [],
            photoShootType: payload.shootTypeId,
            skipPresetWatermark: false,
            isOnBoarding: true,
          });

          gallery = {
            id: result.id,
            name: result.name,
            medias: [],
            isSample: false,
            ...mapGalleryUrlTemplates(result),
          };
        }

        if (state.onBoarding.sites && enableAiWebsiteCreation()) {
          await api.siteEngine.cancelSites();
          dispatch.onBoarding.clearSites();
        }

        dispatch.onBoarding.createInitialGallerySuccess({ ...payload, gallery });
      } catch (error) {
        dispatch.onBoarding.createInitialGalleryError({ ...payload, error: getErrorFromApi(error) });
      }
    },
    async getOnboardingProcessAsync(_: void, state) {
      dispatch.onBoarding.getOnBoardingProcessStarted({});

      try {
        const data = await Promise.all([
          api.onBoarding.getOnboardingProcess(),
          enableAiWebsiteCreation() ? api.siteEngine.getSites() : Promise.resolve(undefined),
        ]);
        dispatch.onBoarding.getOnBoardingProcessSuccess({
          onBoardingProcess: data[0],
          sites: data[1],
          userInfo: state.session.userInfo!,
        });
      } catch (error) {
        dispatch.onBoarding.getOnBoardingProcessError({ error: getErrorFromApi(error) });
      }
    },
    async getInitialGalleriesInfoAsync() {
      const galleriesInfo = await api.onBoarding.getInitialGalleriesInfo();
      dispatch.onBoarding.setInitialGalleryInfo(galleriesInfo);
    },
    async completeOnBoardingAsync(_: void, state) {
      const data = await Promise.all([
        api.onBoarding.completeOnBoarding(state.session.userInfo!.id),
        pushUploadPhotosGtmEvent(),
      ]);

      clearSitesGenerationData();

      const userInfo = data[0];
      if (userInfo) {
        await dispatch.session.updateUserInfoAsync({ userInfo, updateSegment: false });
      }
    },
  }),
});

const getShootTypes = (steps: IStep[]) => {
  return steps.find((s) => s.id === OnboardingStep.ShootTypes)!.stepAnswers.map((sa) => sa.answerOptionId);
};

const getValidPrimaryShootType = (steps: IStep[]) => {
  const shootTypes = getShootTypes(steps);
  const primaryShootTypeId =
    steps.find((s) => s.id === OnboardingStep.PrimaryShootType)?.stepAnswers[0]?.answerOptionId || "";

  return shootTypes.includes(primaryShootTypeId) ? primaryShootTypeId : "";
};

const pushUploadPhotosGtmEvent = async () => {
  const uploadPhotosInfo = getUploadPhotosInfo();
  if (!uploadPhotosInfo) {
    return;
  }

  removeUploadPhotosInfo();

  const currentTime = moment().valueOf();

  const detail = {
    count_of_photos_selected: uploadPhotosInfo.photosSelected.toString(),
    count_of_photos_uploaded: uploadPhotosInfo.photosUploaded.toString(),
    method: uploadPhotosInfo.method,
    complete_step_time_msec: (currentTime - uploadPhotosInfo.startedAt).toString(),
    upload_time_msec: ((uploadPhotosInfo.finishedAt || currentTime) - uploadPhotosInfo.startedAt).toString(),
  };
  const data = detail;
  await pushGtmEventAsync({
    category: GtmEventCategory.MobileOnBoarding,
    name: "upload photos",
    detail,
    ...data,
  });
  OnBoardingSegment.uploadPhotos(data);
};

const initSitesGenerationStartedAt = (state: IOnBoardingState): IOnBoardingState => {
  if (state.sites && state.sitesGenerationStartedAt === 0) {
    state.sitesGenerationStartedAt = getSitesGenerationDataValue("startedAt");
  }

  return state;
};
