import { createModel } from "@rematch/core";
import api from "../../api";
import { GalleryRecentBy } from "../../models/galleries";
import { RECENT_GALLERIES_INITIAL_LOAD_SIZE } from "../../utilities/constants";
import { getErrorFromApi } from "../common";
import { getGenericReducers } from "../generic";
import { RootModel } from "../store";
import {
  IGalleriesState,
  IGetCountersInput,
  IGetCountersResult,
  IGetRecentGalleriesByTypeInput,
  IGetRecentGalleriesByTypeResult,
  IGetRecentGalleriesInput,
  IGetRecentGalleriesResult,
  initialState,
  IPaginationQuery,
} from "./models";

export const galleries = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...getGenericReducers("getRecentGalleries")<IGalleriesState, IGetRecentGalleriesInput, IGetRecentGalleriesResult>({
      success(_state, payload) {
        return payload;
      },
    }),
    ...getGenericReducers("getRecentGalleriesByType")<
      IGalleriesState,
      IGetRecentGalleriesByTypeInput,
      IGetRecentGalleriesByTypeResult
    >({
      success(state, payload) {
        const { galleries, type } = payload;
        const propertyName = type === GalleryRecentBy.DateModified ? "recentlyModifiedGalleries" : "activeGalleries";
        const currentGalleries = state[propertyName]!;

        return {
          [propertyName]: {
            ...currentGalleries,
            ...galleries,
            galleries: [...currentGalleries.galleries, ...galleries.galleries],
          },
        };
      },
    }),
    ...getGenericReducers("getCounters")<IGalleriesState, IGetCountersInput, IGetCountersResult>(),
  },
  effects: (dispatch) => ({
    async getRecentGalleriesAsync() {
      const query: IPaginationQuery = {
        skip: 0,
        take: RECENT_GALLERIES_INITIAL_LOAD_SIZE,
      };

      dispatch.galleries.getRecentGalleriesStarted({ query });
      try {
        const [recentlyModifiedGalleries, activeGalleries] = await Promise.all([
          api.galleries.getRecentGalleries({
            ...query,
            type: GalleryRecentBy.DateModified,
          }),
          api.galleries.getRecentGalleries({
            ...query,
            type: GalleryRecentBy.LastActivityDate,
          }),
        ]);
        dispatch.galleries.getRecentGalleriesSuccess({ query, recentlyModifiedGalleries, activeGalleries });
      } catch (error) {
        dispatch.galleries.getRecentGalleriesError({ query, error: getErrorFromApi(error) });
      }
    },

    async getRecentGalleriesByTypeAsync(payload: IGetRecentGalleriesByTypeInput) {
      dispatch.galleries.getRecentGalleriesByTypeStarted(payload);
      try {
        const galleries = await api.galleries.getRecentGalleries({
          ...payload.query,
          type: payload.type,
        });
        dispatch.galleries.getRecentGalleriesByTypeSuccess({ ...payload, galleries });
      } catch (error) {
        dispatch.galleries.getRecentGalleriesByTypeError({ ...payload, error: getErrorFromApi(error) });
      }
    },

    async getCountersAsync() {
      dispatch.galleries.getCountersStarted({});
      try {
        const counters = await api.galleries.getCounters();
        dispatch.galleries.getCountersSuccess({ counters });
      } catch (error) {
        dispatch.galleries.getCountersError({ error: getErrorFromApi(error) });
      }
    },
  }),
});
