import { Utilities } from "@zenfolio/core-components";
import { validateTokens } from "./token";

export default abstract class NewRelic {
  public static readonly isEnabled = process.env.REACT_APP_NEW_RELIC_ENABLED === "true";

  public static init() {
    if (!NewRelic.isEnabled) {
      return;
    }

    NewRelic.refreshUserId();

    Utilities.fixNewRelicRequestSize();
  }

  public static refreshUserId() {
    if (NewRelic.isEnabled) {
      newrelic.setUserId(validateTokens(false) || null);
    }
  }

  public static logError(error: Error, customAttributes?: Record<string, string | number>) {
    if (NewRelic.isEnabled) {
      newrelic.noticeError(error, customAttributes);
    }
  }
}
