import { IPoint, ISize } from "./common";

// Models for detailed single gallery experience.

export interface IGalleryName {
  id: string;
  name: string;
}

export interface IGalleryUrlTemplates {
  photoUrlTemplate: string;
  videoUrlTemplate: string;
  thumbnailUrlTemplate: string;
}

export const emptyGalleryUrlTemplates: IGalleryUrlTemplates = {
  photoUrlTemplate: "",
  videoUrlTemplate: "",
  thumbnailUrlTemplate: "",
};

export interface IGallery extends IGalleryName, IGalleryUrlTemplates {
  dateCreated: Date;
  shootDate?: Date;
  cover?: GalleryCoverMedia;
  medias: GalleryMedia[];
  collections: IGalleryCollection[];
  shareEnabled: boolean;
  active: boolean;
  expirationDate?: Date;
  alias?: string;
  details?: IGalleryDetails;
}

export type GalleryMedia = IGalleryPhoto | IGalleryVideo;

export type GalleryCoverMedia = GalleryMedia & IFocusable;

export interface IFocusable {
  focalPoint: IPoint; // 0-100
}

export const defaultFocalPoint: IPoint = { x: 50, y: 50 };

export type GalleryMediaType = "photo" | "video";

export interface IGalleryMediaDescriptor {
  type: GalleryMediaType;
  id: string;
}

export interface IGalleryCoverMediaDescriptor extends IGalleryMediaDescriptor, IFocusable {}

export interface IGalleryMedia extends IGalleryMediaDescriptor {
  dateCreated: Date;
  name: string;
  file: IGalleryMediaFile;
  title?: string;
  version: number;
  urlTemplate: string;
  error?: string;
}

export interface IGalleryMediaFile {
  name: string;
  size: number;
}

export interface IGalleryPhoto extends IGalleryMedia {
  type: "photo";
  size?: ISize;
  watermarkVersion?: number;
}

export interface IGalleryVideo extends IGalleryMedia {
  type: "video";
  duration?: number /* in milliseconds */;
  thumbnail?: IGalleryVideoThumbnail;
}

export interface IGalleryVideoThumbnail {
  id: string;
  size: ISize;
  urlTemplate: string;
  isCustom: boolean;
}

export interface IGalleryCollection {
  id: string | null; // null is for "not in a collection"
  name: string;
  medias: IGalleryMediaDescriptor[];
}

export interface IGalleryCreator {
  parentId: string;
  name: string;
  shootDate?: Date;
  description?: string;
  tags: string[];
  photoShootType: number;
  presetId?: string;
  skipPresetWatermark: boolean;
  isGettingStartedWidget?: boolean;
  isOnBoarding?: boolean;
}

export interface ICreateGalleryResult extends IGalleryName, IGalleryUrlTemplates {}

export interface IDeleteGalleryResult {
  parentId: string;
}

export interface IGalleryUploadNotification {
  id: string;
  medias: IGalleryMediaDescriptor[];
  cover?: GalleryCoverMedia;
  updatedPhotos: IGalleryPhoto[];
  updatedVideos: IGalleryVideo[];
  collections: IGalleryCollection[];
}

export interface IGalleryDetails {
  dateCreated: Date;
  dateModified: Date;
  accessSettings: AccessSettings;
  description: string;
  shootTypeId?: number;
  tags: string[];
}

export enum AccessSettings {
  Clients = 1,
  Guests = 2,
  Visitors = 4,
}

export interface IGalleryShootType {
  id: number;
  name: string;
}

export interface IGalleryParentShootType extends IGalleryShootType {
  children?: IGalleryShootType[];
}

export interface IGalleryShootTypes {
  topShootTypes: number[];
  yourShootTypes: number[];
  allShootTypes: IGalleryParentShootType[];
}

export interface IGalleryDeleteInfo {
  lastClientActivity?: Date;
  hasScheduledEmails: boolean;
  clientsCount: number;
}
