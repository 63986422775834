import classNames from "classnames";
import * as React from "react";
import Base, { IBaseProps } from "../Base";
import Close from "../Close";
import styles from "./index.module.scss";

interface IFullScreenProps extends IBaseProps {
  header?: React.ReactNode;
  buttons?: React.ReactNode;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  closeClassName?: string;
  showClose?: boolean;
}

const FullScreen: React.FC<IFullScreenProps> = (props) => {
  const {
    header,
    buttons,
    onClose,
    children,
    className,
    headerClassName,
    contentClassName,
    footerClassName,
    closeClassName,
    showClose = true,
    ...otherProps
  } = props;

  return (
    <Base className={classNames(styles.modal, className)} onClose={onClose} {...otherProps}>
      {showClose && <Close className={closeClassName} onClick={onClose} />}
      {header && <div className={classNames(styles.header, headerClassName)}>{header}</div>}
      <div className={classNames(styles.content, contentClassName)}>{children}</div>
      {buttons && <div className={classNames(styles.footer, footerClassName)}>{buttons}</div>}
    </Base>
  );
};

export default FullScreen;
