import Segment from "../common";
import { TrackingSegmentCategories } from "../constants";
import { SegmentBookmarkEventName } from "./model";

class BookmarkSegment {
  static trackBookmarkAction(actionName: SegmentBookmarkEventName) {
    Segment.trackAction(actionName, {
      category: TrackingSegmentCategories.Bookmark,
    });
  }

  static addedToHomeScreenEvent() {
    this.trackBookmarkAction(SegmentBookmarkEventName.addedToHomeScreen);
  }

  static clickAddToHomeScreenEvent() {
    this.trackBookmarkAction(SegmentBookmarkEventName.clickAddToHomeScreen);
  }
}

export default BookmarkSegment;
